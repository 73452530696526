import SystemMeter from '@components/_shared/SystemMeter';
import MegapackList from '@components/Battery/Tesla/MegapackList';
import TeslaControllerDetails from '@components/Battery/Tesla/TeslaControllerDetails';
import { CurrentTscIdProvider } from '@hooks/useCurrentTscId';
import useMakePageScrollable from '@hooks/useMakePageScrollable';
import TeslaControllerList from '@components/Battery/Tesla/TeslaControllerList';
import React, { ReactElement } from 'react';

function TESLA(): ReactElement {
  useMakePageScrollable();
  return (
    <CurrentTscIdProvider>
      <div className='home-row'>
        <div className='fcol'>
          <SystemMeter />
          <TeslaControllerList />
        </div>
        <div className='fcol'>
          <TeslaControllerDetails />
          <MegapackList />
        </div>
      </div>
    </CurrentTscIdProvider>
  );
}

export default TESLA;
