import React, { ReactElement } from 'react';
import Unit from '@src/sld/components/Unit/Unit';
import { UnitConfig } from '@src/sld/types';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';
import Switch from '@src/sld/components/Switch/Switch';
import { getUnitConfig } from '@src/sld/sagebrush/sagebrush_b59/utils';

type UnitsProps = {
  unitConfigs: UnitConfig[];
};

// eslint-disable-next-line max-lines-per-function
function Switch2Units(props: UnitsProps): ReactElement {
  const { unitConfigs } = props;

  return (
    <g transform='translate(440,-400)'>
      <Switch
        label='89F12-2'
        isClosed={false}
        translateXCoordinate={507}
        translateYCoordinate={715}
        stroke='green'
      />

      {/* top vertical line */}
      <VerticalLine
        length={30}
        color='white'
        width={0.5}
        translateXCoordinate={507}
        translateYCoordinate={725}
      />

      {/* top horizontal line */}
      <HorizontalLine
        length={570}
        width={0.5}
        color='white'
        translateXCoordinate={215}
        translateYCoordinate={756}
      />

      {/* units */}
      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={215}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[6])}
        rectangleXCoordinate='190'
        rectangleYCoordinate='782'
        textStartingXCoordinate={195}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={125}
        lineTransformYCoordinate={757}
        skipHorizontalLine
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={310}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[7])}
        rectangleXCoordinate='270'
        rectangleYCoordinate='782'
        textStartingXCoordinate={275}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={215}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={390}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[8])}
        rectangleXCoordinate='350'
        rectangleYCoordinate='782'
        textStartingXCoordinate={355}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={280}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={465}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[9])}
        rectangleXCoordinate='430'
        rectangleYCoordinate='782'
        textStartingXCoordinate={435}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={345}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={545}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[10])}
        rectangleXCoordinate='510'
        rectangleYCoordinate='782'
        textStartingXCoordinate={515}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={410}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={625}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[11])}
        rectangleXCoordinate='590'
        rectangleYCoordinate='782'
        textStartingXCoordinate={595}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={475}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={710}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[12])}
        rectangleXCoordinate='670'
        rectangleYCoordinate='782'
        textStartingXCoordinate={675}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={540}
        lineTransformYCoordinate={757}
      />

      <VerticalLine
        length={25}
        color='white'
        width={0.5}
        translateXCoordinate={785}
        translateYCoordinate={756}
      />
      <Unit
        unitConfig={getUnitConfig(unitConfigs[12])}
        rectangleXCoordinate='750'
        rectangleYCoordinate='782'
        textStartingXCoordinate={755}
        textStartingYCoordinate={780}
        lineTransformXCoordinate={540}
        lineTransformYCoordinate={757}
      />
    </g>
  );
}

export default Switch2Units;
