import useApiMutation from '@src/hooks/useApiMutation';

export default (id?: string) => {
  const devicePath = `/devices${id ? `/${id}` : ''}`;
  const generateDevices = useApiMutation({
    apiPath: '/devices/generate',
    method: 'POST',
    useV2API: true,
  });
  const createdevice = useApiMutation({
    apiPath: devicePath,
    method: 'POST',
    useV2API: true,
  });

  const updateDevice = useApiMutation({
    apiPath: devicePath,
    method: 'PATCH',
    useV2API: true,
  });

  const deleteDevice = useApiMutation({
    apiPath: devicePath,
    method: 'DELETE',
    useV2API: true,
  });

  return { generateDevices, createdevice, updateDevice, deleteDevice };
};
