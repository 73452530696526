import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import AppLayout from '@pages/_layout/AppLayout';
import TopBar from './TopBar';
import PointConfig from './pages/PointConfig';
import Devices from './pages/Devices';
import WidgetConfig from './pages/WidgetConfig';

export default [
  {
    path: 'collections',
    element: <AppLayout isNested topBar={<TopBar />} />,
    children: [
      { index: true, element: <Navigate to='devices' replace /> },
      { path: 'devices', element: <Devices /> },
      { path: 'point-configs', element: <PointConfig /> },
      { path: 'widget-configs', element: <WidgetConfig /> },
    ],
  },
] as RouteObject[];
