import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';

export interface UseGetWidgetConfigBody {
  sourceDeviceId?: string;
  unitControllerId?: string;
}

const useGetWidgetConfig = <T>(
  widgetId: string,
  body?: UseGetWidgetConfigBody,
) =>
  useApiQuery<T>({
    queryKey: [QueryKeys['widget-config'], widgetId],
    apiPath: `/datapoints/widget/${widgetId}`,
    method: 'POST',
    body: body || {},
  });

export default useGetWidgetConfig;
