import useMakePageScrollable from '@hooks/useMakePageScrollable';
import React from 'react';
import SystemMeter from '@components/_shared/SystemMeter';
import UnitTable from '@components/_shared/UnitTable/UnitTable';
import { Outlet } from 'react-router-dom';

function UnitListPageLayout() {
  useMakePageScrollable();
  return (
    <div className='home-row'>
      <div className='fcol'>
        <SystemMeter />
        <UnitTable />
      </div>
      <div className='fcol'>
        <Outlet />
      </div>
    </div>
  );
}

export default UnitListPageLayout;
