import React, { useRef, useState } from 'react';
import '@components/Battery/SiteUnitCommandsPopup/SiteUnitCommandsPopup.scoped.scss';
import Draggable from 'react-draggable';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const styles = {
  cmdCheckboxLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 15px 15px',
    cursor: 'pointer',
    margin: 0,
  },
  cmdCheckbox: {
    padding: 0,
    marginRight: '5px',
  },
};

const ConfirmationPopup = ({
  actions,
  popupProps,
  siteMeta,
  commands,
  setCommands,
  unitTable,
  UnitName,
  itemsSelected,
  setItemsSelected,
  multiselectItems,
  setMultiselectItems,
  type,
  confirmationPopupChecklist,
  setConfirmationPopupChecklist,
  setShowConfirmationPopup,
  isSendingConverterPowerCommands,
  converterIdxStr,
  convertersReactiveArr,
  overInput,
  setOverInput,
  isConverterPowerValueChangedArr,
  convertersRealArr,
  getConvertedIdxStr,
  unitCommands,
  setMode,
}) => {
  const [unitReactive, setUnitReactive] = useState(0);
  const [unitReal, setUnitReal] = useState(0);
  const [afterSelectConfirmDlg, setAfterSelectConfirmDlg] = useState(false);
  const inputRef = useRef();

  const titleMultiselectDlg = (commandName) => {
    return `Choose ${popupProps.popupType === 'site' ? 'units' : 'racks'}\
    to execute command ${commandName ? commandName : commands[0]}.`;
  };

  const rackConfirmStr = () => {
    let str = ' for ' + (popupProps.popupType === 'site' ? 'unit' : 'rack');
    if (itemsSelected.filter((el) => !!el).length > 1) {
      str += 's';
    }
    multiselectItems.forEach((r, i) => {
      if (itemsSelected[i] && itemsSelected[i] !== 'disabled') {
        str += ` ${r},`;
      }
    });
    str = str.slice(0, -1);
    return str;
  };

  const submitPopup = () => {
    if (afterSelectConfirmDlg && confirmationPopupChecklist) {
      if (popupProps.popupType === 'site') {
        unitTable.forEach((el, i) => {
          if (itemsSelected[i] === true) {
            const comms =
              commands[0] === 'Power::'
                ? [`${commands[0]}${unitReal}::${unitReactive}`]
                : commands;
            actions.sendCommand(comms, el.SN);
          }
        });
      } else {
        actions.sendCommand(
          [
            `${commands[0]}::[${itemsSelected
              .map((el, i) => (el && el !== 'disabled' ? i : ''))
              .filter((e) => e !== '')
              .join(',')}]`,
          ],
          popupProps.sn,
          popupProps.isPV ? UnitName : null,
        );
      }
      setAfterSelectConfirmDlg(false);
      setConfirmationPopupChecklist(false);
      setShowConfirmationPopup(false);
      setCommands([]);
      setMultiselectItems([]);
      setItemsSelected([]);
    } else {
      if (confirmationPopupChecklist) {
        return;
      }
      if (!isSendingConverterPowerCommands.current) {
        const converterOrUnitName = converterIdxStr.current
          ? converterIdxStr.current
          : popupProps.isPV
            ? UnitName
            : null;
        actions.sendCommand(commands, popupProps.sn, converterOrUnitName);
        converterIdxStr.current = null;
      } else {
        convertersReactiveArr.forEach((_, i) => {
          if (isConverterPowerValueChangedArr.current[i]) {
            actions.sendCommand(
              [`Power::${convertersRealArr[i]}::${convertersReactiveArr[i]}`],
              popupProps.sn,
              `DC${getConvertedIdxStr(i)}`,
            );
          }
        });
        isSendingConverterPowerCommands.current = false;
      }
    }

    if (type === 'mode') {
      const command = commands[0];
      const commandSegments = command.split('::');

      //  command may have a 3rd segment - ::Persist
      //  suffix. So, split and grab the first
      //  element in the array (if it exists)
      if (commandSegments.length > 1) {
        setMode(commandSegments[1]);
      }
    }
  };
  const onEnter = (key) => {
    if (key === 0) {
      inputRef.current.focus();
    }
  };

  const siteCommandsIsArray = Array.isArray(siteMeta?.Site_Commands);
  const unitCommandsIsArray = Array.isArray(unitCommands);

  let allCommandsList;
  if (siteCommandsIsArray && unitCommandsIsArray) {
    allCommandsList = siteMeta?.Site_Commands.concat(unitCommands);
  }

  const commandName = allCommandsList?.find(
    (el) => el.Command === commands[0],
  )?.Name;

  return (
    <div className='cover-container cover-container-confirm'>
      {confirmationPopupChecklist && !afterSelectConfirmDlg ? (
        <Draggable bounds='body' disabled={overInput}>
          <div
            className='control-popup-container'
            style={{ padding: '20px 30px' }}
          >
            <div className='title-line' style={{ textAlign: 'center' }}>
              {titleMultiselectDlg(commandName)}
            </div>
            {multiselectItems.length !== 0 ? (
              <div className='rack-choose-list'>
                <div id='first-cell'>
                  <Button
                    disabled={!itemsSelected.includes(false)}
                    onClick={() => {
                      setItemsSelected((itemsSelected) =>
                        itemsSelected.map((el) =>
                          el === 'disabled' ? el : true,
                        ),
                      );
                    }}
                  >
                    Select All
                  </Button>
                  <Button
                    disabled={!itemsSelected.includes(true)}
                    onClick={() => {
                      setItemsSelected((itemsSelected) =>
                        itemsSelected.map((el) =>
                          el === 'disabled' ? el : false,
                        ),
                      );
                    }}
                  >
                    Unselect All
                  </Button>
                </div>
                {multiselectItems.map((r, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      style={styles.cmdCheckboxLabel}
                      disabled={itemsSelected[i] == 'disabled'}
                      control={
                        <Checkbox
                          style={styles.cmdCheckbox}
                          size='small'
                          onClick={() => {
                            const newArr = [...itemsSelected];
                            newArr[i] = !itemsSelected[i];
                            setItemsSelected(newArr);
                          }}
                          checked={
                            itemsSelected[i] !== 'disabled' && itemsSelected[i]
                          }
                        />
                      }
                      label={`${
                        popupProps.popupType === 'site' ? 'Unit' : 'Rack'
                      } ${r}`}
                    />
                  );
                })}
              </div>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                {`No ${
                  popupProps.popupType === 'site' ? 'units' : 'racks'
                } online`}
              </div>
            )}
            {commands[0] === 'Power::' && (
              <div className='popup-row' style={{ alignSelf: 'center' }}>
                <div className='bold'>Power</div>
                <div className='popup-table'>
                  <div className='popup-table-col'>
                    <div>Real (P):</div>
                    <div className='range-lable' />
                    <div>Reactive (Q):</div>
                    <div className='range-lable' />
                  </div>
                  <div className='popup-table-col'>
                    <div>
                      <input
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            onEnter(0);
                            e.preventDefault();
                          }
                        }}
                        className={'comand-popup-inp '}
                        type='number'
                        step='0.1'
                        value={unitReal}
                        onMouseEnter={() => setOverInput(true)}
                        onMouseLeave={() => setOverInput(false)}
                        onChange={(e) => setUnitReal(e.target.value)}
                      />
                    </div>
                    <div className='range-lable' />
                    <div>
                      <input
                        ref={inputRef}
                        className={'comand-popup-inp '}
                        type='number'
                        step='0.1'
                        value={unitReactive}
                        onMouseEnter={() => setOverInput(true)}
                        onMouseLeave={() => setOverInput(false)}
                        onChange={(e) => setUnitReactive(e.target.value)}
                      />
                    </div>
                    <div className='range-lable' />
                  </div>
                  <div className='popup-table-col'>
                    <div>kW</div>
                    <div className='range-lable' />
                    <div>kvar</div>
                    <div className='range-lable' />
                  </div>
                </div>
              </div>
            )}
            <div className='popup-row commands-popup-control'>
              <Button
                size='m'
                disabled={!itemsSelected.includes(true)}
                onClick={() => {
                  submitPopup();
                  setAfterSelectConfirmDlg(true);
                }}
              >
                OK
              </Button>
              <Button
                size='m'
                onClick={() => {
                  setShowConfirmationPopup(false);
                  setCommands([]);
                  setMultiselectItems([]);
                  setItemsSelected([]);
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Draggable>
      ) : (
        <Draggable bounds='body' disabled={overInput}>
          <div className='control-popup-container small-popup'>
            <div className='popup-row centre'>
              {
                <>
                  <div className='bold '>Are you sure to execute?</div>
                  <div className='normal'>
                    {commandName ? commandName : commands[0]}
                    {`${afterSelectConfirmDlg ? rackConfirmStr() : ''}`}
                  </div>
                </>
              }
            </div>
            <div className='popup-row commands-popup-control'>
              {
                <Button
                  size='m'
                  onClick={() => {
                    submitPopup();
                    setShowConfirmationPopup(false);
                  }}
                >
                  OK
                </Button>
              }
              <Button
                size='m'
                onClick={() => {
                  setShowConfirmationPopup(false);
                  setCommands([]);
                  setAfterSelectConfirmDlg(false);
                  setMultiselectItems([]);
                  setItemsSelected([]);
                  converterIdxStr.current = null;
                  isSendingConverterPowerCommands.current = false;
                }}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </Draggable>
      )}
    </div>
  );
};

export default storeConnector(ConfirmationPopup, {
  service: ['popupProps'],
  config: ['siteMeta'],
});
