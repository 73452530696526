import SimplePointDataTable from '@components/_shared/SimplePointDataTable';
import useSubscription from '@hooks/useSubscription';
import { DataPoint } from '@src/types/DataPoint';
import { MqttClient } from 'mqtt';
import React from 'react';

interface Props {
  data: DataPoint[];
  useNewClient?: MqttClient;
}

function SimplePointDataTableWithSubscribe(props: Props) {
  const dataPointsWithValues = useSubscription(props.data, props.useNewClient);
  return <SimplePointDataTable data={dataPointsWithValues} />;
}

export default SimplePointDataTableWithSubscribe;
