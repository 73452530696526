import React, { useState } from 'react';
import './SiteUnitCommandsPopup.scoped.scss';
import Input from '@components/_elements/Input/Input';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';
import { tsDueTimeMode } from '@utils/index_ts';

const SiteUnitComandsPopup = ({
  timeMode,
  siteMeta,
  loto,
  setOverInput,
  lotoReleaseNotes,
  setLotoReleaseNotes,
  setLotoReleaseConfirm,
}) => {
  const [lotoReleaseActive, setLotoReleaseActive] = useState(false);

  const getCurrentTime = (ts) => {
    return tsDueTimeMode({
      timeMode,
      lat: siteMeta.GPSLat,
      long: siteMeta.GPSLong,
      ts,
    });
  };

  return (
    <div className='release-container'>
      {!lotoReleaseActive ? (
        <Button onClick={() => setLotoReleaseActive(true)}>
          Release Controls Lock
        </Button>
      ) : (
        <>
          <div className='loto-release-title'>Release Controls Lock</div>
          <div className='loto-release-info'>
            <div>Lockout Start:</div>
            <div>{loto.TS ? getCurrentTime(loto.TS) : ''}</div>
          </div>
          <div className='loto-release-info'>
            <div>Operator:</div>
            <div>{loto.Operator}</div>
          </div>
          <div className='loto-release-info'>
            <div>Notes:</div>
            <div>{loto.Note}</div>
          </div>
          <div className='loto-release-info'>
            <div>Release Notes:</div>
            <Input
              type='text'
              onMouseEnter={() => setOverInput(true)}
              onMouseLeave={() => setOverInput(false)}
              value={lotoReleaseNotes}
              onChange={(val) => setLotoReleaseNotes(val)}
              placeholder={'Enter reason'}
            />
          </div>
          <div
            className='popup-row commands-popup-control'
            style={{ marginTop: '50px' }}
          >
            <Button
              disabled={!lotoReleaseNotes}
              onClick={() => setLotoReleaseConfirm(true)}
            >
              UNLOCK CONTROLS
            </Button>
            <Button size='m' onClick={() => setLotoReleaseActive(false)}>
              CANCEL
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default storeConnector(SiteUnitComandsPopup, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
