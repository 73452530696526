import moment from 'moment/moment';
import { store } from '@store';

const CHECK_AUTOLOGOUT_INTERVAL = 1000 * 3;

declare global {
  interface Window {
    timerActivityHandler: number;
  }
}

const activityHandler = (username?: string) => {
  if (
    window.timerActivityHandler !== undefined &&
    window.timerActivityHandler !== null
  ) {
    clearTimeout(window.timerActivityHandler);
  }
  if (username) {
    window.timerActivityHandler = window.setTimeout(() => {
      const currDate = moment().unix();
      localStorage.setItem('INACTIVITY_LOGOUT_DATE_PREV', String(currDate));
    }, CHECK_AUTOLOGOUT_INTERVAL);
  }
};

export default async function prepHtmlDocument() {
  const state = store.getState();
  const { username } = state.user;
  const { siteMeta } = state.config;
  const { darkTheme } = state.service;

  document.title = `Fractal EMS${
    siteMeta.SiteName ? ` - ${siteMeta.SiteName}` : ''
  }`;

  document
    .querySelector('body')!
    .classList.add(`theme--${!darkTheme ? 'light' : 'dark'}`);

  // Hide main loading spinner
  document.getElementById('ems-preload-spinner-container')?.remove();
  document.body.removeAttribute('loading');

  window.onresize = () => {
    window.scrollTo(0, 0);
  };

  document.onmousemove = () => activityHandler(username);
  document.ontouchmove = () => activityHandler(username);
  document.onclick = () => activityHandler(username);
}
