import React, { useEffect, useRef, useState } from 'react';
import Spinner from '@components/_elements/Spinner/Spinner';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { useInterval } from '@utils';
import RackDetailsPopupButton from './RackDetailsPopupButton';
import { COMMON_BATTERY_FIELDS, getRackFields } from './constants.js';
import storeConnector from '@store/storeConnector';
import getTemperatureString from '@src/utils/getTemperatureString';
import getAverageValue from './getAverageValue';

const RackContainer_LG = ({
  actions,
  siteMeta,
  currentUnitDeviceId,
  battType,
  fromPV,
}) => {
  const [currHBInterval, setCurrHBInterval] = useState(0);
  const [prevHB, setPrevHB] = useState(0);
  const [currCounterInterval, setCurrCounterInterval] = useState(0);
  const [prevCounter, setPrevCounter] = useState(0);
  const [rackRt, setRackRt] = useState({});
  const [batteryRt, setBatteryRt] = useState({});
  const [rackAlarmMap, setRackAlarmMap] = useState([]);
  const rackTableRt = useRef({});
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  useEffect(() => {
    setBatteryRt({});
    setRackRt(rackTableRt.current[currentUnitDeviceId] ?? {});
  }, [currentUnitDeviceId]);

  useWsSubscribe(
    {
      bms: {
        sns: [currentUnitDeviceId],
        fields: COMMON_BATTERY_FIELDS,
        cb: (data) => setBatteryRt((prev) => ({ ...prev, ...data })),
      },
      rack: {
        sns: siteMeta.Units,
        fields: getRackFields(battType),
        cb: (data, sn) => {
          let parsedData = {};
          for (let [key, value] of Object.entries(data)) {
            if (typeof value === 'string' && value.includes('[')) {
              try {
                value = JSON.parse(value);
              } catch {
                value = value.replace(/(\[|\])/g, '').split(',');
              }
            }
            parsedData[key] = value;
          }
          if (rackTableRt.current[sn]) {
            Object.assign(rackTableRt.current[sn], parsedData);
          } else {
            rackTableRt.current[sn] = parsedData;
          }
          if (sn === currentUnitSNRef.current) {
            setRackRt({ ...rackTableRt.current[sn] });
          }
        },
      },
    },
    [currentUnitDeviceId],
  );

  useInterval(() => {
    if (batteryRt?.SysHB) {
      if (batteryRt.SysHB !== prevHB) {
        setCurrHBInterval(0);
        setPrevHB(batteryRt.SysHB);
        setCurrCounterInterval(0);
      } else {
        setCurrHBInterval((currHBInterval) => currHBInterval + 1);
        setCurrCounterInterval(0);
      }
    } else {
      setCurrHBInterval(0);
      setPrevHB(0);
      if (batteryRt?.Counter && batteryRt.Counter !== prevCounter) {
        setCurrCounterInterval(0);
        setPrevCounter(batteryRt.Counter);
      } else {
        setCurrCounterInterval(
          (currCounterInterval) => currCounterInterval + 1,
        );
      }
    }
  }, 1000);

  useEffect(() => {
    actions.getRackAlarmMapConfig().then((res) => setRackAlarmMap(res));
  }, []);

  const formatValue = (val, toFixed) => {
    if ([undefined, null, NaN].includes(val) || (!+val && +val !== 0)) {
      return '-';
    }
    return toFixed ? (+val).toFixed(toFixed) : +val;
  };

  const getRackName = (index) => {
    const { RackNames, Units } = siteMeta;
    if (RackNames) {
      const indexBefore = Units.reduce(
        (acc, cv) => {
          if (acc[1]) {
            return acc;
          }
          if (currentUnitDeviceId === cv) {
            return [acc[0] + index, true];
          }
          const rack = rackTableRt.current[cv];
          const arrField = Object.keys(rack).find((r) =>
            Array.isArray(rack[r]),
          );
          const rackCount = rack[arrField]?.length || 0;
          return [acc[0] + rackCount, false];
        },
        [0, false],
      )[0];
      return RackNames[indexBefore];
    } else {
      return (index < 9 ? '0' : '') + (index + 1);
    }
  };

  const getRackArr = () => {
    const { RackWarningCode, RackAlarmCode } = rackRt || {};
    let racksArr = false;
    const fault1 = rackRt.RackFaultCode;
    const fault2 = rackRt.OtherFaultCode;
    const fieldsArray = [
      'MinCellV',
      'MaxCellV',
      'MinCellT',
      'MaxCellT',
      'RackMode',
      'RackVoltage',
      'RackCurrent',
      'RackSOC',
      'RackSOH',
      'RackFaultCode',
      'OtherFaultCode',
    ];
    const field = fieldsArray.find(
      (f) => rackRt && rackRt[f] && rackRt[f].length,
    );

    if (!field) {
      return [];
    }
    racksArr = rackRt[field].map((_e, index) => {
      let minCellV = rackRt.MinCellV?.[index];
      let maxCellV = rackRt.MaxCellV?.[index];
      let minCellT = rackRt.MinCellT?.[index];
      let maxCellT = rackRt.MaxCellT?.[index];
      return {
        name: getRackName(index),
        mode: rackRt.RackMode?.[index],
        minCellV: formatValue(minCellV, 3),
        maxCellV: formatValue(maxCellV, 3),
        deltaV: formatValue(maxCellV - minCellV, 3),
        minCellT: formatValue(minCellT),
        maxCellT: formatValue(maxCellT),
        deltaT: formatValue(maxCellT - minCellT),
        rackVoltage: formatValue(rackRt.RackVoltage?.[index], 1),
        rackCurrent: formatValue(rackRt.RackCurrent?.[index], 1),
        SOC: formatValue(rackRt.RackSOC?.[index], 1),
        SOH: formatValue(rackRt.RackSOH?.[index], 1),
        Fault1: fault1 ? formatValue(fault1[index]) : '-',
        Fault2: fault2 ? formatValue(fault2[index]) : '-',
        RackWarningCode: formatValue(RackWarningCode?.[index]),
        RackAlarmCode: formatValue(RackAlarmCode?.[index]),
      };
    });
    return racksArr;
  };

  const getInfoDict = () => {
    const {
      Thresh_CellTMax,
      Thresh_CellTMin,
      Thresh_CellVHigh,
      Thresh_CellVLow,
      Thresh_DeltaCellT,
      Thresh_HB_BMS_TimeOut,
      Thresh_LowerSOC,
      Thresh_UpperSOC,
      Thresh_LowerSOH,
    } = siteMeta ? siteMeta : {};
    const infoDict = [
      {
        field: 'Power (kW)',
        value: formatValue(
          (batteryRt.SysVoltage * batteryRt.SysCurrent) / 1000,
          1,
        ),
        loading:
          isBatteryLoading('SysVoltage') || isBatteryLoading('SysCurrent'),
      },
      {
        field: 'System Voltage (V)',
        value: batteryRt.SysVoltage,
        loading: isBatteryLoading('SysVoltage'),
        expectedSize: 5,
      },
      {
        field: 'Average Cell V',
        value:
          batteryRt.AvgCellV ??
          getAverageValue(rackRt.AvgCellVoltageValue)?.toFixed(3),
        loading:
          isBatteryLoading('AvgCellV') && isRackLoading('AvgCellVoltageValue'),
        expectedSize: 4,
      },
      {
        field: 'Average Cell T',
        value: getTemperatureString(
          batteryRt.AvgCellT ?? getAverageValue(rackRt.AvgCellTemp),
        ),
        loading: isBatteryLoading('AvgCellT') && isRackLoading('AvgCellTemp'),
      },
      {
        field: 'System SOC',
        value: batteryRt.SysSOC,
        class:
          (+batteryRt.SysSOC > +Thresh_UpperSOC ? 'red-txt' : '') +
          (+batteryRt.SysSOC < +Thresh_LowerSOC ? 'blue-text' : ''),
        loading: isBatteryLoading('SysSOC'),
      },
      {
        field: 'System Current (A)',
        value: batteryRt.SysCurrent,
        loading: isBatteryLoading('SysCurrent'),
      },
      {
        field: 'Max Cell V',
        value: batteryRt.MaxCellV,
        class: +batteryRt.MaxCellV > +Thresh_CellVHigh ? 'red-txt' : '',
        loading: isBatteryLoading('MaxCellV'),
        expectedSize: 4,
      },
      {
        field: 'Max Cell T',
        value: getTemperatureString(batteryRt.MaxCellT),
        class: +batteryRt.MaxCellT > +Thresh_CellTMax ? 'red-txt' : '',
        loading: isBatteryLoading('MaxCellT'),
      },
      {
        field: 'System SOH',
        value: batteryRt.SysSOH,
        class: +batteryRt.SysSOH < +Thresh_LowerSOH ? 'red-txt' : '',
        loading: isBatteryLoading('SysSOH'),
      },
      {
        field: 'Chrg Power Lim',
        value: batteryRt.ChaCurrLimit,
        loading: isBatteryLoading('ChaCurrLimit'),
        expectedSize: 5,
      },
      {
        field: 'Min Cell V',
        value: batteryRt.MinCellV,
        class: +batteryRt.MinCellV < +Thresh_CellVLow ? 'blue-text' : '',
        loading: isBatteryLoading('MinCellV'),
        expectedSize: 3,
      },
      {
        field: 'Min Cell T',
        value: getTemperatureString(batteryRt.MinCellT),
        class: +batteryRt.MinCellT < +Thresh_CellTMin ? 'blue-text' : '',
        loading: isBatteryLoading('MinCellT'),
      },
      {
        field: 'BMS Heartbeat',
        value: batteryRt.SysHB,
        class: currHBInterval > +Thresh_HB_BMS_TimeOut ? 'red-txt' : '',
        loading: isBatteryLoading('SysHB'),
        expectedSize: 7,
      },
      {
        field: 'DisChrg Power Lim',
        value: batteryRt.DischCurrLimit,
        loading: isBatteryLoading('DischCurrLimit'),
        expectedSize: 5,
      },
      {
        field: 'Delta Cell V',
        value: formatValue(batteryRt.MaxCellV - batteryRt.MinCellV, 3),
        loading: isBatteryLoading('MaxCellV') || isBatteryLoading('MinCellV'),
        expectedSize: 4,
      },
      {
        field: 'Delta Cell T',
        value: getTemperatureString(batteryRt.MaxCellT - batteryRt.MinCellT),
        class:
          batteryRt.MaxCellT - batteryRt.MinCellT > +Thresh_DeltaCellT
            ? 'red-txt'
            : '',
        loading: isBatteryLoading('MaxCellT') || isBatteryLoading('MinCellT'),
      },
    ];
    return infoDict;
  };

  const isBatteryLoading = (field) => {
    return !Object.keys(batteryRt).includes(field);
  };

  const isRackLoading = (field) => {
    return !Object.keys(rackRt).includes(field);
  };

  const getAlarms = (fault) => {
    return [...fault.toString(2)].reverse().reduce((acc, cv, i) => {
      if (cv === '1' && rackAlarmMap[i]) {
        acc.push(<div key={i}>{rackAlarmMap[i]}</div>);
      }
      return acc;
    }, []);
  };

  const racksArr = getRackArr();
  const {
    Thresh_CellTMax,
    Thresh_CellTMin,
    Thresh_CellVHigh,
    Thresh_CellVLow,
    Thresh_DeltaCellT,
    Thresh_DeltaV,
    Thresh_LowerSOC,
    Thresh_UpperSOC,
    Thresh_LowerSOH,
    Thresh_HB_BMS_TimeOut,
  } = siteMeta ? siteMeta : {};
  const isContStIsGray =
    +Thresh_HB_BMS_TimeOut < currHBInterval ||
    +Thresh_HB_BMS_TimeOut < currCounterInterval;
  let dscr = batteryRt?.Description || '';
  if (dscr) {
    dscr = ` - ${dscr}`;
  }
  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell'>
        <div className='unit-info'>
          <div className='unit-col' style={{ maxHeight: '100%' }}>
            <div className='title-large'>
              {`${unitName && unitName + ' '}System BMS${dscr || ''}`}
            </div>
            {getInfoDict() === null || !getInfoDict().length ? (
              <div className='bms-placeholder'> NO DATA</div>
            ) : (
              <>
                <div className='info-grid bms'>
                  {getInfoDict().map((f, i) => (
                    <React.Fragment key={i}>
                      <div className='title'>{f.field}</div>
                      <div className={f.class}>
                        <TextLoader
                          orientation='l'
                          loading={f.loading}
                          expectedSize={f.expectedSize}
                        >
                          {f.value}
                        </TextLoader>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}
            <div className='rack-table-container'>
              {racksArr && racksArr.length ? (
                <table className='rack-table'>
                  <thead>
                    <tr>
                      <th>Rack #</th>
                      <th>Contactor Status</th>
                      <th>SOC</th>
                      <th>Rack Voltage</th>
                      <th>Rack Current</th>
                      <th>Min Cell V</th>
                      <th>Max Cell V</th>
                      <th>ΔV</th>
                      <th>Min Cell T</th>
                      <th>Max Cell T</th>
                      <th>ΔT</th>
                      <th>SOH</th>
                      <th>Alarm1</th>
                      <th>Alarm2</th>
                    </tr>
                  </thead>
                  <tbody>
                    {racksArr.map((rack, i) => (
                      <tr key={i + 1}>
                        <td>
                          <RackDetailsPopupButton rack={rack} rackRt={rackRt} />
                        </td>
                        <td>
                          <span
                            className={
                              'circle ' +
                              (isContStIsGray
                                ? 'gray'
                                : rack.mode === 0
                                  ? 'green'
                                  : 'red')
                            }
                          />
                        </td>
                        <td
                          className={`${
                            +rack.SOC > +Thresh_UpperSOC ? 'red-txt' : ''
                          } ${+rack.SOC < +Thresh_LowerSOC ? 'blue-text' : ''}`}
                        >
                          <TextLoader
                            after='%'
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('RackSOC')}
                          >
                            {rack.SOC}
                            {rack.SOC !== '-' ? '%' : ''}
                          </TextLoader>
                        </td>
                        <td>
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('RackVoltage')}
                          >
                            {rack.rackVoltage}
                          </TextLoader>
                        </td>
                        <td>
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('RackCurrent')}
                          >
                            {rack.rackCurrent}
                          </TextLoader>
                        </td>
                        <td
                          className={`${
                            +rack.minCellV < +Thresh_CellVLow ? 'blue-text' : ''
                          }`}
                        >
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('MinCellV')}
                          >
                            {rack.minCellV}
                          </TextLoader>
                        </td>
                        <td
                          className={`${
                            +rack.maxCellV > +Thresh_CellVHigh ? 'red-txt' : ''
                          }`}
                        >
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('MaxCellV')}
                          >
                            {rack.maxCellV}
                          </TextLoader>
                        </td>
                        <td
                          className={`${
                            +rack.deltaV > +Thresh_DeltaV ? 'red-txt' : ''
                          }`}
                        >
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={
                              isRackLoading('MaxCellV') ||
                              isRackLoading('MinCellV')
                            }
                          >
                            {rack.deltaV}
                          </TextLoader>
                        </td>
                        <td
                          className={`${
                            +rack.minCellT < +Thresh_CellTMin ? 'blue-text' : ''
                          }`}
                        >
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('MinCellT')}
                          >
                            {rack.minCellT}
                          </TextLoader>
                        </td>
                        <td
                          className={`${
                            +rack.maxCellT > +Thresh_CellTMax ? 'red-txt' : ''
                          }`}
                        >
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('MaxCellT')}
                          >
                            {rack.maxCellT}
                          </TextLoader>
                        </td>
                        <td
                          className={`${
                            +rack.deltaT >= +Thresh_DeltaCellT ? 'red-txt' : ''
                          }`}
                        >
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={
                              isRackLoading('MaxCellT') ||
                              isRackLoading('MinCellT')
                            }
                          >
                            {rack.deltaT
                              ? getTemperatureString(+rack.deltaT)
                              : '-'}
                          </TextLoader>
                        </td>
                        <td
                          className={`${
                            +rack.SOH < +Thresh_LowerSOH ? 'red-txt' : ''
                          }`}
                        >
                          <TextLoader
                            orientation='c'
                            fontSise={12}
                            loading={isRackLoading('RackSOH')}
                          >
                            {rack.SOH}
                          </TextLoader>
                        </td>
                        <td>
                          <div
                            className={`alarms-box${
                              +rack.Fault1 >= 1 ? ' red-txt' : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={isRackLoading('RackFaultCode')}
                            >
                              {rack.Fault1}
                              {+rack.Fault1 >= 1 && (
                                <span className='alarms-box-text'>
                                  {getAlarms(rack.Fault1)}
                                </span>
                              )}
                            </TextLoader>
                          </div>
                        </td>
                        <td>
                          <div
                            className={`alarms-box${
                              +rack.Fault2 >= 1 ? ' red-txt' : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={isRackLoading('OtherFaultCode')}
                            >
                              {rack.Fault2}
                              {+rack.Fault2 >= 1 && (
                                <span className='alarms-box-text'>
                                  {getAlarms(rack.Fault2)}
                                </span>
                              )}
                            </TextLoader>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='cell' style={{ minHeight: '130px' }}>
                  <Spinner cover='container' />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(RackContainer_LG, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
