import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';

interface BearCanyonWestFordPointsWithValues {
  breaker_52_m1_dataPointValue: string | number | null | undefined;
  breaker_52_f1_dataPointValue: string | number | null | undefined;
  breaker_52_f2_dataPointValue: string | number | null | undefined;
  breaker_52_f3_dataPointValue: string | number | null | undefined;
  breaker_52_f4_dataPointValue: string | number | null | undefined;
  voltage: string | number | null | undefined;
  current: string | number | null | undefined;
  activePower: string | number | null | undefined;
  reactivePower: string | number | null | undefined;
}

const createBearCanyonWestFordFlatDiagramPropertiesFromDataPoints = (
  dataPointsWithValue: DataPointWithValue[],
): BearCanyonWestFordPointsWithValues => {
  const diagramDataPointsWithValues: BearCanyonWestFordPointsWithValues = {
    breaker_52_m1_dataPointValue: null,
    breaker_52_f1_dataPointValue: null,
    breaker_52_f2_dataPointValue: null,
    breaker_52_f3_dataPointValue: null,
    breaker_52_f4_dataPointValue: null,
    voltage: null,
    current: null,
    activePower: null,
    reactivePower: null,
  };

  dataPointsWithValue.forEach((dataPoint: DataPointWithValue): void => {
    const { pointName, value } = dataPoint;

    switch (pointName) {
      case 'msg_52_m1_52a':
        diagramDataPointsWithValues.breaker_52_m1_dataPointValue = value;
        break;
      case 'msg_52_f1_52a':
        diagramDataPointsWithValues.breaker_52_f1_dataPointValue = value;
        break;
      case 'msg_52_f2_52a':
        diagramDataPointsWithValues.breaker_52_f2_dataPointValue = value;
        break;
      case 'msg_52_f3_52a':
        diagramDataPointsWithValues.breaker_52_f3_dataPointValue = value;
        break;
      case 'msg_52_f4_52a':
        diagramDataPointsWithValues.breaker_52_f4_dataPointValue = value;
        break;
      case 'll_average_volts':
        diagramDataPointsWithValues.voltage = value;
        break;
      case 'a_phase_amps':
        diagramDataPointsWithValues.current = value;
        break;
      case 'mw_comp':
        diagramDataPointsWithValues.activePower = value;
        break;
      case 'mvar_comp':
        diagramDataPointsWithValues.reactivePower = value;
        break;
      default:
        break;
    }
  });

  return diagramDataPointsWithValues;
};

interface PointsSearchBody {
  category: string;
  pointName: string;
  sourceDeviceId: string;
}

const bearCanyonWestFordFlatsSearDataPoints: PointsSearchBody[] = [
  { category: 'rtac', pointName: 'msg_52_m1_52a', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'msg_52_f1_52a', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'msg_52_f2_52a', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'msg_52_f3_52a', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'msg_52_f4_52a', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'll_average_volts', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'a_phase_amps', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'mw_comp', sourceDeviceId: 'rtac_1' },
  { category: 'rtac', pointName: 'mvar_comp', sourceDeviceId: 'rtac_1' },
];

const getBearCanyonWestFordPointsForSearchBody = (): PointsSearchBody[] =>
  bearCanyonWestFordFlatsSearDataPoints;

const prepareSubscriptionDataPointArray = (
  dataPoints: DataPoint[] | undefined,
): DataPoint[] =>
  dataPoints !== undefined ? dataPoints : new Array<DataPoint>();

export {
  getBearCanyonWestFordPointsForSearchBody,
  prepareSubscriptionDataPointArray,
  createBearCanyonWestFordFlatDiagramPropertiesFromDataPoints,
};
export type { PointsSearchBody, BearCanyonWestFordPointsWithValues };
