import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import { SavedSearch } from '@types';
import React, { ReactElement } from 'react';
import {
  DATA_POINT_LENGTH_LIMIT,
  dataPointDictInit,
} from '@components/Home/Historian/historianUtils';
import Button from '@components/_elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface AddDataPointButtonProps {
  activeSearch: SavedSearch;
  searchUnderEdit: SavedSearch | undefined;
  setActiveSearch: React.Dispatch<SavedSearch>;
  siteMeta: SiteMeta;
}

function AddDataPointButton(props: AddDataPointButtonProps): ReactElement {
  const { activeSearch, searchUnderEdit, setActiveSearch, siteMeta } = props;

  const initialDataPointObj = dataPointDictInit;
  initialDataPointObj.siteId = siteMeta.siteId;
  const addDataPoint = () => {
    setActiveSearch({
      ...activeSearch,
      dataPoints: [...activeSearch.dataPoints, { ...initialDataPointObj }],
    });
  };
  const disabled = DATA_POINT_LENGTH_LIMIT <= activeSearch.dataPoints.length;
  return (
    <div
      className={`add-item ${!searchUnderEdit && activeSearch.id && 'dis'}`}
      key='add_dp_btn'
    >
      <Button
        disabled={disabled}
        className='round-active-color with-text'
        title='Add data point'
        onClick={addDataPoint}
      >
        Add Data Point <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
}

export default storeConnector(AddDataPointButton, {
  config: ['siteMeta'],
});
