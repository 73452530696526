import HistorianData from '@pages/Home/pages/Historian/pages/Data';
import HistorianDump from '@pages/Home/pages/Historian/pages/Dump';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import AppLayout from '@pages/_layout/AppLayout';
import TopBar from './TopBar';

export default [
  {
    path: 'historian',
    element: <AppLayout isNested topBar={<TopBar />} />,
    children: [
      { index: true, element: <Navigate to='data' replace /> },
      { path: 'data', element: <HistorianData /> },
      { path: 'export', element: <HistorianDump /> },
    ],
  },
] as RouteObject[];
