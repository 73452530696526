/**
 * Sorts an array of objects by a specified date property in descending order.
 *
 * @param {Array<T>} items - The array of objects to be sorted.
 * @param {keyof T} dateProperty - The name of the property containing the date string.
 * @returns {Array<T>} The sorted array.
 */
export default function sortByDateDescending<T>(
  items: T[],
  dateProperty: keyof T,
): T[] {
  return items.slice().sort((a, b) => {
    const dateA = new Date(a[dateProperty] as string);
    const dateB = new Date(b[dateProperty] as string);

    return dateB.getTime() - dateA.getTime(); // Sort descending (most recent first)
  });
}
