import useApiMutation from '@src/hooks/useApiMutation';

export default (id?: string) => {
  const alertsBasePath = '/alerts/alert-configs';

  const alertPath = `${alertsBasePath}${id ? `/${id}` : ''}`;

  const createAlert = useApiMutation({
    apiPath: alertsBasePath,
    method: 'POST',
  });

  const createBulkAlert = useApiMutation({
    apiPath: `${alertsBasePath}/import-file`,
    method: 'POST',
    isFileUpload: true,
    headers: {},
  });

  const updateAlert = useApiMutation({
    apiPath: alertPath,
    method: 'PATCH',
  });

  const deleteAlert = useApiMutation({
    apiPath: alertPath,
    method: 'DELETE',
  });
  const bulkDeleteAlerts = useApiMutation({
    apiPath: `${alertsBasePath}/bulk-delete`,
    method: 'POST',
  });

  return {
    createAlert,
    createBulkAlert,
    updateAlert,
    deleteAlert,
    bulkDeleteAlerts,
  };
};
