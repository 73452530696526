import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import {
  DataPoint,
  QueryDataPointsSearchBodyElement,
} from '@src/types/DataPoint';

const useDataPointsSearch = (
  key: string,
  body: QueryDataPointsSearchBodyElement[],
) =>
  useApiQuery<DataPoint[]>({
    queryKey: [key, QueryKeys['datapoints-search']],
    apiPath: '/datapoints/search',
    method: 'POST',
    body,
  });

export default useDataPointsSearch;
