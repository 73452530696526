import React from 'react';
import camelCase from 'camelcase';
import type { SiteMeta } from '@src/types/SiteMeta';
import type {
  TeslaConfigItem,
  TransformedTeslaConfigItem,
} from '@src/types/TeslaConfig';
import { CommandsCategory } from '@src/types/Commands';
import storeConnector from '@store/storeConnector';
import { CommandsModalContextProvider } from '@src/components/Battery/Tesla/Commands/CommandsModalContext';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import './Modes.scoped.scss';
import TSCRow from '@src/components/Home/Config/Tesla/TSCRow';
import tsc_config from '@src/components/Home/Config/Tesla/TSCMetaData';
import TSCStatusDisplay from '@src/components/Home/Config/Tesla/TSCStatusDisplay';
import tscSx from '@src/components/Home/Config/Tesla/TSCConfigSx';

function groupData(data: TeslaConfigItem[]) {
  return data.reduce(
    (acc, item) => {
      const key = item['Control Mode'];
      if (acc[key] === undefined) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
    {} as Record<string, TeslaConfigItem[]>,
  );
}

function transformProps(groupedData: Record<string, TeslaConfigItem[]>) {
  const transformedData: { [key: string]: TransformedTeslaConfigItem[] } = {};
  Object.keys(groupedData).forEach((key) => {
    transformedData[key] = groupedData[key].map((item: TeslaConfigItem) =>
      Object.keys(item).reduce((acc, prop) => {
        acc[camelCase(prop).replace(' /', '')] = item[prop];
        return acc;
      }, {} as TransformedTeslaConfigItem),
    );
  });
  return transformedData;
}

interface TSCConfigProps {
  siteMeta: SiteMeta;
}

function TSCConfig({ siteMeta }: TSCConfigProps) {
  const { siteId } = siteMeta;
  const tscCommandData = transformProps(groupData(tsc_config));

  return (
    <CommandsModalContextProvider
      category={CommandsCategory.TSC}
      deviceId={siteId}
    >
      <div className='home-row frow tsc_config'>
        <div className='fcol h100 full-width'>
          <div className='cell block-container flex-1 overflow-y-auto'>
            <div className='modes-container'>
              <TSCStatusDisplay />
              <hr />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <b>Active Status</b>
                    </TableCell>
                    <TableCell>
                      <b>Enable / Disable</b>
                    </TableCell>
                    <TableCell sx={tscSx.configCol}>
                      <b>Config</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(tscCommandData).map((key) => (
                    <TSCRow
                      key={key}
                      configItem={tscCommandData[key]}
                      name={key}
                      siteMeta={siteMeta}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </CommandsModalContextProvider>
  );
}

export default storeConnector(TSCConfig, {
  config: ['siteMeta'],
});
