import { SiteMeta } from '@src/types/SiteMeta';
import moment from 'moment/moment';
import {
  ChartDataElement,
  ChartOptionsEMS,
  ChartOptionsWithCriteria,
  Colors,
  ColorsMultiLine,
  SeriesItem,
  UnitCriteria,
} from '@utils/historianChart/historianChartTypes';

const getChartOptions = (
  currentTZString: string,
  tzOffset: number,
  exportFileName = '',
): ChartOptionsEMS => ({
  exporting: {
    filename: exportFileName,
    csv: { dateFormat: '%Y-%m-%d %H:%M:%S' },
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  chart: { zoomType: 'x', height: '600px' },
  xAxis: {
    type: 'datetime',
    title: {
      text: `<span id="high-chart-title">\
            Date <span id="high-chart-tz">${currentTZString}</span>\
          </span>`,
    },
  },
  yAxis: [
    {
      title: { text: 'Max Ambient Temperature' },
      opposite: false,
    },
  ],
  title: { text: ' ' },
  time: { useUTC: true, timezoneOffset: tzOffset },
  series: [],
});

const generateSerialName = (unitCriteria: UnitCriteria, siteMeta: SiteMeta) => {
  const useNewTopicStructure: boolean | undefined =
    siteMeta.ui.Use_New_Topic_Structure;
  const { unitId, sourceDeviceId } = unitCriteria;
  let deviceName: string = siteMeta.SiteName;

  if (unitId) {
    const unitIndex: number = siteMeta.Units.indexOf(unitId);
    const unitName: string = siteMeta.UnitNames[unitIndex];

    if (unitName) {
      deviceName += `, Unit ${unitName}`;

      if (sourceDeviceId && useNewTopicStructure) {
        deviceName += `, ${sourceDeviceId}`;
      }
    }
  }

  return deviceName;
};

const getSeriesForGeneralChartOptions = (
  criteriaItems: UnitCriteria[],
  aggregationTypes: string[],
  siteMeta: SiteMeta,
): SeriesItem[] => {
  const series: SeriesItem[] = [];

  criteriaItems.forEach((unitCriteria: UnitCriteria, index: number): void => {
    [...(aggregationTypes.length > 0 ? aggregationTypes : [null])].forEach(
      (aggregationItem: string | null, arrayItemIndex: number): void => {
        const aggregationItemNameSegment: string = aggregationItem
          ? ` (${aggregationItem})`
          : '';
        const aggregationTypesItemCount: number = aggregationTypes.length;
        const multiplyBy: number =
          aggregationTypesItemCount > 0 ? aggregationTypesItemCount : 1;
        const id: number = index * multiplyBy + index;
        const serialName: string = generateSerialName(unitCriteria, siteMeta);
        const name = `${unitCriteria.category}-${serialName}-${unitCriteria.field}${aggregationItemNameSegment}`;
        const multiLineColor = ColorsMultiLine[index][arrayItemIndex];
        const singleLineColor = Colors[index];
        const color = `rgba(${
          aggregationItem ? multiLineColor : singleLineColor
        },1)`;
        const unitDataType = unitCriteria.dataType ? unitCriteria.dataType : '';

        const seriesItem: SeriesItem = {
          id,
          name,
          data: [],
          sourceDeviceId: unitCriteria.sourceDeviceId,
          deviceId: unitCriteria.unitId || unitCriteria.siteId,
          category: unitCriteria.category,
          field: unitCriteria.field,
          color,
          aggr: aggregationItem || '',
          dataType: unitDataType,
        };

        series.push(seriesItem);
      },
    );
  });

  return series;
};

const populateChartSeriesWithData = (
  chartData: ChartDataElement[],
  chartOptionsWithSeriesTemplate: ChartOptionsWithCriteria,
): ChartOptionsWithCriteria => {
  const knownColumns: string[] = ['result', 'table', '_time'];

  chartData.forEach((dataRow: ChartDataElement): void => {
    Object.keys(dataRow).forEach((key: string): void => {
      //  if key is not a known column then it is
      //  a unit key with data - process this key
      if (!knownColumns.includes(key)) {
        const dataUnitIdSearchKey: string = key.split('_')[1];
        const seriesIndex: number =
          chartOptionsWithSeriesTemplate.chartOptions.series.findIndex(
            (seriesItem: SeriesItem): boolean =>
              seriesItem.deviceId === dataUnitIdSearchKey,
          );
        const unitDataFieldName: string = key.toString();

        if (seriesIndex !== -1) {
          // @ts-expect-error unitDataFieldName is a valid property
          const unitValue: number = dataRow[unitDataFieldName] as number;

          // eslint-disable-next-line no-underscore-dangle
          const time: number = dataRow._time as unknown as number;
          const timeStamp: number = moment.utc(time).valueOf();

          chartOptionsWithSeriesTemplate.chartOptions.series[
            seriesIndex
          ].data.push([timeStamp, unitValue]);
        }
      }
    });
  });

  return chartOptionsWithSeriesTemplate;
};

export {
  getChartOptions,
  getSeriesForGeneralChartOptions,
  populateChartSeriesWithData,
};
