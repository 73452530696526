import { store } from '@store';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const CurrentTscIdContext = createContext(
  null as unknown as {
    currentTscId: string;
    setCurrentTscId: React.Dispatch<React.SetStateAction<string>>;
  },
);

export function CurrentTscIdProvider({ children }: { children: ReactNode }) {
  const [currentTscId, setCurrentTscId] = useState(
    store.getState().config.siteMeta.TeslaSiteControllers[0],
  );

  const value = useMemo(
    () => ({ currentTscId, setCurrentTscId }),
    [currentTscId, setCurrentTscId],
  );

  return (
    <CurrentTscIdContext.Provider value={value}>
      {children}
    </CurrentTscIdContext.Provider>
  );
}

export const useCurrentTscIdContext = () => {
  const { currentTscId, setCurrentTscId } = useContext(CurrentTscIdContext);
  return { currentTscId, setCurrentTscId };
};
