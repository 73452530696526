import useSubscription from '@hooks/useSubscription';
import { isActionRole, isAdmin } from '@src/services/auth';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import { UserState } from '@store/reducers/userData';
import React, { useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CommandsCategory } from '@src/types/Commands';
import useDataPointsSearch from '@src/hooks/api/queries/useDataPointsSearch';
import ControlSection from './ControlSection';
import PowerSection from './PowerSection';
import { CommandsModalContextProvider } from '../CommandsModalContext';

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  deviceName: string;
  open: boolean;
  onClose: () => void;
  siteMeta: SiteMeta;
  role: UserState['role'];
}

function PowinControllerCommandsModal({
  deviceName,
  open,
  onClose,
  siteMeta,
  role,
}: Props) {
  const { data: dataPoints } = useDataPointsSearch('cmd-modal-power', [
    { category: 'site', pointName: 'Pset' },
    { category: 'site', pointName: 'Qset' },
  ]);
  const dataPointsWithValue = useSubscription(dataPoints || []);

  const displayEnableDisableCommandsSection: boolean = useMemo((): boolean => {
    //  if siteMeta role check is false or doesn't exist then
    //  operator, engineer, admin, superadmin
    //  can view the enable / disable command section
    //  is role check is on then only admin / superadmin
    //  can view. The "viewer" role can never see, regardless of
    //  the siteMeta flag
    if (
      (siteMeta.ui.POWIN_Only_Allow_Admin_Enable_Disable_Commands &&
        role &&
        isAdmin(role)) ||
      (!siteMeta.ui.POWIN_Only_Allow_Admin_Enable_Disable_Commands &&
        role &&
        isActionRole(role))
    ) {
      return true;
    }
    return false;
  }, [role, siteMeta.ui.POWIN_Only_Allow_Admin_Enable_Disable_Commands]);

  return (
    <CommandsModalContextProvider
      category={CommandsCategory.POWINEMS}
      deviceId={siteMeta.siteId}
    >
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
        <StyledDialogTitle>
          {deviceName} Commands
          <IconButton onClick={onClose} sx={{ mr: -2, mt: -1 }}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers>
          {displayEnableDisableCommandsSection && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <ControlSection />
            </Box>
          )}
          <PowerSection
            currentPset={String(
              dataPointsWithValue.find((o) => o.pointName === 'Pset')?.value,
            )}
            currentQset={String(
              dataPointsWithValue.find((o) => o.pointName === 'Qset')?.value,
            )}
          />
        </DialogContent>
      </Dialog>
    </CommandsModalContextProvider>
  );
}

export default storeConnector(PowinControllerCommandsModal, {
  config: ['siteMeta'],
  user: ['role'],
});
