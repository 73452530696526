import SystemMeter from '@components/_shared/SystemMeter';
import React, { ReactElement, useState } from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import TrackerTable from '@components/_shared/TrackerTable/TrackerTable';
import TrackerDetail from '@components/_shared/TrackerDetail/TrackerDetail';
import WeatherWidget from '@components/Sun/WeatherWidget';

interface TRACKERSProps {
  siteMeta: SiteMeta;
}
function TRACKERS({ siteMeta }: TRACKERSProps): ReactElement {
  const [activeTrackerSN, setActiveTrackerSN] = useState(siteMeta.siteId);
  const [activeUnitName, setActiveUnitName] = useState('Master');

  return (
    <div className='home-row'>
      <div className='fcol'>
        <SystemMeter />
        <TrackerTable
          setActiveUnitName={setActiveUnitName}
          setActiveTrackerSN={setActiveTrackerSN}
          titleOverride='Tracker'
        />
      </div>
      <div className='fcol'>
        <TrackerDetail
          activeUnitName={activeUnitName}
          activeTrackerSN={activeTrackerSN}
        />
        <WeatherWidget hideForecast={!siteMeta.ui.WeatherForecast_enable} />
      </div>
    </div>
  );
}

export default storeConnector(TRACKERS, {
  config: ['siteMeta'],
});
