import { store, dispatcher } from '@store';
import { useIsFirstRender } from '@utils';
import { generateTopics } from '@utils/generateTopic';
import { useState, useEffect } from 'react';

export const topicTimeoutIds = {};

function subscribeMQTT(topics) {
  return new Promise((resolve) => {
    const state = store.getState();
    const client = state.mqtt?.clientMQTT;
    if (!client) {
      return;
    }
    client.subscribe(topics, (err) => {
      if (err) {
        console.error(err);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  });
}

export function publishMQTT(topic, msg) {
  const state = store.getState();
  const client = state.mqtt?.clientMQTT;
  if (!client) {
    return;
  }
  client.publish(topic, msg);
}

function unsubscribeMQTT(topics) {
  return new Promise((resolve) => {
    const state = store.getState();
    const client = state.mqtt?.clientMQTT;
    if (!client) {
      return;
    }
    client.unsubscribe(topics, (err) => {
      if (err) {
        console.error(err);
        resolve(false);
      } else {
        topics.map((t) => {
          if (topicTimeoutIds[t]) {
            window.clearTimeout(topicTimeoutIds[t]);
          }
        });
        resolve(true);
      }
    });
  });
}

export const componentConnect = (idComp, compSubsDict, sn) => {
  return async function (dispatch) {
    const act = async () => {
      // has to be synchronous because callbacks for topicDict are kept in store
      if (!window.busy) {
        window.busy = true;
        // fetch current callback store
        const subsMqttTopicCbDict = {
          ...store.getState().mqtt.subsMqttTopicCbDict,
        };
        const topicsToSubs = [];
        let topicDictNew;
        // convert the useWsSubscribe js object argument to datapoints/search payload array
        // we're going to generate a dictionary-keys based on the js object provided
        // we're going to compare current callback-topic dictionary with componentId and callbacks if we have record for asked dictionary-key
        // if we do it means we're already subscribed and can attach new callback in the dictionary and filter them out before we get datapoints
        // if not we will create a new entry in callback-topic dictionary
        // and proceed to fetch actual datapoints from datapoints/search and get topics and subscribe
        // finally setting new dictionary-key in topicDict

        // start parsing the useWsSubscribe payload
        Object.keys(compSubsDict).forEach((cat) => {
          const sns = compSubsDict[cat].sns || [sn];
          const sourceDeviceIds = compSubsDict[cat].sourceDeviceIds || [
            compSubsDict[cat].sourceDeviceId,
          ];
          // loop over sns provided to useWsSubscribe
          sns.forEach((currSN) => {
            if (currSN === null) {
              return;
            }
            // loop over sourceDeviceIds provided to useWsSubscribe
            sourceDeviceIds.forEach((srcDeviceId) => {
              // loop over fields provided to useWsSubscribe
              compSubsDict[cat].fields.forEach((f) => {
                // generate new dictionary-key for callback-topic dictionary
                let topicKey = `${cat}/${currSN}`;
                if (f) {
                  topicKey += `/${f}`;
                }
                if (srcDeviceId) {
                  topicKey += `/${srcDeviceId}`;
                }
                if (!subsMqttTopicCbDict[topicKey]) {
                  // create new entry for the topic in callback-topic dictionary
                  subsMqttTopicCbDict[topicKey] = {
                    cbs: [compSubsDict[cat].cb],
                    comps: [idComp],
                  };
                } else {
                  // add new callback and componentId to existing callback-topic dictionary
                  subsMqttTopicCbDict[topicKey].cbs.push(compSubsDict[cat].cb);
                  subsMqttTopicCbDict[topicKey].comps.push(idComp);
                }
                // push the topic we havent subscribed to yet to process further down
                topicsToSubs.push(topicKey);
              });
            });
          });
        });
        // get current topicDict that allows to fire callbacks in another store
        const topicDict = { ...store.getState().mqtt.topicDict };
        // get already subscribed topics
        const subscribedTopics = Object.keys(topicDict);
        // filter and get the ones we havent subscribed to yet
        const topicsToSubsFiltered = topicsToSubs.filter(
          (t) => !subscribedTopics.includes(t),
        );
        // get actual topics for the requested points we havent subscribed to yet
        // skip to updating callback-topic dictionary if nothing new
        if (topicsToSubsFiltered.length) {
          // Generate topics
          const generateTopicsPayload = topicsToSubsFiltered.map((t) => {
            const tParts = t.split('/');
            return {
              category: tParts[0],
              controllerId: tParts[1],
              pointName: tParts[2] || null,
              sourceDeviceId: tParts[3],
            };
          });
          const { siteId, topicPrefix, topicRegister, ui } =
            store.getState().config.siteMeta;
          const originalTopics = generateTopics(
            ui.Use_New_Topic_Structure,
            generateTopicsPayload,
            siteId,
            topicPrefix,
            topicRegister,
          );

          // set new topicDict to allow mapping callbacks to newly subscribed topics
          if (originalTopics.length) {
            topicDictNew = originalTopics.reduce((acc, t) => {
              return {
                ...acc,
                [t.fullTopic]: `${t.category}/${t.controllerId}${
                  t.pointName ? `/${t.pointName}` : ''
                }${
                  t.sourceDeviceId === null ||
                  t.sourceDeviceId === undefined ||
                  t.sourceDeviceId === ''
                    ? ''
                    : `/${t.sourceDeviceId}`
                }`,
              };
            }, {});
            dispatch(
              dispatcher('UPDATE_MQTT_MODULE', {
                topicDict: { ...topicDict, ...topicDictNew },
              }),
            );
          }
        }
        // update store with new callback-topic dictionary
        dispatch(dispatcher('UPDATE_MQTT_MODULE', { subsMqttTopicCbDict }));

        // subscribe to new topics after all dictionaries are updated
        if (topicDictNew) {
          await subscribeMQTT(Object.keys(topicDictNew));
        }

        window.busy = false;
      } else {
        setTimeout(() => {
          act();
        }, 100);
      }
    };
    act();
  };
};

export const componentDisconnect = (idComp) => {
  return function (dispatch) {
    return new Promise((resolve) => {
      const act = async () => {
        if (!window.busy) {
          window.busy = true;
          const newSubsMqttTopicCbDict = {
            ...store.getState().mqtt.subsMqttTopicCbDict,
          };
          const newTopicDict = {
            ...store.getState().mqtt.topicDict,
          };
          const topicsToUnsubs = [];
          Object.keys(newSubsMqttTopicCbDict).forEach((t) => {
            if (newSubsMqttTopicCbDict[t].comps.includes(idComp)) {
              const index = newSubsMqttTopicCbDict[t].comps.findIndex(
                (el) => el === idComp,
              );
              newSubsMqttTopicCbDict[t].comps.splice(index, 1);
              newSubsMqttTopicCbDict[t].cbs.splice(index, 1);
            }
            if (newSubsMqttTopicCbDict[t].comps.length === 0) {
              const topic = Object.keys(newTopicDict).find(
                (key) => newTopicDict[key] === t,
              );
              if (topic) {
                delete newTopicDict[topic];
                topicsToUnsubs.push(topic);
              }
              delete newSubsMqttTopicCbDict[t];
            }
          });
          if (topicsToUnsubs.length) {
            const unsubsRes = await unsubscribeMQTT(topicsToUnsubs);
            if (unsubsRes && !unsubsRes.error) {
              dispatch(
                dispatcher('UPDATE_MQTT_MODULE', {
                  subsMqttTopicCbDict: newSubsMqttTopicCbDict,
                  topicDict: newTopicDict,
                }),
              );
            }
          }
          window.busy = false;
          resolve();
        } else {
          setTimeout(() => {
            act();
          }, 100);
        }
      };
      act();
    });
  };
};

export const useWsSubscribe = (dict, shouldResubs = []) => {
  const isFirstRender = useIsFirstRender();
  const [idComp] = useState(`${Math.random().toString(16).substring(2)}`);
  const siteSN = store.getState().config.siteMeta.siteId;

  useEffect(() => {
    store.dispatch(componentConnect(idComp, dict, siteSN));
    return () => {
      store.dispatch(componentDisconnect(idComp));
    };
  }, [idComp]);

  useEffect(() => {
    const validResubFlagsArr = shouldResubs.filter(Boolean);
    if (!isFirstRender && validResubFlagsArr.length > 0) {
      (() =>
        store.dispatch(componentDisconnect(idComp)).then(() => {
          store.dispatch(componentConnect(idComp, dict, siteSN));
        }))();
    }
  }, shouldResubs);
};
