import { DataPointWithValue } from '@src/types/DataPoint';
import { PointsSearchBody } from '@src/sld/components/utils';

interface BrazosBendPointsWithValues {
  k_010: string | number | null | undefined;
  k_011: string | number | null | undefined;
  k_019: string | number | null | undefined;
  k_100: string | number | null | undefined;
  k_200: string | number | null | undefined;
  k_300: string | number | null | undefined;
  k_010_frequency: string | number | null | undefined;
  k_010_voltage: string | number | null | undefined;
  k_010_current: string | number | null | undefined;
  k_010_activePower: string | number | null | undefined;
  k_010_reactivePower: string | number | null | undefined;
  k_100_frequency: string | number | null | undefined;
  k_100_voltage: string | number | null | undefined;
  k_100_current: string | number | null | undefined;
  k_100_activePower: string | number | null | undefined;
  k_100_reactivePower: string | number | null | undefined;
  k_200_frequency: string | number | null | undefined;
  k_200_voltage: string | number | null | undefined;
  k_200_current: string | number | null | undefined;
  k_200_activePower: string | number | null | undefined;
  k_200_reactivePower: string | number | null | undefined;
  k_300_frequency: string | number | null | undefined;
  k_300_voltage: string | number | null | undefined;
  k_300_current: string | number | null | undefined;
  k_300_activePower: string | number | null | undefined;
  k_300_reactivePower: string | number | null | undefined;
}

const brazosBendSearchDataPoints: PointsSearchBody[] = [
  {
    category: 'meter',
    pointName: 'SUB_MOS_K011_SWITCH_STATUS',
    sourceDeviceId: 'k011',
  },
  {
    category: 'meter',
    pointName: 'SUB_MOS_K019_SWITCH_STATUS',
    sourceDeviceId: 'k019',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K010_BREAKER_STATUS',
    sourceDeviceId: 'sel_351_k010',
  },
  { category: 'meter', pointName: 'SUB_K010_HZ', sourceDeviceId: 'k010' },
  {
    category: 'meter',
    pointName: 'SUB_411L_P_V_AB',
    sourceDeviceId: 'sel_411l_p',
  },
  { category: 'meter', pointName: 'SUB_K010_I_A', sourceDeviceId: 'k010' },
  { category: 'meter', pointName: 'SUB_K010_MW_TOT', sourceDeviceId: 'k010' },
  { category: 'meter', pointName: 'SUB_K010_MVAR_TOT', sourceDeviceId: 'k010' },
  {
    category: 'meter',
    pointName: 'SUB_351_K100_BREAKER_STATUS',
    sourceDeviceId: 'sel_351_k100',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K100_HZ',
    sourceDeviceId: 'sel_351_k100',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K100_V_AB',
    sourceDeviceId: 'sel_351_k100',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K100_I_A',
    sourceDeviceId: 'sel_351_k100',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K100_KW_TOT',
    sourceDeviceId: 'sel_351_k100',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K100_KVAR_TOT',
    sourceDeviceId: 'sel_351_k100',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K200_BREAKER_STATUS',
    sourceDeviceId: 'sel_351_k200',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K200_HZ',
    sourceDeviceId: 'sel_351_k200',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K200_V_AB',
    sourceDeviceId: 'sel_351_k200',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K200_I_A',
    sourceDeviceId: 'sel_351_k200',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K200_KW_TOT',
    sourceDeviceId: 'sel_351_k200',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K200_KVAR_TOT',
    sourceDeviceId: 'sel_351_k200',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K300_BREAKER_STATUS',
    sourceDeviceId: 'sel_351_k300',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K300_HZ',
    sourceDeviceId: 'sel_351_k300',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K300_V_AB',
    sourceDeviceId: 'sel_351_k300',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K300_I_A',
    sourceDeviceId: 'sel_351_k300',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K300_KW_TOT',
    sourceDeviceId: 'sel_351_k300',
  },
  {
    category: 'meter',
    pointName: 'SUB_351_K300_KVAR_TOT',
    sourceDeviceId: 'sel_351_k300',
  },
];

// eslint-disable-next-line max-lines-per-function
const createBrazosBendDiagramPropertiesFromDataPoints = (
  dataPointsWithValue: DataPointWithValue[],
): BrazosBendPointsWithValues => {
  const diagramDataPointsWithValues: BrazosBendPointsWithValues = {
    k_010: null,
    k_011: null,
    k_019: null,
    k_100: null,
    k_200: null,
    k_300: null,
    k_010_frequency: null,
    k_010_voltage: null,
    k_010_current: null,
    k_010_activePower: null,
    k_010_reactivePower: null,
    k_100_frequency: null,
    k_100_voltage: null,
    k_100_current: null,
    k_100_activePower: null,
    k_100_reactivePower: null,
    k_200_frequency: null,
    k_200_voltage: null,
    k_200_current: null,
    k_200_activePower: null,
    k_200_reactivePower: null,
    k_300_frequency: null,
    k_300_voltage: null,
    k_300_current: null,
    k_300_activePower: null,
    k_300_reactivePower: null,
  };

  // eslint-disable-next-line max-lines-per-function
  dataPointsWithValue.forEach((dataPoint: DataPointWithValue): void => {
    const { pointName, value } = dataPoint;

    switch (pointName) {
      case 'SUB_MOS_K011_SWITCH_STATUS':
        diagramDataPointsWithValues.k_011 = value;
        break;
      case 'SUB_MOS_K019_SWITCH_STATUS':
        diagramDataPointsWithValues.k_019 = value;
        break;
      case 'SUB_351_K010_BREAKER_STATUS':
        diagramDataPointsWithValues.k_010 = value;
        break;
      case 'SUB_K010_HZ':
        diagramDataPointsWithValues.k_010_frequency = value;
        break;
      case 'SUB_411L_P_V_AB':
        diagramDataPointsWithValues.k_010_voltage = value;
        break;
      case 'SUB_K010_I_A':
        diagramDataPointsWithValues.k_010_current = value;
        break;
      case 'SUB_K010_MW_TOT':
        diagramDataPointsWithValues.k_010_activePower = value;
        break;
      case 'SUB_K010_MVAR_TOT':
        diagramDataPointsWithValues.k_010_reactivePower = value;
        break;
      case 'SUB_351_K100_BREAKER_STATUS':
        diagramDataPointsWithValues.k_100 = value;
        break;
      case 'SUB_351_K100_HZ':
        diagramDataPointsWithValues.k_100_frequency = value;
        break;
      case 'SUB_351_K100_V_AB':
        diagramDataPointsWithValues.k_100_voltage = value;
        break;
      case 'SUB_351_K100_I_A':
        diagramDataPointsWithValues.k_100_current = value;
        break;
      case 'SUB_351_K100_KW_TOT':
        diagramDataPointsWithValues.k_100_activePower = value;
        break;
      case 'SUB_351_K100_KVAR_TOT':
        diagramDataPointsWithValues.k_100_reactivePower = value;
        break;
      case 'SUB_351_K200_BREAKER_STATUS':
        diagramDataPointsWithValues.k_200 = value;
        break;
      case 'SUB_351_K200_HZ':
        diagramDataPointsWithValues.k_200_frequency = value;
        break;
      case 'SUB_351_K200_V_AB':
        diagramDataPointsWithValues.k_200_voltage = value;
        break;
      case 'SUB_351_K200_I_A':
        diagramDataPointsWithValues.k_200_current = value;
        break;
      case 'SUB_351_K200_KW_TOT':
        diagramDataPointsWithValues.k_200_activePower = value;
        break;
      case 'SUB_351_K200_KVAR_TOT':
        diagramDataPointsWithValues.k_200_reactivePower = value;
        break;
      case 'SUB_351_K300_BREAKER_STATUS':
        diagramDataPointsWithValues.k_300 = value;
        break;
      case 'SUB_351_K300_HZ':
        diagramDataPointsWithValues.k_300_frequency = value;
        break;
      case 'SUB_351_K300_V_AB':
        diagramDataPointsWithValues.k_300_voltage = value;
        break;
      case 'SUB_351_K300_I_A':
        diagramDataPointsWithValues.k_300_current = value;
        break;
      case 'SUB_351_K300_KW_TOT':
        diagramDataPointsWithValues.k_300_activePower = value;
        break;
      case 'SUB_351_K300_KVAR_TOT':
        diagramDataPointsWithValues.k_300_reactivePower = value;
        break;
      default:
        break;
    }
  });

  return diagramDataPointsWithValues;
};

const getBrazosBendPointsForSearchBody = (): PointsSearchBody[] =>
  brazosBendSearchDataPoints;

export {
  createBrazosBendDiagramPropertiesFromDataPoints,
  getBrazosBendPointsForSearchBody,
};

export type { BrazosBendPointsWithValues };
