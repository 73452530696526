import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import React, { useState } from 'react';
import type { ReactElement } from 'react';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import type { BooleanInteger } from '@src/types';

interface IndicatorProps {
  size: number;
  currentUnitDeviceId: string;
  sourceDeviceId: string;
}

function getBackground(status: boolean | undefined) {
  if (status === undefined) {
    return 'gray';
  }
  if (status) {
    return '#20c191';
  }
  return '#fc4349';
}
function Indicator(p: IndicatorProps) {
  const [status, setStatus] = useState<boolean | undefined>();
  useWsSubscribe(
    {
      rack: {
        sns: [p.currentUnitDeviceId],
        sourceDeviceId: p.sourceDeviceId,
        fields: ['Alarms'],
        cb: (data: { Alarms: BooleanInteger }) => {
          setStatus(data.Alarms === 0);
        },
      },
    },
    [p.currentUnitDeviceId],
  );

  return (
    <div
      style={{
        width: p.size,
        height: p.size,
        backgroundColor: getBackground(status),
        borderRadius: '50%',
      }}
    />
  );
}
interface RackTableProps {
  siteMeta: SiteMeta;
  bmsIndex: number;
  bmsId: string;
  currentUnitDeviceId: string;
}
const styles = {
  table: {
    display: 'flex',
  },

  columns: {
    flex: 1,
    border: '1px solid white',
    flexDirection: 'column',
  },
  cell: {
    height: 40,
    padding: 5,
    border: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
  },
} as const;

function RackTable(p: RackTableProps): ReactElement {
  const { NumRacks, Batt_perUnit_BMS_count: numBms, Units } = p.siteMeta;

  const rackCountOverride = p.siteMeta.Topology_Override?.bmsRack?.find(
    (o) => o.unitSN === p.currentUnitDeviceId && o.bmsId === p.bmsId,
  );
  const racksPerBMS = rackCountOverride
    ? rackCountOverride.rackCount
    : Number(NumRacks) / Number(numBms) / Units.length;

  const numColumns = [...Array(Number(racksPerBMS))] as undefined[];

  return (
    <div style={styles.table}>
      <div style={styles.columns}>
        <div style={styles.cell}>
          <b>Rack</b>
        </div>
        <div style={styles.cell}>
          <b>Status</b>
        </div>
      </div>
      {numColumns.map((_, i) => (
        <div style={styles.columns} key={Math.random()}>
          <div style={styles.cell}>
            <b>{i + 1}</b>
          </div>
          <div style={styles.cell}>
            <Indicator
              {...p}
              sourceDeviceId={`bms_${p.bmsIndex + 1}_rack_${i + 1}`}
              size={21}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default storeConnector(RackTable, {
  config: ['siteMeta'],
  service: ['currentUnitDeviceId'],
});
