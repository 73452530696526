import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import AppLayout from '@pages/_layout/AppLayout';
import ActiveAlerts from '@pages/Home/pages/Alerts/pages/Active';
import AlertsTimeline from '@pages/Home/pages/Alerts/pages/Timeline';
import AlertConfigs from '@pages/Home/pages/Alerts/pages/Configs';
import { LookbackProvider } from '@src/components/Home/Alerts/AlertsTimelineContext';
import TopBar from './TopBar';

export default [
  {
    path: 'alerts',
    element: <AppLayout isNested topBar={<TopBar />} />,
    children: [
      { index: true, element: <Navigate to='active' replace /> },
      { path: 'active', element: <ActiveAlerts /> },
      {
        path: 'timeline',
        element: (
          <LookbackProvider>
            <AlertsTimeline />
          </LookbackProvider>
        ),
      },
      { path: 'configs', element: <AlertConfigs /> },
    ],
  },
] as RouteObject[];
