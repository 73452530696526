import React, { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';

function CommandsNumberInput({
  label,
  unit,
  minimum,
  maximum,
  value,
  setValue,
}: {
  label: string;
  unit: string;
  minimum: number;
  maximum: number;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  const isValid = useMemo(
    () =>
      value.length > 0 &&
      parseFloat(value) <= maximum &&
      parseFloat(value) >= minimum,
    [value, minimum, maximum],
  );

  return (
    <FormControl
      variant='outlined'
      sx={{ width: '100%', mt: 2.5 }}
      error={!isValid}
    >
      <TextField
        size='small'
        label={label}
        InputProps={{
          endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
        }}
        variant='filled'
        type='number'
        value={value}
        onChange={handleChange}
        onFocus={(event) => event.target.select()}
        error={!isValid}
      />
      <FormHelperText>
        Range from {minimum} to {maximum}
      </FormHelperText>
    </FormControl>
  );
}

export default CommandsNumberInput;
