import React from 'react';
import RackContainerDefault from '@components/Battery/RackContainers/RackContainer_default';
import RackContainerClou from '@components/Battery/RackContainers/RackContainer_Clou';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import RackContainerLG from '@components/Battery/RackContainers/RackContainer_LG';
import RackContainerSamsung from '@components/Battery/RackContainers/RackContainer_Samsung';
import { UnitBMSConfig } from '@types';

interface SwitchRackContainerProps {
  bmsId: string;
  battType: string;
  bmsIndex: number;
}

function SwitchRackContainer(p: SwitchRackContainerProps) {
  switch (p.battType) {
    case 'LG':
      return <RackContainerLG {...p} />;
    case 'Samsung':
      return <RackContainerSamsung {...p} />;
    case 'Clou':
      return <RackContainerClou {...p} />;
    default:
      return <RackContainerDefault {...p} />;
  }
}

function BMS({
  currentUnitDeviceId,
  siteMeta,
}: {
  currentUnitDeviceId: string;
  siteMeta: SiteMeta;
}) {
  const defaultBMSSlots: string[] = Array.from(
    { length: siteMeta.Batt_perUnit_BMS_count },
    (_, i) => `bms_${i + 1}`,
  );
  const unitConfig: UnitBMSConfig | undefined =
    siteMeta.Topology_Override?.bms?.find(
      (e) => e.unitSN === currentUnitDeviceId || '',
    );
  const unitBMSSlots: string[] = unitConfig
    ? defaultBMSSlots.filter((x) => !unitConfig.bmsExcludeList.includes(x))
    : defaultBMSSlots;

  return (
    <div className='fcol'>
      {unitBMSSlots.map((bmsId, bmsIndex) => (
        <SwitchRackContainer
          key={bmsId}
          bmsId={bmsId}
          battType={siteMeta.Batt_type}
          bmsIndex={bmsIndex}
        />
      ))}
    </div>
  );
}

export default storeConnector(BMS, {
  config: ['siteMeta'],
  service: ['currentUnitDeviceId'],
});
