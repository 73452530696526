import React, { ReactElement } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import '@components/_shared/MultiDateSelector/MultiDateSelector.scoped.scss';
import { Dayjs } from 'dayjs';

interface MultiDateSelectorProps {
  startDateISO: string;
  endDateISO: string | null;
  startDateChange: (startDate: Dayjs | null) => void;
  endDateChange: (endDate: Dayjs | null) => void;
}

function MultiDateSelector(props: MultiDateSelectorProps): ReactElement {
  const { startDateISO, endDateISO, startDateChange, endDateChange } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        label={startDateISO}
        onAccept={startDateChange}
        className='multiDateSelectorFilter'
      />
      <DateTimePicker
        label={endDateISO}
        onAccept={endDateChange}
        className='multiDateSelectorFilter'
      />
    </LocalizationProvider>
  );
}

export default MultiDateSelector;
