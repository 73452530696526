import { useState, useEffect } from 'react';

function useIsSunSystemView(): boolean {
  const [isSunSystemView, setIsSunSystemView] = useState<boolean>(false);

  useEffect(() => {
    setIsSunSystemView(window.location.pathname.split('/')[1] === 'sun');
  }, []);

  return isSunSystemView;
}

export default useIsSunSystemView;
