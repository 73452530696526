import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import { AlertConfig } from '@src/types/Alerts';

export default () =>
  useApiQuery<AlertConfig[]>({
    queryKey: [QueryKeys.alerts],
    apiPath: `/alerts/alert-configs`,
    method: 'GET',
  });
