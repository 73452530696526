import React, { useEffect, useRef, useState } from 'react';
import './TrackerTable.scoped.scss';
import TrackerTableItem from '../TrackerTableItem/TrackerTableItem';
import MasterTrackerTableItem from '../TrackerTableItem/MasterTrackerTableItem';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';

const TrackerTable = ({
  currentUnitDeviceId,
  siteMeta,
  actions,
  UnitTable,
  titleOverride,
  setActiveTrackerSN,
  setActiveUnitName,
}) => {
  const [loading, setLoading] = useState(false);
  const [tableFirstRowsCount, setTableFirstRowsCount] = useState(null);
  const didMountRef = useRef(false);
  const prevUnitTable = useRef();

  useEffect(() => {
    if (didMountRef.current === false) {
      didMountRef.current = true;
      setLoading(true);
      if (!currentUnitDeviceId && siteMeta?.Units?.length) {
        actions.setActiveUnitSN(siteMeta.Units[0]);
      }
      setTables();
      setLoading(false);
    } else {
      if (prevUnitTable.current) {
        setTables();
      }
    }

    const listener = () => {
      setTables();
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  });
  useEffect(() => (prevUnitTable.current = UnitTable));

  const setTables = () => {
    const container = document.getElementById('tracker-table-container');
    if (container) {
      const tableLength = siteMeta.Units.length;
      const maxRowsNotScrolled = 16;
      if (tableLength < maxRowsNotScrolled) {
        if (tableFirstRowsCount !== tableLength) {
          setTableFirstRowsCount(tableLength);
        }
        return;
      }
      if (Math.ceil(tableLength / 2) > maxRowsNotScrolled) {
        if (tableFirstRowsCount !== Math.ceil(tableLength / 2)) {
          setTableFirstRowsCount(Math.ceil(tableLength / 2));
        }
        return;
      }
      if (tableFirstRowsCount !== maxRowsNotScrolled) {
        setTableFirstRowsCount(maxRowsNotScrolled);
      }
    }
  };

  return (
    <>
      {
        <div className='block-container cell' style={{ flex: 1 }}>
          <div className='title-line'>{titleOverride} Table</div>
          <div
            className={`tracker-table-container ${
              !loading && tableFirstRowsCount && 'with-top-border'
            }`}
            id='tracker-table-container'
          >
            {!loading &&
              tableFirstRowsCount &&
              [
                ...Array(siteMeta.Units.length <= tableFirstRowsCount ? 1 : 2),
              ].map((_v, tableColIdx) => (
                <table className='tracker-table' key={tableColIdx}>
                  <thead>
                    <tr>
                      <th className='max-w' />
                      <th className='max-w'>Status</th>
                      <th className='max-w'>Stow Status</th>
                      <th className='max-w'>Actual Angle</th>
                      <th className='max-w'>Target Angle</th>
                      <th className='max-w'>Wind Speed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <MasterTrackerTableItem
                      setActiveTrackerSN={setActiveTrackerSN}
                      sn={siteMeta.siteId}
                    />
                    {siteMeta.Units.slice(
                      tableColIdx === 0 ? 0 : tableFirstRowsCount,
                      tableColIdx === 0
                        ? tableFirstRowsCount
                        : siteMeta.Units.length,
                    ).map((sn, idx) => (
                      <TrackerTableItem
                        setActiveTrackerSN={setActiveTrackerSN}
                        setActiveUnitName={setActiveUnitName}
                        key={`tracker-table-item-${sn}`}
                        sn={sn}
                        index={
                          tableColIdx === 0 ? idx : idx + tableFirstRowsCount
                        }
                      />
                    ))}
                  </tbody>
                </table>
              ))}
          </div>
          {loading && <Spinner cover='container' />}
        </div>
      }
    </>
  );
};

export default storeConnector(TrackerTable, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
