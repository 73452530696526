import React, { ReactElement } from 'react';
import Circle from '@src/sld/components/Circle/Circle';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import Switch from '@src/sld/components/Switch/Switch';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';

const rectTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

function BreakerStack1(): ReactElement {
  return (
    <g transform='translate(735 -506)'>
      <VerticalLine
        translateXCoordinate={-112.5}
        translateYCoordinate={690}
        length={50}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />
      <Switch
        label='89F12-1'
        isClosed={false}
        translateXCoordinate={-112}
        translateYCoordinate={680}
        stroke='green'
      />

      <rect
        x={-123}
        y='723'
        width='20'
        height='20'
        fill='grey'
        strokeWidth={0.5}
      />
      <text x={-90} y={735} {...rectTextProps}>
        52F12
      </text>

      <VerticalLine
        translateXCoordinate={-113.5}
        translateYCoordinate={744}
        length={76}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />

      <HorizontalLine
        translateXCoordinate={-113.5}
        translateYCoordinate={775}
        color='white'
        width={0.5}
        length={20}
      />
      <Circle
        label='CAISO 2 A/B'
        innerText='M'
        translateXCoordinate={-83}
        translateYCoordinate={765}
        diameter={10}
        stroke='white'
        strokeWidth={0.5}
      />
    </g>
  );
}

export default BreakerStack1;
