import Mkt from '@pages/Mkt/index';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import AppLayout from '@pages/_layout/AppLayout';

export default [
  {
    path: 'mkt',
    element: <AppLayout />,
    children: [{ index: true, element: <Mkt /> }],
  },
] as RouteObject[];
