import React, { useEffect } from 'react';
import './Modes.scoped.scss';
import {
  ExecutionsSettingsFormData,
  ModeConfig,
} from '@src/types/control-modes/Modes';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import ModeConfigInput from './Inputs/ModeConfigInput';

interface ExecutionSettingsFormProps {
  config: ModeConfig;
  executionSettingsFormData: ExecutionsSettingsFormData;
  inputErrorCount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExecutionSettingsFormData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInputErrorCount: any;
  siteMeta: SiteMeta;
}

// eslint-disable-next-line max-lines-per-function
function ExecutionSettingsForm({
  config,
  executionSettingsFormData,
  inputErrorCount,
  setExecutionSettingsFormData,
  setInputErrorCount,
  siteMeta,
}: ExecutionSettingsFormProps) {
  const commandAccessEnabled = siteMeta.ui.Command_Access_Enable;

  useEffect(() => {
    setExecutionSettingsFormData(
      (prevFormData: ExecutionsSettingsFormData) => ({
        ...prevFormData,
        additive: config.body['Execution Settings']?.Additive || '',
        // enabled: config.body['Execution Settings']?.Enabled || '',
        dataSource: config.body['Execution Settings']?.['Data Source'] || '',
        priority: config.body['Execution Settings']?.Priority || '',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputUpdate = (e: any) => {
    setExecutionSettingsFormData(
      (prevFormData: ExecutionsSettingsFormData) => ({
        ...prevFormData,
        [e.target.name]: e.target.value,
      }),
    );
  };

  return (
    <div className='modes-row'>
      {/* <ModeConfigInput
        configInputLabel='Enabled'
        formType='full'
        handleInputUpdate={handleInputUpdate}
        inputErrorCount={inputErrorCount}
        name='enabled'
        setInputErrorCount={setInputErrorCount}
        value={executionSettingsFormData.enabled}
      /> */}

      <ModeConfigInput
        configInputLabel='Additive'
        formType='full'
        handleInputUpdate={handleInputUpdate}
        inputErrorCount={inputErrorCount}
        name='additive'
        setInputErrorCount={setInputErrorCount}
        value={executionSettingsFormData.additive}
        disabled={!commandAccessEnabled}
      />

      <ModeConfigInput
        configInputLabel='Priority'
        formType='full'
        handleInputUpdate={handleInputUpdate}
        inputErrorCount={inputErrorCount}
        name='priority'
        setInputErrorCount={setInputErrorCount}
        range={config.body['Execution Settings']?.Priority__range || ''}
        value={executionSettingsFormData.priority}
        disabled={!commandAccessEnabled}
      />

      <ModeConfigInput
        configInputLabel='Data Source'
        formType='full'
        handleInputUpdate={handleInputUpdate}
        inputErrorCount={inputErrorCount}
        inputType='string'
        name='dataSource'
        setInputErrorCount={setInputErrorCount}
        value={executionSettingsFormData.dataSource}
        disabled={!commandAccessEnabled}
      />

      <div className='modes-cell' style={{ flex: 4 }} />
    </div>
  );
}

export default storeConnector(ExecutionSettingsForm, {
  config: ['siteMeta'],
});
