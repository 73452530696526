/* eslint-disable react/require-default-props */
import React from 'react';
import {
  ModeConfigFormType,
  ModeConfigInputType,
} from '@src/types/control-modes/Modes';
import ValidatedModeInput from './ValidatedModeInput';

interface ModeConfigInputProps {
  configInputLabel: string;
  formType: ModeConfigFormType;
  handleInputUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  inputType?: ModeConfigInputType | 'number';
  inputErrorCount: number;
  name: string;
  range?: string | '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInputErrorCount: any;
  value: string;
  disabled?: boolean;
}

function ModeConfigInput({
  configInputLabel,
  formType,
  handleInputUpdate,
  inputType = 'number',
  inputErrorCount,
  name,
  range = '',
  setInputErrorCount,
  value,
  disabled,
}: ModeConfigInputProps) {
  return formType === 'quick' ? (
    <div className='modes-cell' style={{ justifyContent: 'right' }}>
      {configInputLabel ? (
        <ValidatedModeInput
          handleInputUpdate={handleInputUpdate}
          inputType={inputType}
          inputErrorCount={inputErrorCount}
          name={name}
          range={range}
          setInputErrorCount={setInputErrorCount}
          value={value}
          disabled={disabled}
        />
      ) : (
        ''
      )}
    </div>
  ) : (
    <div className='modes-cell' style={{ justifyContent: 'left' }}>
      {configInputLabel ? (
        <div>
          <label style={{ minHeight: '42px' }}>{configInputLabel}:</label>
          <br />
          <ValidatedModeInput
            handleInputUpdate={handleInputUpdate}
            inputType={inputType}
            inputErrorCount={inputErrorCount}
            name={name}
            range={range}
            setInputErrorCount={setInputErrorCount}
            value={value}
            disabled={disabled}
          />
        </div>
      ) : (
        <div style={{ width: '138px' }} />
      )}
    </div>
  );
}

export default ModeConfigInput;
