import React, { useEffect, useRef, useState } from 'react';
import './index.scoped.scss';
import Input from '@components/_elements/Input/Input.jsx';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';

function Index({ siteMeta, actions }) {
  const { siteId } = siteMeta;
  const [loading, setLoading] = useState(true);
  const [dashboardList, setDashboardList] = useState([]);
  const [activeDashboard, setActiveDashboard] = useState(false);
  const dashboard = useRef(null);

  useEffect(() => {
    (async () => {
      let url = '';
      let activeDashboard = false;
      const listRes = await actions.listDashboards(siteId);
      const list = listRes.result.sort((a, b) => {
        if (a.Name < b.Name) {
          return -1;
        }
        if (a.Name > b.Name) {
          return 1;
        }
        return 0;
      });
      if (!list.length) {
        const r = await actions.getQSConsoleUrl();
        url = r.result;
      } else {
        const favorite = list.find((l) => l.IsFavorite);
        if (favorite) {
          activeDashboard = favorite;
        } else {
          activeDashboard = list[0];
        }
        const r = await actions.getQSDashboardUrl(activeDashboard.DashboardId);
        url = r.result;
      }
      setDashboardList(list);
      setActiveDashboard(activeDashboard);
      setLoading(false);
      await prepDashboard(url);
    })();
  }, []);

  const prepDashboard = async (url) => {
    const containerDiv = document.getElementById('quickSightEmbed');
    var options = {
      url: url,
      container: containerDiv,
      height: '100%',
      width: '100%',
      printEnabled: true,
    };
    const iframes = document.getElementsByTagName('iframe');
    if (iframes.length) {
      iframes[0].remove();
    }
    const embeddingContext = await createEmbeddingContext();
    const { embedDashboard } = embeddingContext;
    dashboard.current = embedDashboard(options);
  };

  const markAsFavorite = async (item) => {
    actions.markAsFavorite({
      sn: siteId,
      dashboardId: item.DashboardId,
    });
    const newList = dashboardList.map((d) => {
      return {
        ...d,
        IsFavorite: d.DashboardId === item.DashboardId,
      };
    });
    setDashboardList(newList);
  };

  return (
    <div id='quickSightEmbed' style={{ flexGrow: 1, width: '100%' }}>
      {loading && <Spinner type='fullPage' />}
      <div className='row-select-dashboard'>
        <div>Active Dashboard:</div>
        <Input
          type='dropdown'
          afterOptBtn={{
            activeIconClass: 'heart fill',
            nonActiveIconClass: 'heart',
            field: 'IsFavorite',
            onClick: (val) => markAsFavorite(val),
          }}
          objModeLabel='Name'
          placeholder='Select Dashboard'
          value={activeDashboard}
          onChange={async (val) => {
            setLoading(true);
            if (val === '+ Create Dashboard') {
              const r = await actions.getQSConsoleUrl();
              const url = r.result;
              setActiveDashboard({ Name: '+ Create Dashboard' });
              setLoading(false);
              await prepDashboard(url);
            } else {
              const r = await actions.getQSDashboardUrl(val.DashboardId);
              const url = r.result;
              setActiveDashboard(val);
              setLoading(false);
              await prepDashboard(url);
            }
          }}
          options={[...['+ Create Dashboard'], ...dashboardList]}
        />
      </div>
    </div>
  );
}

export default storeConnector(Index, { config: ['siteMeta'] });
