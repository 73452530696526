import React, { useEffect, useRef, useState } from 'react';
import './SiteUnitCommandsPopup.scoped.scss';
import Draggable from 'react-draggable';
import ConverterCommandsTable from './ConverterCommandsTable';
import LotoPopup from './LotoPopup';
import PowerCommandForm from './PowerCommandForm';
import ConfirmationPopup from '@components/_shared/ConfirmationPopup';
import LotoReleaseForm from './LotoReleaseForm';
import Spinner from '@components/_elements/Spinner/Spinner';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';
import { devices } from '@src/store/actionCreators/devices';
import PersistCommandsPopup from '@components/Battery/SiteUnitCommandsPopup/PersistCommandsPopup.tsx';

const UNITS_CMD = 'Unit Power Cmd';

const AutoCommandPersistEnum = Object.freeze({
  NEVER: 'never',
  ALWAYS: 'always',
  PROMPT: 'prompt',
});

const SiteUnitCommandsPopup = (props) => {
  let initialMode = '';
  if (props.popupProps.popupType === 'unit') {
    initialMode = props.runMode;
  } else if (props.popupProps.popupType === 'site') {
    initialMode = props.LocRemCtl || 'Auto';
  }

  const [mode, setMode] = useState(initialMode);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showPersistCommandPopup, setShowPersistCommandPopup] = useState(false);
  const [systemModeValue, setSystemModeValue] = useState('');
  const [confirmationPopupChecklist, setConfirmationPopupChecklist] =
    useState(false);
  const [commands, setCommands] = useState([]);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [multiselectItems, setMultiselectItems] = useState([]);
  const [lotoPopupIsOpen, setLotoPopupIsOpen] = useState(false);
  const [lotoReason, setLotoReason] = useState('');
  const [lotoConfirm, setLotoConfirm] = useState(false);
  const [lotoReleaseNotes, setLotoReleaseNotes] = useState('');
  const [lotoReleaseConfirm, setLotoReleaseConfirm] = useState(false);
  const [lotoTableRtl, setLotoTableRtl] = useState(props.lotoTableRt);
  const [isLotoLoading, setIsLotoLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [overInput, setOverInput] = useState(false);
  const isConfirmLOTORef = useRef(false);
  const converterIdxStr = useRef(null);
  const isSendingConverterPowerCommands = useRef(false);
  const [convertersReactiveArr, setConvertersReactiveArr] = useState(
    Array(+props.siteMeta.NumConverters || 0).fill(0),
  );
  const [convertersRealArr, setConvertersRealArr] = useState(
    Array(+props.siteMeta.NumConverters || 0).fill(0),
  );
  const { unitTable } = props;

  useEffect(() => {
    if (props.siteMeta.NumConverters === undefined) {
      return;
    }
    setConvertersReactiveArr((prev) => {
      const arr = [...prev];
      const prevLength = arr.length;
      arr.length = props.siteMeta.NumConverters;
      return arr.fill(0, prevLength, props.siteMeta.NumConverters);
    });
    setConvertersRealArr((prev) => {
      const arr = [...prev];
      const prevLength = arr.length;
      arr.length = props.siteMeta.NumConverters;
      return arr.fill(0, prevLength, props.siteMeta.NumConverters);
    });
  }, [props.siteMeta.NumConverters]);

  const fetchLotoResponses = async (unitTable) => {
    const responses = await Promise.all(
      unitTable.map((dev) => devices.getLOTO(dev.SN)()),
    );
    return responses.reduce((loto, res) => {
      if (res.Mode === 'lockout') {
        loto[res.deviceId] = res;
      }
      return loto;
    }, {});
  };

  useEffect(() => {
    async function fetchAndSetLoto() {
      if (unitTable?.length > 0) {
        setIsLotoLoading(true);
        const loto = await fetchLotoResponses(unitTable);
        setLotoTableRtl(loto);
        setIsLotoLoading(false);
      }
    }

    fetchAndSetLoto();
  }, [props.unitTable]);

  const isConverterPowerValueChangedArr = useRef(
    Array(props.siteMeta.NumConverters || 0).fill(false),
  );

  //  this is the callback handler that is invoked from the PersistCommandsPopup component
  //  the persistCommands input parameter is either true or false depending on the
  //  selection from the PersistCommandsPopup modal
  const persistCommandPopupOnSelectionClickCallback = (
    shouldPersistCommand,
  ) => {
    //  continue on with calling the showConfPopup function - passing in the true / false
    //  from the persistCommands input parameter
    const persistCommandString = shouldPersistCommand ? '::Persist' : '';
    setShowPersistCommandPopup(false);
    showConfPopup(
      `System Mode::${systemModeValue}${persistCommandString}`,
      'mode',
    );
  };

  const showConfPopup = (commandString, type, isMulti) => {
    setShowConfirmationPopup(true);
    setCommands([commandString]);
    setType(type);
    setConfirmationPopupChecklist(!!isMulti);

    const forSite = props.popupProps.popupType === 'site';

    if (isMulti && !forSite) {
      setLoading(true);
      setMultiselectItems(
        props.rackModeArr.map(
          (_e, index) => (index < 9 ? '0' : '') + (index + 1),
        ),
      );

      if (['Rack Open', 'Rack Close'].includes(commandString)) {
        setItemsSelected(
          props.rackModeArr.map((el) =>
            commandString === 'Rack Open' ? el === 0 : el !== 0,
          ),
        );
      } else {
        setItemsSelected(Array(props.rackModeArr.length).fill(false));
      }
    }

    if (isMulti && forSite) {
      setLoading(true);
      const arr = props.unitTable.map((u) => u.UnitName);
      setMultiselectItems(arr);

      if (commandString === 'Stop Units') {
        setItemsSelected(
          props.unitTable.map((el) => {
            if (lotoTableRtl[el.SN]) {
              return 'disabled';
            }

            return !(
              ['RunPQ', 'RunUF'].includes(el['InvStatus']) ||
              ['RunPQ', 'RunUF'].includes(el['Inv Status'])
            );
          }),
        );
      } else if (commandString === 'Start Units') {
        setItemsSelected(
          props.unitTable.map((el) => {
            if (lotoTableRtl[el.SN]) {
              return 'disabled';
            }

            return (
              ['RunPQ', 'RunUF'].includes(el['InvStatus']) ||
              ['RunPQ', 'RunUF'].includes(el['Inv Status'])
            );
          }),
        );
      } else if (commandString === 'Units to Manual') {
        setItemsSelected(
          props.unitTable.map((el) => {
            if (lotoTableRtl[el.SN]) {
              return 'disabled';
            }

            return [el['RunMode'], el['Run Mode']].includes('Manual');
          }),
        );
      } else if (commandString === 'Units to Auto') {
        setItemsSelected(
          props.unitTable.map((el) => {
            if (lotoTableRtl[el.SN]) {
              return 'disabled';
            }

            return [el['RunMode'], el['Run Mode']].includes('Auto');
          }),
        );
      } else if (commandString === 'Open AC Breakers') {
        setItemsSelected(
          props.unitTable.map((el) => {
            if (lotoTableRtl[el.SN]) {
              return 'disabled';
            }

            return [el['ACBreaker'], el['AC Breaker']].includes('0');
          }),
        );
      } else if (commandString === 'Close AC Breakers') {
        setItemsSelected(
          props.unitTable.map((el) => {
            if (lotoTableRtl[el.SN]) {
              return 'disabled';
            }

            return ![el['ACBreaker'], el['AC Breaker']].includes('0');
          }),
        );
      } else {
        setItemsSelected(
          props.unitTable.map((el) =>
            lotoTableRtl[el.SN] ? 'disabled' : false,
          ),
        );
      }

      if (UNITS_CMD === commandString) {
        setItemsSelected(
          props.unitTable.map((el) => {
            const isManual = [el['RunMode'], el['Run Mode']].includes('Manual');
            return !isManual || lotoTableRtl[el.SN] ? 'disabled' : false;
          }),
        );
      }
    }

    setLoading(false);
  };

  const changeMode = (e) => {
    const promptPersistCommand =
      props.siteMeta.ui.Auto_Command_Persist === AutoCommandPersistEnum.PROMPT;

    const alwaysPersistCommands =
      props.siteMeta.ui.Auto_Command_Persist === AutoCommandPersistEnum.ALWAYS;

    //  persist command logic only runs for
    //  manual mode (not auto mode)
    const isManualSelected = e.target.value.toLowerCase() === 'manual';

    //  if props.UnitName exists then this
    //  is a unit level command
    //  else it is a site level command
    //  persist command logic only runs for
    //  site level commands
    const isUnitCommand = props.UnitName;

    //  only run for site commands in manual mode
    if (isManualSelected && !isUnitCommand && promptPersistCommand) {
      //  run code if siteMeta.AUTO_COMMAND_PERSIST === 'sometimes';
      //  pop the dialog to let user select yes / no
      //  set value to state (needed in callback)
      setSystemModeValue(e.target.value);

      //  pop persist command modal window
      setShowPersistCommandPopup(true);
      return;
    }

    //  Auto_Command_Persist = 'always' - so
    //  add the ::Persist suffix to the command string automatically
    const persistString =
      isManualSelected && !isUnitCommand && alwaysPersistCommands
        ? '::Persist'
        : '';
    const commandString = `System Mode::${e.target.value}${persistString}`;
    showConfPopup(commandString, 'mode');
  };

  const replaceNulls = () => {
    return props.UnitName.replace(/^0+/, '');
  };

  const getCommandsList = () => {
    if (props.siteMeta && props.popupProps) {
      const siteCommandsIsArray = Array.isArray(props.siteMeta.Site_Commands);
      const unitCommandsIsArray = Array.isArray(unitCommands);
      let unitCommandsArray = [];
      if (unitCommandsIsArray) {
        unitCommandsArray = unitCommands.map((k) => {
          return {
            label: k.Name,
            value: k.Command,
            multiselect: false,
          };
        });
      } else {
        unitCommandsArray = unitCommands
          ? Object.keys(unitCommands).map((k) => {
              return {
                label: k,
                value: unitCommands[k],
                multiselect: false,
              };
            })
          : [];
      }
      if (props.popupProps.popupType === 'site') {
        if (siteCommandsIsArray) {
          return props.siteMeta.Site_Commands.map((k) => {
            return {
              label: k.Name,
              value: k.Command,
              multiselect: k.Device === 'Unit',
            };
          });
        } else {
          return props.siteMeta.Site_Commands
            ? Object.keys(props.siteMeta.Site_Commands).map((k) => {
                return {
                  label: k,
                  value: props.siteMeta.Site_Commands[k],
                  multiselect: k !== 'Fault Reset',
                };
              })
            : [];
        }
      } else {
        return [
          ...unitCommandsArray,
          ...(props.siteMeta.Rack_Commands
            ? Object.keys(props.siteMeta.Rack_Commands).map((k) => {
                return {
                  label: k,
                  value: props.siteMeta.Rack_Commands[k],
                  multiselect: true,
                };
              })
            : []),
        ];
      }
    }
    return [];
  };

  const confirmLOTO = (tagout) => {
    if (props.popupProps.sn) {
      isConfirmLOTORef.current = true;
      if (tagout) {
        setLotoReleaseConfirm(false);
      } else {
        setLotoConfirm(false);
      }
    }
  };

  const updateLOTO = async (loto) => {
    await props.actions.setLOTO(loto).then(() => {
      props.actions.showComandsPopup(false, {}, loto);
      setLotoReleaseNotes('');
      setLotoReason('');

      props.actions.publishMQTT(
        `MSG/${loto.SiteSN}`,
        JSON.stringify({
          MSG: `Controls ${
            loto.Mode === 'lockout' ? 'Locked' : 'Unlocked'
          } by ${loto.Operator}`,
          ...loto,
        }),
      );
    });
  };

  useEffect(() => {
    if (isConfirmLOTORef.current === true) {
      isConfirmLOTORef.current = false;
      if (props.popupProps.sn) {
        (async () => {
          await updateLOTO({
            SiteSN: props.siteMeta.siteId,
            UnitSN: props.popupProps.sn,
            Note: lotoReleaseNotes,
            Mode: 'tagout',
            Operator: props.username,
            TS: new Date(),
          });
        })();
      }
    }
  }, [lotoReleaseConfirm]);
  useEffect(() => {
    if (isConfirmLOTORef.current === true) {
      isConfirmLOTORef.current = false;
      if (props.popupProps.sn) {
        (async () => {
          await updateLOTO({
            SiteSN: props.siteMeta.siteId,
            UnitSN: props.popupProps.sn,
            Note: lotoReason,
            Mode: 'lockout',
            Operator: props.username,
            TS: new Date(),
          });
        })();
      }
    }
  }, [lotoConfirm]);

  const getConvertedIdxStr = (i) => {
    return String(i + 1).padStart(
      String(props.siteMeta.NumConverters).length,
      '0',
    );
  };
  const unitCommands = props.popupProps.isPV
    ? props.siteMeta?.UnitPV_Commands
    : props.siteMeta?.Unit_Commands;
  const isConverterCommandsTableShown =
    mode === 'Manual' &&
    props.popupProps.popupType === 'unit' &&
    props.siteMeta?.NumConverters > 0;

  const allowedMinReal = +(props.allowedMinReal ?? props.AllowedMinP ?? -3);
  const allowedMaxReal = +(props.allowedMaxReal ?? props.AllowedMaxP ?? 10);
  const allowedMaxReactive = +(
    props.allowedMaxReactive ??
    +props.AllowedMaxQ ??
    10
  );
  const allowedMinReactive = +(
    props.allowedMinReactive ??
    +props.AllowedMinQ ??
    -3
  );
  const commandsList = getCommandsList();
  const loto =
    props.popupProps.popupType === 'unit'
      ? lotoTableRtl[props.popupProps.sn]
      : null;
  return (
    <div className='cover-container'>
      <Draggable bounds='body' disabled={overInput}>
        <div
          className='control-popup-container'
          style={{ minWidth: '540px', minHeight: '400px' }}
        >
          {(loading || isLotoLoading) && <Spinner cover='container' />}
          {(props.popupProps.popupType === 'site' && (
            <div className='title-line'>Site Commands</div>
          )) ||
            (props.popupProps.popupType === 'unit' && props.UnitName && (
              <div className='title-line'>
                Unit #{replaceNulls(props.UnitName)} Commands
              </div>
            ))}
          <div
            className='popup-close'
            onClick={() => props.actions.showComandsPopup(false, {})}
          />
          <div className='commands-popup-content'>
            {loto && (Object.keys(loto).length || loto.length) ? (
              <LotoReleaseForm
                {...{
                  loto,
                  setOverInput,
                  lotoReleaseNotes,
                  setLotoReleaseNotes,
                  setLotoReleaseConfirm,
                }}
              />
            ) : (
              <>
                <div
                  className='popup-content-col'
                  style={
                    isConverterCommandsTableShown
                      ? { marginRight: '40px' }
                      : null
                  }
                >
                  <div
                    className='popup-row mode'
                    onChange={(e) => changeMode(e)}
                  >
                    <div className='bold'>Mode</div>
                    <div className='comand-popup-row'>
                      <label className='inp-container' htmlFor='popupModeA'>
                        <input
                          type='radio'
                          value='Auto'
                          id='popupModeA'
                          defaultChecked={mode === 'Auto'}
                          name='mode'
                        />
                        <div>Auto</div>
                      </label>
                      <label className='inp-container' htmlFor='popupModeM'>
                        <input
                          type='radio'
                          value='Manual'
                          id='popupModeM'
                          name='mode'
                          defaultChecked={mode === 'Manual'}
                        />
                        <div>Manual</div>
                      </label>
                    </div>
                  </div>
                  {mode === 'Manual' && (
                    <PowerCommandForm
                      showConfPopup={showConfPopup}
                      allowedMinReal={allowedMinReal}
                      allowedMaxReal={allowedMaxReal}
                      allowedMinReactive={allowedMinReactive}
                      allowedMaxReactive={allowedMaxReactive}
                      Qset={props.Qset}
                      Pset={props.Pset}
                      setOverInput={setOverInput}
                    />
                  )}

                  {isConverterCommandsTableShown && (
                    <ConverterCommandsTable
                      getConvertedIdxStr={getConvertedIdxStr}
                      setOverInput={setOverInput}
                      setConvertersRealArr={setConvertersRealArr}
                      setConvertersReactiveArr={setConvertersReactiveArr}
                      showConfPopup={showConfPopup}
                      convertersRealArr={convertersRealArr}
                      allowedMinReal={allowedMinReal}
                      allowedMaxReal={allowedMaxReal}
                      convertersReactiveArr={convertersReactiveArr}
                      allowedMinReactive={allowedMinReactive}
                      allowedMaxReactive={allowedMaxReactive}
                      converterIdxStr={converterIdxStr}
                      isSendingConverterPowerCommands={
                        isSendingConverterPowerCommands
                      }
                      isConverterPowerValueChangedArr={
                        isConverterPowerValueChangedArr
                      }
                    />
                  )}
                </div>
                {commandsList.length ? (
                  <div className='popup-content-col comands popup-row'>
                    <div className='bold'>Commands</div>
                    <div className='popup-commands'>
                      {commandsList.map((com, i) => (
                        <Button
                          key={i}
                          onClick={() =>
                            showConfPopup(com.value, 'status', com.multiselect)
                          }
                        >
                          {com.label}
                        </Button>
                      ))}
                      {props.popupProps.popupType === 'unit' &&
                        !props.siteMeta.ui.Disable_Controls_Lock && (
                          <Button onClick={() => setLotoPopupIsOpen(true)}>
                            Controls Lock
                          </Button>
                        )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </Draggable>
      {lotoConfirm && (
        <div className='cover-container cover-container-confirm'>
          <Draggable bounds='body' disabled={overInput}>
            <div
              className='control-popup-container'
              style={{ padding: '20px 30px' }}
            >
              <div className='popup-row centre'>
                Controls locked by {props.username}
              </div>
              <div className='popup-row commands-popup-control'>
                <Button
                  size='m'
                  disabled={!lotoReason}
                  onClick={() => confirmLOTO()}
                >
                  OK
                </Button>
                <Button
                  size='m'
                  onClick={() => {
                    setLotoConfirm(false);
                    setLotoReason('');
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </Draggable>
        </div>
      )}
      {lotoReleaseConfirm && (
        <div className='cover-container cover-container-confirm'>
          <Draggable bounds='body' disabled={overInput}>
            <div
              className='control-popup-container'
              style={{ padding: '20px 30px' }}
            >
              <div className='popup-row centre'>
                Controls lock released by {props.username}
              </div>
              <div className='popup-row commands-popup-control'>
                <Button size='m' onClick={() => confirmLOTO(true)}>
                  OK
                </Button>
                <Button
                  size='m'
                  onClick={() => {
                    setLotoReleaseConfirm(false);
                    setLotoReleaseNotes('');
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </Draggable>
        </div>
      )}
      {lotoPopupIsOpen && (
        <LotoPopup
          overInput={overInput}
          setLotoPopupIsOpen={setLotoPopupIsOpen}
          setOverInput={setOverInput}
          lotoReason={lotoReason}
          setLotoReason={setLotoReason}
          setLotoConfirm={setLotoConfirm}
          invStatus={props.invStatus}
          LocRemCtl={props.LocRemCtl}
          batteryConnectingStatus={props.batteryConnectingStatus}
        />
      )}
      {showConfirmationPopup && (
        <ConfirmationPopup
          {...{
            commands,
            setCommands,
            unitTable: props.unitTable,
            UnitName: props.UnitName,
            itemsSelected,
            setItemsSelected,
            multiselectItems,
            setMultiselectItems,
            confirmationPopupChecklist,
            setConfirmationPopupChecklist,
            setShowConfirmationPopup,
            isSendingConverterPowerCommands,
            converterIdxStr,
            convertersReactiveArr,
            isConverterPowerValueChangedArr,
            convertersRealArr,
            getConvertedIdxStr,
            type,
            setMode,
            unitCommands,
            overInput,
            setOverInput,
            UNITS_CMD,
          }}
        />
      )}

      {showPersistCommandPopup && (
        <PersistCommandsPopup
          onClickHandler={persistCommandPopupOnSelectionClickCallback}
        />
      )}
    </div>
  );
};

export default storeConnector(SiteUnitCommandsPopup, {
  service: ['popupProps'],
  config: ['siteMeta'],
  user: ['username'],
});
