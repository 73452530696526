import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '@mui/material';
import { CollectionPayload } from '@src/types/Collection';

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
}

function GenerateDevicesButton({
  setJsonPayload,
  setOpen,
}: Props): React.ReactElement {
  const handleGenerateDevicesClick = () => {
    setJsonPayload({
      siteControllerId: '0000000A',
      unitControllerIds: ['0000001F', '0000002F'],
      sourceDevices: [
        {
          type: 'tsc',
          parentType: 'siteController',
          count: 4,
        },
        {
          type: 'mp',
          parentType: 'tsc',
          count: 4,
          namePrefix: 'Megapack',
        },
        {
          type: 'bms',
          parentType: 'unitController',
          count: 4,
        },
        {
          type: 'rack',
          parentType: 'bms',
          count: 2,
        },
        {
          type: 'meter',
          parentType: 'siteController',
          count: 2,
        },
        {
          type: 'pcs',
          parentType: 'unitController',
          count: 2,
        },
      ],
    });
    setOpen(true);
  };

  return (
    <Button
      component='label'
      variant='contained'
      onClick={handleGenerateDevicesClick}
    >
      Generate Devices
    </Button>
  );
}

export default GenerateDevicesButton;
