import React, { Dispatch, SetStateAction } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { CollectionPayload, DeviceExtended } from '@src/types/Collection';
import { GridRowId } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import useDeviceMutations from '@src/hooks/api/mutations/useDeviceMutations';

interface DialogProps {
  jsonData: CollectionPayload;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleCancel: (id?: GridRowId) => () => void;
}

function DeleteDeviceDialog({ jsonData, handleCancel, setOpen }: DialogProps) {
  const { id } = jsonData as DeviceExtended;
  const queryClient = useQueryClient();

  const { deleteDevice } = useDeviceMutations(id);

  const handleDeviceDelete = () =>
    deleteDevice.mutate(
      {},
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.devices],
          });
          setOpen(false);
        },
      },
    );

  return (
    <Dialog open>
      <DialogContent>Are you sure you want to delete?</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel(id)}>Cancel</Button>
        <Button size='small' variant='contained' onClick={handleDeviceDelete}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDeviceDialog;
