import React, { useEffect, useState } from 'react';
import storeConnector from '@store/storeConnector';
import styles from './Clock.module.css';
import { optionsSelectTZ } from '@utils';
import Input from '../Input/Input';
import { tsDueTimeMode } from '@utils/index_ts';

// eslint-disable-next-line max-len
const Clock = ({
  actions,
  timeMode,
  siteMeta,
  updateFinished,
  showMap,
  audioNotificationEnabled,
  bannerNotificationEnabled,
  darkTheme,
}) => {
  const [, setRenderKey] = useState(0);

  const setTimeMode = (val) => {
    actions.setTimeMode(val);

    //  update Keycloak - this supports maintaining timeMode
    //  across browser refreshes and new sessions
    const userKeycloakData = {
      timeMode: val.toLowerCase(),
      showMap,
      theme: darkTheme ? 'dark' : 'light',
      audioNotificationEnabled,
      bannerNotificationEnabled,
    };

    actions.changeUserData(userKeycloakData).then();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRenderKey({ renderKey: Math.random() });
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const getCurrentTime = () => {
    return tsDueTimeMode({
      timeMode: timeMode,
      lat: siteMeta.GPSLat,
      long: siteMeta.GPSLong,
      splited: true,
    });
  };

  const tsArr = getCurrentTime();
  return (
    <div className={styles.container}>
      <div className={styles.selectRow}>
        <span>Time Mode:</span>
        <Input
          type='dropdown'
          placeholder='Select Device'
          ddMaxHeight='260'
          value={{ value: timeMode }}
          styleDD={{
            maxWidth: '60px',
            minWidth: '60px',
            marginRight: 'auto',
          }}
          onChange={(val) => setTimeMode(optionsSelectTZ[val])}
          options={optionsSelectTZ}
        />
      </div>
      <div className={styles.time}>
        <div>{tsArr[0]}</div>
        <div>
          <div>{tsArr[1]}</div>
          <div
            style={{
              height: 5,
              background: 'green',
              borderRadius: 5,
              visibility: updateFinished ? 'visible' : 'hidden',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default storeConnector(Clock, {
  config: ['siteMeta'],
  service: [
    'updateFinished',
    'timeMode',
    'showMap',
    'darkTheme',
    'audioNotificationEnabled',
    'bannerNotificationEnabled',
  ],
});
