import React, { useRef, useState } from 'react';
import './SiteUnitCommandsPopup.scoped.scss';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';
import { roundToTwoNonZero, isNumeric } from '@utils/mathHelpers';

const PowerCommandForm = ({
  popupProps,
  Qset,
  Pset,
  showConfPopup,
  allowedMinReal,
  allowedMaxReal,
  allowedMinReactive,
  allowedMaxReactive,
  setOverInput,
}) => {
  const [reactive, setReactive] = useState(+Qset || 0);
  const [real, setReal] = useState(+Pset || 0);
  const inputFirstRef = useRef();
  const inputSecondRef = useRef();

  const onEnter = (key) => {
    if (key === 0) {
      inputSecondRef.current.focus();
    }
    if (key === 1) {
      const invokeCurrentFocus = () => {
        const refObject =
          real > allowedMinReal && real < allowedMaxReal
            ? inputSecondRef
            : inputFirstRef;
        refObject.current.focus();
      };

      const isReactiveError = getErrorClass(
        reactive,
        allowedMinReactive,
        allowedMaxReactive,
      );
      const isRealError = getErrorClass(real, allowedMinReal, allowedMaxReal);

      if (real || reactive || !isReactiveError || !isRealError) {
        showConfPopup(`Power::${real}::${reactive}`, 'value');
      } else {
        invokeCurrentFocus();
      }
    }
  };
  // Function to get error class based on conditions
  const getErrorClass = (value, allowedMin, allowedMax) => {
    if (Number.isNaN(allowedMax)) {
      return '';
    } // No error if max is not a number
    if (!isNumeric(value)) {
      return 'error';
    }
    return value >= parseFloat(allowedMin) && value <= parseFloat(allowedMax)
      ? ''
      : 'error';
  };

  return (
    <>
      <div className='popup-row'>
        <div className='bold vertical-margin'>
          {popupProps.popupType === 'unit' ? 'Unit Power Command' : 'Power'}
        </div>
        <div className='popup-table'>
          <div className='popup-table-col'>
            <div>Real (P):</div>
            <div className='range-lable' />
            <div>Reactive (Q):</div>
            <div className='range-lable' />
          </div>
          <div className='popup-table-col'>
            <div>
              <input
                ref={inputFirstRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onEnter(0);
                    e.preventDefault();
                  }
                }}
                className={
                  'comand-popup-inp ' +
                  getErrorClass(real, allowedMinReal, allowedMaxReal)
                }
                type='number'
                step='0.1'
                value={real}
                onMouseEnter={() => setOverInput(true)}
                onMouseLeave={() => setOverInput(false)}
                onChange={(e) => setReal(e.target.value)}
              />
            </div>
            <div className='range-lable'>
              Range from {roundToTwoNonZero(allowedMinReal)} to{' '}
              {roundToTwoNonZero(allowedMaxReal)}
            </div>
            <div>
              <input
                ref={inputSecondRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onEnter(1);
                    e.preventDefault();
                  }
                }}
                className={
                  'comand-popup-inp ' +
                  getErrorClass(
                    reactive,
                    allowedMinReactive,
                    allowedMaxReactive,
                  )
                }
                type='number'
                step='0.1'
                value={reactive}
                onMouseEnter={() => setOverInput(true)}
                onMouseLeave={() => setOverInput(false)}
                onChange={(e) => setReactive(e.target.value)}
              />
            </div>

            {!isNaN(allowedMinReactive) && (
              <div className='range-lable'>
                Range from {roundToTwoNonZero(allowedMinReactive)} to&nbsp;
                {roundToTwoNonZero(allowedMaxReactive)}
              </div>
            )}
          </div>
          <div className='popup-table-col'>
            <div>kW</div>
            <div className='range-lable' />
            <div>kvar</div>
            <div className='range-lable' />
          </div>
        </div>
      </div>
      {getErrorClass(real, allowedMinReal, allowedMaxReal) === 'error' && (
        <div className='popup-row warn-alert'>
          The value of real power must be between {allowedMinReal} and{' '}
          {allowedMaxReal}
        </div>
      )}
      {getErrorClass(reactive, allowedMinReactive, allowedMaxReactive) ===
        'error' && (
        <div className='popup-row warn-alert'>
          The value of reactive power must be between{' '}
          {roundToTwoNonZero(allowedMinReactive)} and{' '}
          {roundToTwoNonZero(allowedMaxReactive)}
        </div>
      )}
      <div className='popup-row commands-popup-control'>
        <Button
          size='m'
          disabled={
            real === '' ||
            reactive === '' ||
            getErrorClass(reactive, allowedMinReactive, allowedMaxReactive) ===
              'error' ||
            getErrorClass(real, allowedMinReal, allowedMaxReal) === 'error'
          }
          onClick={() => showConfPopup(`Power::${real}::${reactive}`, 'value')}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default storeConnector(PowerCommandForm, {
  service: ['popupProps'],
});
