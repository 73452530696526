import {
  actionRoleGroups,
  adminRoleGroups,
  engineerRoleGroups,
  UserRole,
} from './roles';

const isSuperAdmin = (role: string) => role === 'SuperAdmin';

const isAdmin = (role: string) => adminRoleGroups.includes(role);

const engineerOrAdmin = (role: string) => engineerRoleGroups.includes(role);

const isActionRole = (role: string) => actionRoleGroups.includes(role);

const getUserRole = (roles: string[]): UserRole => {
  if (roles.includes('superadmin')) {
    return 'SuperAdmin';
  }
  if (roles.includes('administrator')) {
    return 'Administrator';
  }
  if (roles.includes('engineer')) {
    return 'Engineer';
  }
  if (roles.includes('operator')) {
    return 'Operator';
  }
  if (roles.includes('viewer')) {
    return 'Viewer';
  }
  return '-No roles-';
};

export { isSuperAdmin, isAdmin, engineerOrAdmin, isActionRole, getUserRole };
