import React, { useEffect, useMemo, useRef, useState } from 'react';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { getPCSFields, UNIT_FIELDS } from './constants.js';
import storeConnector from '@store/storeConnector';
import { isKeyInObject } from '@src/utils/index.js';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 30,
    height: '38vh',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    width: 140,
    justifyContent: 'space-between',
    marginLeft: 15,
    marginBottom: 10,
    fontSize: 12,
  },
};

const PCSContainerEPC = ({
  siteMeta,
  currentUnitDeviceId,
  pcsType,
  fromPV,
  pcsIndex,
}) => {
  const [unitRt, setUnitRt] = useState({});
  const [pcsRt, setPCSRt] = useState({});
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  useEffect(() => {
    setUnitRt({});
    setPCSRt({});
  }, [currentUnitDeviceId]);

  const useNewTopicStructure = siteMeta.ui.Use_New_Topic_Structure;

  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';
  const uiOrderNoArr = Array.from(
    { length: siteMeta.Batt_perUnit_PCS_count },
    (_v, i) => i + 1 + siteMeta.Batt_perUnit_PCS_count * unitNameIdx,
  );

  const oldTopicStructureAttributePrefix = `pcs${pcsIndex + 1}_`;
  const pcsFields = useMemo(
    () =>
      useNewTopicStructure
        ? getPCSFields(pcsType)
        : getPCSFields(pcsType).map(
            (field) => `${oldTopicStructureAttributePrefix}${field}`,
          ),
    [],
  );

  useWsSubscribe(
    {
      unit: {
        sns: [currentUnitDeviceId],
        fields: UNIT_FIELDS,
        cb: (data) => setUnitRt((prev) => ({ ...prev, ...data })),
      },
      pcs: {
        sns: [currentUnitDeviceId],
        fields: pcsFields,
        sourceDeviceId: `pcs_${pcsIndex + 1}`,
        cb: (data) => setPCSRt((prev) => ({ ...prev, ...data })),
      },
    },
    [currentUnitDeviceId],
  );

  const getData = () => {
    return [
      [
        {
          field: 'Status',
          value: pcsRt.InvStatus,
          loading: isPCSLoading('InvStatus'),
        },
        {
          field: 'Mode',
          value: pcsRt.RunMode,
          loading: isPCSLoading('RunMode'),
        },
        {
          field: 'AC Breaker',
          value:
            Number(pcsRt.ACBreaker) === 1
              ? 'Closed'
              : Number(pcsRt.ACBreaker) === 0
                ? 'Open'
                : 'ERR',
          loading: isPCSLoading('ACBreaker'),
        },
        { field: 'Frequency', value: pcsRt.Hz, loading: isPCSLoading('Hz') },
        {
          field: 'PCS Heartbeat',
          value: pcsRt.WatchdogResponse || '-',
          loading: isPCSLoading('WatchdogResponse'),
        },
        {
          field: 'Chg Power Limit ',
          value: pcsRt.PCS_MinP,
          loading: isPCSLoading('PCS_MinP'),
        },
        {
          field: 'Dchg Power Limit',
          value: pcsRt.PCS_MaxP,
          loading: isPCSLoading('PCS_MaxP'),
        },
        {
          field: 'kW Cmd',
          value: pcsRt.Pcmd_Sent,
          loading: isPCSLoading('Pcmd_Sent'),
        },
        {
          field: 'kVAR Cmd',
          value: pcsRt.PCS_Q,
          loading: isKeyInObject(unitRt, 'PCS_Q'),
        },
        {
          field: 'kW Setpoint',
          value: pcsRt.PCS_P,
          loading: isKeyInObject(unitRt, 'PCS_P'),
        },
      ],
      [
        { field: 'AC kW', value: pcsRt.kW, loading: isPCSLoading('kW') },
        { field: 'AC kVar', value: pcsRt.kVAR, loading: isPCSLoading('kVAR') },
        { field: 'AC kVA', value: pcsRt.kVA, loading: isPCSLoading('kVA') },
      ],
      [
        { field: 'Va', value: pcsRt.PhVphA, loading: isPCSLoading('PhVphA') },
        { field: 'Vb', value: pcsRt.PhVphB, loading: isPCSLoading('PhVphB') },
        { field: 'Vc', value: pcsRt.PhVphC, loading: isPCSLoading('PhVphC') },
        { field: 'Ia', value: pcsRt.AphA, loading: isPCSLoading('AphA') },
        { field: 'Ib', value: pcsRt.AphB, loading: isPCSLoading('AphB') },
        { field: 'Ic', value: pcsRt.AphC, loading: isPCSLoading('AphC') },
        { field: 'PFa', value: pcsRt.PFa, loading: isPCSLoading('PFa') },
        { field: 'PFb', value: pcsRt.PFb, loading: isPCSLoading('PFb') },
        { field: 'PFc', value: pcsRt.PFc, loading: isPCSLoading('PFc') },
      ],
      [
        {
          field: 'Coolant Temp',
          value: pcsRt.PCS_TmpInlt,
          loading: isPCSLoading('PCS_TmpInlt'),
        },
        {
          field: 'Water Inlet Temp',
          value: pcsRt.PCS_tInlet,
          loading: isPCSLoading('PCS_tInlet'),
        },
        {
          field: 'Bridge A Temp',
          value: pcsRt.PCS_tBridgeA,
          loading: isPCSLoading('PCS_tBridgeA'),
        },
        {
          field: 'Bridge B Temp',
          value: pcsRt.PCS_tBridgeB,
          loading: isPCSLoading('PCS_tBridgeB'),
        },
      ],
      [
        {
          field: 'DCA High Side',
          value: pcsRt.DCAHi,
          loading: isPCSLoading('DCAHi'),
        },
        {
          field: 'DCV High Side',
          value: pcsRt.DCVHi,
          loading: isPCSLoading('DCVHi'),
        },
      ],
    ];
  };

  const isPCSLoading = (field) => {
    return !Object.keys(pcsRt).includes(field);
  };

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell unit-cell'>
        <div className='unit-info'>
          <div className='unit-col'>
            <div className='title-large'>
              {`${unitName && unitName + ' '}PCS ${
                uiOrderNoArr[pcsIndex]
              } Status${pcsType ? ` - ${pcsType}` : ''}`}
              <span
                style={{
                  marginLeft: '10px',
                  opacity: '0.8',
                  fontWeight: 'normal',
                }}
              >
                {pcsRt.Description}
              </span>
            </div>
            <div style={{ display: 'flex' }}>
              {getData().map((arr) => (
                <div key={Math.random()} style={styles.container}>
                  {arr.map((f) => (
                    <div style={styles.field} key={f.field}>
                      <div style={{ fontWeight: 'bold' }}>{f.field}</div>
                      <div className={f.class}>
                        <TextLoader orientation='l' loading={f.loading}>
                          {f.value}
                        </TextLoader>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(PCSContainerEPC, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
