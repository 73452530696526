import React, { ReactElement, useState } from 'react';
import { SavedSearch } from '@types';
import SelectForm from './SelectForm';
import EditForm from './EditForm';

interface SavedSearchProps {
  setActiveSearch: React.Dispatch<SavedSearch>;
  activeSearch: SavedSearch;
  newSearch: SavedSearch;
  setSearchUnderEdit: React.Dispatch<SavedSearch>;
  // eslint-disable-next-line react/require-default-props
  searchUnderEdit?: SavedSearch;
  setSearchField: (field: string, value: string) => void;
}
function SavedSearchContent(props: SavedSearchProps): ReactElement {
  const { searchUnderEdit, ...rest } = props;
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);
  const searchOptions = savedSearches.map((search) => search.name);
  if (searchUnderEdit) {
    return (
      <EditForm
        searchOptions={searchOptions}
        searchUnderEdit={searchUnderEdit}
        {...rest}
      />
    );
  }
  return (
    <SelectForm
      savedSearches={savedSearches}
      setSavedSearches={setSavedSearches}
      searchOptions={searchOptions}
      {...rest}
    />
  );
}

function SavedSearchRow(props: SavedSearchProps): ReactElement {
  return (
    <div className='inputs-row no-pd-top with-buttons-search'>
      <SavedSearchContent {...props} />
    </div>
  );
}

export default SavedSearchRow;
