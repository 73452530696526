import React, { useEffect, useRef, useState } from 'react';
import './Input.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner/Spinner';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

const Input = ({
  type,
  options = [],
  onChange,
  ddMaxHeight = '',
  value = {},
  label = '',
  checked = false,
  placeholder = '',
  objModeLabel = null,
  afterOptBtn = null,
  error = '',
  disabled = false,
  className = '',
  showPasswordIcon = null,
  noAutocomplete = true,
  styleDD = {},
  disabledOptions = null,
  onClick = null,
  onMouseEnter = null,
  onMouseLeave = null,
  noError = null,
  rememberScrollPosition = false,
  columns = null,
  keyField = null,
  numOfPages = null,
  currentPage = null,
  loading = null,
  changePage = null,
  setFilterStr = null,
}) => {
  const [ddActive, setDDActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ddDirectionBottom, setDDDirectionBottom] = useState(true);
  const [filterInp, setFilterInp] = useState(value?.value || '');
  const [filterInpTablePagin, setFilterInpTablePagin] = useState(
    value?.value || '',
  );
  const wrapperRef = useRef();
  const timeHoursRef = useRef();
  const timeMinutesRef = useRef();
  const setFilterDebounceRef = useRef(null);

  const handleFilterInpTablePaginChange = (val) => {
    setFilterInpTablePagin(val);

    if (setFilterDebounceRef.current) {
      clearTimeout(setFilterDebounceRef.current);
    }
    setFilterDebounceRef.current = setTimeout(() => {
      setFilterStr(val);
    }, 500);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    if (type === 'time') {
      const time = getTimeStrs();
      timeHoursRef.current.value = time[0];
      timeMinutesRef.current.value = time[1];
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (
        ['dropdown', 'multiselect-dropdown', 'dropdown-multilevel'].includes(
          type,
        )
      ) {
        setDDActive(false);
      }
    }
  };
  const getMultiLevelValueText = () => {
    const opts = JSON.parse(JSON.stringify(options));
    return opts
      .map((opt) => {
        if (opt.inputs && opt.isActive) {
          return (
            opt.label +
            '(' +
            opt.inputs.map((inp) => `${inp.label}:${inp.value}`).join(', ') +
            ')'
          );
        } else if (
          opt.options &&
          opt.options.length &&
          opt.options.find((o) => o.isActive)
        ) {
          return (
            opt.label +
            '(' +
            opt.options
              .filter((o) => o.isActive)
              .map((inp) => inp.label)
              .join(', ') +
            ')'
          );
        } else if (!opt.options && opt.isActive) {
          return opt.label;
        } else {
          return false;
        }
      })
      .filter((o) => o !== false)
      .join('\r\n');
  };
  const setMultiLevelValue = (action, params) => {
    const opts = JSON.parse(JSON.stringify(options));
    if (action === 'showSubOpt') {
      opts[params.index].isActive = params.value;
    }
    if (action === 'checkSubOpt') {
      opts[params.index].options[params.indexSub].isActive =
        !opts[params.index].options[params.indexSub].isActive;
    }
    onChange(opts);
  };
  const isMultiSelectOptionActive = (opt) => {
    if (opt.isActive && !opt.options) {
      return true;
    }
    return !!(opt.options && opt.options.find((o) => o.isActive));
  };
  const selectUnselectAllSub = (value, index) => {
    const opts = JSON.parse(JSON.stringify(options));
    opts[index] = {
      ...opts[index],
      options: opts[index].options.map((o) => ({ ...o, isActive: value })),
    };
    onChange(opts);
  };
  const onChangeInpMultilevel = (value, index, indexSub) => {
    const opts = JSON.parse(JSON.stringify(options));
    opts[index] = {
      ...opts[index],
      inputs: opts[index].inputs.map((inp, i) => ({
        ...inp,
        value: i === indexSub ? value : inp.value,
      })),
    };
    onChange(opts);
  };
  const handleDDActive = () => {
    if (!ddActive) {
      if (type === 'dropdown-searchable') {
        setFilterInp(value?.value || '');
      }
      const bodyHeight = document.body.clientHeight;
      const rect = document
        .getElementById('dd-inp-for-direction')
        .getBoundingClientRect();
      const yBottom = rect.y + rect.height;
      const ddMaxH = ddMaxHeight || 106;
      if (ddMaxH + yBottom > bodyHeight) {
        setDDActive((ddActive) => !ddActive);
        setDDDirectionBottom(false);
      } else {
        setDDActive((ddActive) => !ddActive);
        setDDDirectionBottom(true);
      }
      return;
    }
    setDDActive((ddActive) => !ddActive);
  };

  const getTimeStrs = () => {
    if (type === 'time' && (value || value === 0)) {
      const hours = Math.floor(+value / 3600);
      const minutes = (value - hours * 3600) / 60;
      return [hours || '0', minutes || '00'];
    }
    return ['0', '00'];
  };
  const formatValue = (index, val) => {
    if (index === 0) {
      return +val;
    }
    if (index === 1) {
      if (+val <= 59) {
        return +val;
      }
      if (+val / 100 >= 1) {
        const newVal = ('' + val).substring(0, 2);
        if (+newVal <= 59) {
          return +newVal;
        }
        return 59;
      }
    }
  };
  const onChangetime = (ref, index) => {
    const hours = formatValue(0, timeHoursRef.current.value);
    const minutes = formatValue(1, timeMinutesRef.current.value);
    const newTime = hours * 3600 + minutes * 60;
    ref.current.value = index === 0 ? hours : minutes;
    onChange(newTime);
  };
  // noinspection XmlDeprecatedElement
  return (
    <>
      {type === 'smartsearchinput' && (
        <span
          id='dd-inp-for-direction'
          className={`smart-search-input ${
            disabled && 'disabled'
          } input-field ${ddActive ? 'act' : ''} ${className}`}
        >
          <>
            <input
              onClick={onClick}
              className={`${
                disabled && 'disabled'
              } inp-container-gen  input-field ${
                ddActive ? 'act' : ''
              } ${className}`}
              ref={wrapperRef}
              type='text'
              autoComplete={noAutocomplete ? 'new-password' : ''}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={(e) => onChange(e.target.value)}
            />
          </>
        </span>
      )}
      {type === 'dropdown-searchable-table-paginated' && (
        <div
          style={styleDD || {}}
          className={`${className} ${
            disabled && 'disabled'
          } inp-container-gen dir-btm-${ddDirectionBottom} input-scrollbar with-paginated-table`}
          ref={wrapperRef}
        >
          {label && <div className='hint-label'>{label}</div>}
          <div
            className={`input-field ${ddActive && !disabled ? 'act' : ''}`}
            onClick={() => handleDDActive()}
            id='dd-inp-for-direction'
          >
            <div
              className={`${
                [null, undefined, false].includes(value) && 'placeholder'
              }`}
            >
              {ddActive ? (
                <input
                  onClick={(e) => {
                    const event = e || window.event;
                    event.stopPropagation();
                  }}
                  type='text'
                  autoFocus
                  autoComplete='new-password'
                  value={filterInpTablePagin.current}
                  placeholder={placeholder || ''}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      const i = filterInpTablePagin.current
                        ? options.findIndex((optItem) => {
                            return optItem[keyField]
                              .toLowerCase()
                              .includes(
                                filterInpTablePagin.current.toLowerCase(),
                              );
                          })
                        : 0;
                      if (i !== -1) {
                        onChange(options[i][keyField]);
                        setDDActive(false);
                      }
                    }
                  }}
                  onChange={(e) =>
                    handleFilterInpTablePaginChange(e.target.value)
                  }
                />
              ) : (
                <span
                  title={
                    ([null, undefined, false].includes(value) && placeholder) ||
                    value.value ||
                    (value.index !== undefined ? options[value.index] : '')
                  }
                >
                  {([null, undefined, false].includes(value) &&
                    placeholder &&
                    placeholder) ||
                    value.value ||
                    (value.index !== undefined ? options[value.index] : '')}
                </span>
              )}
            </div>
            <div
              className={`cntrl-btn ${
                ddActive && !disabled ? 'close-cntrl' : 'open'
              }`}
            />
          </div>
          {
            <div
              className={`dd-options${
                ddActive && !disabled
                  ? ''
                  : rememberScrollPosition
                    ? ' dd-hidden'
                    : ' hide'
              }`}
              style={ddMaxHeight ? { maxHeight: ddMaxHeight + 'px' } : {}}
            >
              {loading ? (
                <Spinner type='content-small' />
              ) : (
                <>
                  {options.length ? (
                    <>
                      <table>
                        <thead>
                          <tr className='dd-opt'>
                            {columns.map((c, ci) => (
                              <th key={ci}>{c.title}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {options.map((opt) => (
                            <tr
                              key={Math.random()}
                              className='dd-opt'
                              onClick={() => {
                                onChange(opt[keyField]);
                                setDDActive(false);
                              }}
                            >
                              {columns.map((c, ci) => (
                                <td key={ci}>{opt[c.field]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {numOfPages > 1 && (
                        <div className='pgn-container'>
                          <Pagination
                            numOfPages={numOfPages}
                            currentPage={currentPage}
                            noBtnText
                            classType='input-pagination'
                            changeCurrPage={(val) => changePage(val)}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className='placeholder-options-pgn'>
                      no options for selected filters
                    </div>
                  )}
                </>
              )}
            </div>
          }
        </div>
      )}
      {type === 'dropdown-searchable' && (
        <div
          style={styleDD || {}}
          className={`${className} ${
            disabled && 'disabled'
          } inp-container-gen dir-btm-${ddDirectionBottom} input-scrollbar`}
          ref={wrapperRef}
        >
          {label && <div className='hint-label'>{label}</div>}
          <div
            className={`input-field ${ddActive ? 'act' : ''}`}
            onClick={() => handleDDActive()}
            id='dd-inp-for-direction'
          >
            <div
              className={`${
                [null, undefined, false].includes(value) && 'placeholder'
              }`}
            >
              {ddActive ? (
                <input
                  onClick={(e) => {
                    const event = e || window.event;
                    event.stopPropagation();
                  }}
                  type='text'
                  autoFocus
                  autoComplete='new-password'
                  value={filterInp}
                  disabled={disabled}
                  placeholder={placeholder || ''}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      const i = filterInp
                        ? options.findIndex((optItem) => {
                            return optItem
                              .toLowerCase()
                              .includes(filterInp.toLowerCase());
                          })
                        : 0;
                      if (i !== -1) {
                        onChange(i);
                        setDDActive(false);
                      }
                    }
                  }}
                  onChange={(e) => setFilterInp(e.target.value)}
                />
              ) : (
                <span
                  title={
                    ([null, undefined, false].includes(value) && placeholder) ||
                    value.value ||
                    (value.index !== undefined ? options[value.index] : '')
                  }
                >
                  {([null, undefined, false].includes(value) &&
                    placeholder &&
                    placeholder) ||
                    value.value ||
                    (value.index !== undefined ? options[value.index] : '')}
                </span>
              )}
            </div>
            <div className={`cntrl-btn ${ddActive ? 'close-cntrl' : 'open'}`} />
          </div>
          {options.filter((el) => {
            return (
              value?.value === filterInp ||
              (value?.value !== filterInp &&
                el.toLowerCase().includes(filterInp.toLowerCase()))
            );
          })?.length ? (
            <div
              className={`dd-options${
                ddActive ? '' : rememberScrollPosition ? ' dd-hidden' : ' hide'
              }`}
              style={ddMaxHeight ? { maxHeight: ddMaxHeight + 'px' } : {}}
            >
              {options.map((opt, i) =>
                value?.value === filterInp ||
                (value?.value !== filterInp &&
                  opt.toLowerCase().includes(filterInp.toLowerCase())) ? (
                  <div
                    key={i}
                    className={`dd-opt ${
                      disabledOptions &&
                      disabledOptions.includes(opt) &&
                      'disabled-opt'
                    }`}
                    onClick={() => {
                      if (disabledOptions && disabledOptions.includes(opt)) {
                        return;
                      }
                      onChange(i);
                      setDDActive(false);
                    }}
                    title={`${
                      !(disabledOptions && disabledOptions.includes(opt))
                        ? opt
                        : ''
                    }`}
                  >
                    {opt}
                    {afterOptBtn &&
                      Object.keys(opt).includes(afterOptBtn.field) && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            afterOptBtn.onClick(opt);
                          }}
                          className={`after-btn ${
                            opt[afterOptBtn.field]
                              ? afterOptBtn.activeIconClass
                              : afterOptBtn.nonActiveIconClass
                          } `}
                        />
                      )}
                  </div>
                ) : (
                  <React.Fragment key={i} />
                ),
              )}
            </div>
          ) : (
            <div
              className={`dd-options hint ${!ddActive && 'hide'}`}
              style={ddMaxHeight ? { maxHeight: ddMaxHeight + 'px' } : {}}
            >
              <div className='placeholder-options'>
                no options with {filterInp}
              </div>
            </div>
          )}
        </div>
      )}
      {type === 'dropdown' && (
        <div
          style={styleDD || {}}
          className={`${className} ${
            disabled && 'disabled'
          } inp-container-gen dir-btm-${ddDirectionBottom} input-scrollbar`}
          ref={wrapperRef}
        >
          {label && <div className='hint-label'>{label}</div>}
          <div
            className={`input-field ${ddActive ? 'act' : ''}`}
            onClick={() => handleDDActive()}
            id='dd-inp-for-direction'
          >
            <>
              {objModeLabel ? (
                <div>
                  {value
                    ? typeof value === 'string'
                      ? value
                      : value[objModeLabel]
                    : placeholder || ' '}
                </div>
              ) : (
                <div
                  className={`${
                    [null, undefined, false].includes(value) && 'placeholder'
                  }`}
                >
                  <span
                    title={
                      ([null, undefined, false].includes(value) &&
                        placeholder &&
                        placeholder) ||
                      value.value ||
                      (value.index !== undefined ? options[value.index] : '')
                    }
                  >
                    {([null, undefined, false].includes(value) &&
                      placeholder &&
                      placeholder) ||
                      value.value ||
                      (value.index !== undefined ? options[value.index] : '')}
                  </span>
                </div>
              )}
            </>
            <div className={`cntrl-btn ${ddActive ? 'close-cntrl' : 'open'}`} />
          </div>
          {options?.length ? (
            <div
              className={`dd-options${
                ddActive ? '' : rememberScrollPosition ? ' dd-hidden' : ' hide'
              }`}
              style={ddMaxHeight ? { maxHeight: ddMaxHeight + 'px' } : {}}
            >
              {options.map((opt, i) => (
                <div
                  key={i}
                  className={`dd-opt ${
                    disabledOptions &&
                    disabledOptions.includes(opt) &&
                    'disabled-opt'
                  }`}
                  onClick={() => {
                    if (disabledOptions && disabledOptions.includes(opt)) {
                      return;
                    }
                    onChange(objModeLabel ? opt : i);
                    setDDActive(false);
                  }}
                  title={`${
                    !objModeLabel &&
                    !(disabledOptions && disabledOptions.includes(opt))
                      ? opt
                      : ''
                  }`}
                >
                  {objModeLabel ? opt[objModeLabel] || opt : opt}
                  {afterOptBtn &&
                    Object.keys(opt).includes(afterOptBtn.field) && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          afterOptBtn.onClick(opt);
                        }}
                        className={`after-btn ${
                          opt[afterOptBtn.field]
                            ? afterOptBtn.activeIconClass
                            : afterOptBtn.nonActiveIconClass
                        } `}
                      />
                    )}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {type === 'multiselect-dropdown' && (
        <div
          style={styleDD || {}}
          className={`${className} ${
            disabled && 'disabled'
          } inp-container-gen dir-btm-${ddDirectionBottom}`}
          ref={wrapperRef}
        >
          <div
            className={`input-field ${ddActive ? 'act' : ''}`}
            onClick={() => handleDDActive()}
            id='dd-inp-for-direction'
          >
            <>
              {
                <div className={`${!value.length && 'placeholder'}`}>
                  <span
                    title={!value.length ? placeholder || '' : value.join(', ')}
                  >
                    {!value.length ? placeholder || '' : value.join(', ')}
                  </span>
                </div>
              }
            </>
            <div className={`cntrl-btn ${ddActive ? 'close-cntrl' : 'open'}`} />
          </div>
          {options.length && ddActive ? (
            <div
              className='dd-options'
              style={ddMaxHeight ? { maxHeight: ddMaxHeight + 'px' } : {}}
            >
              {options.map((opt, i) => (
                <div
                  key={i}
                  className={`dd-opt ${
                    value.includes(opt) && 'multiselect-choosen'
                  }`}
                  onClick={() => {
                    onChange(
                      value.includes(opt)
                        ? value.filter((v) => v !== opt)
                        : [...value, opt],
                    );
                  }}
                  title={opt}
                >
                  {opt}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {type === 'number' && (
        <div className={`inp-container-text ${className}`}>
          <div className='col-inp-container'>
            {error && <div className='err'>{error || ''}</div>}
            <input
              className={`${error && 'with-error'}`}
              type='number'
              value={value}
              disabled={disabled}
              onChange={(e) => {
                if (e.target.value && ('' + e.target.value).length < 5) {
                  onChange(e.target.value);
                }
              }}
              placeholder={placeholder}
            />
          </div>
        </div>
      )}
      {['text', 'password'].includes(type) && (
        <div className={`inp-container-text ${className}`}>
          <div className='col-inp-container'>
            {!noError && <div className='err'>{error || ''}</div>}
            <input
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              className={`${error && 'with-error'}`}
              ref={wrapperRef}
              type={showPassword ? 'text' : type}
              autoComplete={noAutocomplete ? 'new-password' : ''}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={(e) => {
                if (onChange(e.target.value)) {
                  onChange(e.target.value);
                }
              }}
            />
          </div>
          {type === 'password' && showPasswordIcon && (
            <div
              className='show-pass'
              onClick={() => setShowPassword((showPassword) => !showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </div>
          )}
          <div />
        </div>
      )}
      {type === 'checkbox' && (
        <div
          onClick={() => onChange()}
          className={`input-checkbox ${disabled && 'disabled'} ${className} ${
            value ? 'act' : ''
          }`}
        />
      )}
      {type === 'time' && (
        <div className='inp-container-text time'>
          <span>
            <input
              type='number'
              ref={timeHoursRef}
              placeholder='dd'
              onChange={() => onChangetime(timeHoursRef, 0)}
            />
            <span>hours</span>
          </span>
          &nbsp; : &nbsp;
          <span>
            <input
              type='number'
              placeholder='hh'
              ref={timeMinutesRef}
              max={59}
              onChange={() => onChangetime(timeMinutesRef, 1)}
            />
            <span>minutes</span>
          </span>
        </div>
      )}
      {type === 'dropdown-multilevel' && (
        <div
          className={`${className} ${ddActive ? 'act' : ''} ${
            disabled && 'disabled'
          } inp-container-gen`}
          ref={wrapperRef}
        >
          <div
            className={`input-field ${ddActive ? 'act' : ''}`}
            onClick={() => setDDActive((ddActive) => !ddActive)}
          >
            <>
              {
                <div style={{ whiteSpace: 'pre-line' }}>
                  {getMultiLevelValueText() || (
                    <span style={{ opacity: '0.6' }}>{placeholder || ''}</span>
                  )}
                </div>
              }
            </>
            {ddActive ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </div>
          {options.length && ddActive && (
            <div className='dd-options'>
              {options.map((opt, i) => (
                <div
                  key={i}
                  className={`dd-opt ${
                    isMultiSelectOptionActive(opt) && 'checked'
                  }`}
                >
                  <div
                    className='opt-label'
                    key={i}
                    onClick={() =>
                      setMultiLevelValue('showSubOpt', {
                        index: i,
                        value: !opt.isActive,
                      })
                    }
                  >
                    {!opt.options && (
                      <div
                        className={`input-checkbox ${
                          opt.isActive ? 'act' : ''
                        }`}
                      />
                    )}
                    {opt.label}
                    {opt.options && (
                      <>
                        {opt.isActive ? (
                          <FontAwesomeIcon
                            className='auto-mrg-l'
                            icon={faAngleDoubleUp}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className='auto-mrg-l'
                            icon={faAngleDoubleDown}
                          />
                        )}
                      </>
                    )}
                  </div>
                  {opt.isActive && opt.inputs && (
                    <div className='inputs'>
                      {opt.inputs.map((inp, ind) => (
                        <input
                          type='text'
                          key={ind}
                          value={inp.value}
                          onChange={(e) =>
                            onChangeInpMultilevel(e.target.value, i, ind)
                          }
                          placeholder={inp.label}
                        />
                      ))}
                    </div>
                  )}
                  {opt.isActive && opt.options && opt.options.length && (
                    <>
                      <div className='sub-opt'>
                        <span
                          className='btn-check-all'
                          onClick={() => selectUnselectAllSub(true, i)}
                        >
                          Select All
                        </span>
                        <span>&nbsp;/&nbsp;</span>
                        <span
                          className='btn-check-all'
                          onClick={() => selectUnselectAllSub(false, i)}
                        >
                          Unselect All
                        </span>
                      </div>
                      {opt.options.map((o, k) => (
                        <div className='sub-opt' key={k}>
                          <div
                            onClick={() =>
                              setMultiLevelValue('checkSubOpt', {
                                index: i,
                                indexSub: k,
                              })
                            }
                            className={`input-checkbox ${
                              o.isActive ? 'act' : ''
                            }`}
                          />
                          {o.label}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {type === 'toggle' && (
        <div className='toggle' style={styleDD || {}}>
          <div className='toggle-button'>
            <input
              type='checkbox'
              className='checkbox'
              name={label}
              id={label}
              defaultChecked={checked}
              onChange={onChange}
            />
            <label className='label' htmlFor={label}>
              <span className='inner' />
              <span className='circle' />
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default Input;
