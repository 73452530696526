enum WithBodyRequests {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
}

export function isRequireBody(o: unknown): o is ApiQueryWithBodyOptions {
  return (
    o != null &&
    typeof o === 'object' &&
    'method' in o &&
    typeof o.method === 'string' &&
    o.method in WithBodyRequests
  );
}
type ApiQueryBasicOptions = {
  queryKey: string[];
  apiPath: string;
  headers?: HeadersInit;
  useV2API?: boolean;
  enabled?: boolean;
  isFile?: boolean;
};

type ApiQueryWithBodyOptions = ApiQueryBasicOptions & {
  method: 'POST' | 'PUT' | 'PATCH';
  body: NonNullable<unknown>;
};

type ApiQueryWithoutBodyOptions = ApiQueryBasicOptions & {
  method: 'GET' | 'DELETE';
};

export type ApiQueryOptions =
  | ApiQueryWithoutBodyOptions
  | ApiQueryWithBodyOptions;

export type ApiQueryBodyOptions = ApiQueryWithBodyOptions;

export type ApiMutationOptions = {
  apiPath: string;
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: HeadersInit;
  useV2API?: boolean;
  isFileUpload?: boolean;
};
