import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import Highcharts from 'highcharts';
import hcExporting from 'highcharts-exporting';
import hcExportCsv from 'highcharts-export-csv';
import hcBoost from 'highcharts-boost';

hcExporting(Highcharts);
hcExportCsv(Highcharts);
hcBoost(Highcharts);
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const HighchartsReact = forwardRef(function HighchartsReact(
  {
    constructorType,
    options,
    callback,
    allowChartUpdate,
    immutable,
    updateArgs,
    containerProps,
  },
  ref,
) {
  const containerRef = useRef();
  const chartRef = useRef();

  useIsomorphicLayoutEffect(() => {
    function createChart() {
      const definedConstructorType = constructorType || 'chart';

      if (Highcharts) {
        chartRef.current = Highcharts[definedConstructorType](
          containerRef.current,
          options,
          callback ? callback : undefined,
        );
      }
    }

    if (!chartRef.current) {
      createChart();
    } else {
      if (allowChartUpdate !== false) {
        if (!immutable && chartRef.current) {
          chartRef.current.update(options, ...(updateArgs || [true, true]));
        } else {
          createChart();
        }
      }
    }
  });

  useIsomorphicLayoutEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, []);

  const downloadPNG = () => {
    chartRef.current.exportChart();
  };

  const updateChart = (data) => {
    chartRef.current.update(data);
  };

  useImperativeHandle(
    ref,
    () => ({
      get chart() {
        return chartRef.current;
      },
      container: containerRef,
      updateChart,
      downloadPNG,
    }),
    [],
  );

  return <div {...containerProps} ref={containerRef} />;
});

export default memo(HighchartsReact);
