import BarHighChart from '@components/_elements/BarHighChart/BarHighChart';
import React from 'react';

interface WindProductionProps {
  loading: boolean;
  data: Record<string, number | undefined>;
}

function WindProduction({ loading, data }: WindProductionProps) {
  return (
    <BarHighChart
      variables={false}
      isAxesInvisible
      unit='MWh'
      name='Index'
      barsClasses={[1]}
      loading={loading}
      hourly
      margins={{ top: 10, right: 5, bottom: 10, left: 5 }}
      data={data}
    />
  );
}

export default WindProduction;
