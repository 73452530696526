import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
import {
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import useWidgetConfigMutations from '@src/hooks/api/mutations/useWidgetConfigMutations';
import JSONEditor from '@src/components/_elements/JSONEditor/JSONEditor';
import { CollectionPayload } from '@src/types/Collection';
import { WidgetConfigBase } from '@src/types/Widget';
import widgetConfigSx from '../configs/widgetConfigSx';

interface DialogProps {
  jsonData: WidgetConfigBase;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  handleCancel: (id?: GridRowId) => () => void;
  setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
}

// eslint-disable-next-line max-lines-per-function
function WidgetConfigDialog({
  jsonData,
  handleCancel,
  setOpen,
  setRowModesModel,
  setJsonPayload,
}: DialogProps) {
  const { id } = jsonData;
  const [newData, setNewData] = useState<WidgetConfigBase>(jsonData);
  const queryClient = useQueryClient();

  const getTitle = () => {
    if (id) return 'Are you sure you want to delete this config?';

    return 'Add Widget Config';
  };

  const { createWidgetConfig, deleteWidgetConfig } =
    useWidgetConfigMutations(id);

  const handleSuccessCallback = () => {
    if (id) {
      setRowModesModel((prevState) => ({
        ...prevState,
        [id]: { mode: GridRowModes.View },
      }));
    }

    queryClient.invalidateQueries({
      queryKey: [QueryKeys.widgets],
    });
    setJsonPayload(null);
    setOpen(false);
  };

  const handleCreate = () =>
    createWidgetConfig.mutate(newData, {
      onSuccess: handleSuccessCallback,
    });

  const handleDelete = () =>
    deleteWidgetConfig.mutate(newData, {
      onSuccess: handleSuccessCallback,
    });

  const handleConfirm = () => {
    if (id) return handleDelete();
    return handleCreate();
  };

  return (
    <Dialog
      open
      PaperProps={
        !id
          ? {
              sx: widgetConfigSx.pcDialogPaper,
            }
          : {}
      }
    >
      <DialogTitle>{getTitle()}</DialogTitle>
      {!id && (
        <DialogContent>
          <JSONEditor
            json={newData}
            onChange={setNewData}
            options={{ mode: 'code' }}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel(id)}>Cancel</Button>
        <Button size='small' variant='contained' onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WidgetConfigDialog;
