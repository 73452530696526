import Spinner from '@components/_elements/Spinner/Spinner';
import SimplePointDataTableWithTitle from '@components/_shared/MultiColPointDataTableWithTitle';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';
import { WidgetTypeMultiSectionPointList } from '@src/types/Widget';
import { MqttClient } from 'mqtt';
import * as React from 'react';

interface Props {
  selectedBessId: string;
  useNewClient?: MqttClient;
}

function PowinBessDetails(props: Props) {
  const { selectedBessId } = props;

  const { data: widgetConfig, isLoading } =
    useGetWidgetConfig<WidgetTypeMultiSectionPointList>(
      'powinBessDetailsModal',
      {
        sourceDeviceId: selectedBessId,
      },
    );

  return isLoading ? (
    <Spinner type='content-small' />
  ) : (
    widgetConfig?.sections.map((sectionData, i) => (
      <SimplePointDataTableWithTitle
        key={`powinbess-details-${Math.random()}`}
        sectionIndex={i}
        data={sectionData}
        useNewClient={props.useNewClient}
      />
    ))
  );
}

export default PowinBessDetails;
