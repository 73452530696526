import React, { useEffect, useState } from 'react';
import './BarHighChart.scss';
import HighChart from '../HighChart';
import ChartLoader from '../ChartLoader';

const BarHighChart = ({
  data,
  variables,
  barsClasses,
  name = '',
  margins,
  isAxesInvisible = false,
  hourly = false,
  unit,
  loading,
}) => {
  // Below renders the chart after component loads
  // to allow flexbox _layout to load,
  // and the chart to fill container width and height
  const [isLayoutLoaded, setLayoutLoaded] = useState(false);
  useEffect(() => {
    setLayoutLoaded(true);
  }, []);

  const dataWithConvertedDate = data.map((dataPoint) => ({
    ...dataPoint,
    date: new Date(dataPoint.date).getTime(),
  }));
  let seriesArr = [
    {
      name: name,
      colorIndex: barsClasses[0],
      data: data.map((dataPoint) => [
        new Date(dataPoint.date).getTime(),
        +dataPoint.value,
      ]),
    },
  ];
  if (variables) {
    const varLabelsArr = Object.keys(variables);
    const varFieldsArr = Object.values(variables);
    seriesArr = varFieldsArr.map((varField, i) => {
      return {
        name: varLabelsArr[i],
        data: dataWithConvertedDate.map((dataPoint) => [
          dataPoint.date,
          +dataPoint.value[varField],
        ]),
        colorIndex: barsClasses[i],
      };
    });
  }

  let chartOptions = {
    chart: {
      type: 'column',
      styledMode: true,
      marginTop: margins?.top,
      marginRight: margins?.right,
      marginBottom: margins?.bottom,
      marginLeft: margins?.left,
    },
    title: {
      text: '',
    },
    yAxis: {
      visible: !isAxesInvisible,
      title: {
        text: '',
      },
      softMax: 1,
    },
    xAxis: {
      visible: !isAxesInvisible,
      type: 'datetime',
      dateTimeLabelFormats: { day: '%e %b' },
      ...(hourly
        ? {
            units: [
              ['hour', [1]],
              ['day', null],
            ],
            dateTimeLabelFormats: { hour: '%H', day: '%H' },
          }
        : null),
    },
    legend: {
      enabled: false,
    },
    time: {
      useUTC: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      xDateFormat: hourly ? '%H' : '%A, %B %d',
      valueSuffix: unit && ` ${unit}`,
      useHTML: true,
    },
    plotOptions: {
      column: {
        minPointLength: 3,
      },
    },
    series: seriesArr,
  };

  return (
    <div className='bar-chart-container'>
      {isLayoutLoaded ? (
        <HighChart
          options={chartOptions}
          containerProps={{ style: { height: '100%', width: '100%' } }}
        />
      ) : null}
      {!!loading && <ChartLoader />}
    </div>
  );
};

export default BarHighChart;
