import React, { ReactElement } from 'react';

interface ResistorProps {
  label: string;
}

export function Resistor({ label }: ResistorProps): ReactElement {
  const textProps = {
    fill: 'currentColor',
    stroke: 'none',
    fontSize: '8px',
    textAnchor: 'middle',
    transform: 'rotate(-90) translate(-730,-720)',
    x: -10,
    y: 740,
  };

  const path_1_coordinates =
    'M33.36 758.64h-8.34a4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17h8.34';
  const path_2_coordinates =
    'M0 758.64h8.34a4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17A4.17 4.17 0 0 1 8.34 792H0';

  return (
    <>
      <path d={path_1_coordinates} className='st1' />
      <path d={path_2_coordinates} className='st1' />
      <text {...textProps} transform={textProps.transform}>
        {label}
      </text>
    </>
  );
}

export default Resistor;
