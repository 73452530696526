import { store } from '@store';
import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import { AlertConfig, AlertEntity, HistoricalAlert } from '@src/types/Alerts';
import { ALERTS_SERVICE_URL } from '@src/utils';
import AlarmInfoModal from '../AlarmInfoModal';

function HelpCell({
  alarmEntity,
}: {
  alarmEntity: HistoricalAlert | AlertEntity;
}) {
  const alertConfigId =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (alarmEntity as AlertEntity).config?.id ||
    (alarmEntity as HistoricalAlert).configId;
  const [alertConfig, setAlertConfig] = useState<AlertConfig>();

  const handleClick = () => {
    fetch(`${ALERTS_SERVICE_URL}/alert-configs/${alertConfigId}`, {
      headers: {
        authorization: `Bearer ${store.getState().user.keycloak?.token}`,
      },
    })
      .then((res) => res.json())
      .then((config: AlertConfig) => setAlertConfig(config))
      .catch((err) => {
        console.error(err);
        setAlertConfig(undefined);
      });
  };

  return (
    <>
      <Chip
        className='button-chip'
        label='?'
        onClick={handleClick}
        variant='outlined'
        color='primary'
      />
      {alertConfig && (
        <AlarmInfoModal
          isOpen
          toggle={() => setAlertConfig(undefined)}
          historicalAlertObj={alarmEntity}
          alertConfig={alertConfig}
        />
      )}
    </>
  );
}

export default HelpCell;
