import { Action } from 'redux';
import { AlertEntity, AlertsState } from '@src/types/Alerts';
import { ConfigState } from '@store/reducers/configData';
import { destructureTopic } from '@utils/index_ts';

export function getTopicInfo(
  useNewTopicStructure: boolean | undefined,
  topic: string,
) {
  const destructuredTopic = destructureTopic(useNewTopicStructure, topic);
  let siteId = '';
  let dataSource = '';
  let deviceName = '';
  if (destructuredTopic.v === 'V1') {
    siteId = destructuredTopic.siteId as string;
    dataSource = destructuredTopic.deviceId as string;
    deviceName = destructuredTopic.deviceName as string;
  } else {
    siteId = destructuredTopic.siteControllerId;
    dataSource =
      destructuredTopic.sourceDeviceId ||
      destructuredTopic.unitControllerId ||
      destructuredTopic.siteControllerId;
    deviceName =
      destructuredTopic.sourceDeviceType ||
      (destructuredTopic.unitControllerId ? 'Unit' : 'Site');
  }
  return { siteId, dataSource, deviceName };
}

const initState: AlertsState = {
  activeAlerts: [],
};

interface SetAlertsDispatchProps {
  setAlerts: AlertEntity[];
  addAlert?: never;
  changeAlert?: never;
  removeAlert?: never;
}
interface AddAlertDispatchProps {
  setAlerts?: never;
  addAlert: AlertEntity;
  changeAlert?: never;
  removeAlert?: never;
}
interface ChangeAlertDispatchProps {
  setAlerts?: never;
  addAlert?: never;
  changeAlert: AlertEntity;
  removeAlert?: never;
}
interface RemoveAlertDispatchProps {
  setAlerts?: never;
  addAlert?: never;
  changeAlert?: never;
  removeAlert: AlertEntity;
}

export type AlertDispatchProps =
  | SetAlertsDispatchProps
  | AddAlertDispatchProps
  | ChangeAlertDispatchProps
  | RemoveAlertDispatchProps;

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AlertsState | ConfigState = initState,
  action: Action<string> & AlertDispatchProps,
) => {
  const handlers: Record<string, () => AlertsState | ConfigState> = {
    UPDATE_ACTIVE_ALERTS: () => {
      const { setAlerts, addAlert, removeAlert, changeAlert } = action;
      if (setAlerts) {
        return {
          ...state,
          activeAlerts: setAlerts,
        };
      }
      const newAlerts = [...(state as AlertsState).activeAlerts];
      const alertToUpdate = addAlert || removeAlert || changeAlert;
      if (alertToUpdate !== undefined) {
        const index = newAlerts.findIndex(
          (e) => e.topic === alertToUpdate.topic,
        );
        if (addAlert || changeAlert) {
          if (index >= 0) newAlerts.splice(index, 1, alertToUpdate);
          else newAlerts.push(alertToUpdate);
        } else if (removeAlert !== undefined && index >= 0) {
          newAlerts.splice(index, 1);
        }

        return {
          ...state,
          activeAlerts: newAlerts,
        };
      }

      return state;
    },
  };
  if (action.type && handlers[action.type] !== undefined) {
    return handlers[action.type]();
  }
  return state;
};
