import QueryKeys from '@src/constants/queryKeys';
import useApiQuery from '@src/hooks/useApiQuery';
import { Group } from '@src/types/Group';

const useGroups = () =>
  useApiQuery<Group[]>({
    queryKey: [QueryKeys.groups],
    apiPath: '/user-groups',
    method: 'GET',
    useV2API: true,
  });

export default useGroups;
