// Enum for the class returned by the function
export enum CircleClass {
  NONE = '',
  BLUE_A = 'blue-a',
  GREEN_A = 'green-a',
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  GRAY = 'gray',
}

// Enum for inventory statuses
export enum InventoryStatus {
  IDLE = 'Idle',
  READY = 'Ready',
  RUN_PQ = 'RunPQ',
  RUN_UF = 'RunUF',
  FORMING = 'Forming',
  FAULT = 'Fault',
  COMMS = 'Comms',
  CONNECTING = 'Connecting',
  DISCONNECTING = 'Disconnecting',
  STARTING = 'Starting',
  STOPPING = 'Stopping',
  STANDBY = 'Standby',
  GOING_TO_STANDBY = 'GoingToStandby',
  OFF = 'Off',
}

// Enum for run modes
export enum RunMode {
  AUTO = 'Auto',
  MANUAL = 'Manual',
}
