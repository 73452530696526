import moment from 'moment-timezone';
import { store } from '@store';
import { notifyError } from '@store/actionCreators/notifications';
import tzlookup from 'tz-lookup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { titleCase } from 'title-case';

export const IS_LOCALHOST = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export const KEYCLOAK_URL = `${
  IS_LOCALHOST ? process.env.VITE_KEYCLOAK_URL : document.location.origin
}${process.env.VITE_KEYCLOAK_BASE_PATH || '/keycloak/'}`;

export const API_URL = `${
  IS_LOCALHOST ? process.env.VITE_API_URL : document.location.origin
}${process.env.VITE_API_BASE_PATH || '/api'}`;

export const API_V2_URL = `${
  IS_LOCALHOST ? process.env.VITE_API_V2_URL : document.location.origin
}${process.env.VITE_API_V2_BASE_PATH ?? '/api/v2'}`;

export const ALERTS_SERVICE_URL = IS_LOCALHOST
  ? process.env.VITE_ALERTS_SERVICE_URL
  : `${document.location.origin}/api/alerts`;

export const API_ENERGY_URL = IS_LOCALHOST
  ? process.env.VITE_API_ENERGY_BASE_PATH
  : `${document.location.origin}/api`;

// TODO: Need to securely store defaults usernames and passwords for fractal energy
export const API_ENERGY_CAISO_USER = IS_LOCALHOST
  ? process.env.VITE_CAISO_USERNAME
  : 'caiso_user';
export const API_ENERGY_CAISO_PWD = IS_LOCALHOST
  ? process.env.VITE_CAISO_PASSWORD
  : 'dcg3ajd3frf!FWH!yzk';

export const API_ENERGY_ERCOT_USER = IS_LOCALHOST
  ? process.env.VITE_ERCOT_USERNAME
  : 'ercot_user';
export const API_ENERGY_ERCOT_PWD = IS_LOCALHOST
  ? process.env.VITE_ERCOT_PASSWORD
  : 'ezr6egb_gyp3gtg-ZVK';

export const MQTT_WSS_URL = `${
  IS_LOCALHOST
    ? process.env.VITE_MQTT_WS_URL
    : `wss://${document.location.host}`
}${process.env.VITE_MQTT_WS_BASE_PATH || '/mqtt-ws/'}`;

export const optionsSelectTZ = ['UTC', 'Site', 'User'];
export const tzDict = {
  utc: 'UTC',
  site: 'Site',
  user: 'User',
};

export function callAPI(
  path,
  method = 'GET',
  queryParamsOrBody = {},
  returnUrlOnly,
) {
  return new Promise((resolve) => {
    let url = path;
    const noBodyMethods = ['GET'];
    if (noBodyMethods.includes(method)) {
      Object.keys(queryParamsOrBody).forEach((el, i) => {
        url += i === 0 ? '?' : '&';
        if (Array.isArray(queryParamsOrBody[el])) {
          const paramVal = queryParamsOrBody[el].reduce((acc, cv, i) => {
            if (i !== queryParamsOrBody[el].length - 1) {
              return `${acc}${cv},`;
            }
            return `${acc}${cv}`;
          }, '');
          url += `${el}=${encodeURIComponent(paramVal)}`;
        } else if (typeof queryParamsOrBody[el] === 'string') {
          url += `${el}=${encodeURIComponent(queryParamsOrBody[el])}`;
        } else {
          url += `${el}=${encodeURIComponent(
            JSON.stringify(queryParamsOrBody[el]),
          )}`;
        }
      });
    }
    if (returnUrlOnly) {
      return resolve(`${API_URL}${url}`);
    }
    return fetch(`${API_URL}${url}`, {
      method,
      headers: {
        Authorization: `Bearer ${store.getState().user?.keycloak?.token}`,
        ...(method !== 'GET' ? { 'Content-Type': 'application/json' } : {}),
      },
      ...(!noBodyMethods.includes(method)
        ? {
            body: JSON.stringify(queryParamsOrBody),
          }
        : {}),
    })
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json();
          } else {
            return {};
          }
        }
        notifyError('Request failed, please contact support');
        return { error: 'Request failed' };
      })
      .then((data) => {
        resolve(data);
      });
  });
}

let pendingRequests = [];

export function cancelPendingRequests() {
  pendingRequests = pendingRequests.filter((d) => {
    return (
      !d.data || !(d.data.action === 'getTableItems' && d.data.table === 'MSG')
    );
  });
}

export function getLocale(data) {
  const { timeMode, lat, long, returnOffset, utcOffset } = data;
  const modesTz = {
    UTC: 'UTC',
    Site: tzlookup(lat, long),
    User: moment.tz.guess(),
  };
  if (returnOffset) {
    return -moment().tz(modesTz[timeMode]).utcOffset();
  }
  if (utcOffset) {
    return moment().tz(modesTz[timeMode]).utcOffset();
  }
  return modesTz[timeMode];
}

export function getCurrentDateTime() {
  const now = new Date();
  return now.toISOString();
}

export function getYesterdayDateTime() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString();
}

export function currentTzString(data) {
  const { timeMode, lat, long } = data;
  const modesTz = {
    UTC: 'UTC±00:00',
    Site: 'UTC' + moment().tz(tzlookup(lat, long)).format('Z'),
    User: 'UTC' + moment().tz(moment.tz.guess()).format('Z'),
  };
  return modesTz[timeMode];
}

export function isEmptyObject(obj) {
  return !Object.keys(obj || {}).length;
}

export function cleanTitle(title) {
  if (title) {
    return title.replace(/_/g, ' ');
  }
  return '';
}

export function CapitalizeWord(word) {
  let string = cleanTitle(word);
  if (word) {
    string = word.charAt(0).toUpperCase() + word.slice(1);
  } else {
    string = '';
  }
  return string;
}

export function formatTS(ts, timeMode, format = 'YYYY-MM-DD HH:mm:ss') {
  if (timeMode === 'utc') {
    return moment.utc(ts).format(format);
  } else {
    return moment(ts).local().format(format);
  }
}

export function windDir(num) {
  const val = Math.floor(num / 22.5 + 0.5);
  const arr = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  return arr[val % 16];
}

export const siteCommands = [
  'Stop Units',
  'Start Units',
  'Units to Manual',
  'Units to Auto',
  'Open AC Breakers',
  'Close AC Breakers',
  'Fault Reset',
];

export function getTextColor(value) {
  const online = +value.split(' / ')[0];
  const all = +value.split(' / ')[1];
  if (online === 0) {
    return 'red-text';
  }
  if (online === all) {
    return 'green-text';
  }
  if (online < all) {
    return 'yellow-text';
  }
}

export function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === 'number') {
      intervalRef.current = setInterval(tick, delay);
      return () => clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}

export function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like useState

  useEffect(() => {
    // cb.current is null on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}

export function useIsFirstRender() {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export function useLegacyState(initialValue) {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const cbRef = useRef([]); // init mutable ref container for callbacks

  const setStateCallback = useCallback((newState, cb) => {
    if (cb) {
      if (cbRef.current.length) {
        cbRef.current.push(cb);
      } else {
        cbRef.current = [cb]; // store current, passed callback in ref
      }
    }
    setState((state) => ({
      ...state,
      ...(typeof newState === 'function' ? newState(state) : newState),
    }));
  }, []); // keep object reference stable, exactly like useState

  useEffect(() => {
    // cb.current is null on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current.length) {
      cbRef.current.forEach(() => {
        cbRef.current.shift()(state);
      });
    }
  }, [state]);

  return [state, stateRef, setStateCallback];
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function calcTooltipPosition(eventX, eventY, tooltip) {
  const widthBound = window.innerWidth;
  const heightBound = window.pageYOffset;
  let x;
  let y;
  const tooltipWidth = tooltip.node().getBoundingClientRect().width;
  const tooltipHeight = tooltip.node().getBoundingClientRect().height;
  if (eventX + tooltipWidth > widthBound) {
    x = eventX - tooltipWidth;
  } else {
    x = eventX;
  }
  if (eventY - tooltipHeight < heightBound) {
    y = eventY;
  } else {
    y = eventY - tooltipHeight;
  }
  return [x, y];
}

export const formatNum = ({ v, a, p, r, d }) => {
  // value, accuracy, placeholder, returnNum, divider
  const fielldPassed = (f) => {
    return f !== undefined;
  };
  let num = v;
  if ([null, undefined, NaN].includes(num)) {
    if (fielldPassed(p)) {
      return p;
    } else {
      num = 0;
    }
  } else {
    num = +num;
    if (fielldPassed(d)) {
      num /= d;
    }
    if (fielldPassed(a)) {
      num = num.toFixed(a);
      if (num === '-0') {
        num = '0';
      }
    }
  }
  if (r) {
    return +num;
  } else {
    return num;
  }
};

export const snakeToTitle = (str) => titleCase(str.split('_').join(' '));

export const isKeyInObject = (obj, key) => {
  return !obj || !Object.keys(obj).includes(key);
};
