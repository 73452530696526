import React from 'react';
import './TextLoader.scoped.scss';
import { isNumberString } from '@utils/mathHelpers';
import { Tooltip } from '@mui/material';
import storeConnector from '@store/storeConnector';
import { isSuperAdmin } from '@src/services/auth';
const TextLoader = ({
  loading,
  fontSise = 14,
  expectedSize = 3,
  children,
  orientation = 'r',
  fractionDigits = 3,
  after = '',
  invertedCcolor = null,
  toolTipContent = '', // could be a string or a react component
  role,
}) => {
  const orientDict = {
    l: 'flex-start',
    c: 'center',
    r: 'flex-end',
  };
  const size = Math.round(fontSise * 0.4);
  if (typeof children === 'number') {
    // converts a number 1.300003 to 1.3 not 1.300
    children = Number(children.toFixed(fractionDigits));
  }
  if (isNumberString(children)) {
    // converts a number 1.300003 to 1.3 not 1.300
    children = Number(parseFloat(children).toFixed(fractionDigits));
  }
  const title = isSuperAdmin(role) ? toolTipContent : '';
  return (
    <Tooltip title={title} placement='top'>
      {loading ? (
        <div
          className='text-loader-container'
          style={{
            justifyContent: orientDict[orientation],
            ...(invertedCcolor ? { filter: 'invert(1)' } : {}),
          }}
        >
          <div className='text-loader'>
            {Array.apply(0, new Array(expectedSize))
              .map((_, i) => i)
              .map((el) => (
                <div
                  key={el}
                  style={{
                    width: `${size}px`,
                    height: `${size}px`,
                    animationDelay: `${el / 10}s`,
                  }}
                />
              ))}
            {!!after && <>&nbsp;{after}</>}
          </div>
        </div>
      ) : (
        <div>
          {children}
          {!!after && <>&nbsp;{after}</>}
        </div>
      )}
    </Tooltip>
  );
};

export default storeConnector(TextLoader, {
  user: ['role'],
});
