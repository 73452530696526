import React from 'react';
import './RackDetailsPopupButton.scoped.scss';
import TextLoader from '@components/_elements/TextLoader';
import PopupButton from '@components/_elements/Popup/PopupButton';

const RackDetailsPopupButton = ({ rack, rackRt }) => {
  const isRackLoading = (field) => {
    return !Object.keys(rackRt).includes(field);
  };

  return (
    <PopupButton
      title={`Rack ${rack.name} details`}
      content={
        <table className='table'>
          <tbody>
            <tr>
              <td className='label'>RackWarningCode</td>
              <td>
                <TextLoader
                  orientation='l'
                  loading={isRackLoading('RackWarningCode')}
                >
                  {rack.RackWarningCode}
                </TextLoader>
              </td>
            </tr>
            <tr>
              <td className='label'>RackAlarmCode</td>
              <td>
                <TextLoader
                  orientation='l'
                  loading={isRackLoading('RackWarningCode')}
                >
                  {rack.RackAlarmCode}
                </TextLoader>
              </td>
            </tr>
            <tr>
              <td className='label'>RackFaultCode</td>
              <td>
                <TextLoader
                  orientation='l'
                  loading={isRackLoading('RackFaultCode')}
                >
                  {rack.Fault1}
                </TextLoader>
              </td>
            </tr>
            <tr>
              <td className='label'>OtherFaultCode</td>
              <td>
                <TextLoader
                  orientation='l'
                  loading={isRackLoading('OtherFaultCode')}
                >
                  {rack.Fault2}
                </TextLoader>
              </td>
            </tr>
          </tbody>
        </table>
      }
      link
    >
      {rack.name}
    </PopupButton>
  );
};

export default RackDetailsPopupButton;
