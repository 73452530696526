export default {
  masterTracker: [
    'avg_angle',
    'avg_batt_volt_master',
    'avg_batt_volt_trackers',
    'num_trackers',
    'stow_east_limit',
    'stow_mode_active',
    'stow_mode_high_priority_manual',
    'stow_mode_low_battery',
    'stow_mode_low_priority_manual',
    'stow_mode_snow',
    'stow_mode_snow_sens_disconnect',
    'stow_mode_water',
    'stow_mode_wind',
    'stow_mode_wind_sens_disconnect',
    'stow_west_limit',
    'tracking_mode',
    'wind_avg',
    'wind_instant',
    'wind_peak',
  ],
  unitTracker: [
    'avg_angle',
    'avg_motor_current',
    'peak_motor_current',
    'avg_batt_volt',
    'charging_status',
    'peak_temperature',
    'status_failed_east',
    'status_failed_west',
    'status_high_priority_stow',
    'status_low_priority_stow',
    'status_missed_status_request',
    'status_bod_reset',
  ],
};
