import React from 'react';
import './SiteUnitCommandsPopup.scoped.scss';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';

const ConverterCommandsTable = ({
  getConvertedIdxStr,
  setOverInput,
  setConvertersRealArr,
  setConvertersReactiveArr,
  showConfPopup,
  siteMeta,
  convertersRealArr,
  allowedMinReal,
  allowedMaxReal,
  convertersReactiveArr,
  allowedMinReactive,
  allowedMaxReactive,
  converterIdxStr,
  isSendingConverterPowerCommands,
  isConverterPowerValueChangedArr,
}) => {
  return (
    <>
      <div className='bold vertical-margin'>Converter Power Commands</div>
      <table className='converter-commands-table'>
        <tbody>
          {Array(+siteMeta.NumConverters)
            .fill()
            .map((_, i) => (
              <tr key={`converter row ${i}`}>
                <td className='bold'>{getConvertedIdxStr(i)}</td>
                <td>
                  P:{' '}
                  <input
                    className={
                      'comand-popup-inp width-50 horizontal-margin ' +
                      (convertersRealArr[i] < allowedMinReal ||
                      convertersRealArr[i] > allowedMaxReal
                        ? 'error'
                        : '')
                    }
                    type='number'
                    step='0.1'
                    value={convertersRealArr[i]}
                    onMouseEnter={() => setOverInput(true)}
                    onMouseLeave={() => setOverInput(false)}
                    onChange={(e) => {
                      const value = e.target.value;
                      setConvertersRealArr((prev) => {
                        const arr = [...prev];
                        arr[i] = value;
                        return arr;
                      });
                      isConverterPowerValueChangedArr.current[i] = true;
                    }}
                  />{' '}
                  kW
                </td>
                <td>
                  Q:{' '}
                  <input
                    className={
                      'comand-popup-inp width-50 horizontal-margin ' +
                      (convertersReactiveArr[i] < allowedMinReactive ||
                      convertersReactiveArr[i] > allowedMaxReactive
                        ? 'error'
                        : '')
                    }
                    type='number'
                    step='0.1'
                    value={convertersReactiveArr[i]}
                    onMouseEnter={() => setOverInput(true)}
                    onMouseLeave={() => setOverInput(false)}
                    onChange={(e) => {
                      const value = e.target.value;
                      setConvertersReactiveArr((prev) => {
                        const arr = [...prev];
                        arr[i] = value;
                        return arr;
                      });
                      isConverterPowerValueChangedArr.current[i] = true;
                    }}
                  />{' '}
                  kVAR
                </td>
                <td>
                  <Button
                    onClick={() => {
                      converterIdxStr.current = `DC${getConvertedIdxStr(i)}`;
                      showConfPopup('Start Converter', 'value', false);
                    }}
                  >
                    Start
                  </Button>
                </td>
                <td>
                  <Button
                    onClick={() => {
                      converterIdxStr.current = `DC${getConvertedIdxStr(i)}`;
                      showConfPopup('Stop Converter', 'value', false);
                    }}
                  >
                    Stop
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className='popup-row commands-popup-control'>
        <Button
          size='m'
          onClick={() => {
            showConfPopup('Send Converter Power PVSystemCommands', 'value');
            isSendingConverterPowerCommands.current = true;
          }}
          disabled={
            convertersRealArr.some(
              (real) =>
                real === '' || real < allowedMinReal || real > allowedMaxReal,
            ) ||
            convertersReactiveArr.some(
              (reactive) =>
                reactive === '' ||
                reactive < allowedMinReactive ||
                reactive > allowedMaxReactive,
            ) ||
            isConverterPowerValueChangedArr.current.every((el) => el === false)
          }
        >
          Send
        </Button>
      </div>
      {convertersRealArr.some(
        (real) => real < allowedMinReal || real > allowedMaxReal,
      ) && (
        <div className='popup-row warn-alert'>
          The value of real power must be between {allowedMinReal} and{' '}
          {allowedMaxReal}
        </div>
      )}
      {convertersReactiveArr.some(
        (reactive) =>
          reactive < allowedMinReactive || reactive > allowedMaxReactive,
      ) && (
        <div className='popup-row warn-alert'>
          The value of reactive power must be between {allowedMinReactive} and{' '}
          {allowedMaxReactive}
        </div>
      )}
    </>
  );
};

export default storeConnector(ConverterCommandsTable, {
  config: ['siteMeta'],
});
