import { Theme } from '@mui/material';
import { BooleanInteger } from '@types';
import React, { ReactElement } from 'react';
import CircleIcon from '@mui/icons-material/Circle';

function getIconColor(
  theme: Theme,
  value?: BooleanInteger,
  invertDisplay?: boolean,
): string {
  switch (value) {
    case 1:
      return invertDisplay
        ? theme.palette.error.main
        : theme.palette.success.main;
    case 0:
      return invertDisplay
        ? theme.palette.success.main
        : theme.palette.error.main;
    default:
      return theme.palette.grey[500];
  }
}

function Indicator(p: {
  value?: BooleanInteger;
  invertDisplay?: boolean;
}): ReactElement {
  return (
    <CircleIcon
      data-testid='indicator-circle'
      sx={(theme: Theme) => ({
        // width: 20,
        // height: 20,
        color: getIconColor(theme, p.value, p.invertDisplay),
      })}
    />
  );
}

export default Indicator;
