import NotesButton from '@components/_shared/Notes/NotesButton';
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders';
import React, { useEffect, useState } from 'react';
import ProgressBar from '@components/_shared/ProgressBar/ProgressBar';
import Input from '@components/_elements/Input/Input';
import CustomSitePoints from '@components/_shared/CustomSitePoints/CustomSitePoints';
import TextLoader from '@components/_elements/TextLoader/index';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';
import ActiveModesList from '@components/Home/Config/Modes/ActiveModesList';
import { isActionRole } from '@src/services/auth';
import { getTopicForTooltip } from '@utils/index_ts';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SystemMeter = ({ siteMeta, actions, role, topicDict }) => {
  const { Meters } = siteMeta;
  const { Use_New_Topic_Structure: useNewTopicStructure } = siteMeta.ui;
  const [socRange, setSOCRange] = useState({
    min: 10,
    max: 90,
  });
  const [currentMeterId, setCurrentMeterId] = useState(
    Meters?.[0]?.id || 'meter_1',
  );
  const [selectedPOIMeterIndex, setSelectedPOIMeterIndex] = useState(0);

  const [siteRt, setSiteRtData] = useState({});
  const [meterData, setMeterData] = useState({});
  const location = useLocation();
  const isTeslaPage = location.pathname.endsWith('tesla');
  const isSunPage = location.pathname.startsWith('/sun/');

  useEffect(() => {
    setMeterData({});
  }, [currentMeterId]);

  useEffect(() => {
    setCurrentMeterId(Meters?.[selectedPOIMeterIndex]?.id);
  }, [selectedPOIMeterIndex]);

  const POIMeterAllVarNamesArr = [
    'kW',
    'kVAR',
    'kVA',
    'PF',
    'Hz',
    'Voltage',
    'Current',
  ];

  useWsSubscribe(
    {
      site: {
        fields: [
          'SOC',
          'UnitsOnline',
          'ChaSt',
          'LocRemCtl',
          'Application',
          'DchgMWh_POI',
          'DchgMWh',
          'PCS_P',
          'PCS_Q',
          'racks_online',
          ...(useNewTopicStructure ? [] : POIMeterAllVarNamesArr),
        ],
        cb: (data) => setSiteRtData((prev) => ({ ...prev, ...data })),
      },
      ...(useNewTopicStructure
        ? {
            meter: {
              sourceDeviceIds: [currentMeterId],
              fields: POIMeterAllVarNamesArr,
              cb: (data) => setMeterData((prev) => ({ ...prev, ...data })),
            },
          }
        : {}),
    },
    [currentMeterId],
  );

  const isSiteLoading = (field) => {
    return !Object.keys(siteRt).includes(field);
  };
  const isMeterLoading = (field) => {
    return !Object.keys(meterData).includes(field);
  };

  useEffect(() => {
    (async () => {
      const r = {};
      r.result = await actions.getSystemConfig(siteMeta.siteId, 'Fault Config');
      if (r && r.result && r.result.length) {
        const msgs = r.result.map((el) => el.body.Faults || []).flat();
        const min = msgs.find(
          (el) =>
            el['Fault Name'] && el['Fault Name'] === 'Battery Rack SOC Low',
        );
        const max = msgs.find(
          (el) =>
            el['Fault Name'] && el['Fault Name'] === 'Battery Rack SOC High',
        );
        setSOCRange({
          min: min ? +min['Low Threshold'] : socRange.min,
          max: max ? +max['Hi Threshold'] : socRange.max,
        });
      }
    })();
  }, []);

  const getClass = (value) => {
    const online = +value.split(' / ')[0];
    const all = +value.split(' / ')[1];
    if (online === 0) {
      return 'red-text';
    }
    if (online === all) {
      return 'green-text';
    }
    if (online < all) {
      return 'yellow-text';
    }
  };

  const getActivePower = () => {
    return (
      Number(meterData.kW) / (siteMeta.MW_Enable === '1' ? 1000 : 1)
    ).toFixed(2);
  };

  const getReactivePower = () => {
    return (
      Number(meterData.kVAR) / (siteMeta.MW_Enable === '1' ? 1000 : 1)
    ).toFixed(2);
  };

  const getApparentPower = () => {
    return (
      Number(meterData.kVA) / (siteMeta.MW_Enable === '1' ? 1000 : 1) ||
      Math.sqrt(
        Math.pow(Number(meterData.kW), 2) + Math.pow(Number(meterData.kVAR), 2),
      ) / (siteMeta.MW_Enable === '1' ? 1000 : 1)
    ).toFixed(2);
  };

  const getPowerFactor = () => {
    return useNewTopicStructure
      ? Number(meterData.PF).toFixed(2) || null
      : siteRt['PF'] ||
          (
            siteRt['kW'] /
              Math.abs(
                Math.sqrt(
                  Math.pow(siteRt['kW'], 2) + Math.pow(siteRt['kVAR'], 2),
                ),
              ) || 0
          ).toFixed(2);
  };

  const racksOnline = [siteRt?.racks_online, siteMeta?.SiteRackCountLabel].join(
    ' / ',
  );
  const unitsOnline = [siteRt?.UnitsOnline, siteMeta?.Units.length].join(' / ');

  return (
    <>
      {
        <div
          className={'block-container cell'}
          style={{ justifyContent: 'flex-start' }}
        >
          <table className='sys-meter-mark-info'>
            <tbody>
              <tr>
                <td style={{ width: '55%', paddingRight: '2%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h4>System</h4>
                    </div>
                    <div style={{ display: 'flex', columnGap: '15px' }}>
                      {isActionRole(role) && siteMeta.ui.Notes_Enable ? (
                        <NotesButton
                          className='cell-with-cursor-pointer'
                          size={'2xl'}
                          deviceId={siteMeta.siteId}
                        />
                      ) : null}
                      {isActionRole(role) &&
                      siteMeta.ui.Command_Access_Enable &&
                      !process.env.VITE_READ_ONLY_MODE ? (
                        <FontAwesomeIcon
                          className='cell-with-cursor-pointer rounded-border'
                          onClick={() => {
                            actions.showComandsPopup(true, {
                              popupType: 'site',
                              sn: siteMeta.siteId,
                            });
                          }}
                          icon={faSliders}
                          size={'2xl'}
                        />
                      ) : null}
                    </div>
                  </div>
                  <table className='info in'>
                    <tbody>
                      <tr>
                        <td>Status:</td>
                        <td
                          className={
                            siteRt.ChaSt === 'RunPQ' || siteRt.ChaSt === 'RunUF'
                              ? 'bold'
                              : siteRt.ChaSt === 'FAULT'
                                ? 'bold'
                                : 'italic-white'
                          }
                        >
                          <div className='flex-row-e'>
                            <TextLoader
                              expectedSize={5}
                              loading={isSiteLoading('ChaSt')}
                              fractionDigits={2}
                              toolTipContent={getTopicForTooltip(
                                'ChaSt',
                                topicDict,
                              )}
                            >
                              {siteRt.ChaSt}
                            </TextLoader>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Mode:</td>
                        <td>
                          <div className='flex-row-e'>
                            <TextLoader
                              expectedSize={5}
                              fractionDigits={2}
                              loading={isSiteLoading('LocRemCtl')}
                              toolTipContent={getTopicForTooltip(
                                'LocRemCtl',
                                topicDict,
                              )}
                            >
                              {siteRt.LocRemCtl}
                            </TextLoader>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ verticalAlign: 'baseline' }}>
                          Application:
                        </td>
                        <td>
                          <div className='flex-row-e'>
                            <ActiveModesList />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{isTeslaPage ? 'TSCs Online' : 'Units Online'}:</td>
                        <td
                          className={
                            isSiteLoading('UnitsOnline')
                              ? ''
                              : getClass(unitsOnline)
                          }
                        >
                          <div className='flex-row-e'>
                            <span>
                              <TextLoader
                                expectedSize={
                                  `${siteMeta?.Units.length}`.length
                                }
                                loading={isSiteLoading('UnitsOnline')}
                                fractionDigits={2}
                                toolTipContent={getTopicForTooltip(
                                  'UnitsOnline',
                                  topicDict,
                                )}
                              >
                                {siteRt.UnitsOnline}
                              </TextLoader>
                            </span>
                            <span>&nbsp;/&nbsp;</span>
                            <span>{siteMeta?.Units.length}</span>
                          </div>
                        </td>
                      </tr>

                      {/* do not show for Sun page */}
                      {!isSunPage && (
                        <tr>
                          <td>
                            {isTeslaPage ? 'MPs Online' : 'Racks Online'}:
                          </td>
                          <td
                            className={
                              isSiteLoading('racks_online')
                                ? ''
                                : getClass(racksOnline)
                            }
                          >
                            <div className='flex-row-e'>
                              <span>
                                <TextLoader
                                  expectedSize={
                                    siteMeta?.SiteRackCountLabel
                                      ? `${siteMeta?.SiteRackCountLabel}`.length
                                      : 1
                                  }
                                  fractionDigits={0}
                                  loading={isSiteLoading('racks_online')}
                                  toolTipContent={getTopicForTooltip(
                                    'racks_online',
                                    topicDict,
                                  )}
                                >
                                  {siteRt.racks_online}
                                </TextLoader>
                              </span>
                              <span>&nbsp;/&nbsp;</span>
                              <span>{siteMeta?.SiteRackCountLabel}</span>
                            </div>
                          </td>
                        </tr>
                      )}

                      {/* do not show for Sun page */}
                      {!isSunPage && (
                        <tr>
                          <td>Avail Dchg MWh:</td>
                          <td>
                            <TextLoader
                              loading={
                                isSiteLoading('DchgMWh_POI') &&
                                isSiteLoading('DchgMWh')
                              }
                              fractionDigits={2}
                              toolTipContent={getTopicForTooltip(
                                'DchgMWh',
                                topicDict,
                              )}
                            >
                              {siteRt.DchgMWh_POI ?? siteRt.DchgMWh ?? '-'}
                            </TextLoader>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>P Command:</td>
                        <td>
                          <div className='flex-row-e'>
                            <span>
                              <TextLoader
                                toolTipContent={getTopicForTooltip(
                                  'PCS_P',
                                  topicDict,
                                )}
                                fractionDigits={2}
                                loading={isSiteLoading('PCS_P')}
                              >
                                {Number(siteMeta.MW_Enable)
                                  ? `${(+siteRt.PCS_P / 1000).toFixed(2)}`
                                  : (siteRt.PCS_P ?? '-')}
                              </TextLoader>
                            </span>
                            <span>
                              &nbsp;{Number(siteMeta.MW_Enable) ? 'MW' : 'kW'}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Q Command:</td>
                        <td>
                          <div className='flex-row-e'>
                            <span>
                              <TextLoader
                                toolTipContent={getTopicForTooltip(
                                  'PCS_Q',
                                  topicDict,
                                )}
                                fractionDigits={2}
                                loading={isSiteLoading('PCS_Q')}
                              >
                                {Number(siteMeta.MW_Enable)
                                  ? `${(+siteRt.PCS_Q / 1000).toFixed(2)}`
                                  : (siteRt.PCS_Q ?? '-')}
                              </TextLoader>
                            </span>
                            <span>
                              &nbsp;
                              {Number(siteMeta.MW_Enable) ? 'MVAR' : 'kVAR'}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <CustomSitePoints siteMeta={siteMeta} />
                    </tbody>
                  </table>
                </td>
                <td style={{ width: '45%', verticalAlign: 'top' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '10px',
                    }}
                  >
                    {siteMeta?.Meters?.length ? (
                      <Input
                        type='dropdown'
                        ddMaxHeight='260'
                        value={{ index: selectedPOIMeterIndex }}
                        styleDD={{
                          minHeight: '36px',
                          maxHeight: '36px',
                          fontSize: '24px',
                          maxWidth: '200px',
                          minWidth: '200px',
                          marginLeft: '10px',
                          marginBottom: '20px',
                        }}
                        onChange={(val) => setSelectedPOIMeterIndex(val)}
                        options={Meters.map(({ displayName }) => displayName)}
                      />
                    ) : (
                      <h4>POI Meter</h4>
                    )}
                  </div>
                  <table className='info in'>
                    <tbody>
                      <tr>
                        <td className='bold'>Active Power:</td>
                        <td>
                          <div className='flex-row-e'>
                            <span>
                              <TextLoader
                                toolTipContent={getTopicForTooltip(
                                  'kW',
                                  topicDict,
                                )}
                                loading={
                                  isSiteLoading('kW') && isMeterLoading('kW')
                                }
                              >
                                {getActivePower()}
                              </TextLoader>
                            </span>
                            <span>
                              &nbsp;
                              {Number(siteMeta.MW_Enable) ? 'MW' : 'kW'}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='bold'>Reactive Power:</td>
                        <td>
                          <div className='flex-row-e'>
                            <span>
                              <TextLoader
                                toolTipContent={getTopicForTooltip(
                                  'kVAR',
                                  topicDict,
                                )}
                                fractionDigits={2}
                                loading={
                                  isSiteLoading('kVAR') &&
                                  isMeterLoading('kVAR')
                                }
                              >
                                {getReactivePower()}
                              </TextLoader>
                            </span>
                            <span>
                              &nbsp;
                              {Number(siteMeta.MW_Enable) ? 'MVAR' : 'kVAR'}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='bold'>Apparent Power:</td>
                        <td>
                          <div className='flex-row-e'>
                            <span>
                              <TextLoader
                                toolTipContent={getTopicForTooltip(
                                  'kVA',
                                  topicDict,
                                )}
                                fractionDigits={2}
                                loading={
                                  isMeterLoading('kVA') &&
                                  isSiteLoading('kVA') &&
                                  (isSiteLoading('kW') || isSiteLoading('kVAR'))
                                }
                              >
                                {getApparentPower()}
                              </TextLoader>
                            </span>
                            <span>
                              &nbsp;
                              {Number(siteMeta.MW_Enable) ? 'MVA' : 'kVA'}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='bold'>Frequency:</td>
                        <td>
                          <div className='flex-row-e'>
                            <span>
                              <TextLoader
                                toolTipContent={getTopicForTooltip(
                                  'Hz',
                                  topicDict,
                                )}
                                fractionDigits={2}
                                loading={
                                  isSiteLoading('Hz') && isMeterLoading('Hz')
                                }
                              >
                                {Object.keys(meterData).length > 0
                                  ? Number(meterData.Hz).toFixed(3)
                                  : (+siteRt['Hz']).toFixed(3)}
                              </TextLoader>
                            </span>
                            <span>&nbsp;Hz</span>
                          </div>
                        </td>
                      </tr>
                      <>
                        <tr>
                          <td className='bold'>Voltage:</td>
                          <td>
                            <div className='flex-row-e'>
                              <span>
                                <TextLoader
                                  toolTipContent={getTopicForTooltip(
                                    'Voltage',
                                    topicDict,
                                  )}
                                  fractionDigits={2}
                                  loading={
                                    isSiteLoading('Voltage') &&
                                    isMeterLoading('Voltage')
                                  }
                                >
                                  {Object.keys(meterData).length > 0
                                    ? Number(meterData.Voltage).toFixed(2)
                                    : (+siteRt['Voltage']).toFixed(3)}
                                </TextLoader>
                              </span>
                              <span>&nbsp;kV</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='bold'>Current:</td>
                          <td>
                            <div className='flex-row-e'>
                              <span>
                                <TextLoader
                                  toolTipContent={getTopicForTooltip(
                                    'Current',
                                    topicDict,
                                  )}
                                  fractionDigits={2}
                                  loading={
                                    isSiteLoading('Current') &&
                                    isMeterLoading('Current')
                                  }
                                >
                                  {Object.keys(meterData).length > 0
                                    ? Number(meterData.Current).toFixed(2)
                                    : (+siteRt['Current']).toFixed(3)}
                                </TextLoader>
                              </span>
                              <span>&nbsp;A</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='bold'>Power Factor:</td>
                          <td>
                            <TextLoader
                              toolTipContent={getTopicForTooltip(
                                'PF',
                                topicDict,
                              )}
                              fractionDigits={2}
                              loading={
                                isMeterLoading('PF') &&
                                isSiteLoading('PF') &&
                                (isSiteLoading('kW') || isSiteLoading('kVAR'))
                              }
                            >
                              {getPowerFactor()}
                            </TextLoader>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan='2'
                            style={{
                              verticalAlign: 'bottom',
                              textAlign: 'center',
                            }}
                          >
                            {/* do not show for Sun page */}
                            {!isSunPage && (
                              <ProgressBar
                                title='Avg SOC'
                                value={siteRt['SOC']}
                                loading={isSiteLoading('SOC')}
                                styleClass={
                                  socRange.min > siteRt['SOC'] ||
                                  siteRt['SOC'] > socRange.max
                                    ? 'red'
                                    : ''
                                }
                              />
                            )}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </>
  );
};

export default storeConnector(SystemMeter, {
  user: ['role'],
  config: ['siteMeta'],
  mqtt: ['topicDict'],
});
