import { SiteMeta } from '@src/types/SiteMeta';
import { UUID } from 'crypto';
import { ControlModeTopic, ControlMode } from './ControlModes';

interface ConfigFieldData {
  inputType?: string;
  label: string;
  quickConfig?: boolean;
}

export interface ConfigField {
  configInput1?: ConfigFieldData;
  configInput2?: ConfigFieldData;
  configInput3?: ConfigFieldData;
  configInput4?: ConfigFieldData;
  configInput5?: ConfigFieldData;
  configInput6?: ConfigFieldData;
  configInput7?: ConfigFieldData;
  configInput8?: ConfigFieldData;
  configInput9?: ConfigFieldData;
  configParentProp?: string;
}

export interface ModeMetadataContent {
  displayName?: string;
  configFields: ConfigField[];
}

export type ModeMetadata = Record<ControlMode, ModeMetadataContent>;

export type ModeConfig = {
  id: UUID;
  deviceId: string;
  description: ControlMode;
  version: string;
  updatedBy: string;
  updatedAt: string;
  body: Record<string, Record<string, string>>;
};

export type ModeConfigState = Record<ControlMode, ModeConfig>;

export enum ModeStatus {
  Enabled = 0,
  Disabled = 1,
  Pending = 2,
}

export type EnabledModesState = Record<ControlModeTopic, ModeStatus>;

export interface ActiveModesListProps {
  siteMeta: SiteMeta;
}

export interface ConfigFormData {
  row1Input1: string;
  row1Input2: string;
  row1Input3: string;
  row1Input4: string;
  row2Input1: string;
  row2Input2: string;
  row2Input3: string;
  row2Input4: string;
  row3Input1: string;
  row3Input2: string;
  row3Input3: string;
  row3Input4: string;
}

export interface ExecutionsSettingsFormData {
  additive: string;
  dataSource: string;
  priority: string;
}

export interface ParsedInputName {
  idx: number;
  input: number;
}

export type ModeConfigFormType = 'quick' | 'full';

export type ModeConfigInputType = 'string' | 'number';

export type ModeSubmitForm = (
  mode: ControlMode,
  config: ModeConfig,
  isFullConfig: boolean,
) => Promise<void>;
