import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import { DeviceNotesResponse } from '@src/types/Notes';

export default (deviceId: string, isNotesOpen?: boolean) =>
  useApiQuery<DeviceNotesResponse>({
    queryKey: [QueryKeys.notes, deviceId],
    apiPath: `/notes?deviceId=${deviceId}`,
    method: 'GET',
    useV2API: true,
    enabled: (isNotesOpen && !!deviceId) ?? !!deviceId,
  });
