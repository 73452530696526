import React from 'react';
import { findForwardRouteForBatteryRoot } from '@pages/_loader/runRouteGuards';
import SYS from '@pages/Battery/pages/SYS';
import PCS from '@pages/Battery/pages/PCS';
import BMS from '@pages/Battery/pages/BMS';
import BOP from '@pages/Battery/pages/BOP';
import SLD from '@pages/Battery/pages/SLD';
import TESLA from '@pages/Battery/pages/TESLA';
import POWIN from '@pages/Battery/pages/POWIN';
import UnitListPageLayout from '@pages/Battery/pages/UnitListPageLayout';
import { SiteMeta } from '@src/types/SiteMeta';
import { Navigate, RouteObject, useRouteLoaderData } from 'react-router-dom';

import AppLayout from '@pages/_layout/AppLayout';
import { PageNotFound } from '@pages/PageError';
import TopBar from './TopBar';

function RouteForward() {
  const siteMeta = useRouteLoaderData('root') as SiteMeta;
  const forwardPath = findForwardRouteForBatteryRoot(siteMeta);
  return forwardPath ? <Navigate to={forwardPath} /> : <PageNotFound />;
}

function CheckBatteryRootRoutePerms() {
  const siteMeta = useRouteLoaderData('root') as SiteMeta;
  const forwardPath = findForwardRouteForBatteryRoot(siteMeta);
  return forwardPath ? <AppLayout topBar={<TopBar />} /> : <PageNotFound />;
}

export default [
  {
    path: '/battery',
    element: <CheckBatteryRootRoutePerms />,
    children: [
      { index: true, element: <RouteForward /> },
      {
        element: <UnitListPageLayout />,
        children: [
          { path: 'sys', element: <SYS /> },
          { path: 'pcs', element: <PCS /> },
          { path: 'bms', element: <BMS /> },
        ],
      },
      { path: 'tesla', element: <TESLA /> },
      { path: 'powin', element: <POWIN /> },
      { path: 'bop', element: <BOP /> },
      { path: 'sld', element: <SLD /> },
    ],
  },
] as RouteObject[];
