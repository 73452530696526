import TextLoader from '@components/_elements/TextLoader/index.jsx';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import './index.scoped.scss';

import WeatherWidget from '@components/Home/WeatherWidget';
import LineHighChart from '@components/_elements/LineHighChart/LineHighChart';
import HomeSystemMeter from '@components/Home/HomeSystemMeter/HomeSystemMeter';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';
import SOCChart from '@components/Home/SOCChart';
import TempChart from '@components/Home/TempChart';
import EnergyProduction from '@components/Home/EnergyProduction';
import PVProduction from '@components/Home/PVProduction';
import WindProduction from '@components/Home/WindProduction';
import { tsDueTimeMode } from '@utils/index_ts';
const MemoEnergyProduction = memo(EnergyProduction);
const MemoPVProduction = memo(PVProduction);
const MemoWindProduction = memo(WindProduction);

const HOURLY_INTERVAL = 5 * 60 * 1000;
const DAILY_INTERVAL = 60 * 60 * 1000;

const Overview = ({ actions, siteMeta, timeMode }) => {
  const { siteId } = siteMeta;
  const { Use_New_Topic_Structure: useNewTopicStructure } = siteMeta.ui;
  const [hourlyPV, setHourlyPV] = useState([]);
  const [hourlyWind, setHourlyWind] = useState([]);
  const [dailyChartData, setDailyChartData] = useState([]);
  const [loadingChartsData, setLoadingChartsData] = useState({
    hourly: true,
    daily: true,
  });
  const timerHourly = useRef(null);
  const timerDaily = useRef(null);

  const [siteRt, setSiteRtData] = useState({});
  const meterPointDictionary = siteMeta.ui.Meter_Point_Dictionary;
  let chartMeterFields = ['Hz', 'kW', 'kVAR'];
  if (meterPointDictionary) {
    chartMeterFields = chartMeterFields.map(
      (field) => meterPointDictionary[field] || field,
    );
  }

  useWsSubscribe({
    site: {
      fields: [
        'SOC',
        'MaxSOC',
        'MinSOC',
        'MaxAmbT',
        'MinAmbT',
        'AvgAmbT',
        'MaxCellT',
        'MinCellT',
        'AvgCellT',
        'AvgHumidity',
        'PVkW',
        'NumPVOnline',
        'PVInvOnline',
        'WindkW',
        'NumWindOnline',
        'WindTOnline',
        'PVMaxT',
        'PVMinT',
        'PVAvgT',
        'UnitAvgP',
        'UnitMaxP',
        'UnitMinP',
      ],
      cb: (data) => setSiteRtData((prev) => ({ ...prev, ...data })),
    },
  });

  const siteMetaRef = useRef(siteMeta);
  useEffect(() => {
    siteMetaRef.current = siteMeta;
  }, [siteMeta]);

  const getFormatHourlyData = (data) => {
    const tzData = data.map((el) => {
      const dateMoment = tsDueTimeMode({
        timeMode: timeMode,
        lat: siteMeta.GPSLat,
        long: siteMeta.GPSLong,
        ts: el.ts,
        returnMoment: true,
      });
      return {
        value: el.value,
        x: dateMoment.hour(),
        date: dateMoment.format('MM/DD/YYYY'),
      };
    });
    let currentDateMoment;
    currentDateMoment = tsDueTimeMode({
      timeMode: timeMode,
      lat: siteMeta.GPSLat,
      long: siteMeta.GPSLong,
      returnMoment: true,
    });
    const currentHourForChart = currentDateMoment.hour();
    currentDateMoment.set({ hour: currentHourForChart, minute: 0, second: 0 });
    const hours = Array(24)
      .fill(0)
      .map((_el, i) => {
        let hour = currentHourForChart - i;
        if (hour < 0) {
          hour = 24 + hour;
        }
        return {
          hour,
          date: currentDateMoment.clone().add(-i, 'h'),
        };
      });
    return hours
      .map((el) => {
        const valueFromData = tzData.find(
          (elData) =>
            elData.x === el.hour &&
            elData.date === el.date.format('MM/DD/YYYY'),
        );
        return {
          value: valueFromData ? valueFromData.value : 0,
          date: el.date.format('YYYY-MM-DD HH:mm:ss'),
        };
      })
      .reverse();
  };

  const formatWindHourlyData = useMemo(
    () => getFormatHourlyData(hourlyWind),
    [hourlyWind],
  );
  const formatPVHourlyData = useMemo(() => {
    return getFormatHourlyData(hourlyPV);
  }, [hourlyPV]);

  const getFormatDailyData = (data) => {
    if (!dailyChartData || !dailyChartData.length || !siteMeta) {
      return [];
    }
    const dataTz = data.map((d) => {
      const date = tsDueTimeMode({
        // timeMode: timeMode,
        timeMode: 'UTC',
        // prevTimeMode: 'UTC',
        lat: siteMeta.GPSLat,
        long: siteMeta.GPSLong,
        ts: d.date,
        returnMoment: true,
      });
      return {
        ...d,
        date: date.format('YYYY-MM-DD'),
      };
    });
    let currentDateMoment = tsDueTimeMode({
      timeMode: timeMode,
      lat: siteMeta.GPSLat,
      long: siteMeta.GPSLong,
      returnMoment: true,
    });
    const dateArr = Array(7)
      .fill(0)
      .map((_el, i) =>
        currentDateMoment.clone().add(-i, 'd').format('YYYY-MM-DD'),
      )
      .reverse();
    const initArr = dateArr.map((el) => ({
      date: el,
      value: { BESS: 0, PV: 0, Index: 0 },
    }));
    return dataTz.reduce((acc, cv) => {
      const newAcc = [...acc];
      if (dateArr.includes(cv.date)) {
        const index = dateArr.findIndex((d) => d === cv.date);
        const dateData = { ...acc[index] };
        Object.keys(cv.value).forEach((f) => {
          if (Object.keys(dateData.value).includes(f)) {
            dateData.value[f] = +dateData.value[f] + +cv.value[f];
            dateData.value[f] = +dateData.value[f].toFixed(3);
          }
        });
        newAcc[index] = dateData;
      }
      return newAcc;
    }, initArr);
  };

  const formatDailyData = useMemo(
    () => getFormatDailyData(dailyChartData),
    [dailyChartData],
  );

  const setHourlyData = (data) => {
    const dataPV = [];
    const dataWind = [];
    data.forEach((d) => {
      if (d.PVkW !== undefined && d.PVkW !== null) {
        dataPV.push({
          value: +(+d.PVkW).toFixed(3),
          ts: d.time,
        });
      }
      if (d.WindkW !== undefined && d.WindkW !== null) {
        dataWind.push({
          value: +(+d.WindkW).toFixed(3),
          ts: d.time,
        });
      }
    });
    setHourlyPV(dataPV.reverse());
    setHourlyWind(dataWind.reverse());
  };
  const setDailyData = (data) => {
    const dailySumData = data
      .map((entity) => ({
        ...entity,
        BESSkW: +(entity.BESSkW ?? 0),
        PVkW: +(entity.PVkW ?? 0),
        WindkW: +(entity.WindkW ?? 0),
      }))
      .reduce((acc, cur) => {
        const date = cur.time.split('T')[0];
        if (acc[date] === undefined) {
          acc[date] = {
            ...cur,
            count: 1,
          };
        } else {
          acc[date] = {
            BESSkW: cur.BESSkW + acc[date].BESSkW,
            PVkW: cur.PVkW + acc[date].PVkW,
            WindkW: cur.WindkW + acc[date].WindkW,
            count: acc[date].count + 1,
          };
        }

        return acc;
      }, {});

    const dailyChartData = Object.entries(dailySumData).map(
      ([date, entity]) => ({
        date: date,
        value: {
          BESS: entity.BESSkW.toFixed(3),
          PV: entity.PVkW.toFixed(3),
          Index: entity.WindkW.toFixed(3),
        },
      }),
    );

    setDailyChartData(dailyChartData);
  };
  const setInitData = () => {
    if (siteMetaRef.current) {
      actions.getEnergyProduction('hourly').then((res) => {
        if (res) {
          setHourlyData(res);
        }
        setLoadingChartsData((loadingChartsData) => ({
          ...loadingChartsData,
          hourly: false,
        }));
      });
    }
    if (timerHourly.current) {
      clearInterval(timerHourly.current);
    }
    timerHourly.current = setInterval(() => {
      if (siteMetaRef.current) {
        actions.getEnergyProduction('hourly').then((res) => {
          if (res) {
            setHourlyData(res);
          }
        });
      }
    }, HOURLY_INTERVAL);
    if (siteMetaRef.current) {
      actions.getEnergyProduction('daily').then((res) => {
        if (res) {
          setDailyData(res);
        }
        setLoadingChartsData((loadingChartsData) => ({
          ...loadingChartsData,
          daily: false,
        }));
      });
    }
    if (timerDaily.current) {
      clearInterval(timerDaily.current);
    }
    timerDaily.current = setInterval(() => {
      if (siteMetaRef.current) {
        actions.getEnergyProduction('daily').then((res) => {
          if (res) {
            setDailyData(res);
          }
        });
      }
    }, DAILY_INTERVAL);
  };

  useEffect(() => {
    const setInfo = () => {
      if (siteMetaRef.current) {
        setInitData();
      } else {
        setTimeout(() => {
          setInfo();
        }, 500);
      }
    };
    setInfo();

    return () => {
      if (timerHourly.current) {
        clearInterval(timerHourly.current);
      }
      if (timerDaily.current) {
        clearInterval(timerDaily.current);
      }
    };
  }, []);

  const isSiteLoading = (field) => {
    return !Object.keys(siteRt).includes(field);
  };

  const windActive = useMemo(
    () => siteMeta && siteMeta.NumWind !== '0' && siteMeta.ui?.Wind_Enable,
    [],
  );
  const pvActive = useMemo(
    () => siteMeta && siteMeta.NumPV !== '0' && siteMeta.ui?.Sun_Enable,
    [],
  );
  const battActive = useMemo(
    () => siteMeta && siteMeta.NumRacks !== '0' && siteMeta.ui?.Battery_Enable,
    [],
  );

  const { dailyEnergy, dailyWind } = useMemo(() => {
    const result = { dailyEnergy: '-', dailyWind: '-' };
    if (dailyChartData) {
      const lastItem = dailyChartData[dailyChartData.length - 1];
      if (lastItem && lastItem.value) {
        if (lastItem.value.PV) {
          result.dailyEnergy = lastItem.value.PV;
        }
        if (lastItem.value.Index) {
          result.dailyWind = lastItem.value.Index;
        }
      }
    }
    return result;
  }, [dailyChartData]);

  const mwEnable = useMemo(() => +siteMeta?.MW_Enable, [siteMeta]);

  return (
    <div className='home-row frow'>
      <div className='fcol h100'>
        <div className='cell h40 block-container'>
          <div className='in' style={{ height: '100%' }}>
            <LineHighChart
              defaultShowVariables={['Freq', 'kW']}
              hasSecondAxis
              megaVars={
                // fields
                mwEnable
                  ? [
                      { prevLabel: 'kW', newLabel: 'MW', newUnit: 'MW' },
                      {
                        prevLabel: 'kVAR',
                        newLabel: 'MVAR',
                        newUnit: 'MVAR',
                      },
                      { prevLabel: 'BESS', newLabel: 'BESS', newUnit: 'MW' },
                    ]
                  : []
              }
              priorityVar='Freq'
              variables={{
                // 'label': 'field'
                Freq: 'Hz',
                kW: 'kW',
                kVAR: 'kVAR',
                ...(battActive ? { BESS: 'BESSkW' } : {}),
                ...(pvActive ? { PV: 'PVkW' } : {}),
                ...(windActive ? { Index: 'WindkW' } : {}),
              }}
              units={{
                // 'field': 'unit'
                Hz: 'Hz',
                kW: 'kW',
                kVAR: 'kVAR',
                ...(battActive ? { BESSkW: 'kW' } : {}),
                ...(pvActive ? { PVkW: 'kW' } : {}),
                ...(windActive ? { WindkW: 'kW' } : {}),
              }}
              minmax={{
                Freq: [
                  +siteMeta.NomFreq - siteMeta.FreqDeviation,
                  +siteMeta.NomFreq + +siteMeta.FreqDeviation,
                ],
                kW: [
                  +siteMeta.kWMinP / (mwEnable ? 1000 : 1),
                  (+siteMeta.kWMaxP + +siteMeta.kWPV + +siteMeta.kWWind) /
                    (mwEnable ? 1000 : 1),
                ],
                kVAR: [
                  +siteMeta.kVARMinQ / (mwEnable ? 1000 : 1),
                  +siteMeta.kVARMaxQ / (mwEnable ? 1000 : 1),
                ],
                ...(pvActive
                  ? {
                      PV: [0, +siteMeta.kWPV],
                    }
                  : {}),
                ...(windActive
                  ? {
                      Index: [0, +siteMeta.kWWind],
                    }
                  : {}),
              }}
              margins={{ top: 50 }}
              fixedYTicks={5}
              dataLoadFunction={async (duration) => {
                const [sitePointData, meterPointData] = await Promise.all([
                  actions.loadChartData(
                    siteId,
                    duration,
                    ['BESSkW', 'PVkW', 'WindkW'],
                    'Site',
                  ),
                  actions.loadChartData(
                    useNewTopicStructure ? 'meter_1' : siteId,
                    duration,
                    chartMeterFields,
                    'Meter',
                  ),
                ]);
                let newMeterPointData = meterPointData;

                if (meterPointDictionary) {
                  newMeterPointData = meterPointData.map((el) => {
                    const value = {
                      Hz: el.value[chartMeterFields[0]],
                      kW: el.value[chartMeterFields[1]],
                      kVAR: el.value[chartMeterFields[2]],
                    };
                    return {
                      date: el.date,
                      value,
                    };
                  });
                }
                const compareFn =
                  sitePointData.length > newMeterPointData.length;
                const baseArr = compareFn ? sitePointData : newMeterPointData;
                const joinArr = compareFn ? newMeterPointData : sitePointData;
                return baseArr.map((timeObj) => ({
                  ...timeObj,
                  value: {
                    ...timeObj.value,
                    ...joinArr.find((o) => o.date === timeObj.date)?.value,
                  },
                }));
              }}
              durationSwitches={['15M', '1H', '12H', '1D', '3D']}
            />
          </div>
        </div>
        <div className='frow h60'>
          <div className='fcol' style={{ width: '40%' }}>
            <div className='cell h100 block-container centered-content'>
              <HomeSystemMeter />
            </div>
          </div>
          <div className='fcol' style={{ width: '60%' }}>
            <SOCChart
              battActive={battActive}
              isSiteLoading={isSiteLoading}
              siteRt={siteRt}
            />
            <TempChart
              battActive={battActive}
              isSiteLoading={isSiteLoading}
              siteRt={siteRt}
            />
          </div>
        </div>
      </div>
      <div className='fcol h100'>
        {pvActive && (
          <div className='cell h15 block-container'>
            <div className='in'>
              <div className='pv-wind-area'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className='title-line mb-2'>PV</div>
                      </td>
                    </tr>
                    <tr>
                      <td>Output</td>
                      <td className='right'>
                        <div className='flex-row-s'>
                          <TextLoader
                            after='kW'
                            loading={isSiteLoading('PVkW')}
                          >
                            {Math.round(Number(siteRt.PVkW))}
                          </TextLoader>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Daily Energy</td>
                      <td className='right'>
                        <div className='flex-row-s'>{dailyEnergy} MWh</div>
                      </td>
                    </tr>
                    <tr>
                      <td>Inverters</td>
                      <td className='right'>
                        <div className='flex-row-s'>
                          <TextLoader
                            loading={
                              isSiteLoading('NumPVOnline') &&
                              isSiteLoading('PVInvOnline')
                            }
                          >
                            {siteRt.NumPVOnline}
                          </TextLoader>
                          <span>&nbsp;/&nbsp;{siteMeta.NumPV}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <MemoPVProduction
                  data={formatPVHourlyData}
                  loading={loadingChartsData.hourly}
                />
              </div>
            </div>
          </div>
        )}
        {windActive && (
          <div className='cell h15 block-container'>
            <div className='in'>
              <div className='pv-wind-area'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className='title-line mb-2'>Index</div>
                      </td>
                    </tr>
                    <tr>
                      <td>Output</td>
                      <td className='right'>
                        <div className='flex-row-s'>
                          <TextLoader
                            after='kW'
                            loading={isSiteLoading('WindkW')}
                          >
                            {Math.round(Number(siteRt.WindkW))} kW
                          </TextLoader>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Daily Energy</td>
                      <td className='right'>
                        <div className='flex-row-s'>{dailyWind} MWh</div>
                      </td>
                    </tr>
                    <tr>
                      <td>Inverters</td>
                      <td className='right'>
                        <div className='flex-row-s'>
                          <TextLoader
                            loading={
                              isSiteLoading('NumWindOnline') &&
                              isSiteLoading('WindTOnline')
                            }
                          >
                            {siteRt.NumWindOnline !== undefined
                              ? siteRt.NumWindOnline
                              : siteRt.WindTOnline}
                          </TextLoader>
                          <span>&nbsp;/&nbsp;{siteMeta.NumWind}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <MemoWindProduction
                  data={formatWindHourlyData}
                  loading={loadingChartsData.hourly}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`cell ${
            pvActive && windActive ? 'h30' : 'h45'
          } block-container`}
        >
          <MemoEnergyProduction
            battActive={battActive}
            pvActive={pvActive}
            windActive={windActive}
            dailyChartData={dailyChartData}
            formatDailyData={formatDailyData}
            loadingChartsData={loadingChartsData}
          />
        </div>
        {siteMeta.ui.Hide_Weather_Widget !== true ? (
          <div className='cell h30 flex-1'>
            <WeatherWidget
              hideSun={!pvActive}
              hideWind={!windActive}
              hideWeather={siteMeta.ui.WeatherForecast_enable === false}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default storeConnector(Overview, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
