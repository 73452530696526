const tscSx = {
  configCol: {
    textAlign: 'center',
  },
  configTable: {
    textField: {
      padding: '4px 4px',
      bgcolor: 'white',
      color: 'black',
    },
  },
  configSaveButton: { flex: 1, justifyContent: 'right', marginRight: '10px' },
};

export default tscSx;
