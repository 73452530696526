import Spinner from '@components/_elements/Spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { GridRowSelectionModel, GridRowId } from '@mui/x-data-grid-pro';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import { getDateTimeFromTimeMode } from '@utils/index_ts';
import { TimeMode } from '@types';
import { currentTzString, getLocale } from '@utils';
import {
  getSeriesForGeneralChartOptions,
  getChartOptions,
  populateChartSeriesWithData,
} from '@utils/historianChart/historianDataChartUtils';

import ActionCreators from '@store/actionCreators';
import HighChartRow from '@components/Home/Historian/HighChartRow';
import {
  ChartDataElement,
  ChartOptionsEMS,
  ChartOptionsWithCriteria,
  ChartQuery,
  ChartRequest,
  UnitCriteria,
} from '@utils/historianChart/historianChartTypes';
import { runHistorianQuery } from '@utils/historianChart/historianChartService';

const MAX_AMB_T = 'MaxAmbT';
const AXIS = 'Y1';
const FLOAT = 'float';
const CATEGORY = 'unit';

const buildChartRequest = (
  siteId: string,
  selectedUnits: GridRowSelectionModel,
  timeMode: TimeMode,
  siteMeta: SiteMeta,
): ChartRequest => {
  const dateFrom: string = getDateTimeFromTimeMode(timeMode, siteMeta)
    .subtract(1, 'hour')
    .toISOString();
  const dateTo: string = getDateTimeFromTimeMode(
    timeMode,
    siteMeta,
  ).toISOString();

  const criteria: UnitCriteria[] = [];

  selectedUnits.forEach((unit: GridRowId): void => {
    const unitCriteriaItem: UnitCriteria = {
      category: CATEGORY,
      siteId,
      field: MAX_AMB_T,
      axis: AXIS,
      unitId: unit.toString(),
      sourceDeviceId: '',
      deviceId: unit.toString(),
      dataType: FLOAT,
    };

    criteria.push(unitCriteriaItem);
  });

  return {
    dateFrom,
    dateTo,
    interval: {
      value: 2,
      unit: 'second',
    },
    aggregateFunctions: ['mean'],
    criteria,
  };
};
const buildChartOptionsWithSeriesTemplate = (
  selectedUnits: GridRowSelectionModel,
  timeMode: TimeMode,
  siteMeta: SiteMeta,
): ChartOptionsWithCriteria => {
  const { siteId, GPSLong, GPSLat } = siteMeta;
  const chartRequest: ChartRequest = buildChartRequest(
    siteId,
    selectedUnits,
    timeMode,
    siteMeta,
  );

  const currentTimeZoneString = currentTzString({
    timeMode,
    lat: GPSLat,
    long: GPSLong,
  });

  const tzOffset = getLocale({
    timeMode,
    lat: GPSLat,
    long: GPSLong,
    returnOffset: true,
  });

  const chartOptions: ChartOptionsEMS = getChartOptions(
    currentTimeZoneString,
    tzOffset,
    'Incident Command Export',
  );
  const aggregation = ['mean'];

  chartOptions.series = getSeriesForGeneralChartOptions(
    chartRequest.criteria,
    aggregation,
    siteMeta,
  );
  return { chartOptions, chartRequest };
};

interface IncidentCmdChartProps {
  siteMeta: SiteMeta;
  selectedUnits: GridRowSelectionModel;
  timeMode: TimeMode;
  actions: typeof ActionCreators;
}

// eslint-disable-next-line max-lines-per-function
function IncidentCmdChart(chartProps: IncidentCmdChartProps) {
  const { selectedUnits, timeMode, siteMeta } = chartProps;
  const chartOptionsWithSeriesTemplate: ChartOptionsWithCriteria =
    buildChartOptionsWithSeriesTemplate(selectedUnits, timeMode, siteMeta);
  const [chartOptionsWithData, setChartOptionsWithData] =
    useState<ChartOptionsWithCriteria | null>(null);
  const { chartRequest } = chartOptionsWithSeriesTemplate;

  useEffect(() => {
    const getData = async () => {
      const queryObject: ChartQuery = {
        dateFrom: chartRequest.dateFrom,
        dateTo: chartRequest.dateTo,
        interval: chartRequest.interval,
        aggregateFunctions: ['mean'],
        criteria: chartRequest.criteria,
      };

      const data = await runHistorianQuery(queryObject);
      const chartOptions: ChartOptionsWithCriteria =
        populateChartSeriesWithData(
          data as ChartDataElement[],
          chartOptionsWithSeriesTemplate,
        );

      setChartOptionsWithData(chartOptions);
    };
    getData();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnits]);

  return (
    <div className='historian-data'>
      <div className='fcol full-width'>
        <div className='frow' style={{ minHeight: '250px' }}>
          {chartOptionsWithData !== null ? (
            <HighChartRow
              chart={chartOptionsWithData.chartOptions}
              chartType='PLOT'
              tooManyPoints={false}
              loadingStatus='loaded'
              reqParams={chartOptionsWithData.chartRequest}
              initTimeMode='UTC'
              tzOffset={chartOptionsWithData.chartOptions.time.timezoneOffset}
              setWrapperRef={{ current: undefined }}
              wrapperRef={undefined}
              key={0}
            />
          ) : (
            <Spinner cover='container' />
          )}
        </div>
      </div>
    </div>
  );
}

export default storeConnector(IncidentCmdChart, {
  config: ['siteMeta'],
  service: ['timeMode'],
});
