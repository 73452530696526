const adminRoleGroups = ['SuperAdmin', 'Administrator'];

const engineerRoleGroups = [...adminRoleGroups, 'Engineer'];

const actionRoleGroups = [...engineerRoleGroups, 'Operator'];

const roleGroups = [...actionRoleGroups, 'Viewer'] as const;

export { roleGroups, adminRoleGroups, engineerRoleGroups, actionRoleGroups };

export type UserRole = (typeof roleGroups)[number];
