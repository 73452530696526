import React, { ReactElement } from 'react';
import { DataPoint, DataPointWithValue } from '@src/types/DataPoint';
import useSubscription from '@hooks/useSubscription';
import useDataPointsSearch from '@hooks/api/queries/useDataPointsSearch';
import Breaker from '@src/sld/components/Breaker/Breaker';
import {
  DatapointConnector,
  DynamicConnector,
  Resistor,
} from '@src/sld/components/Shared';
import {
  BearCanyonWestFordPointsWithValues,
  createBearCanyonWestFordFlatDiagramPropertiesFromDataPoints,
  getBearCanyonWestFordPointsForSearchBody,
  prepareSubscriptionDataPointArray,
} from '@src/sld/components/utils';

// eslint-disable-next-line max-lines-per-function
function WestFordFlatDiagram(): ReactElement {
  const { data: dataPoints } = useDataPointsSearch(
    'west-ford-sld',
    getBearCanyonWestFordPointsForSearchBody(),
  );

  const subscriptionDataPoints: DataPoint[] =
    prepareSubscriptionDataPointArray(dataPoints);

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    subscriptionDataPoints,
  );

  const diagramPointsWithValues: BearCanyonWestFordPointsWithValues =
    createBearCanyonWestFordFlatDiagramPropertiesFromDataPoints(
      dataPointsWithValue,
    );

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      width='17in'
      height='11in'
      viewBox='0 0 1224 792'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor' }}
    >
      <g>
        <title>West Ford Flat</title>

        <g transform='translate(574.48 -620.031)'>
          <title>PG&E 230KV</title>
          <polygon
            transform='translate(-13, 715) rotate(-90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <DynamicConnector
            label='PG&E 230KV'
            pathCoordinates='M-8.99 792v-11.84h-.02v-64.02'
            className='st2'
            xCoordinate={0}
            yCoordinate={695}
          />
        </g>

        <g transform='rotate(90 588.59 752.22)'>
          <title>Resistor</title>
          <Resistor
            label='XFMR T1'
            path_1_coordinates='M33.36 758.64h-8.34a4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17h8.34'
            path_2_coordinates='M0 758.64h8.34a4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17A4.17 4.17 0 0 1 8.34 792H0'
            xCoordinate={15}
            yCoordinate={740}
          />
        </g>

        <g transform='translate(551.952 -504)'>
          <title>52-M1</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_m1_dataPointValue}
            enableBreakerCommands={false}
            label='52-M1'
            xCoordinate={70}
            yCoordinate={785}
          />

          <DatapointConnector
            voltage={`Voltage: ${diagramPointsWithValues.voltage}`}
            current={`Current: ${diagramPointsWithValues.current}`}
            activePower={`Active Power: ${diagramPointsWithValues.activePower}`}
            reactivePower={`Reactive Power: ${diagramPointsWithValues.reactivePower}`}
            pathCoordinates=''
            className='st5'
            xCoordinate={200}
            yCoordinate={700}
          />
        </g>

        <g transform='translate(218.214 -288)'>
          <title>52-F1</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_f1_dataPointValue}
            enableBreakerCommands={false}
            label='52-F1'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17, 909) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          {/* arrow */}
          <text x={-60} y={950} fill='currentColor' stroke='none'>
            34.5KV BESS FEEDER #1
          </text>
        </g>

        <g transform='translate(439.113 -288)'>
          <title>52-F2</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_f2_dataPointValue}
            enableBreakerCommands={false}
            label='52-F2'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17, 909) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          {/* arrow */}
          <text x={-60} y={950} fill='currentColor' stroke='none'>
            34.5KV BESS FEEDER #2
          </text>
        </g>

        <g transform='translate(661.526 -288)'>
          <title>52-F3</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_f3_dataPointValue}
            enableBreakerCommands={false}
            label='52-F3'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17, 909) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          {/* arrow */}
          <text x={-60} y={950} fill='currentColor' stroke='none'>
            34.5KV BESS FEEDER #3
          </text>
        </g>

        <g transform='translate(882.425 -288)'>
          <title>52-F4</title>
          <Breaker
            status={diagramPointsWithValues.breaker_52_f4_dataPointValue}
            enableBreakerCommands={false}
            label='52-F4'
            xCoordinate={70}
            yCoordinate={785}
          />
          {/* arrow */}
          <polygon
            transform='translate(17, 909) rotate(90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          {/* arrow */}
          <text x={-60} y={950} fill='currentColor' stroke='none'>
            34.5KV BESS FEEDER #4
          </text>
        </g>

        <g transform='translate(231.75 -315.073)'>
          <title>Dynamic connector.1269</title>
          <path d='M0 792v-80.93h333.74v-108' className='st5' />
        </g>

        <g transform='translate(556.489 -531.073)'>
          <title>Dynamic connector.1323</title>
          <path d='M9 792v-72.28' className='st5' />
        </g>

        <g transform='translate(675.062 -306.073)'>
          <title>Dynamic connector.1325</title>
          <path d='M0 783v-80.93h-222.41V783' className='st5' />
        </g>

        <g transform='rotate(90 259.875 763.875)'>
          <title>Sheet.1329</title>
          <path d='M0 792h115.38' className='st6' />
        </g>

        <g transform='rotate(90 370.325 874.325)'>
          <title>Sheet.1356</title>
          <path d='M0 792h115.38' className='st6' />
        </g>

        <g transform='rotate(90 481.53 985.53)'>
          <title>Sheet.1362</title>
          <path d='M0 792h115.38' className='st6' />
        </g>

        <g transform='translate(565.489 -504)'>
          <title>Dynamic connector.1363</title>
          <path d='M0 792v108h-112.84v80.93' className='st5' />
        </g>

        <g transform='rotate(90 591.98 1095.98)'>
          <title>Sheet.1372</title>
          <path d='M0 792h115.38' className='st6' />
        </g>

        <g transform='translate(565.489 -504)'>
          <title>Dynamic connector.1373</title>
          <path d='M0 792v108h330.47v80.93' className='st5' />
        </g>
      </g>
    </svg>
  );
}

export default WestFordFlatDiagram;
