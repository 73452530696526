import React from 'react';
import ApplicationAddEdit from '@pages/Home/pages/Config/pages/ApplicationAddEdit';
import ApplicationList from '@pages/Home/pages/Config/pages/ApplicationList';
import StackList from '@pages/Home/pages/Config/pages/StackList';
import CreateStackPage from '@pages/Home/pages/Config/pages/CreateStackPage';
import Alerts from '@pages/Home/pages/Config/pages/Alerts';
import TSCConfig from '@pages/Home/pages/Config/pages/TSCConfig';
import Modes from '@pages/Home/pages/Config/pages//Modes';
import { Navigate, RouteObject, useRouteLoaderData } from 'react-router-dom';
import { SiteMeta } from '@src/types/SiteMeta';
import { findForwardRouteForConfigRoot } from '@pages/_loader/runRouteGuards';
import AppLayout from '@pages/_layout/AppLayout';
import { PageNotFound } from '@pages/PageError';
import TopBar from './TopBar';

function RouteForward() {
  const siteMeta = useRouteLoaderData('root') as SiteMeta;
  const forwardPath = findForwardRouteForConfigRoot(siteMeta);
  return forwardPath ? <Navigate to={forwardPath} /> : <PageNotFound />;
}

function CheckConfigRootRoutePerms() {
  const siteMeta = useRouteLoaderData('root') as SiteMeta;
  const forwardPath = findForwardRouteForConfigRoot(siteMeta);
  return forwardPath ? (
    <AppLayout isNested topBar={<TopBar />} />
  ) : (
    <PageNotFound />
  );
}

export default [
  {
    path: 'config',
    element: <CheckConfigRootRoutePerms />,
    children: [
      {
        index: true,
        element: <RouteForward />,
      },
      { path: 'applications', element: <ApplicationList /> },
      { path: 'applications/edit/:name', element: <ApplicationAddEdit /> },
      { path: 'applications/create', element: <ApplicationAddEdit /> },
      { path: 'stacks/', element: <StackList /> },
      { path: 'stacks/:stackID/', element: <CreateStackPage /> },
      { path: 'alerts', element: <Alerts /> },
      { path: 'modes', element: <Modes /> },
      { path: 'tsc-config', element: <TSCConfig /> },
    ],
  },
] as RouteObject[];
