import { User } from '@src/types/User';
import useApiMutation from '@src/hooks/useApiMutation';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';

export type EditUserFormValues = {
  email?: string;
  firstName?: string;
  lastName?: string;
  groups: string[];
};

export default (userId: string) => {
  const queryClient = useQueryClient();

  return useApiMutation<EditUserFormValues, User>(
    {
      apiPath: `/users/${userId}`,
      method: 'PUT',
      useV2API: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.users] });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys['user-groups'], userId],
        });
      },
    },
  );
};
