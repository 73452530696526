/* eslint max-lines: "off" */
import React, { ReactElement, useMemo, useState } from 'react';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import useSubscription from '@hooks/useSubscription';
import Spinner from '@components/_elements/Spinner/Spinner';
import { SiteMeta } from '@src/types/SiteMeta';
import UIBox from '@components/_shared/UIBox';
import storeConnector from '@store/storeConnector';
import { DataPointWithValue } from '@src/types/DataPoint';
import DeviceDataTable from '@components/_shared/DeviceDataTable';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import IncidentCmdChart from '@components/IncidentCommand/IncidentCmdChart';
import useMakePageScrollable from '@hooks/useMakePageScrollable';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';

interface IncidentCommandProps {
  siteMeta: SiteMeta;
}

// eslint-disable-next-line max-lines-per-function
function IncidentCommand(p: IncidentCommandProps): ReactElement {
  useMakePageScrollable();

  const { siteMeta } = p;
  const [selectedUnits, setSelectedUnits] = useState<GridRowSelectionModel>([
    siteMeta.Units[0],
  ]);

  const rowSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ): void => {
    if (rowSelectionModel.length > 0) {
      //  save new selectedUnits to state
      setSelectedUnits(rowSelectionModel);
    }
  };

  const deviceNameDictionary = useMemo(
    () =>
      siteMeta.Units.map((unitId, i) => ({
        id: unitId,
        name: siteMeta.UnitNames[i],
      })),
    [siteMeta],
  );

  const { data: widgetConfig, isLoading } =
    useGetWidgetConfig<WidgetTypeDeviceList>('incidentCommandList', {});

  const dataPointsWithValues: DataPointWithValue[] = useSubscription(
    widgetConfig?.dataPoints || [],
  );

  return isLoading ? (
    <Spinner type='fullPage' />
  ) : (
    <div className='home-row full-width'>
      <div className='fcol full-width'>
        <div className='frow full-width'>
          <div className='fcol full-width'>
            <UIBox header='Incident Command List'>
              <DeviceDataTable
                enableCmdBtnColumn={false}
                hideFooter
                data={dataPointsWithValues}
                rowSelectionModel={selectedUnits}
                checkboxSelection
                onRowSelectionChange={rowSelectionModelChange}
                deviceNameDictionary={deviceNameDictionary}
                getRowClassName={() => 'MuiDataGrid-clickable'}
                boxStyle={{ height: '400px' }}
              />
            </UIBox>
          </div>
        </div>
        <div className='frow full-width'>
          <div className='fcol full-width'>
            <UIBox header='Max Ambient Temperature'>
              <IncidentCmdChart selectedUnits={selectedUnits} />
            </UIBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default storeConnector(IncidentCommand, {
  config: ['siteMeta'],
});
