import React, { useEffect, useRef, useState } from 'react';
import './HVAC.scoped.scss';
import GaugeChart from '@components/_elements/GaugeChart/GaugeChart';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';

const COMMON_SITE_UNIT_FIELDS = [
  'FanStatus',
  'Compressor1Status',
  'Compressor2Status',
  'HeaterStatus',
  'MinAmbT',
  'MaxAmbT',
  'MinCellT',
  'MaxCellT',
];

const HVAC = ({ siteMeta, selectedUnit }) => {
  const [unitOrSite, setUnitOrSite] = useState({});
  const siteRTRef = useRef(null);
  const unitTableRef = useRef([]);
  const selectedUnitRef = useRef();
  selectedUnitRef.current = selectedUnit;

  useEffect(() => {
    if (selectedUnit === -1) {
      setUnitOrSite({ ...siteRTRef.current });
    } else {
      setUnitOrSite({ ...unitTableRef.current[selectedUnit] });
    }
  }, [selectedUnit]);

  useWsSubscribe({
    unit: {
      sns: siteMeta.Units,
      fields: [...COMMON_SITE_UNIT_FIELDS, 'Humidity'],
      cb: (data, sn) => {
        const idx = siteMeta.Units.indexOf(sn);
        if (unitTableRef.current[idx]) {
          Object.assign(unitTableRef.current[idx], data);
        } else {
          unitTableRef.current[idx] = data;
        }
        if (idx === selectedUnitRef.current) {
          setUnitOrSite({ ...unitTableRef.current[idx] });
        }
      },
    },
    site: {
      fields: [...COMMON_SITE_UNIT_FIELDS, 'AvgHumidity'],
      cb: (data) => {
        if (siteRTRef.current === null) {
          siteRTRef.current = data;
        } else {
          Object.assign(siteRTRef.current, data);
        }
        if (selectedUnitRef.current === -1) {
          setUnitOrSite({ ...siteRTRef.current });
        }
      },
    },
  });

  const isLoading = (field) => {
    return !Object.keys(unitOrSite).includes(field);
  };

  const humidityField = selectedUnit === -1 ? 'AvgHumidity' : 'Humidity';
  const statuses = {
    'Fan Status': unitOrSite.FanStatus,
    'Compressor 1 Status': unitOrSite.Compressor1Status,
    'Compressor 2 Status': unitOrSite.Compressor2Status,
    'Heater Status': unitOrSite.HeaterStatus,
  };
  const {
    Cell_temp_GB_high,
    Cell_temp_GB_low,
    Cell_temp_range_high,
    Cell_temp_range_low,
    Amb_Temp_GB_high,
    Amb_Temp_GB_low,
    Amb_Temp_Range_High,
    Amb_Temp_Range_Low,
  } = siteMeta;
  return (
    <div className='hvac-container alarm-and-warning'>
      <div className='col-hvac-temp'>
        <div className='chart-row-hvac-temp'>
          <GaugeChart
            height='150px'
            width='33%'
            loading={isLoading('MinAmbT') || isLoading('MaxAmbT')}
            key={1}
            min={Amb_Temp_Range_Low || 0}
            max={Amb_Temp_Range_High || 45}
            minRangeNorm={Amb_Temp_GB_low || 18}
            maxRangeNorm={Amb_Temp_GB_high || 28}
            minValue={Math.round(+unitOrSite.MinAmbT)}
            maxValue={Math.round(+unitOrSite.MaxAmbT)}
            units='°C'
            title='Ambient Temperature'
            gradient='ambientGradient'
          />
          {siteMeta.ui.features?.Humidity_Gauge_Enabled ? (
            <GaugeChart
              height='150px'
              width='33%'
              loading={isLoading(humidityField)}
              key={2}
              min={0}
              max={100}
              gradient='humidityGradient'
              value={+unitOrSite[humidityField]}
              units='%'
              title='Humidity'
            />
          ) : null}
          <GaugeChart
            height='150px'
            width='33%'
            loading={isLoading('MinCellT') || isLoading('MaxCellT')}
            key={3}
            gradient='cellGradient'
            minRangeNorm={Cell_temp_GB_low || 20}
            maxRangeNorm={Cell_temp_GB_high || 30}
            min={Cell_temp_range_low || 10}
            max={Cell_temp_range_high || 50}
            minValue={Math.round(+unitOrSite.MinCellT)}
            maxValue={Math.round(+unitOrSite.MaxCellT)}
            units='°C'
            title='Cell Temperature'
          />
        </div>
        {selectedUnit !== -1 && (
          <div className='row pd-top'>
            <div className='col-hvac'>
              <table className='hvac-table'>
                <thead>
                  <tr>
                    <th>
                      <div className='title-line'>HVAC</div>
                    </th>
                    {[statuses].map((_s, i) => (
                      <th key={i}>#{i + 1}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(statuses).map((key, index) => (
                    <tr key={index}>
                      <td>{key}</td>
                      {[statuses].map((s, i) => (
                        <td key={i}>
                          <div
                            className={
                              'aw-item ' + (s[key] === '1' ? 'red' : 'green')
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default storeConnector(HVAC, {
  config: ['siteMeta'],
});
