import React from 'react';
import PointListCell from '@components/Home/Dashboard/variants/PointListCell';
import { IDashboardCell } from '@src/types/SiteMetaUI';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import './DashboardCell.scoped.scss';

interface DashboardCellProps {
  variant: 'points-list';
  data: IDashboardCell['data'];
}

function DashboardCell({ variant, data }: DashboardCellProps) {
  const renderCell = (component: ReactJSXElement) => (
    <div
      className={`dashboard-cell-container${data.title ? ' with-title' : ''}`}
    >
      <div className='drag-handle'>
        <DragIndicatorIcon />
      </div>
      {data.title ? (
        <div className='cell-title'>
          <h5 className='bold'>{data.title}</h5>
        </div>
      ) : null}
      {component}
    </div>
  );

  switch (variant) {
    case 'points-list':
      return renderCell(<PointListCell data={data} />);
    default:
  }
}

export default React.memo(DashboardCell);
