import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import storeConnector from '@store/storeConnector';
import { Actions } from '@src/types/Actions';
import { useQueryClient } from '@tanstack/react-query';
import { ConfirmType, NotesState } from '@src/types/Notes';
import { UserState } from '@store/reducers/userData';
import useGetDeviceNotesQuery from '@src/hooks/api/queries/useGetDeviceNotesQuery';
import QueryKeys from '@src/constants/queryKeys';
import useNoteMutations from '@src/hooks/api/mutations/useNoteMutations';
import NotesList from './NotesList';

import NotesConfirmDialog from './NotesConfirmDialog';

import './Notes.scoped.scss';
import notesx from './NotesSx';

interface NotesProps {
  actions: Actions;
  noteProps: NotesState;
  username: UserState['username'];
  role: UserState['role'];
}

/* eslint-disable max-lines-per-function */
export function NotesModal({ noteProps, actions, username, role }: NotesProps) {
  const { deviceId, isNotesOpen } = noteProps;

  const { data, isFetching, isPending } = useGetDeviceNotesQuery(
    deviceId,
    isNotesOpen,
  );
  const [currentNote, setCurrentNote] = useState<string>('');
  const [selectedNoteId, setSelectedNoteId] = useState<string>('');
  const [confirmType, setConfirmType] = useState<ConfirmType>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const successCallBack = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.notes, deviceId],
    });
    setError(false);
    setConfirmOpen(false);
  };

  const { createNote, clearNotes, deleteNote } = useNoteMutations({
    deviceId,
    selectedNoteId,
    setCurrentNote,
    setError,
    successCallBack,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentNote(e.currentTarget.value);
  };

  const handleOpenConfirm = (type: ConfirmType) => {
    setConfirmType(type);
    setConfirmOpen(true);
  };

  const handleCreateNote = () => {
    const notePayload = {
      user: username,
      note: currentNote,
      deviceId,
    };
    createNote.mutate(notePayload);
  };

  const handleConfirm = () => {
    if (confirmType === ConfirmType.CREATE) {
      handleCreateNote();
    } else if (confirmType === ConfirmType.CLEAR) {
      clearNotes.mutate([]);
    } else if (confirmType === ConfirmType.DELETE) {
      deleteNote.mutate([]);
    }
  };

  const handleClose = () => {
    setCurrentNote('');
    setConfirmOpen(false);
    actions.showNotesModal({ deviceId: '', isNotesOpen: false });
  };

  return (
    <Modal open={isNotesOpen} onClose={handleClose}>
      <Box sx={notesx.boxWrapper}>
        <header className='notes_modal--header'>
          <Typography variant='h6' component='h6' sx={notesx.title}>
            Notes - {deviceId}
          </Typography>
          <FontAwesomeIcon
            size='xl'
            icon={faClose}
            onClick={handleClose}
            className='notes_modal--header-icon'
            data-testid='close-notes-modal'
          />
        </header>
        <main className='notes_modal--main'>
          <section className='notes_modal--list-section'>
            <NotesList
              notes={data?.notes || []}
              pending={isPending || isFetching}
              username={username}
              role={role}
              handleOpenConfirm={handleOpenConfirm}
              setSelectedNoteId={setSelectedNoteId}
            />
          </section>

          {!process.env.VITE_READ_ONLY_MODE && (
            <section className='notes_modal--field-wrapper'>
              <TextField
                multiline
                variant='outlined'
                rows={2}
                sx={notesx.textField}
                onChange={handleChange}
                value={currentNote}
                placeholder='Type your notes here...'
              />
              <div className='notes_modal--field-actions'>
                <Button
                  variant='contained'
                  size='small'
                  data-testid='create-note-button'
                  sx={{ borderRadius: 0 }}
                  disabled={currentNote.length < 1}
                  onClick={() => handleOpenConfirm(ConfirmType.CREATE)}
                >
                  Create note
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  data-testid='clear-notes-button'
                  disabled={data?.notes && data.notes.length < 1}
                  sx={{ mt: 1, borderRadius: 0 }}
                  onClick={() => handleOpenConfirm(ConfirmType.CLEAR)}
                >
                  Clear All Notes
                </Button>
              </div>
            </section>
          )}
        </main>
        <footer className='notes_modal--footer'>
          <NotesConfirmDialog
            error={error}
            confirmOpen={confirmOpen}
            handleClose={handleClose}
            confirmType={confirmType || ''}
            deviceId={deviceId}
            setError={setError}
            setConfirmOpen={setConfirmOpen}
            handleConfirm={handleConfirm}
          />
        </footer>
      </Box>
    </Modal>
  );
}

export default storeConnector(NotesModal, {
  user: ['username', 'role'],
  config: ['siteMeta'],
  service: ['noteProps'],
});
