import React, { useState } from 'react';
import storeConnector from '@store/storeConnector';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SiteMeta, SiteOption } from '@src/types/SiteMeta';

interface SiteDropdown {
  siteMeta: SiteMeta;
}

const selectSx = {
  select: {
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      bgcolor: 'rgba(255, 255, 255, 0.041)',
    },
    pr: 1,
  },
  selectInput: {
    sx: { fontSize: '1.4em' },
  },
};

const findCurrentSite = (siteOptions: SiteOption[], url: string) =>
  siteOptions.find((s) => s.url === url);

function SiteSelect({ siteMeta }: SiteDropdown) {
  const siteOptions = siteMeta.siteSelectorLinks || [];
  const currentSiteUrl =
    findCurrentSite(siteOptions, window.location.origin)?.url || '';
  const [selectValue, setSelectValue] = useState<string>(
    currentSiteUrl as string,
  );

  const handleChange = (e: SelectChangeEvent<string>) => {
    const chosenSite = e.target.value;
    const fullChosenSiteUrl = chosenSite + window.location.pathname;
    setSelectValue(chosenSite);
    window.location.href = fullChosenSiteUrl;
  };

  return (
    <Select
      value={selectValue}
      renderValue={(value: string) => (
        <span>{findCurrentSite(siteOptions, value)?.label}</span>
      )}
      variant='standard'
      sx={selectSx.select}
      inputProps={selectSx.selectInput}
      onChange={handleChange}
      disableUnderline
    >
      {siteOptions.length > 0 &&
        siteOptions.map((option) => (
          <MenuItem value={option.url} key={option.url}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  );
}

export default storeConnector(SiteSelect, {
  config: ['siteMeta'],
});
