import { WidgetConfigBase } from './Widget';

export interface SourceDevice {
  type: string;
  namePrefix?: string;
  parentType: string;
  count: number;
}
export interface GenerateDevicePayload {
  id?: string;
  siteControllerId: string;
  unitControllerIds?: string[];
  sourceDevices: SourceDevice[];
}

export interface Device {
  id: string;
  name: string;
  type: string;
  controllerId: string;
  deviceId: string;
  isSiteControllerSourceDevice: boolean;
  engineeringId: string;
  notesRefs: string[];
  deleted: boolean;
}

export interface PointConfigs {
  category?: string;
  dataType: string;
  pointName: string;
  id?: string;
  name: string;
  decimalPrecision?: number;
}

export type DeviceExtended = Device & {
  isNew?: boolean;
  remove?: boolean;
};

export type PointConfigExtended = PointConfigs & {
  isNew?: boolean;
  remove?: boolean;
};

export type CollectionPayload =
  | GenerateDevicePayload
  | DeviceExtended
  | PointConfigExtended
  | Device
  | PointConfigs
  | WidgetConfigBase;

export enum CollectionTypes {
  DEVICES,
  POINT_CONFIGS,
  WIDGETS,
}
