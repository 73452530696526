/* eslint-disable
  import/extensions,
  max-lines-per-function,
  @typescript-eslint/no-explicit-any
*/
import React, { ReactElement, useMemo, useState } from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import ThermometerIcon from '@assets/thermometer.svg?react';
import TextLoader from '@components/_elements/TextLoader';
import LineHighChart from '@components/_elements/LineHighChart/LineHighChart';
import Input from '@components/_elements/Input/Input';
import getTemperatureString, {
  getSiteTemperatureUnit,
} from '@src/utils/getTemperatureString';

interface TempChartProps {
  actions: any;
  battActive: boolean;
  isSiteLoading: any;
  siteRt: any;
  siteMeta: SiteMeta;
}

const ChartTypeDict = {
  Ambient: {
    label: 'Ambient',
    defaultVariables: ['MaxAmbT', 'MinAmbT', 'AvgAmbT'],
    variables: {
      MaxAmbT: 'MaxAmbT',
      MinAmbT: 'MinAmbT',
      AvgAmbT: 'AvgAmbT',
    },
  },
  Cell: {
    label: 'Cell',
    defaultVariables: ['MaxCellT', 'MinCellT', 'AvgCellT'],
    variables: {
      MaxCellT: 'MaxCellT',
      MinCellT: 'MinCellT',
      AvgCellT: 'AvgCellT',
    },
  },
};

function TempChart({
  actions,
  battActive,
  isSiteLoading,
  siteRt,
  siteMeta,
}: TempChartProps): ReactElement {
  const { siteId } = siteMeta;
  const [selectedTemperatureMode, setSelectedTemperatureMode] = useState(0);
  const defaultTemp = `°${getSiteTemperatureUnit()}`;
  const isAmbient = !battActive || selectedTemperatureMode === 1;

  const chartInfo = useMemo(() => {
    if (isAmbient) {
      return ChartTypeDict.Ambient;
    }
    return ChartTypeDict.Cell;
  }, [isAmbient]);

  return (
    <div className='cell h-50 block-container'>
      <LineHighChart
        hideVarSwitches
        key={selectedTemperatureMode}
        hideGrids
        fixedYTicks={3}
        min={siteMeta.TempPlotMin}
        max={siteMeta.TempPlotMax}
        defaultShowVariables={chartInfo.defaultVariables}
        margins={{ top: 65, right: 30, bottom: 40, left: 40 }}
        units={
          isAmbient
            ? {
                MaxAmbT: defaultTemp,
                MinAmbT: defaultTemp,
                AvgAmbT: defaultTemp,
              }
            : {
                MaxCellT: defaultTemp,
                MinCellT: defaultTemp,
                AvgCellT: defaultTemp,
              }
        }
        variables={chartInfo.variables}
        dataLoadFunction={(duration: string): any =>
          actions.loadChartData(siteId, duration, [
            ...chartInfo.defaultVariables,
          ])
        }
        durationSwitches={['15M', '1H', '12H', '1D', '3D']}
      >
        <div className='over-chart'>
          <div className='thermo-section'>
            <ThermometerIcon className='therm' />
            <table>
              <tbody>
                <tr>
                  <td className='left'>Ambient</td>
                  <td className='flex-row-c right'>
                    <TextLoader loading={isSiteLoading('MaxAmbT')}>
                      {getTemperatureString(siteRt.MaxAmbT, {
                        decimals: 0,
                        initialUnit: siteMeta.ui.Default_Temp_Unit,
                      })}
                    </TextLoader>
                    <span>&nbsp;/&nbsp;</span>
                    <TextLoader loading={isSiteLoading('MinAmbT')}>
                      {getTemperatureString(siteRt.MinAmbT, {
                        decimals: 0,
                        initialUnit: siteMeta.ui.Default_Temp_Unit,
                      })}
                    </TextLoader>
                  </td>
                </tr>
                {battActive && (
                  <tr>
                    <td className='left'>Cell</td>
                    <td className='flex-row-c right'>
                      <TextLoader loading={isSiteLoading('MaxCellT')}>
                        {getTemperatureString(siteRt.MaxCellT, {
                          decimals: 0,
                          initialUnit: siteMeta.ui.Default_Temp_Unit,
                        })}
                      </TextLoader>
                      <span>&nbsp;/&nbsp;</span>
                      <TextLoader loading={isSiteLoading('MinCellT')}>
                        {getTemperatureString(siteRt.MinCellT, {
                          decimals: 0,
                          initialUnit: siteMeta.ui.Default_Temp_Unit,
                        })}
                      </TextLoader>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {battActive && (
              <div className='chart-dd-container'>
                <Input
                  type='dropdown'
                  value={{ index: selectedTemperatureMode }}
                  onChange={(val: any): any => setSelectedTemperatureMode(val)}
                  options={['Cell', 'Ambient']}
                />
              </div>
            )}
          </div>
          <div className='chart-titles'>
            <div className='color-chart-0 small-text'>
              Max {chartInfo.label}
            </div>
            <div className='color-chart-1 small-text'>
              Min {chartInfo.label}
            </div>
            <div className='color-chart-2 small-text'>
              Avg {chartInfo.label}
            </div>
          </div>
        </div>
      </LineHighChart>
    </div>
  );
}

export default storeConnector(TempChart, {
  config: ['siteMeta'],
});
