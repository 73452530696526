import { IDashboard } from '@src/types/SiteMetaUI';
import React, { useEffect, useState } from 'react';
import { WidthProvider, Responsive, Layouts, Layout } from 'react-grid-layout';
import './index.scoped.scss';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import DashboardCell from '@components/Home/Dashboard/DashboardCell';
import Spinner from '@components/_elements/Spinner/Spinner';
import useMakePageScrollable from '@hooks/useMakePageScrollable';
import { getFromLS, saveToLS } from '@utils/index_ts';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function Dashboards({ siteMeta }: { siteMeta: SiteMeta }) {
  useMakePageScrollable();
  const [isLoading, setIsLoading] = useState(true);
  const [currentDashboard, setCurrentDashboard] = useState<IDashboard>();
  const [layouts, setLayouts] = useState<Layouts>();

  useEffect(() => {
    const lsCurrentDashboard = getFromLS<IDashboard>(
      'fractal-ems-dashboards-layouts',
      'currentDashboard',
    );
    const initialDashboard = lsCurrentDashboard || siteMeta.ui.dashboards?.[0];
    const lsLayouts = getFromLS<Layouts>(
      'fractal-ems-dashboards-layouts',
      initialDashboard?.id || '0',
    );
    const currentLayout = lsLayouts || initialDashboard?.layouts || {};
    setCurrentDashboard(initialDashboard);
    setLayouts(currentLayout);
    setIsLoading(false);
  }, [siteMeta.ui.dashboards]);

  const onLayoutChange = (_currentLayout: Layout[], allLayouts: Layouts) => {
    saveToLS(
      'fractal-ems-dashboards-layouts',
      (currentDashboard as IDashboard).id,
      allLayouts,
    );
    setLayouts(allLayouts);
  };

  return (
    <div className='home-row dashboard-root'>
      {isLoading ? <Spinner type='fullPage' /> : null}
      {!isLoading && currentDashboard ? (
        <ResponsiveReactGridLayout
          className='layout'
          layouts={layouts}
          autoSize
          compactType='horizontal'
          draggableHandle='.drag-handle'
          containerPadding={[3, 3]}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
          rowHeight={100}
          onLayoutChange={onLayoutChange}
        >
          {currentDashboard.cells.map((cellData) => (
            <div key={cellData.key} className='cell block-container'>
              <DashboardCell variant='points-list' data={cellData.data} />
            </div>
          ))}
        </ResponsiveReactGridLayout>
      ) : null}
    </div>
  );
}

export default storeConnector(Dashboards, {
  config: ['siteMeta'],
});
