import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { User } from '@src/types/User';
import { EditUserFormValues } from '@src/hooks/api/mutations/useUserUpdate';
import GroupField from './Fields/GroupField';
import EmailField from './Fields/EmailField';
import FirstNameField from './Fields/FirstNameField';
import LastNameField from './Fields/LastNameField';

const schema = yup
  .object({
    email: yup.string().email().label('Email'),
    firstName: yup.string().optional().label('First Name'),
    lastName: yup.string().optional().label('Last Name'),
    groups: yup
      .array()
      .of(yup.string().required())
      .min(1, 'Groups cannot be an empty array')
      .required()
      .label('Groups'),
  })
  .required();

const getDefaultValues = (user: User): EditUserFormValues => ({
  email: user?.email ?? '',
  firstName: user?.firstName ?? '',
  lastName: user?.lastName ?? '',
  groups: [],
});

function EditUserForm({
  onCancel,
  onSubmit,
  isLoading,
  user,
}: {
  onCancel: () => void;
  onSubmit: (values: EditUserFormValues) => void;
  isLoading: boolean;
  user: User;
}) {
  const methods = useForm<EditUserFormValues>({
    defaultValues: getDefaultValues(user),
    resolver: yupResolver<EditUserFormValues>(schema),
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack gap={2}>
            <TextField
              fullWidth
              label='Username'
              value={user.username}
              disabled
              size='small'
            />
            <EmailField />
            <FirstNameField />
            <LastNameField />
            <GroupField userId={user.id} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <LoadingButton type='submit' variant='contained' loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}

export default EditUserForm;
