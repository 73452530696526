import React, { useState } from 'react';
import './TemperaturesTable.scoped.scss';
import Input from '@components/_elements/Input/Input';
import Spinner from '@components/_elements/Spinner/Spinner';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';
import getTemperatureString from '@src/utils/getTemperatureString';
import { isKeyInObject } from '@src/utils';

const TEMP_TABLE_MODE_OPTIONS = ['Real Time', 'Hourly', 'Daily'];
const BATT_MODE = TEMP_TABLE_MODE_OPTIONS[0];
const TEMP_TABLE_MODES_WITH_DELTA = ['Real Time', 'Hourly'];

const TemperaturesTable = ({ siteMeta }) => {
  const [tempTableMode, setTempTableMode] = useState(
    TEMP_TABLE_MODE_OPTIONS[0],
  );
  const [showBattery, setShowBattery] = useState(false);
  const [unitTable, setUnitTable] = useState(
    siteMeta.Units.map((sn, idx) => ({
      SN: sn,
      UnitName: siteMeta.UnitNames[idx],
    })),
  );
  const [batteryTable, setBatteryTable] = useState({});

  const tFields = Array(+siteMeta.NumTempSensor || 0)
    .fill()
    .map((_, i) => `T${i + 1}`);
  useWsSubscribe({
    unit: {
      sns: siteMeta.Units,
      fields: [
        ...tFields,
        'HourlyAvgT',
        'DailyAvgT',
        'InstantDeltaT',
        'HourlyDeltaT',
        'MaxCellT',
        'MinCellT',
        'AvgCellT',
        'MaxAmbT',
        'MinAmbT',
      ],
      cb: (data, sn) => {
        if (
          ['MaxCellT', 'MinCellT', 'AvgCellT'].includes(Object.keys(data)[0])
        ) {
          return setBatteryTable((prev) => {
            const batteryTable = { ...prev };
            if (batteryTable[sn]) {
              batteryTable[sn] = { ...batteryTable[sn], ...data };
            } else {
              batteryTable[sn] = data;
            }
            return batteryTable;
          });
        }
        const idx = siteMeta.Units.indexOf(sn);
        setUnitTable((prev) => {
          const unitTable = [...prev];
          unitTable[idx] = { ...unitTable[idx], ...data };
          return unitTable;
        });
      },
    },
  });

  const getTextColor = (value) => {
    if (!value && value !== 0) {
      return '';
    }

    if (+value > +siteMeta.Thresh_AmbTMax) {
      return 'red-text';
    }
    if (+value < +siteMeta.Thresh_AmbTMin) {
      return 'blue-text';
    }
  };

  const getTextColorBatt = (value) => {
    if (!value && value !== 0) {
      return '';
    }

    if (+value > +siteMeta.Thresh_CellTMax) {
      return 'red-text';
    }
    if (+value < +siteMeta.Thresh_CellTMin) {
      return 'blue-text';
    }
  };

  const renderTempBattTableCells = (unit) => {
    const batt = batteryTable[unit.SN];
    const isBattLoading = (field) => {
      return !batt || !Object.keys(batt).includes(field);
    };
    return (
      <>
        <td className={getTextColorBatt(batt?.MaxCellT)}>
          <TextLoader
            fontSize={12}
            orientation='c'
            loading={isBattLoading('MaxCellT')}
          >
            {getTemperatureString(batt?.MaxCellT)}
          </TextLoader>
        </td>
        <td className={getTextColorBatt(batt?.MinCellT)}>
          <TextLoader
            fontSize={12}
            orientation='c'
            loading={isBattLoading('MinCellT')}
          >
            {getTemperatureString(batt?.MinCellT)}
          </TextLoader>
        </td>
        <td className={getTextColorBatt(batt?.AvgCellT)}>
          <TextLoader
            fontSize={12}
            orientation='c'
            loading={isBattLoading('AvgCellT')}
          >
            {getTemperatureString(batt?.AvgCellT)}
          </TextLoader>
        </td>
        <td>
          <TextLoader
            fontSize={12}
            orientation='c'
            loading={isBattLoading('MaxCellT') || isBattLoading('MinCellT')}
          >
            {getTemperatureString(+(batt?.MaxCellT - +batt?.MinCellT))}
          </TextLoader>
        </td>
      </>
    );
  };

  const renderTempTableCells = (unit) => {
    const unitController = unitTable.find((u) => u.SN === unit.SN);
    const isUnitLoading = (field) => {
      return !unitController || !Object.keys(unitController).includes(field);
    };
    const tdDueMode = {
      'Real Time': () => {
        const arr = Array(+siteMeta.NumTempSensor || 0)
          .fill(0)
          .map((_, i) => (
            <td className={getTextColor(unit[`T${i + 1}`])} key={i}>
              <TextLoader
                fontSize={12}
                orientation='c'
                loading={isKeyInObject(unit, `T${i + 1}`)}
              >
                {getTemperatureString(unit[`T${i + 1}`])}
              </TextLoader>
            </td>
          ));
        arr.push(
          <td key={arr.length}>
            <TextLoader
              fontSize={12}
              orientation='c'
              loading={isUnitLoading('MaxAmbT') || isUnitLoading('MinAmbT')}
            >
              {getTemperatureString(
                +(unitController?.MaxAmbT - +unitController?.MinAmbT),
              )}
            </TextLoader>
          </td>,
        );
        return arr;
      },
      Hourly: () => {
        let tempHourlyAvgArr;
        try {
          tempHourlyAvgArr = JSON.parse(unit.HourlyAvgT);
        } catch {
          tempHourlyAvgArr = undefined;
        }
        const tdArr = Array(+siteMeta.NumTempSensor || 0)
          .fill(0)
          .map((_, i) => (
            <td className={getTextColor(tempHourlyAvgArr?.[i])} key={i}>
              <TextLoader
                fontSize={12}
                orientation='c'
                loading={isKeyInObject(unit, 'HourlyAvgT')}
              >
                {tempHourlyAvgArr?.[i]}
              </TextLoader>
            </td>
          ));
        tdArr.push(
          <td key={tdArr.length}>
            <TextLoader
              fontSize={12}
              orientation='c'
              loading={isKeyInObject(unit, 'HourlyDeltaT')}
            >
              {unit.HourlyDeltaT}
            </TextLoader>
          </td>,
        );
        return tdArr;
      },
      Daily: () => {
        let tempDailyAvgArr;
        try {
          tempDailyAvgArr = JSON.parse(unit.DailyAvgT);
        } catch {
          tempDailyAvgArr = undefined;
        }

        return Array(+siteMeta.NumTempSensor || 0)
          .fill(0)
          .map((_, i) => (
            <td className={getTextColor(tempDailyAvgArr?.[i])} key={i}>
              <TextLoader
                fontSize={12}
                orientation='c'
                loading={isKeyInObject(unit, 'DailyAvgT')}
              >
                {tempDailyAvgArr?.[i]}
              </TextLoader>
            </td>
          ));
      },
    };
    return tdDueMode[tempTableMode]();
  };

  return (
    <>
      {unitTable.length ? (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='title-line' style={{ marginRight: '15px' }}>
              Temperatures
            </div>
            <div className='toogle-container'>
              <span>BATTERY</span>
              <Input
                onChange={() => setShowBattery((showBattery) => !showBattery)}
                checked={showBattery}
                type='toggle'
                label='changeTheme'
              />
            </div>
            <Input
              type='dropdown'
              value={{ value: showBattery ? BATT_MODE : tempTableMode }}
              disabled={showBattery}
              className='width-140px'
              onChange={(val) => setTempTableMode(TEMP_TABLE_MODE_OPTIONS[val])}
              options={TEMP_TABLE_MODE_OPTIONS}
            />
          </div>
          <div
            style={{
              position: 'relative',
              marginTop: '15px',
              display: 'flex',
              maxHeight: 'calc(100% - 45px)',
            }}
          >
            <div className='temp-table-container'>
              <table className='temperatures-table'>
                <thead>
                  <tr>
                    <th>Unit</th>
                    {showBattery ? (
                      <>
                        {['Max', 'Min', 'Avg', 'Delta'].map((t, i) => (
                          <th key={i}>{t}</th>
                        ))}
                      </>
                    ) : (
                      <>
                        {Array(+siteMeta.NumTempSensor || 0)
                          .fill(0)
                          .map((_, i) => (
                            <th key={i}>T{i + 1}</th>
                          ))}
                        {TEMP_TABLE_MODES_WITH_DELTA.includes(
                          tempTableMode,
                        ) && <th>Delta</th>}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {unitTable.map((unit) => (
                    <tr key={unit.SN}>
                      <td>{unit.UnitName}</td>
                      {showBattery
                        ? renderTempBattTableCells(unit)
                        : renderTempTableCells(unit)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <Spinner cover='container' />
      )}
    </>
  );
};

export default storeConnector(TemperaturesTable, {
  config: ['siteMeta'],
});
