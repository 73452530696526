/* eslint-disable max-lines */
import {
  prepColumns,
  prepTimelineChartOptions,
} from '@components/Home/Alerts/utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { DataGridPro, GridRowClassNameParams } from '@mui/x-data-grid-pro';

import './index.scoped.scss';
import { store } from '@store';
import storeConnector from '@store/storeConnector';
import { ALERTS_SERVICE_URL } from '@utils';
import Spinner from '@components/_elements/Spinner/Spinner';
import {
  AlertsState,
  HistoricalAlert,
  TimelineChartSeries,
} from '@src/types/Alerts';
import { ConfigState } from '@store/reducers/configData';
import { ServiceState } from '@store/reducers/serviceData';
import {
  initialDataGridState,
  LOOKBACK_MS,
  slotProps,
  TIMELINE_REFRESH,
  useGetRowSpacing,
} from '@components/Home/Alerts/config';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ErrorOutlineTwoTone from '@mui/icons-material/ErrorOutlineTwoTone';
import processAlertsForTimelineChart from '@src/components/Home/Alerts/utils/processAlertsForTimelineChart';
import { getAlertId } from '@src/utils/alerts';
import AlertsGridToolbar from '@src/components/Home/Alerts/Table/AlertsGridToolbar';
import dayjs, { Dayjs } from 'dayjs';
import TimeRangeModal from '@src/components/Home/Alerts/TimeRangeModal';
import LookbackContext from '@src/components/Home/Alerts/AlertsTimelineContext';

interface AlertsTimelineProps {
  siteMeta: ConfigState['siteMeta'];
  timeMode: ServiceState['timeMode'];
  activeAlerts: AlertsState['activeAlerts'];
}

// eslint-disable-next-line max-lines-per-function
function AlertsTimeline({
  siteMeta,
  timeMode,
  activeAlerts,
}: AlertsTimelineProps) {
  const { lookback, setLookback } = useContext(LookbackContext);
  const defaultLookback =
    (lookback.length > 0 && lookback) ||
    siteMeta.ui?.Alerts_Timeline_Default_Period ||
    '-15m';

  const [timelineAlerts, setTimelineAlerts] = useState<HistoricalAlert[]>([]);
  const [lookbackPeriod, setLookbackPeriod] = useState(defaultLookback);
  const [openModal, setOpenModal] = useState(false);
  const [start, setStart] = useState<Dayjs>(dayjs().subtract(1, 'hour'));
  const [stop, setStop] = useState<Dayjs>(dayjs());
  const lookbackPeriodRef = useRef(lookbackPeriod);
  useEffect(() => {
    lookbackPeriodRef.current = lookbackPeriod;
  }, [lookbackPeriod]);

  const [chartZoomed, setChartZoomed] = useState(false);
  const chartZoomedRef = useRef(chartZoomed);
  useEffect(() => {
    chartZoomedRef.current = chartZoomed;
  }, [chartZoomed]);

  const [chartMouseIn, setChartMouseIn] = useState(false);
  const chartMouseInRef = useRef(chartMouseIn);
  useEffect(() => {
    chartMouseInRef.current = chartMouseIn;
  }, [chartMouseIn]);

  const FREEZE_EVENTS = useMemo(
    () => ({
      mouseMove: () => setChartMouseIn(true),
      mouseLeave: () => setChartMouseIn(false),
      beforeZoom: () => setChartZoomed(true),
      beforeResetZoom: () => setChartZoomed(false),
    }),
    [setChartMouseIn, setChartZoomed],
  );

  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>(
    prepTimelineChartOptions(
      timelineAlerts.length,
      activeAlerts.length,
      lookbackPeriod,
      FREEZE_EVENTS,
      siteMeta,
      timeMode,
    ),
  );
  const [chartTimelineAlerts, setChartTimelineAlerts] = useState<
    TimelineChartSeries[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [showTimelineChart, setShowTimelineChart] = useState(false);
  const [errMessage, setErrMessage] = useState<string>();
  const errMessageRef = useRef(errMessage);
  useEffect(() => {
    errMessageRef.current = errMessage;
  }, [errMessage]);

  const [timelineProcessingError, setTimelineProcessingError] = useState(false);

  const getRowSpacing = useGetRowSpacing();

  useEffect(() => {
    setShowTimelineChart(true);
  }, []);

  useEffect(() => {
    const chartOpts = prepTimelineChartOptions(
      timelineAlerts.length,
      activeAlerts.length,
      lookbackPeriod,
      FREEZE_EVENTS,
      siteMeta,
      timeMode,
      start,
      stop,
    );
    setChartOptions(chartOpts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timelineAlerts.length,
    activeAlerts.length,
    lookbackPeriod,
    FREEZE_EVENTS,
    siteMeta,
    timeMode,
  ]);

  const intervalRef = useRef<NodeJS.Timeout>();

  const getHistoricalAlerts = async () => {
    const startMs = Date.now();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (errMessageRef.current) clearInterval(intervalRef.current);
    try {
      const alerts = await fetch(
        `${ALERTS_SERVICE_URL}/alert-history/period?period=${lookbackPeriodRef.current}`,
        {
          headers: {
            authorization: `Bearer ${store.getState().user.keycloak?.token}`,
          },
        },
      ).then((res) => res.json());
      if (!chartZoomedRef.current && !chartMouseInRef.current) {
        setChartOptions((c) => ({
          ...c,
          xaxis: {
            ...c.xaxis,
            max: Date.now(),
            // TODO
            // Check if alert count is reached the MAX_LIMIT of 500 events and
            // change xaxis.min and disaply Throttling enabled Warning in the box
            min: Date.now() - LOOKBACK_MS[lookbackPeriodRef.current],
          },
        }));
        try {
          // TODO
          // Add logic to pull current active alerts and check if any begin
          // before historical Period, if so grab and place them into
          // the timeline chart as ongoing
          const dataForChart = processAlertsForTimelineChart(
            alerts,
            lookbackPeriodRef.current,
          );
          setTimelineProcessingError(false);
          setChartTimelineAlerts(dataForChart);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setTimelineProcessingError(true);
          setLoading(false);
        }
      }

      setTimelineAlerts(alerts);
      setLoading(false);
      if (Date.now() - startMs > TIMELINE_REFRESH[lookbackPeriodRef.current]) {
        setErrMessage(
          'Requests are taking too long. Timeline will not auto-refresh. Please reduce the time period.',
        );
      }
    } catch (err) {
      console.error(err);
      setErrMessage('Error fetching historical alerts');
      setLoading(false);
    }
  };

  useEffect(() => {
    clearInterval(intervalRef.current);
    if (lookbackPeriod === 'custom') {
      setOpenModal(true);
    } else {
      getHistoricalAlerts();
      intervalRef.current = setInterval(
        getHistoricalAlerts,
        TIMELINE_REFRESH[lookbackPeriodRef.current],
      );
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [lookbackPeriod]);

  const onModalClose = () => {
    setLookback(lookbackPeriod);
    setOpenModal(false);
  };

  return (
    <div className='home-row frow'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <div className='frow'>
            <div className='fcol'>
              <FormControl
                className='timeline-lookback-select-form'
                fullWidth
                variant='filled'
              >
                <InputLabel
                  role='alert-lookback-dropdown'
                  id='lookback-period-select-label'
                >
                  Period
                </InputLabel>
                <Select
                  labelId='lookback-period-select-label'
                  id='lookback-period-select'
                  value={lookbackPeriod}
                  label='Lookback Period'
                  onChange={(e) => {
                    setLookbackPeriod(e.target.value as string);
                    setErrMessage(undefined);
                  }}
                >
                  <MenuItem
                    role='alert-lookback-dropdown-custom'
                    value='custom'
                    onClick={() => {
                      if (lookbackPeriod === 'custom') setOpenModal(true);
                    }}
                  >
                    Custom Time Range
                  </MenuItem>
                  <MenuItem value='-1m'>-1m</MenuItem>
                  <MenuItem value='-15m'>-15m</MenuItem>
                  <MenuItem value='-1h'>-1h</MenuItem>
                  <MenuItem value='-6h'>-6h</MenuItem>
                  <MenuItem value='-12h'>-12h</MenuItem>
                  <MenuItem value='-24h'>-24h</MenuItem>
                  <MenuItem value='-48h'>-48h</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className='fcol flex-1'>
              {showTimelineChart && !timelineProcessingError ? (
                <Chart
                  id='alert-timeline-chart'
                  className='timeline-chart-container'
                  options={chartOptions}
                  series={chartTimelineAlerts as unknown as never[]}
                  type='rangeBar'
                  height={100}
                />
              ) : null}
              {loading ? <Spinner type='content' /> : null}
              {timelineProcessingError ? (
                <Box className='timeline-chart-error-box'>
                  <div>
                    <ErrorOutlineTwoTone />
                    <span>Could not process the data for events timeline</span>
                  </div>
                </Box>
              ) : null}
            </div>
          </div>

          {errMessage ? (
            <Box
              sx={{
                borderRadius: '5px',
                padding: '5px',
                background: 'rgb(255 0 0 / 50%)',
                marginBottom: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {errMessage}
            </Box>
          ) : null}
          <DataGridPro
            getRowId={getAlertId}
            getRowSpacing={getRowSpacing}
            rows={timelineAlerts}
            columns={prepColumns(siteMeta)}
            density='standard'
            pagination
            pageSizeOptions={[100, 200, 500]}
            initialState={initialDataGridState}
            slots={{ toolbar: AlertsGridToolbar }}
            slotProps={slotProps}
            loading={loading}
            localeText={{
              noRowsLabel:
                activeAlerts.length > 0
                  ? 'There are active alerts, but no recent events.'
                  : 'No recent events.',
            }}
            getRowClassName={({ row: { isAcked } }: GridRowClassNameParams) =>
              isAcked === true ? 'inactive-row' : ''
            }
            checkboxSelection
            disableRowSelectionOnClick
          />
        </div>
      </div>
      <TimeRangeModal
        setStart={setStart}
        setStop={setStop}
        stop={stop}
        start={start}
        setTimelineAlerts={setTimelineAlerts}
        open={openModal}
        onClose={onModalClose}
      />
    </div>
  );
}

export default storeConnector(AlertsTimeline, {
  service: ['timeMode'],
  config: ['siteMeta'],
  alerts: ['activeAlerts'],
});
