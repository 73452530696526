import dayjs from 'dayjs';
import { HistorianDataPoint, PointField, SavedSearch } from '@src/types';
import { parseOrReturn } from './index_ts';

type SerializedSavedSearch = SavedSearch & {
  dataPoints: Array<HistorianDataPoint & { dataPoint: string }>;
};

export default {
  serialize: (search: SavedSearch): SerializedSavedSearch => ({
    ...search,
    dataPoints: search.dataPoints.map((dataPoint) => ({
      ...dataPoint,
      dataPoint:
        typeof dataPoint.dataPoint === 'string'
          ? dataPoint.dataPoint
          : JSON.stringify(dataPoint.dataPoint),
    })),
  }),

  deserialize: (serializedSearch: SerializedSavedSearch): SavedSearch => ({
    ...serializedSearch,
    stopDateTime: dayjs(serializedSearch.stopDateTime),
    startDateTime: dayjs(serializedSearch.startDateTime),
    dataPoints: serializedSearch.dataPoints.map((dataPoint) => ({
      ...dataPoint,
      dataPoint: parseOrReturn(dataPoint.dataPoint as string) as
        | string
        | PointField,
    })),
  }),
};
