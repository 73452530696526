import { SiteMeta } from '@src/types/SiteMeta';
import dayjs, { Dayjs } from 'dayjs';
import { getTsDueTimeMode } from '@utils/index_ts';
import { TimeMode } from '@types';

enum apiFields {
  httpMethod = 'httpMethod',
  username = 'username',
  email = 'email',
  url = 'url',
}

type DefaultAPIBody = {
  from: string;
  to: null;
  page: 0;
  count: 25;
  email: null;
  url: null;
  username: null;
};

const getAdjustedTime = (
  dateFrame: Dayjs,
  siteMeta: SiteMeta,
  selectedTimeMode: TimeMode,
): string => {
  const isoDate: string = dayjs(dateFrame).toISOString();
  const timeModeAdjustmentIso: moment.Moment = getTsDueTimeMode(
    siteMeta,
    selectedTimeMode,
    isoDate,
  );

  return timeModeAdjustmentIso.toISOString(true);
};

const getDefaultAPIBody = (
  selectedTimeMode: TimeMode,
  siteMeta: SiteMeta,
): DefaultAPIBody => {
  const now = dayjs().startOf('date').toISOString();
  const adjustedStartTimeISO = getAdjustedTime(
    dayjs(now),
    siteMeta,
    selectedTimeMode,
  );

  return {
    from: adjustedStartTimeISO,
    to: null,
    page: 0,
    count: 25,
    email: null,
    url: null,
    username: null,
  };
};

export { getDefaultAPIBody, getAdjustedTime, apiFields };
export type { DefaultAPIBody };
