import { AlertEntity, HistoricalAlert } from '@src/types/Alerts';
import React from 'react';
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { SiteMeta } from '@src/types/SiteMeta';
import { getTopicInfo } from '@store/reducers/alerts';
import { MenuItem, TextField } from '@mui/material';
import AlarmHelpCell from '../Table/HelpCell';
import AlarmAcknowledgeCell from '../Table/AcknowledgeCell';
import EventTypeCell from '../Table/EventTypeCell';
import SeverityCell from '../Table/SeverityCell';
import EventTimeCell from '../Table/EventTimeCell';

const getEventTimeCol = (): GridColDef => ({
  headerName: 'Event time',
  field: 'ts',
  sortable: true,
  minWidth: 220,
  type: 'dateTime',
  align: 'center',
  headerAlign: 'center',
  valueGetter: (value: string) => new Date(value),
  renderCell: ({ value }) => <EventTimeCell time={value} />,
});

const eventSeverityBooleanFilterOperator: GridFilterOperator = {
  label: 'Equals',
  value: 'equals',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (filterItem.value == null) {
      return null;
    }

    return (value: string) => value === filterItem.value;
  },
  InputComponent: ({ item, applyValue }: GridFilterInputValueProps) => {
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <TextField
        select
        value={item.value ?? ''}
        onChange={handleFilterChange}
        variant='standard'
        label='Value'
      >
        <MenuItem value='Fire'>Fire Alarm</MenuItem>
        <MenuItem value='Alarm'>Alarm</MenuItem>
        <MenuItem value='Warning'>Warning</MenuItem>
      </TextField>
    );
  },
};

const getSeverityCol = (): GridColDef => ({
  headerName: 'Severity',
  field: 'severity',
  sortable: false,
  flex: 0.25,
  headerAlign: 'center',
  align: 'center',
  filterOperators: [eventSeverityBooleanFilterOperator],
  valueGetter: (_value, row: AlertEntity | HistoricalAlert) =>
    ['Warning', 'Alarm', 'Fire'][
      (row as AlertEntity).config?.severity ??
        (row as HistoricalAlert).configSeverity
    ],
  renderCell: ({
    value = 'Warning',
    row,
  }: {
    value?: 'Warning' | 'Alarm' | 'Fire';
    row: AlertEntity | HistoricalAlert;
  }) => <SeverityCell severity={value} isAcked={row.isAcked} />,
});

const eventTypeBooleanFilterOperator: GridFilterOperator = {
  label: 'Equals',
  value: 'equals',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (filterItem.value == null) {
      return null;
    }
    return (value: boolean) => value === (filterItem.value === 'On');
  },
  InputComponent: ({ item, applyValue }: GridFilterInputValueProps) => {
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      applyValue({ ...item, value: event.target.value });
    };

    return (
      <TextField
        select
        value={item.value ?? ''}
        onChange={handleFilterChange}
        variant='standard'
        label='Value'
      >
        <MenuItem value='On'>On</MenuItem>
        <MenuItem value='Off'>Off</MenuItem>
      </TextField>
    );
  },
};

const getEventTypeCol = (): GridColDef => ({
  headerName: 'Event type',
  field: 'isActive',
  sortable: false,
  flex: 0.2,
  filterOperators: [eventTypeBooleanFilterOperator],
  renderCell: ({ value }) => <EventTypeCell isActive={value} />,
});
const getUnitCol = (siteMeta: SiteMeta): GridColDef => ({
  headerName: 'Unit',
  field: 'unitName',
  sortable: false,
  flex: 0.2,
  valueGetter: (_value, row: AlertEntity | HistoricalAlert) => {
    const { Use_New_Topic_Structure: useNewTopicStructure } = siteMeta.ui;
    const { dataSource } = getTopicInfo(useNewTopicStructure, row.topic);
    const index = siteMeta.Units.indexOf(dataSource);
    return index === -1 ? 'Site' : siteMeta.UnitNames[index] || 'N/A';
  },
});
const getAlertNameCol = (): GridColDef => ({
  headerName: 'Alert name',
  field: 'alertName',
  sortable: false,
  flex: 0.5,
  valueGetter: (_value, row: AlertEntity | HistoricalAlert) =>
    (row as AlertEntity).config?.alertName ??
    (row as HistoricalAlert).configAlertName,
});
const getMessageCol = (): GridColDef => ({
  headerName: 'Message',
  field: 'message',
  sortable: false,
  flex: 1,
});
const getTopicCol = (): GridColDef => ({
  headerName: 'Topic',
  field: 'topic',
  sortable: false,
  flex: 1,
});
const getHelpCol = (): GridColDef => ({
  headerName: 'Help',
  field: 'details',
  sortable: false,
  flex: 0.2,
  disableColumnMenu: true,
  renderCell: ({ row }: { row: AlertEntity | HistoricalAlert }) => (
    <AlarmHelpCell alarmEntity={row} />
  ),
});
const getAcknowledgeCol = (): GridColDef => ({
  headerName: 'Acknowledged',
  field: 'acknowledged',
  sortable: false,
  flex: 1,
  disableColumnMenu: true,
  renderCell: ({ row }: { row: AlertEntity | HistoricalAlert }) => (
    <AlarmAcknowledgeCell alarmEntity={row} />
  ),
});

const prepColumns = (siteMeta: SiteMeta): GridColDef[] => [
  getEventTimeCol(),
  getSeverityCol(),
  getEventTypeCol(),
  getUnitCol(siteMeta),
  getAlertNameCol(),
  getMessageCol(),
  getTopicCol(),
  getHelpCol(),
  ...(!process.env.VITE_READ_ONLY_MODE ? [getAcknowledgeCol()] : []),
];

export default prepColumns;
