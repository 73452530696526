import { useCallback } from 'react';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
import { GridRowSpacingParams } from '@mui/x-data-grid-pro';

export const initialDataGridState: GridInitialStatePro = {
  columns: {
    columnVisibilityModel: {
      topic: false,
      config: false,
      timeoutActivateMs: false,
      timeoutRemoveMs: false,
    },
  },
  pagination: {
    paginationModel: { pageSize: 500 },
  },
  density: 'comfortable',
  sorting: {
    sortModel: [{ field: 'ts', sort: 'desc' }],
  },
};

export const useGetRowSpacing = () =>
  useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 10 : 5,
      bottom: params.isLastVisible ? 10 : 5,
    }),
    [],
  );

export const slotProps: GridProSlotProps = {
  toolbar: {
    showQuickFilter: true,
    printOptions: { disableToolbarButton: true },
  },
};

export const LOOKBACK_MS: Record<string, number> = {
  '-1m': 1000 * 60 * 1,
  '-15m': 1000 * 60 * 15,
  '-1h': 1000 * 60 * 60 * 1,
  '-6h': 1000 * 60 * 60 * 6,
  '-12h': 1000 * 60 * 60 * 12,
  '-24h': 1000 * 60 * 60 * 24,
  '-48h': 1000 * 60 * 60 * 48,
};

export const TIMELINE_REFRESH: Record<string, number> = {
  '-1m': 1_000,
  '-15m': 1_000,
  '-1h': 2_000,
  '-6h': 3_000,
  '-12h': 5_000,
  '-24h': 5_000,
  '-48h': 10_000,
};

export const seriesColors: Record<string, string> = {
  Warning: '#d2b913',
  Alarm: '#dc162b',
  Fire: '#d25e11',
};
