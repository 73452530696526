/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { SeverityTypes } from '@src/types/Alerts';
import { Controller, useFormContext } from 'react-hook-form';
import AlertSeverityField from './AlertSeverityField';

const StyledTextField = styled(TextField)({
  margin: '12px 0',
});

export default function AlertAddEditFormFields() {
  const { control } = useFormContext();

  return (
    <>
      <FormGroup>
        <Controller
          name='alertName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              helperText={error ? error.message : null}
              error={!!error}
              fullWidth
              required
              type='text'
              label='Alert Name'
            />
          )}
        />
        <Controller
          name='topic'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              helperText={error ? error.message : null}
              error={!!error}
              {...field}
              fullWidth
              required
              type='text'
              label='Alert Topic'
            />
          )}
        />
        <Controller
          name='severity'
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id='severity-select-label'>Severity</InputLabel>
              <Select
                error={!!error}
                value={value}
                onChange={onChange}
                labelId='severity-label'
                id='severity'
                required
                label='Severity'
              >
                {Object.entries(SeverityTypes).map(([_, val]) => {
                  if (typeof val === 'number') {
                    return (
                      <MenuItem key={val} value={val}>
                        <AlertSeverityField severity={val} />
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name='isEquipmentFault'
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              control={<Checkbox checked={value} onChange={onChange} />}
              label='Is Equipment Fault'
            />
          )}
        />
      </FormGroup>

      <Typography sx={{ mt: 3, mb: 2 }} variant='subtitle1' align='center'>
        Threshold & Messaging
        <br />
        <sub>(at least one message is required)</sub>
      </Typography>

      <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
        <Controller
          name='messageAboveMax'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              helperText={error ? error.message : null}
              error={!!error}
              {...field}
              sx={{ flexGrow: 1, mr: 2 }}
              type='text'
              label='Message Above Max'
            />
          )}
        />
        <Controller
          name='thresholdMax'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              helperText={error ? error.message : null}
              error={!!error}
              {...field}
              type='number'
              sx={{ width: 120 }}
              label='Threshold Max'
            />
          )}
        />
      </FormGroup>

      <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
        <Controller
          name='messageBelowMin'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              helperText={error ? error.message : null}
              error={!!error}
              {...field}
              sx={{ flexGrow: 1, mr: 2 }}
              type='text'
              label='Message Below Min'
            />
          )}
        />
        <Controller
          name='thresholdMin'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              helperText={error ? error.message : null}
              error={!!error}
              {...field}
              type='number'
              sx={{ width: 120 }}
              label='Threshold Min'
            />
          )}
        />
      </FormGroup>

      <Typography sx={{ mt: 3, mb: 2 }} variant='subtitle1' align='center'>
        Timeouts
      </Typography>
      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '20px',
        }}
      >
        <Controller
          name='timeoutActivateMs'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              helperText={error ? error.message : null}
              error={!!error}
              {...field}
              type='number'
              label='Timeout Activate (ms)'
            />
          )}
        />
        <Controller
          name='timeoutRemoveMs'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              helperText={error ? error.message : null}
              error={!!error}
              {...field}
              type='number'
              label='Timeout Remove (ms)'
            />
          )}
        />
      </FormGroup>
    </>
  );
}
