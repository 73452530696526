import React from 'react';
import storeConnector from '@store/storeConnector';
import { Chip } from '@mui/material';
import { getTsDueTimeMode } from '@src/utils/index_ts';
import { ConfigState } from '@src/store/reducers/configData';
import { ServiceState } from '@src/store/reducers/serviceData';

function EventTimeCell({
  time,
  siteMeta,
  timeMode,
}: {
  time: Date | undefined;
  siteMeta: ConfigState['siteMeta'];
  timeMode: ServiceState['timeMode'];
}) {
  if (!time || time.toString() === 'Invalid Date') return '';
  const date = getTsDueTimeMode(siteMeta, timeMode, time.toISOString());
  return (
    <Chip
      className='value-chip'
      label={`${date.format('YYYY-MM-DD HH:mm:ss')}`}
    />
  );
}

export default storeConnector(EventTimeCell, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
