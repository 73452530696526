import React, { useEffect, useRef, useState } from 'react';
import './BatChart.scoped.scss';
import ThermometerIcon from '@assets/thermometer.svg?react';
import Input from '@components/_elements/Input/Input';
import LineHighChart from '@components/_elements/LineHighChart/LineHighChart';
import Spinner from '@components/_elements/Spinner/Spinner';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';
import getTemperatureString, {
  getSiteTemperatureUnit,
} from '@src/utils/getTemperatureString';

const COMMON_SITE_UNIT_FIELDS = [
  'MaxAmbT',
  'MaxCellT',
  'MinAmbT',
  'MinCellT',
  'AvgAmbT',
  'AvgCellT',
];

const firstVar = ['MaxAmbT', 'MaxCellT'];
const secondVar = ['MinAmbT', 'MinCellT'];
const firstVarTitle = ['Max Ambient', 'Max Cell T'];
const secondVarTitle = ['Min Ambient', 'Min Cell T'];
const constAvg = ['AvgAmbT', 'AvgCellT'];
const titleConstAvg = ['Avg Ambient', 'Avg Cell T'];

const BatChart = ({
  actions,
  siteMeta,
  changeTempMode,
  selectedTemperatureMode: i,
  selectedUnit,
}) => {
  const [unitOrSite, setUnitOrSite] = useState(null);
  const siteRTRef = useRef(null);
  const unitTableRef = useRef([]);
  const selectedUnitRef = useRef();
  selectedUnitRef.current = selectedUnit;

  useEffect(() => {
    if (selectedUnit === -1) {
      setUnitOrSite({ ...siteRTRef.current });
    } else {
      setUnitOrSite({ ...unitTableRef.current[selectedUnit] });
    }
  }, [selectedUnit]);

  useWsSubscribe({
    unit: {
      sns: siteMeta.Units,
      fields: [...COMMON_SITE_UNIT_FIELDS, 'Humidity'],
      cb: (data, sn) => {
        const idx = siteMeta.Units.indexOf(sn);
        if (unitTableRef.current[idx]) {
          Object.assign(unitTableRef.current[idx], data);
        } else {
          unitTableRef.current[idx] = data;
        }
        if (idx === selectedUnitRef.current) {
          setUnitOrSite({ ...unitTableRef.current[idx] });
        }
      },
    },
    site: {
      fields: [...COMMON_SITE_UNIT_FIELDS, 'AvgHumidity'],
      cb: (data) => {
        if (siteRTRef.current === null) {
          siteRTRef.current = data;
        } else {
          Object.assign(siteRTRef.current, data);
        }
        if (selectedUnitRef.current === -1) {
          setUnitOrSite({ ...siteRTRef.current });
        }
      },
    },
  });

  const getTargetSn = () => {
    if (selectedUnit === -1) {
      return siteMeta.siteId;
    } else {
      return siteMeta.Units[selectedUnit];
    }
  };

  const isLoading = (field) => {
    return !Object.keys(unitOrSite).includes(field);
  };

  const variables = {};
  if (unitOrSite) {
    variables[firstVar[i]] = firstVar[i];
    variables[secondVar[i]] = secondVar[i];
  }
  const humidityField = selectedUnit === -1 ? 'AvgHumidity' : 'Humidity';
  const defaultTemp = `°${getSiteTemperatureUnit()}`;

  return unitOrSite ? (
    <div
      className='bat-chart in'
      style={{ position: 'relative', height: '100%' }}
    >
      <LineHighChart
        key={`${selectedUnit}${i}`}
        deviceId={getTargetSn()}
        defaultShowVariables={[firstVar[i], secondVar[i], constAvg[i]]}
        hideVarSwitches={true}
        unit={defaultTemp}
        variables={{ ...variables, [constAvg[i]]: constAvg[i] }}
        margins={{ top: 65, right: 40, bottom: 55, left: 50 }}
        fixedYTicks={5}
        dataLoadFunction={(duration) =>
          actions.loadChartData(getTargetSn(), duration, [
            firstVar[i],
            secondVar[i],
            constAvg[i],
          ])
        }
        durationSwitches={['5M', '15M', '30M', '1H']}
      >
        <div className='over-chart'>
          <div className='thermo-section'>
            <ThermometerIcon className='therm' />
            <table>
              <tbody>
                <tr>
                  <td>Ambient</td>
                  <td className='right'>
                    <div className='row-cell'>
                      <TextLoader
                        fontSise={13}
                        loading={isLoading(firstVar[0])}
                      >
                        {getTemperatureString(unitOrSite[firstVar[0]])}
                      </TextLoader>
                      /
                      <TextLoader loading={isLoading(secondVar[0])}>
                        {getTemperatureString(unitOrSite[secondVar[0]])}
                      </TextLoader>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Cell</td>
                  <td className='right'>
                    <div className='row-cell'>
                      <TextLoader
                        fontSise={13}
                        loading={isLoading(firstVar[1])}
                      >
                        {getTemperatureString(unitOrSite[firstVar[1]])}
                      </TextLoader>
                      /
                      <TextLoader loading={isLoading(secondVar[1])}>
                        {getTemperatureString(unitOrSite[secondVar[1]])}
                      </TextLoader>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Humidity</td>
                  <td className='right'>
                    <TextLoader
                      expectedSize={2}
                      fontSise={13}
                      loading={isLoading(humidityField)}
                    >
                      {`${unitOrSite[humidityField]}%`}
                    </TextLoader>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='chart-dd-container'>
              <Input
                type='dropdown'
                value={{ index: i }}
                onChange={(val) => changeTempMode(val)}
                options={['Ambient', 'Cell']}
              />
            </div>
          </div>
        </div>
      </LineHighChart>

      <div className='chart-titles'>
        <div className='color-chart-0 small-text row-cell'>
          {firstVarTitle[i]} -&nbsp;
          <TextLoader fontSise={12} loading={isLoading(firstVar[i])}>
            {unitOrSite[firstVar[i]]}
          </TextLoader>
        </div>
        <div className='color-chart-1 small-text row-cell'>
          {secondVarTitle[i]} -&nbsp;
          <TextLoader fontSise={12} loading={isLoading(secondVar[i])}>
            {unitOrSite[secondVar[i]]}
          </TextLoader>
        </div>
        {(unitOrSite?.[constAvg[i]] || unitOrSite?.[constAvg[i]] === 0) && (
          <div className='color-chart-2 small-text'>
            {selectedUnit === -1 ? 'Site ' : 'Unit '}
            {titleConstAvg[i]} - {unitOrSite?.[constAvg[i]]}
          </div>
        )}
      </div>
    </div>
  ) : (
    <Spinner cover='container' />
  );
};

export default storeConnector(BatChart, {
  config: ['siteMeta'],
});
