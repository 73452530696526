import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SavedSearch } from '@types';
import Input from '@components/_elements/Input/Input';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

interface SavePopupProps {
  setSavePopup: React.Dispatch<boolean>;
  setSearchNewName: React.Dispatch<string>;
  searchNewName: string;
  searchOptions: string[];
  addNewSearch: () => Promise<void>;
}

export function SavePopup(props: SavePopupProps): ReactElement {
  const {
    setSavePopup,
    searchNewName,
    searchOptions,
    setSearchNewName,
    addNewSearch,
  } = props;
  let error = '';
  if (searchOptions.includes(searchNewName)) {
    error = 'This name is already in use';
  } else if (!searchNewName) {
    error = 'Search name cannot be empty';
  }
  const disabled =
    searchOptions.includes(searchNewName) || !searchNewName ? 'disabled' : '';
  return (
    <div className='btn-container'>
      <div className='btn-popup'>
        <div className='title'>Save Search</div>
        <Input
          error={error}
          type='text'
          noAutocomplete
          value={searchNewName}
          onChange={setSearchNewName}
          placeholder='Enter new search name'
        />
        <div className='conf-btns'>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(): void => {
              setSavePopup(false);
              setSearchNewName('');
            }}
          />
          <FontAwesomeIcon
            icon={faCheck}
            className={disabled}
            onClick={addNewSearch}
          />
        </div>
      </div>
    </div>
  );
}

interface DeletePopupProps {
  setDeletePopup: React.Dispatch<boolean>;
  activeSearch: SavedSearch;
  deleteSavedSearch: () => void;
}

export function DeletePopup(props: DeletePopupProps): ReactElement {
  const { setDeletePopup, activeSearch, deleteSavedSearch } = props;
  return (
    <div className='btn-container'>
      <div className='btn-popup'>
        <div className='title'>
          Delete Saved Search &nbsp;
          <strong>{activeSearch.name}</strong>?
        </div>
        <div className='conf-btns'>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(): void => setDeletePopup(false)}
          />
          <FontAwesomeIcon icon={faCheck} onClick={deleteSavedSearch} />
        </div>
      </div>
    </div>
  );
}
