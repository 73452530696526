import RatioValueLoader from '@components/_shared/RatioValueLoader';
import React, { ReactElement } from 'react';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridValidRowModel,
  useGridApiRef,
  GridApiPro,
} from '@mui/x-data-grid-pro';
import { DataPointWithValue } from '@src/types/DataPoint';
import { Box, IconButton } from '@mui/material';
import ValueLoader from '@components/_shared/ValueLoader';
import { Build } from '@mui/icons-material';

interface CellProps {
  dataPoint: DataPointWithValue;
}

// utils
function getUniquePointAliases(
  data: DataPointWithValue[],
): DataPointWithValue[] {
  //  does not filter by category
  // this assumes that you are going to call this within the context of category
  const uniquePointAliases = data.map((d) => d.pointAlias);
  return data.filter((d, i) => uniquePointAliases.indexOf(d.pointAlias) === i);
}

export function Cell(p: CellProps): ReactElement {
  return p.dataPoint.displayDenominator != null ? (
    <RatioValueLoader
      dataPoint={p.dataPoint}
      denominator={p.dataPoint.displayDenominator}
    />
  ) : (
    <ValueLoader dataPoint={p.dataPoint} />
  );
}

// eslint-disable-next-line max-lines-per-function
function getColumns(
  dataPoints: DataPointWithValue[],
  enableCmdBtnColumn?: boolean,
  openCmdModal?: (deviceId: string) => void,
): GridColDef[] {
  const staticData: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Device ID',
      flex: 1,
      type: 'string',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'name',
      headerName: 'Device',
      flex: 1,
      type: 'string',
      align: 'center',
      headerAlign: 'center',
    },
  ];

  if (enableCmdBtnColumn && openCmdModal !== undefined) {
    staticData.push({
      field: 'cmdBtn',
      headerName: 'CMD',
      flex: 0.6,
      sortable: false,
      filterable: false,
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            openCmdModal(params.row.id);
          }}
        >
          <Build />
        </IconButton>
      ),
    });
  }

  const uniquePointAliases = getUniquePointAliases(dataPoints);
  const liveData = uniquePointAliases.map(
    (dp): GridColDef => ({
      field: dp.pointAlias as string,
      headerName: dp.displayName || dp.pointName,
      flex: 1,
      type: 'string',
      align: 'center',
      cellClassName: 'fractal-datapoint-value-cell',
      headerAlign: 'center',
      renderCell: (params: GridCellParams) => (
        <Cell dataPoint={params.value as DataPointWithValue} />
      ),
    }),
  );
  return [...staticData, ...liveData];
}

const sourceDeviceIdsExist = (data: DataPointWithValue[]): boolean =>
  data.every((d) => d.sourceDeviceId);

function getUniqueDeviceIds(data: DataPointWithValue[]): string[] {
  const deviceIds = data.map((d) =>
    sourceDeviceIdsExist(data) ? d.sourceDeviceId : d.controllerId,
  ) as string[];
  return Array.from(new Set(deviceIds));
}

function createRow(
  data: DataPointWithValue[],
  id: string,
  name?: string,
): DeviceDataTableRow {
  const uniquePointAliases = getUniquePointAliases(data);

  const row = { id, name } as DeviceDataTableRow;
  uniquePointAliases.forEach((point) => {
    const dataPoint = data.find((d) => {
      const deviceId = sourceDeviceIdsExist(data)
        ? d.sourceDeviceId
        : d.controllerId;
      return deviceId === id && d.pointAlias === point.pointAlias;
    });
    if (dataPoint) {
      row[point.pointAlias as string] = dataPoint;
    }
  });

  return row;
}
export type DeviceDataTableRow = {
  id: string;
  name: string;
  [key: string]: DataPointWithValue | string;
};
export function getRows(
  data: DataPointWithValue[],
  deviceNameDictionary?: { id: string; name: string }[],
): DeviceDataTableRow[] {
  const deviceIds = getUniqueDeviceIds(data);
  return deviceIds.map((id) => {
    let foundName;
    if (deviceNameDictionary) {
      foundName = deviceNameDictionary.find((e) => e.id === id)?.name;
    }
    return createRow(data, id, foundName);
  });
}

interface DeviceDataTableProps extends GridValidRowModel {
  data: DataPointWithValue[];
  enableCmdBtnColumn?: boolean;
  openCmdModal?: (deviceId: string) => void;
  deviceNameDictionary: { id: string; name: string }[];
  rowSelectionModel?: GridRowSelectionModel;
  commandAccessEnable?: boolean;
  disableMultipleRowSelection?: boolean;
  boxStyle?: Record<string, string | number>;
  checkboxSelection?: boolean;
  defaultUnitSelection?: string;
  onRowSelectionChange?: (selectedRows: GridRowSelectionModel) => void;
}

// eslint-disable-next-line max-lines-per-function
function DeviceDataTable(p: DeviceDataTableProps): ReactElement {
  const {
    checkboxSelection,
    disableMultipleRowSelection,
    onRowSelectionChange,
    unitSelectionModel,
  } = p;

  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiRef();

  const columns: GridColDef[] = getColumns(
    p.data,
    p.enableCmdBtnColumn,
    p.openCmdModal,
  );

  const rows: DeviceDataTableRow[] = getRows(p.data, p.deviceNameDictionary);

  const rowSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
  ): void => {
    if (onRowSelectionChange !== undefined) {
      onRowSelectionChange(rowSelectionModel);
    }
  };

  return (
    <Box sx={p.boxStyle}>
      <DataGridPro
        rowSelectionModel={
          unitSelectionModel !== undefined ? unitSelectionModel : undefined
        }
        throttleRowsMs={1000}
        onRowSelectionModelChange={rowSelectionModelChange}
        checkboxSelection={checkboxSelection}
        {...p}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        className='MuiDataGrid-DeviceDataTable'
        disableMultipleRowSelection={disableMultipleRowSelection || false}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
      />
    </Box>
  );
}

export default DeviceDataTable;
