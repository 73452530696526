import React, { useState } from 'react';
import { TableRow, TableCell, TextField, Button } from '@mui/material';
import { useCommandsModalContext } from '@src/components/Battery/Tesla/Commands/CommandsModalContext';
import { styled } from '@mui/material/styles';
import storeConnector from '@src/store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import tscSx from './TSCConfigSx';

interface TSCPointCellProps {
  label: string;
  pointValue: string | number | null | undefined;
  payload: string;
  siteMeta: SiteMeta;
}

const StyledPointTableCell = styled(TableCell)({
  borderBottom: 0,
});

function TSCPointCell({
  label,
  pointValue,
  payload,
  siteMeta,
}: TSCPointCellProps) {
  const [newValue, setNewValue] = useState<string>('');
  const { sendCommand } = useCommandsModalContext();
  const handleCommand = () => {
    sendCommand('WriteToVariant', {
      P: payload.replace('[input value]', newValue),
    });
    setNewValue('');
  };

  return (
    <TableRow>
      <StyledPointTableCell className='modes-cell'>
        {label}
      </StyledPointTableCell>
      <StyledPointTableCell className='modes-cell'>
        {pointValue}
      </StyledPointTableCell>
      <StyledPointTableCell className='modes-cell'>
        <TextField
          value={newValue}
          id='newTscValue'
          inputProps={{
            sx: tscSx.configTable.textField,
          }}
          onChange={(e) => {
            setNewValue(e.target.value);
          }}
        />
      </StyledPointTableCell>
      <StyledPointTableCell className='modes-cell' sx={tscSx.configSaveButton}>
        {siteMeta.ui.Command_Access_Enable && (
          <Button
            variant='contained'
            disabled={newValue.length < 1}
            onClick={handleCommand}
          >
            Save
          </Button>
        )}
      </StyledPointTableCell>
    </TableRow>
  );
}

export default storeConnector(TSCPointCell, {
  config: ['siteMeta'],
});
