import { callAPI } from '@utils';
import { dispatcher, store } from '@store';

export const otherActions = {
  getQSDashboardUrl(dashboardId) {
    return function () {
      return callAPI('/quickSight', 'POST', {
        action: 'getDashboardURL',
        dashboardId,
      });
    };
  },
  markAsFavorite(params) {
    return function () {
      return callAPI('/quickSight', 'POST', {
        action: 'markAsFavorite',
        ...params,
      });
    };
  },
  getQSConsoleUrl() {
    return function () {
      return callAPI('/quickSight', 'POST', {
        action: 'getSessionURL',
      });
    };
  },
  listDashboards(sn) {
    return function () {
      return callAPI('/quickSight', 'POST', {
        action: 'listDashboards',
        sn,
      });
    };
  },
  getWeather() {
    return function (dispatch) {
      return new Promise((resolve) => {
        const { GPSLat, GPSLong } = store.getState().config.siteMeta;
        callAPI(`/weather/${GPSLat}/${GPSLong}`)
          .then((data) => {
            if (data.error || !Object.keys(data || {}).length || data.reason) {
              resolve(false);
            } else {
              dispatch(
                dispatcher('UPDATE_SERVICE_MODULE', { weatherForecast: data }),
              );
              resolve(true);
            }
          })
          .catch((e) => {
            console.error(e);
            resolve(false);
          });
      });
    };
  },
  setOptions(newValue) {
    return function () {
      return new Promise((resolve) => {
        callAPI('/config/serve', 'POST', {
          action: 'setGlobalSettings',
          newValue,
          table: 'Options',
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
  getOptions() {
    return function () {
      return new Promise((resolve) => {
        callAPI('/config/serve', 'POST', {
          action: 'getTableItems',
          table: 'Options',
          extraParams: {
            ProjectionExpression: 'options',
            ScanIndexForward: false,
            ExpressionAttributeValues: {
              ':v': 'GLOBAL',
            },
            KeyConditionExpression: 'SN = :v',
          },
        }).then((res) => {
          resolve(res);
        });
      });
    };
  },
};
