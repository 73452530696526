import { Checkbox, FormControlLabel } from '@mui/material';
import { AddUserFormValues } from '@src/hooks/api/mutations/useUserCreate';
import React from 'react';
import { useFormContext } from 'react-hook-form';

function MfaRequiredField() {
  const { watch, setValue } = useFormContext<AddUserFormValues>();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={watch('totp')}
          onChange={(e) => setValue('totp', e.target.checked)}
          size='small'
        />
      }
      label='MFA Required'
    />
  );
}

export default MfaRequiredField;
