import Spinner from '@components/_elements/Spinner/Spinner';
import SimplePointDataTableWithTitle from '@components/_shared/MultiColPointDataTableWithTitle';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';
import { WidgetTypeMultiSectionPointList } from '@src/types/Widget';
import { MqttClient } from 'mqtt';
import * as React from 'react';

interface Props {
  selectedMpId: string;
  useNewClient?: MqttClient;
}

function MegapackDetails(props: Props) {
  const { selectedMpId } = props;

  const { data: widgetConfig, isLoading } =
    useGetWidgetConfig<WidgetTypeMultiSectionPointList>('mpDetailsModal', {
      sourceDeviceId: selectedMpId,
    });

  return isLoading ? (
    <Spinner type='content-small' />
  ) : (
    widgetConfig?.sections.map((sectionData, i) => (
      <SimplePointDataTableWithTitle
        key={`megapack-details-${Math.random()}`}
        sectionIndex={i}
        data={sectionData}
        useNewClient={props.useNewClient}
      />
    ))
  );
}

export default MegapackDetails;
