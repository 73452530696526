import { dispatcher, store } from '@store';
import { callAPI } from '@utils';
import { SiteMeta, SiteMetaError, ApiSiteMeta } from '@src/types/SiteMeta';

export function parseSiteMetaResponse(siteMetaResponse: ApiSiteMeta): SiteMeta {
  const { siteId, updatedBy, updatedAt } = siteMetaResponse;
  return {
    ...siteMetaResponse.body,
    siteId,
    updatedAt,
    updatedBy,
  };
}

export default async function loadSiteMeta() {
  const siteMetasRes: ApiSiteMeta = await callAPI('/site-meta');
  const isApiError = siteMetasRes as unknown as SiteMetaError;

  if (
    siteMetasRes === undefined ||
    siteMetasRes === null ||
    isApiError.error !== undefined
  ) {
    throw new Error('Error fetching site config', { cause: isApiError.error });
  }

  const siteMeta = parseSiteMetaResponse(siteMetasRes);
  store.dispatch(
    dispatcher('UPDATE_CONFIG_MODULE', {
      siteMeta,
    }),
  );
  return siteMeta;
}
