import { Action } from 'redux';
import { SiteMeta } from '@src/types/SiteMeta';

export interface ConfigState {
  siteMeta: SiteMeta;
}

const initState: ConfigState = {
  siteMeta: {} as SiteMeta,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ConfigState = initState,
  action: Action<string> & ConfigState & { sn?: string },
) => {
  const handlers: Record<string, () => ConfigState> = {
    UPDATE_CONFIG_MODULE: () => {
      const { type: _type, ...cleanAction } = action;
      return { ...state, ...cleanAction };
    },
  };
  if (action.type && handlers[action.type] !== undefined) {
    return handlers[action.type]();
  }
  return state;
};
