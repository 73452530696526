import React from 'react';
import { ModeStatus } from '@src/types/control-modes/Modes';
import './Modes.scoped.scss';

interface ModeStatusIconProps {
  status: ModeStatus;
}

const getModeStatusClass = (modeStatus: ModeStatus) => {
  switch (modeStatus) {
    case ModeStatus.Disabled:
      return 'red';
    case ModeStatus.Enabled:
      return 'green';
    case ModeStatus.Pending:
      return 'yellow';
    default:
      return 'yellow';
  }
};

function ModeStatusIcon({ status }: ModeStatusIconProps) {
  return (
    <span className='mode-form-box'>
      <i className={`status ${getModeStatusClass(status)}`} />
      <span className='mode-form-tooltip'>{ModeStatus[status]}</span>
    </span>
  );
}

export default ModeStatusIcon;
