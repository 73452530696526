import React from 'react';
import './Pagination.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button/Button';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const maxNumShown = 7;
const activeWindowLength = 3;

const Pagination = ({
  currentPage,
  numOfPages,
  changeCurrPage,
  classType,
  noBtnText,
}) => {
  const generatePgTxt = () => {
    let arr = [1];
    if (currentPage <= activeWindowLength) {
      arr = [
        ...arr,
        ...Array.from(Array(activeWindowLength + 1), (_, index) => index + 2),
        '...',
      ];
    } else if (currentPage + 1 >= numOfPages - activeWindowLength) {
      arr = [
        ...arr,
        '...',
        ...Array.from(
          Array(activeWindowLength + 1),
          (_, index) => index + numOfPages - activeWindowLength - 1,
        ),
      ];
    } else {
      arr = [
        ...arr,
        '...',
        currentPage,
        currentPage + 1,
        currentPage + 2,
        '...',
      ];
    }
    arr.push(numOfPages);
    return arr;
  };
  const changeActivePage = (val, index, blindPlacesNum) => {
    if (val !== '...') {
      changeCurrPage(val - 1);
    } else {
      if (blindPlacesNum === 2) {
        const diff = (activeWindowLength - 1) / 2 + 1;
        changeCurrPage(currentPage + (index === 1 ? -diff : diff));
      } else {
        changeCurrPage(
          index !== 1
            ? activeWindowLength + 2
            : numOfPages - activeWindowLength - 3,
        );
      }
    }
  };

  const pgArr = generatePgTxt();
  return (
    <div className={`pagination ${classType}`}>
      {numOfPages <= maxNumShown ? (
        <>
          {Array.from(Array(numOfPages), (_, index) => index).map((i) => (
            <div
              onClick={() => changeCurrPage(i)}
              className={`btn-num ${currentPage === i && 'cur'}`}
              key={i}
            >
              {i + 1}
            </div>
          ))}
        </>
      ) : (
        <>
          <Button
            disabled={currentPage === 0}
            className='prev-next-pagin'
            onClick={() => changeCurrPage(currentPage - 1)}
          >
            <FontAwesomeIcon icon={faAngleLeft} /> {noBtnText ? '' : 'Prev'}
          </Button>
          {pgArr.map((p, i) => (
            <div
              className={`btn-num ${
                p !== '...' && currentPage === p - 1 && 'cur'
              }`}
              key={i}
              onClick={() =>
                changeActivePage(p, i, pgArr.filter((e) => e === '...').length)
              }
            >
              {p}
            </div>
          ))}
          <Button
            disabled={currentPage === numOfPages - 1}
            className='prev-next-pagin'
            onClick={() => changeCurrPage(currentPage + 1)}
          >
            {noBtnText ? '' : 'Next'} <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </>
      )}
    </div>
  );
};

export default Pagination;
