const dialogActionsStyles = {
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    pb: 2,
  },
  dialogPaper: {
    width: '100%',
    maxWidth: 900,
    height: '100%',
    display: 'flex',
    maxHeight: 900,
    '& .jsoneditor-preview': {
      color: 'white',
    },
  },
};

export default dialogActionsStyles;
