export const UNIT_DROPDOWN_TABLES = ['Unit', 'PCS', 'Battery', 'CMD'];
export const UNIT_RACK_DROPDOWNS_TABLES = ['Rack'];
export const OPTIONAL_UNIT_TABLES = ['CMD'];
export const DATA_POINT_LENGTH_LIMIT = 25;
export const AGREG_TYPES = ['max', 'min', 'mean', 'sum'];
export const DISABLED_CATEGORIES = [];
export const MAX_PAGINATED_DP_PER_REQ = 10;

export const timeFields = ['startDate', 'stopDate', 'startTime', 'stopTime'];

export const dataPointDictInit = {
  category: '',
  siteId: '',
  unitId: '',
  sourceDeviceId: '',
  dataPoint: '',
  axis: 'Y1',
};

export function getKeyByValue(object, value, oneFromValues) {
  return Object.keys(object).find(
    (key) =>
      object[key] === value &&
      (!oneFromValues ? true : oneFromValues.includes(value)),
  );
}

export function checkShouldAggr({ interval }) {
  return +interval.value === 1 && interval.unit === 'second';
}

export function getDpFilterOptions({
  options,
  siteDictSnToName,
  unitsDictSnToName,
}) {
  const opts = { ...options };
  DISABLED_CATEGORIES.forEach((el) => delete opts[el]);
  const result = {};
  let dpOpts = [];
  let dpFields = [];
  const countDict = {};
  let allSns = Object.keys(opts || {}).reduce((acc, cv) => {
    const countCatDict = Object.keys(opts[cv] || {}).reduce((a, c) => {
      return { ...a, [c]: opts[cv][c].columns?.length };
    }, {});
    countDict[cv] = {
      count: Object.values(countCatDict).reduce((a, c) => a + c, 0),
      dict: countCatDict,
    };
    Object.keys(opts[cv]).forEach((sn) => {
      if (!dpFields.length && opts[cv][sn]?.columns[0]) {
        dpFields = Object.keys(opts[cv][sn]?.columns[0]);
      }
      dpOpts = [
        ...dpOpts,
        ...(opts[cv][sn]?.columns?.map((c) => c.DisplayName) || {}),
      ];
    });
    return [...acc, ...Object.keys(opts[cv] || {})];
  }, []);
  allSns = Array.from(new Set(allSns));
  result.countDict = countDict;
  result.dpFields = dpFields;
  const allSitesSns = allSns.filter((el) =>
    Object.keys(siteDictSnToName).includes(el),
  );
  const allUnitsSns = allSns.filter((el) => !allSitesSns.includes(el));
  const siteOpts = allSitesSns.map((s) => ({
    sn: s,
    name: siteDictSnToName[s],
  }));
  const siteOptsSorted = sortObjects(siteOpts, 'name');
  result.siteOpts = ['[Any Site]', ...siteOptsSorted.map((o) => o.name)];
  result.siteOptsSN = siteOptsSorted.map((o) => o.sn);
  const unitOpts = allUnitsSns.map((u) => ({
    sn: u,
    name: unitsDictSnToName[u],
  }));
  const unitOptsSorted = sortObjects(unitOpts, 'name');
  result.unitOpts = ['[Any Unit]', ...unitOptsSorted.map((o) => o.name)];
  result.unitOptsSN = unitOptsSorted.map((o) => o.sn);
  result.dpOpts = [
    '[Any DataPoint]',
    ...simpleSort(Array.from(new Set(dpOpts))),
  ];
  result.catOpts = ['[Any Category]', ...Object.keys(opts)];
  return result;
}
/*
export function getHistorianOptions({
  options, category, siteSN, unitSN, rackIndex, siteDictSnToName, siteDictUnits, unitsDictSnToName,
  unitDropdownTables, optionalUnitTables,
}) {
  const ctgrWithUnits = [...unitDropdownTables, ...UNIT_RACK_DROPDOWNS_TABLES];
  const sitesSNs = Object.keys(siteDictSnToName);
  const result = {};
  if (category) {
    const ctgrValue = options[category] || {};
    const snsOfCategory = Object.keys(ctgrValue);
    let sitesOfCategory = [];
    if(ctgrWithUnits.includes(category)) {
      sitesOfCategory = sitesSNs.filter(el => {
        return snsOfCategory.includes(el) || siteDictUnits[el].some(u => snsOfCategory.includes(u));
      });
    } else {
      sitesOfCategory = snsOfCategory.filter(el => sitesSNs.includes(el));
    }
    const unitsOfCategory = snsOfCategory.filter(el =>!sitesOfCategory.includes(el));
    const siteOpts = sitesOfCategory.map(s => ({sn: s, name: siteDictSnToName[s]}));
    const siteOptsSorted = sortObjects(siteOpts, 'name');
    result.siteOpts = siteOptsSorted.map(o => o.name);
    result.siteOptsSN = siteOptsSorted.map(o => o.sn);
    if (siteSN) {
      if (ctgrWithUnits.includes(category)) {
        const unitOpts = (siteDictUnits[siteSN] || []).filter(el => unitsOfCategory.includes(el))
          .map(u => ({sn: u, name: unitsDictSnToName[u]}));
        const unitOptsSorted = sortObjects(unitOpts, 'name');
        result.unitOpts = unitOptsSorted.map(o => o.name);
        result.unitOptsSN = unitOptsSorted.map(o => o.sn);
        if (unitSN) {
          if (UNIT_RACK_DROPDOWNS_TABLES.includes(category)) {
            const rackNum = +options[category][unitSN].rackCount;
            const rackOpts = Array.from({length: rackNum}, (_, i) => `Rack ${i + 1}`);
            result.rackOpts = rackOpts;
            if (![undefined,null].includes(rackIndex)) {
              result.dataPointOpts = options[category][unitSN].columns;
            }
          } else {
            result.dataPointOpts = options[category][unitSN].columns;
          }
        } else if (optionalUnitTables.includes(category)) {
          result.dataPointOpts = options[category][siteSN].columns;
        }
      } else {
        result.dataPointOpts = options[category][siteSN].columns;
      }
    }
  }
  return result;
}*/
export function throwErr(errorMessage) {
  throw new Error(errorMessage);
}

export function getHistorianDevicesOpts({
  categories,
  devices,
  category,
  unitId,
  siteId,
}) {
  const result = {};
  if (category && devices.length) {
    const hierarchies = categories
      .filter((c) => c.name === category)
      .map((c) => c.hierarchy);
    const siteOpts = [];
    const siteOptsId = [];
    const sortedSites = sortObjects(devices, 'name', 'id');
    sortedSites.forEach((d) => {
      if (!siteOptsId.includes(d.id)) {
        siteOptsId.push(d.id);
        siteOpts.push(d.name || d.id);
      }
    });
    result.siteOptsId = siteOptsId;
    result.siteOpts = siteOpts;
    if (siteId && hierarchies.some((h) => h.includes('Unit'))) {
      const unitsOfSite = sortedSites.find((s) => s.id === siteId).children;
      if (unitsOfSite?.length) {
        const sortedUnitsOfSite = sortObjects(unitsOfSite, 'name', 'id');
        const unitOpts = [];
        const unitOptsId = [];
        sortedUnitsOfSite.forEach((u) => {
          if (!unitOptsId.includes(u.id)) {
            unitOptsId.push(u.id);
            unitOpts.push(u.name || u.id);
          }
        });
        result.unitOptsId = unitOptsId;
        result.unitOpts = unitOpts;
        if (unitId && hierarchies.some((h) => h.includes('Rack'))) {
          const racksOfUnit = sortedUnitsOfSite.find(
            (s) => s.id === unitId,
          ).children;
          if (racksOfUnit?.length) {
            const sortedRacksOfUnit = sortObjects(racksOfUnit, 'name', 'id');
            const rackOpts = [];
            const rackOptsId = [];
            sortedRacksOfUnit.forEach((r) => {
              if (!rackOptsId.includes(r.id)) {
                rackOptsId.push(r.id);
                rackOpts.push(r.name || r.id);
              }
            });
            result.rackOptsId = rackOptsId;
            result.rackOpts = rackOpts;
          }
        }
      }
    }
  }
  return result;
}

export function getFullNamesSplitArr(options, category) {
  return Object.keys(options[category]).map((el) => el.split(', '));
}

export function stripOutUnitText(unit) {
  if (unit?.toLowerCase().includes('unit ')) {
    return unit.split(' ')[1];
  } else {
    return unit;
  }
}

export function getFullDeviceName(dp) {
  const fullNamePartsOrJustSN = [];
  if (dp.site) {
    fullNamePartsOrJustSN.push(dp.site);
  }
  if (dp.unit) {
    fullNamePartsOrJustSN.push(stripOutUnitText(dp.unit));
  }
  if (dp.rack) {
    fullNamePartsOrJustSN.push(dp.rack);
  }
  return fullNamePartsOrJustSN.join(', ');
}

export function sortObjects(arr, field) {
  const sortArr = arr.sort((a, b) => {
    const aStr = (a[field] || '').toUpperCase();
    const bStr = (b[field] || '').toUpperCase();
    if (aStr < bStr) {
      return -1;
    } else if (aStr > bStr) {
      return 1;
    }
    return 0;
  });
  return sortArr;
}

export function simpleSort(arr) {
  return arr.sort((a, b) => {
    const aStr = a.toUpperCase();
    const bStr = b.toUpperCase();
    if (aStr < bStr) {
      return -1;
    } else if (aStr > bStr) {
      return 1;
    }
    return 0;
  });
}

// todo: refactor me
export const convertSearchDateFields = (
  s,
  getTsDueTimeMode,
  timeMode,
  siteId,
  reverse,
) => {
  const newS = { ...s };
  if (reverse) {
    ['start', 'stop'].forEach((fs) => {
      const dateTime = newS[`${fs}DateTime`];
      const tsDueTimeMode = getTsDueTimeMode(dateTime, false, 'UTC');
      newS[`${fs}Time`] = tsDueTimeMode;
      newS[`${fs}Date`] = tsDueTimeMode;
      delete newS[`${fs}DateTime`];
    });
    return newS;
  } else {
    ['start', 'stop'].forEach((fs) => {
      const timeParts = newS[`${fs}Time`]?.split(' ');
      const dateParts = newS[`${fs}Date`]?.split(' ');
      const dateTime = getTsDueTimeMode(
        `${dateParts[0]} ${timeParts[1]}`,
        true,
        timeMode,
        'UTC',
      ).format();
      newS[`${fs}DateTime`] = dateTime;
    });
    timeFields.forEach((f) => {
      delete newS[f];
    });
    newS.siteId = siteId;
    return newS;
  }
};
