import useMakePageScrollable from '@hooks/useMakePageScrollable';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import './index.scoped.scss';

import {
  AGREG_TYPES,
  checkShouldAggr,
} from '@components/Home/Historian/historianUtils.js';
import { store } from '@store';
import storeConnector from '@store/storeConnector';
import Spinner from '@components/_elements/Spinner/Spinner';

const HistorianTableView = ({ actions }) => {
  useMakePageScrollable();
  const { request } = useParams();
  const useNewTopicStructure =
    store.getState().config.siteMeta.ui.Use_New_Topic_Structure;
  const [loading, setLoading] = useState(true);
  const reqParams = useRef(null);
  const timeMode = useRef(null);
  const tzOffset = useRef(null);
  const devices = useRef([]);

  const setDataToTable = (formatedReq, res) => {
    const isMultiline = !checkShouldAggr(formatedReq);
    const agrArr = isMultiline
      ? AGREG_TYPES.filter((el) =>
          (reqParams.current?.aggregateFunctions || []).includes(el),
        )
      : formatedReq.aggregateFunctions.length > 0
        ? formatedReq.aggregateFunctions
        : [];
    const nameArr = formatedReq.criteria.reduce((acc, c) => {
      const aggrParts = [];
      agrArr.forEach((aggr) => {
        const categoryName = c.category === 'Battery' ? 'bms' : c.category;
        aggrParts.push(
          `${categoryName.toLowerCase()}_${c.deviceId}_${c.field}_${aggr}`,
        );
      });
      return [...acc, ...aggrParts];
    }, []);
    const tableBody = document.getElementById('historian-table-view-id');
    res.forEach((rowData) => {
      const dateUTC = rowData._time;
      const r = document.createElement('tr');
      const tdDate = document.createElement('td');
      let date = moment
        .utc(dateUTC)
        .format(`YYYY-MM-DD HH:mm:ss${!isMultiline ? '.SSS' : ''}`);
      if (timeMode.current !== 'UTC') {
        date += ' / ';
        date += moment
          .utc(moment(dateUTC).format('YYYY-MM-DD HH:mm:ss.SSS'))
          .utcOffset(tzOffset.current)
          .format(`YYYY-MM-DD HH:mm:ss${!isMultiline ? '.SSS' : ''}`);
      }
      const text = document.createTextNode(date);
      tdDate.appendChild(text);
      r.appendChild(tdDate);
      nameArr.forEach((n) => {
        const newTd = document.createElement('td');
        newTd.appendChild(document.createTextNode(formatNum(rowData[n])));
        r.appendChild(newTd);
      });
      tableBody.appendChild(r);
    });
    setLoading(false);
  };

  const formatNum = (num) => {
    if (!num) {
      return '-';
    }
    if (isNaN(num)) {
      return num;
    }
    return +(+num).toFixed(3);
  };

  const formatReq = (req) => {
    return {
      ...req,
      criteria: req.criteria.reduce((a, c) => {
        const q = { ...c };
        if (useNewTopicStructure) {
          q.deviceId = c.sourceDeviceId || c.unitId || c.siteId;
          q.unitId = c.unitId;
        } else {
          q.deviceId = c.unitId || c.siteId;
          delete q.sourceDeviceId;
          delete q.unitId;
        }
        delete q.rackId;
        return [...(a || []), q];
      }, []),
    };
  };

  useEffect(() => {
    const decodedGen = JSON.parse(atob(request));
    const loadData = async () => {
      if (decodedGen) {
        devices.current = decodedGen.req.criteria.map((c) => {
          return c.sourceDeviceId || c.siteId;
        });
        reqParams.current = decodedGen.req;
        timeMode.current = decodedGen.timeMode;
        tzOffset.current = -decodedGen.tzOffset;
        const formatedReq = formatReq(decodedGen.req);

        const res = await actions.getHistorianData(formatedReq);
        if (res && !res.error) {
          setDataToTable(formatedReq, res);
        } else {
          setLoading(false);
        }
      }
    };
    loadData();
  }, []);

  const reqIntrvl = reqParams.current?.interval;
  const isMultiline = reqIntrvl
    ? !checkShouldAggr({ interval: reqIntrvl })
    : false;

  const agrArr = isMultiline
    ? AGREG_TYPES.filter((el) =>
        (reqParams.current?.aggregateFunctions || []).includes(el),
      )
    : [];

  const getNamesArr = () => {
    if (!reqIntrvl) {
      return [];
    }
    return reqParams.current.criteria.map((dp) => {
      return `${dp.category} - ${dp.sourceDeviceId || dp.siteId} - ${dp.field}`;
    });
  };

  const nameArr = getNamesArr();
  return (
    <div id='app-layout-content' className='historian-table-view content'>
      {loading && <Spinner cover='container' />}
      <div>
        <table>
          {reqParams.current && (
            <thead>
              <tr>
                <th rowSpan={isMultiline ? 2 : 1}>
                  Date UTC
                  {timeMode.current !== 'UTC' ? ` / ${timeMode.current}` : ''}
                </th>
                {reqParams.current.criteria.map((_r, i) => (
                  <th
                    key={i}
                    style={{ verticalAlign: 'middle' }}
                    rowSpan={isMultiline ? 1 : 2}
                    colSpan={isMultiline ? agrArr.length : 1}
                  >
                    {nameArr[i]}
                  </th>
                ))}
              </tr>
              <tr>
                {isMultiline &&
                  reqParams.current.criteria.map((_r, i) => (
                    <React.Fragment key={i}>
                      {agrArr.map((t, j) => (
                        <th key={j}>{t.toUpperCase()}</th>
                      ))}
                    </React.Fragment>
                  ))}
              </tr>
            </thead>
          )}
          <tbody id='historian-table-view-id' />
        </table>
      </div>
    </div>
  );
};

export default storeConnector(HistorianTableView, {});
