import React, { createContext, useState, useMemo } from 'react';

interface LookbackContextProps {
  lookback: string;
  setLookback: React.Dispatch<React.SetStateAction<string>>;
}

const LookbackContext = createContext<LookbackContextProps>({
  lookback: '',
  setLookback: () => '',
});

export function LookbackProvider({ children }: { children: React.ReactNode }) {
  const [lookback, setLookback] = useState<string>('');

  const value = useMemo(() => ({ lookback, setLookback }), [lookback]);

  return (
    <LookbackContext.Provider value={value}>
      {children}
    </LookbackContext.Provider>
  );
}

export default LookbackContext;
