import React, { ReactElement } from 'react';
import Circle from '@src/sld/components/Circle/Circle';
import VerticalLine from '@src/sld/components/Line/VerticalLine';
import Switch from '@src/sld/components/Switch/Switch';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';

const rectTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

function BreakerStack2(): ReactElement {
  return (
    <g transform='translate(900 -526)'>
      <VerticalLine
        translateXCoordinate={-112.5}
        translateYCoordinate={710}
        length={30}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />
      <Switch
        label='89F13-1'
        isClosed={false}
        translateXCoordinate={-112}
        translateYCoordinate={700}
        stroke='green'
      />

      <HorizontalLine
        translateXCoordinate={-113.5}
        translateYCoordinate={790}
        color='white'
        width={0.5}
        length={20}
      />
      <Circle
        label='CAISO 3 A/B'
        innerText='M'
        translateXCoordinate={-83}
        translateYCoordinate={780}
        diameter={10}
        stroke='white'
        strokeWidth={0.5}
      />

      <rect
        x={-123}
        y='739'
        width='20'
        height='20'
        fill='grey'
        strokeWidth={0.5}
      />
      <text x={-90} y={751} {...rectTextProps}>
        52F13
      </text>

      <text>ddd</text>

      <VerticalLine
        translateXCoordinate={-113.5}
        translateYCoordinate={760}
        length={60}
        color='white'
        width={0.5}
        data-id='vertical line on top of the switch'
      />
    </g>
  );
}

export default BreakerStack2;
