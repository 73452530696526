import React from 'react';
import FractalModelIframe from '@components/Mkt/FractalModelIframe';

function Mkt() {
  return (
    <div className='home-row'>
      <div className='fcol' style={{ width: '100%', height: 'auto' }}>
        <FractalModelIframe />
      </div>
    </div>
  );
}

export default Mkt;
