import React from 'react';
import { UserRole } from '@src/services/auth/roles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './UserPanel.scoped.scss';
import LoginIcon from '@assets/login.svg?react';
import storeConnector from '@store/storeConnector';
import VersionFooter from '@components/_elements/VersionFooter';
import { SiteMeta } from '@src/types/SiteMeta';
import UserInfo from '@components/_shared/UserPanel/UserInfo';
import { isActionRole, isAdmin, isSuperAdmin } from '@src/services/auth';
import UserPanelLinks from './UserPanelLinks';

interface UserPanelProps {
  showUserPanel: boolean;
  close: () => void;
  siteMeta: SiteMeta;
  role: UserRole;
  triggerSettingsPopup: (v: boolean) => void;
  triggerSupportPopup: (v: boolean) => void;
  triggerFastStopPopup: (v: boolean) => void;
  triggerClearAlertsPopup: (v: boolean) => void;
  triggerToggleRDG: (v: boolean) => void;
  triggerSiteMetaEditor: (v: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function
function UserPanel({
  showUserPanel,
  close,
  siteMeta,
  role,
  triggerSettingsPopup,
  triggerSupportPopup,
  triggerFastStopPopup,
  triggerClearAlertsPopup,
  triggerToggleRDG,
  triggerSiteMetaEditor,
}: UserPanelProps) {
  return (
    <div className={showUserPanel ? 'user-panel open' : 'user-panel'}>
      <div className='popup-close' onClick={() => close()} />
      <Stack spacing={2} sx={{ padding: '12px 24px', marginBottom: '15px' }}>
        {siteMeta.FastStop_Enable &&
          role &&
          isActionRole(role) &&
          !process.env.VITE_READ_ONLY_MODE && (
            <Button
              variant='contained'
              color='error'
              onClick={() => triggerFastStopPopup(true)}
            >
              Site Fast Stop
            </Button>
          )}
        {role && isAdmin(role) && !process.env.VITE_READ_ONLY_MODE && (
          <Button
            variant='outlined'
            color='error'
            onClick={() => triggerClearAlertsPopup(true)}
          >
            Clear alerts
          </Button>
        )}
        {role && isSuperAdmin(role) && (
          <>
            <Button
              variant='contained'
              color='warning'
              onClick={() => triggerToggleRDG(true)}
            >
              Toggle RDG
            </Button>
            <Button
              variant='contained'
              color='success'
              onClick={() => triggerSiteMetaEditor(true)}
            >
              Site Meta Editor
            </Button>
          </>
        )}
      </Stack>
      <LoginIcon className='icon login' />
      <UserInfo />
      <UserPanelLinks
        showSettings={() => triggerSettingsPopup(true)}
        showSupport={() => triggerSupportPopup(true)}
      />
      <VersionFooter />
    </div>
  );
}

export default storeConnector(UserPanel, {
  user: ['role'],
  config: ['siteMeta'],
});
