import { Actions } from '@src/types/Actions';

function usePublishCommand({
  deviceId,
  unitName,
  sendCommand,
}: {
  deviceId: string;
  unitName?: string;
  sendCommand: Actions['sendCommand'];
}) {
  return (commandString: string) =>
    sendCommand([commandString], deviceId, unitName);
}

export default usePublishCommand;
