import { callAPI } from '@utils';
import { notifyError } from './notifications.js';
import { dispatcher, store } from '@store';

const MSGS_PER_REQUEST = 100;

export const devices = {
  getLOTO(deviceId) {
    return function () {
      return new Promise((resolve) => {
        callAPI(`/controlLOTO/${deviceId}`, 'GET', {}).then((res) => {
          if (!res || (res.error && res.error !== 404)) {
            notifyError(res.error);
            return resolve([]);
          } else if (res.error && res.error === 404) {
            return resolve([]);
          }
          resolve({
            Mode: res.mode,
            Operator: res.updatedBy,
            TS: res.updatedAt,
            Note: res.notes,
            ...res,
          });
        });
      });
    };
  },
  setLOTO(loto) {
    return function () {
      return new Promise((resolve) => {
        callAPI(`/controlLOTO/${loto.UnitSN}`, 'POST', {
          deviceId: loto.UnitSN,
          notes: loto.Note,
          mode: loto.Mode,
          updatedAt: loto.TS,
          updatedBy: loto.Operator,
        }).then((res) => {
          if (!res || res.error) {
            notifyError(res.error);
            return resolve([]);
          }
          resolve(loto);
        });
      });
    };
  },
  loadChartData(deviceId, duration, fields, category = 'Site') {
    const aggregateEvery = {
      '12h': '15m',
      '1d': '15m',
      '3d': '15m',
    }[duration.toLowerCase()];
    return () => {
      return new Promise((resolve) => {
        callAPI(
          `/chartData/query/${
            store.getState().config.siteMeta?.siteId || deviceId
          }`,
          'POST',
          {
            fields: fields.map((f) => ({
              deviceType: category,
              deviceId,
              name: f,
            })),
            period: duration.toLowerCase(),
            aggregateEvery,
          },
        ).then((res) => {
          if (!res || res.error) {
            notifyError(res.error);
            return resolve([]);
          }
          const processed = res.map((d) => {
            const payload = fields.reduce((acc, cv) => {
              return { ...acc, [cv]: d[cv] };
            }, {});
            return { date: d.time, value: payload };
          });
          resolve(processed);
        });
      });
    };
  },
  loadAwardsDates() {
    return async () => {
      //TODO
    };
  },
  getAwardByDate() {
    return () =>
      new Promise((resolve) => {
        resolve(null); // TODO
      });
  },
  uploadAwardData() {
    return () =>
      new Promise((resolve) => {
        resolve(null); // TODO
      });
  },
  uploadIndex() {
    return () =>
      new Promise((resolve) => {
        resolve(null); //TODO
      });
  },
  uploadSocBalancing() {
    return () =>
      new Promise((resolve) => {
        resolve(null); // TODO
      });
  },
  getEnergyProduction(type) {
    return () =>
      new Promise((resolve) => {
        const { siteId } = store.getState().config.siteMeta || {};
        const typeDict = {
          daily: '1d',
          hourly: '1h',
        };
        const typeCode = typeDict[type];
        if (siteId) {
          callAPI(`/chartData/energyProduction/${siteId}/${typeCode}`).then(
            (r) => {
              if (!r || r.error) {
                notifyError(r.error);
                return resolve([]);
              }
              resolve(r);
            },
          );
        }
      });
  },
  loadSitePV(SN, duration) {
    return async function (dispatch) {
      const period = {
        '15m': '15min',
        '1h': '1hour',
        '12h': '12hour',
        '1d': '1day',
        '3d': '3day',
      }[duration.toLocaleLowerCase()];
      const aggregateEvery = {
        '15m': '1m',
        '1h': '1m',
        '12h': '15m',
        '1d': '15m',
        '3d': '15m',
      }[duration.toLowerCase()];

      const { sitePV } = store.getState().site;
      const fields = ['PVkW', 'PV_Irradiance_GHI'];
      if (!sitePV[period]) {
        await new Promise((resolve) => {
          const parsedFields = fields.map((f) => ({
            deviceType: 'Site',
            deviceId: SN,
            name: f,
          }));

          callAPI(
            `/chartData/query/${
              store.getState().config.siteMeta?.siteId || SN
            }`,
            'POST',
            {
              fields: parsedFields,
              period: duration.toLowerCase(),
              aggregateEvery,
            },
          ).then((res) => {
            if (!res?.length || res.error) {
              notifyError(res.error);
              dispatch(
                dispatcher('UPDATE_SITE_MODULE', {
                  sitePV: {
                    ...sitePV,
                    [period]: [
                      {
                        TS: null,
                        PVkW: null,
                        PV_Irradiance_GHI: null,
                      },
                    ],
                  },
                }),
              );
              resolve([]);
            }
            const processed = res.map((d) => {
              const payload = fields.reduce((acc, cv) => {
                return { ...acc, [cv]: d[cv] };
              }, {});
              return { TS: d.time, ...payload };
            });
            dispatch(
              dispatcher('UPDATE_SITE_MODULE', {
                sitePV: {
                  ...sitePV,
                  [period]: processed,
                },
              }),
            );
            resolve();
          });
        });
      }
    };
  },
  clearSitePV() {
    return async function (dispatch) {
      dispatch(dispatcher('UPDATE_SITE_MODULE', { sitePV: {} }));
    };
  },
  getMessagesSimple() {
    function compare(a, b) {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      return 0;
    }
    return () => {
      return new Promise((resolve) => {
        const meta = store.getState().config.siteMeta;
        let deviceIds = [...meta.Units];
        if (!deviceIds.includes(meta.siteId)) {
          deviceIds.unshift(meta.siteId);
        }
        callAPI('/message/filter', 'GET', { deviceIds, n: 100 }).then((res) => {
          if (!res || res.error) {
            notifyError(!res ? 'Message getting error' : res.error);
            resolve([]);
          } else {
            const finalMessageArr = [...res.data.sort(compare)];
            resolve(
              finalMessageArr.map((dataObj) => ({
                ...dataObj,
                message: JSON.parse(dataObj.message),
              })),
            );
          }
        });
      });
    };
  },
  getMessagePaginated({ filteredSNs, dateTo, dateFrom, keyword, offset }) {
    return () => {
      return new Promise((resolve) => {
        const meta = store.getState().config.siteMeta;
        let snsAll = [...meta.Units];
        if (!snsAll.includes(meta.siteId)) {
          snsAll.unshift(meta.siteId);
        }
        const ids = filteredSNs || snsAll;
        const params = {
          deviceIds: ids,
          n: MSGS_PER_REQUEST,
          ...(dateTo ? { dateTo } : {}),
          ...(dateFrom ? { dateFrom } : {}),
          ...(keyword ? { keyword } : {}),
          ...(offset ? { offset } : {}),
        };
        callAPI('/message/filter', 'GET', params).then((res) => {
          if (!res || res.error) {
            notifyError(!res ? 'Message getting error' : res.error);
            resolve({});
          } else {
            resolve({
              messages: res.data.map((dataObj) => ({
                ...dataObj,
                message: JSON.parse(dataObj.message),
              })),
              count: res.count,
            });
          }
        });
      });
    };
  },
};
