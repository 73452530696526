import TextLoader from '@components/_elements/TextLoader/index';
import storeConnector from '@store/storeConnector';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { isKeyInObject, useInterval } from '@utils';
import { getTopicForTooltip } from '@utils/index_ts';
import React, { useEffect, useRef, useState } from 'react';
import { getPCSFields, UNIT_FIELDS } from './constants.js';

const PCSContainerSungrowBESS = ({
  siteMeta,
  currentUnitDeviceId,
  pcsType,
  fromPV,
  titleOverride,
  pcsIndex,
  topicDict,
}) => {
  const [currHBInterval, setCurrHBInterval] = useState(0);
  const [prevHB, setPrevHB] = useState(0);
  const [unitRt, setUnitRt] = useState({});
  const [pcsRt, setPCSRt] = useState({});
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  useEffect(() => {
    setUnitRt({});
    setPCSRt({});
  }, [currentUnitDeviceId]);

  useWsSubscribe(
    {
      unit: {
        sns: [currentUnitDeviceId],
        fields: UNIT_FIELDS,
        cb: (data) => {
          setUnitRt((prev) => ({ ...prev, ...data }));
        },
      },
      pcs: {
        sns: [currentUnitDeviceId],
        fields: getPCSFields(pcsType),
        sourceDeviceId: `pcs_${pcsIndex + 1}`,
        cb: (data) => setPCSRt((prev) => ({ ...prev, ...data })),
      },
    },
    [currentUnitDeviceId],
  );

  useInterval(() => {
    if (pcsRt && pcsRt.WatchdogResponse !== prevHB) {
      setCurrHBInterval(0);
      setPrevHB(pcsRt.WatchdogResponse);
    } else {
      setCurrHBInterval((currHBInterval) => currHBInterval + 1);
    }
  }, 1000);

  const getInfoDict = () => {
    const { Thresh_HB_PCS_TimeOut } = siteMeta ? siteMeta : {};

    return [
      {
        field: 'Status',
        value: pcsRt.InvStatus,
        loading: isKeyInObject(pcsRt, 'InvStatus'),
        topicTooltip: getTopicForTooltip('InvStatus', topicDict),
      },
      {
        field: 'AC kW',
        value: pcsRt.kW,
        loading: isPCSLoading('kW'),
        topicTooltip: getTopicForTooltip('kW', topicDict),
      },
      {
        field: 'Va',
        value: pcsRt.PhVphA,
        loading: isPCSLoading('PhVphA'),
        topicTooltip: getTopicForTooltip('PhVphA', topicDict),
      },
      {
        field: 'DC kW',
        value: pcsRt.DCkW,
        loading: isPCSLoading('DCkW'),
        topicTooltip: getTopicForTooltip('DCkW', topicDict),
      },
      {
        field: 'Mode',
        value: unitRt['RunMode'],
        loading: isKeyInObject(unitRt, 'RunMode'),
        topicTooltip: getTopicForTooltip('RunMode', topicDict),
      },
      {
        field: 'AC kVAR',
        value: pcsRt.kVAR,
        loading: isPCSLoading('kVAR'),
        topicTooltip: getTopicForTooltip('kVAR', topicDict),
      },
      {
        field: 'Vb',
        value: pcsRt.PhVphB,
        loading: isPCSLoading('PhVphB'),
        topicTooltip: getTopicForTooltip('PhVphB', topicDict),
      },
      {
        field: 'DC Bus Voltage',
        value: pcsRt.DCV,
        loading: isPCSLoading('DCV'),
        topicTooltip: getTopicForTooltip('DCV', topicDict),
      },
      {
        field: 'Frequency',
        value: pcsRt.Hz,
        loading: isPCSLoading('Hz'),
        topicTooltip: getTopicForTooltip('Hz', topicDict),
      },
      {
        field: 'AC kVA',
        value: pcsRt.kVA,
        loading: isPCSLoading('kVA'),
        topicTooltip: getTopicForTooltip('kVA', topicDict),
      },
      {
        field: 'Vc',
        value: pcsRt.PhVphC,
        loading: isPCSLoading('PhVphC'),
        topicTooltip: getTopicForTooltip('PhVphC', topicDict),
      },
      {
        field: 'DC Amps',
        value: pcsRt.DCA,
        loading: isPCSLoading('DCA'),
        topicTooltip: getTopicForTooltip('DCA', topicDict),
      },
      {
        field: 'PCS Heartbeat',
        value: pcsRt.WatchdogResponse || '-',
        class:
          currHBInterval > +Thresh_HB_PCS_TimeOut && prevHB !== 0
            ? 'bold red-txt'
            : '',
        loading: isPCSLoading('WatchdogResponse'),
        topicTooltip: getTopicForTooltip('WatchdogResponse', topicDict),
      },
      {
        field: 'PF',
        value: pcsRt.PF,
        loading: isPCSLoading('PF'),
        topicTooltip: getTopicForTooltip('PF', topicDict),
      },
      {
        field: 'Ia',
        value: pcsRt.AphA,
        loading: isPCSLoading('AphA'),
        topicTooltip: getTopicForTooltip('AphA', topicDict),
      },
      { field: ' ', value: ' ' },
      {
        field: 'Chg Power Limit ',
        value: pcsRt.PCS_MinP,
        loading: isPCSLoading('PCS_MinP'),
        topicTooltip: getTopicForTooltip('PCS_MinP', topicDict),
      },
      { field: ' ', value: ' ' },
      {
        field: 'Ib',
        value: pcsRt.AphB,
        loading: isPCSLoading('AphB'),
        topicTooltip: getTopicForTooltip('AphB', topicDict),
      },
      { field: ' ', value: ' ' },
      {
        field: 'Dchg Power Limit',
        value: pcsRt.PCS_MaxP,
        loading: isPCSLoading('PCS_MaxP'),
        topicTooltip: getTopicForTooltip('PCS_MaxP', topicDict),
      },
      { field: ' ', value: ' ' },
      {
        field: 'Ic',
        value: pcsRt.AphC,
        loading: isPCSLoading('AphC'),
        topicTooltip: getTopicForTooltip('AphC', topicDict),
      },
    ];
  };

  const isPCSLoading = (field) => {
    return !Object.keys(pcsRt).includes(field);
  };

  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';

  const title =
    fromPV && titleOverride && titleOverride !== 'PCS'
      ? titleOverride + ' Status'
      : `${unitName && unitName + ' '}PCS ${pcsIndex + 1} Status${
          pcsType ? ` - ${pcsType}` : ''
        }`;

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell unit-cell'>
        <div className='unit-info'>
          <div className='unit-col'>
            <div className='title-large'>
              {title}
              <span
                style={{
                  marginLeft: '10px',
                  opacity: '0.8',
                  fontWeight: 'normal',
                }}
              >
                {pcsRt.Description}
              </span>
            </div>
            <div className='info-grid pcs'>
              {getInfoDict().map((f, i) => (
                <React.Fragment key={i}>
                  <div className='title'>{f.field}</div>
                  <div className={f.class}>
                    <TextLoader
                      toolTipContent={f.topicTooltip}
                      orientation='l'
                      loading={f.loading}
                    >
                      {f.value}
                    </TextLoader>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(PCSContainerSungrowBESS, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
  mqtt: ['topicDict'],
});
