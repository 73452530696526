import React, { useEffect, useState } from 'react';
import { useStateCallback } from '@utils';

const TableFields = ({ settings, onTableUpdate, property, section }) => {
  const [newSettings, setNewSettings] = useStateCallback(null);
  const [rangeFields, setRangeFields] = useState({});
  const [arrListFields, setArrListFields] = useState({});
  const [arrBoolFields, setArrBoolFields] = useState([]);

  useEffect(() => {
    loadItem(settings);
  }, []);

  const loadItem = (item) => {
    const newSettings = [...item];

    const arrBoolFields = [];
    const arrListFields = {};
    const rangeFields = {};

    Object.keys(newSettings[0]).forEach((prop) => {
      if (prop.endsWith('_list')) {
        arrListFields[prop.replace('_list', '')] = prop;
      }
      if (prop.endsWith('_range')) {
        rangeFields[prop.replace('_range', '')] = prop;
      }
      if (prop.endsWith('_type') && newSettings[0][prop] === 'bool') {
        arrBoolFields.push(prop.replace('_type', ''));
      }
    });

    setNewSettings(newSettings);
    setRangeFields(rangeFields);
    setArrListFields(arrListFields);
    setArrBoolFields(arrBoolFields);
  };

  const minMax = (rangeField) => {
    const result = (rangeField + '').split(',');
    return +result[0] < +result[1] ? result : result.reverse();
  };

  const validValue = (index, prop) => {
    if (!rangeFields[prop]) {
      return true;
    }
    const value = newSettings[index][prop];
    if (value === '') {
      return false;
    }
    const range = minMax(newSettings[index][rangeFields[prop]]);
    return value >= +range[0] && value <= +range[1];
  };

  const hasInvalidValue = () => {
    if (!newSettings) {
      return true;
    }
    const result = newSettings.map((_row, rowIndex) => {
      const middleResult = Object.keys(newSettings[0]).map((propField) => {
        return validValue(rowIndex, propField);
      });
      return !middleResult.includes(false);
    });
    return result.includes(false);
  };
  const updateTable = (value, row, field) => {
    const newSettingsCopy = [...newSettings];
    if (arrBoolFields.includes(field)) {
      value = newSettingsCopy[row][field] === '0' ? '1' : '0';
      newSettingsCopy[row][field] = +value;
    } else {
      newSettingsCopy[row][field] = value;
    }
    onTableUpdate(newSettingsCopy, hasInvalidValue(), section, property);
  };

  return (
    newSettings && (
      <div
        className='table-scroll-input table-input'
        onScroll={(e) => {
          document
            .querySelectorAll('.selectize-dropdown')
            .forEach(
              (el) =>
                (el.style['margin-left'] = `-${e.target.scrollLeft + 400}px`),
            );
        }}
      >
        {newSettings && settings && (
          <table className='table-conf'>
            <tbody>
              <tr
                key={`thr-${section.replace(/ +?/g, '')}
                  ${property ? `-${property.replace(/ +?/g, '')}` : ''}`}
              >
                {Object.keys(newSettings[0]).map((headerName) => {
                  return headerName.includes('_type') ||
                    headerName.includes('_list') ||
                    headerName.includes('_range') ? null : (
                    <th
                      className='th-conf'
                      key={`th-${headerName.replace(/ +?/g, '')}`}
                    >
                      {headerName}
                    </th>
                  );
                })}
              </tr>
              {newSettings.map((_row, rowIndex) => {
                return (
                  <tr key={`tr-${rowIndex}`}>
                    {Object.keys(newSettings[0]).map((propField, propIndex) => {
                      return !propField.endsWith('_range') &&
                        !propField.endsWith('_list') &&
                        !propField.endsWith('_type') ? (
                        <td
                          className='td-conf'
                          key={`td-${section.replace(/ +?/g, '')}-
                            ${
                              property ? property.replace(/ +?/g, '') + '-' : ''
                            }
                            ${propField.replace(/ +?/g, '')}-${propIndex}`}
                        >
                          {rangeFields[propField] && (
                            <>
                              <span className='small-val'>
                                {
                                  minMax(
                                    newSettings[rowIndex][
                                      rangeFields[propField]
                                    ],
                                  )[0]
                                }
                                &nbsp;to&nbsp;
                                {
                                  minMax(
                                    newSettings[rowIndex][
                                      rangeFields[propField]
                                    ],
                                  )[1]
                                }
                              </span>
                              &nbsp;
                            </>
                          )}
                          {arrListFields[propField] ? (
                            <select
                              className='select-dropdown'
                              value={newSettings[rowIndex][propField]}
                              onChange={(e) => {
                                if (e.target.value !== '') {
                                  updateTable(
                                    e.target.value,
                                    rowIndex,
                                    propField,
                                  );
                                } else {
                                  setNewSettings(
                                    (newSettings) => [...newSettings],
                                    (newSettings) => {
                                      updateTable(
                                        newSettings[rowIndex][propField],
                                        rowIndex,
                                        propField,
                                      );
                                    },
                                  );
                                }
                              }}
                            >
                              {newSettings[rowIndex][arrListFields[propField]]
                                .split(',')
                                .map((v) => (
                                  <option key={v} value={v}>
                                    {v}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <input
                              type={
                                arrBoolFields.includes(propField)
                                  ? 'checkbox'
                                  : rangeFields[propField]
                                    ? 'number'
                                    : 'text'
                              }
                              className={
                                'comand-popup-inp ' +
                                (!validValue(rowIndex, propField)
                                  ? 'error'
                                  : '')
                              }
                              defaultChecked={
                                newSettings[rowIndex][propField] !== '0'
                              }
                              onChange={(e) => {
                                updateTable(
                                  e.target.value,
                                  rowIndex,
                                  propField,
                                );
                              }}
                              defaultValue={
                                newSettings[rowIndex][propField]
                                  ? newSettings[rowIndex][propField]
                                  : ''
                              }
                            />
                          )}
                        </td>
                      ) : null;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    )
  );
};

export default TableFields;
