import React, { useEffect, useState } from 'react';
import './index.scoped.scss';
import Input from '@components/_elements/Input/Input';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';

const AlertsConfig = ({ siteMeta, actions }) => {
  const [dataAll, setDataAll] = useState(null);
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [loadingFaults, setLoadingFaults] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(0);
  const [deviceIds, setDeviceIds] = useState();

  useEffect(() => {
    (async () => {
      const deviceIds = [siteMeta.siteId, ...siteMeta.Units];
      const loadingFaultsInit = {};
      deviceIds.forEach((id) => (loadingFaultsInit[id] = true));
      setLoadingUnits(false);
      setLoadingFaults(loadingFaultsInit);
      setDeviceIds(deviceIds);
      const configs = await actions.getCurrentFaultConfigAll(deviceIds);
      configs.forEach((conf) => {
        const dataAllCopy = {};
        if (conf.deviceId) {
          dataAllCopy[conf.deviceId] = conf.Faults;
        }
        const newLoadingFaults = {};
        newLoadingFaults[conf.deviceId] = false;
        setDataAll((dataAll) => ({ ...dataAll, ...dataAllCopy }));
        setLoadingFaults((loadingFaults) => ({
          ...loadingFaults,
          ...newLoadingFaults,
        }));
      });
    })();
  }, []);

  const activeSn = deviceIds ? deviceIds[selectedUnit] : null;
  const activeFaults =
    activeSn && dataAll && dataAll[activeSn] ? dataAll[activeSn] : [];
  const fieldsArr = activeFaults.reduce((acc, cv) => {
    return [...acc, ...Object.keys(cv).filter((f) => !acc.includes(f))];
  }, []);
  const units = siteMeta
    ? ['Site', ...siteMeta.UnitNames.map((name) => `UNIT ${name}`)]
    : [];
  const ends = ['_range', '_type', '_list'];
  const filteredFields = fieldsArr.filter(
    (f) => !ends.find((e) => f.endsWith(e)),
  );
  return (
    <div className='home-row'>
      {!loadingUnits && !loadingFaults[activeSn] ? (
        <div className='fcol h100'>
          <div className='container-alarm-faults cell block-container'>
            <div className='scroll-container'>
              <div className='select-row' style={{ width: '160px' }}>
                <Input
                  type='dropdown'
                  placeholder='Select unit'
                  value={{ index: selectedUnit }}
                  onChange={(val) => setSelectedUnit(val)}
                  options={units}
                />
              </div>
              {loadingFaults[activeSn] ? (
                <Spinner cover='container' />
              ) : (
                <>
                  {filteredFields && filteredFields.length ? (
                    <table>
                      <thead>
                        <tr>
                          <th />
                          {filteredFields.map((f, i) => (
                            <th key={i}>{f}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {activeFaults.map((r, i) => (
                          <tr key={i}>
                            {i === 0 && (
                              <td rowSpan={`${activeFaults.length}`}>
                                <span>Faults</span>
                              </td>
                            )}
                            {filteredFields.map((f, j) => (
                              <td key={j}>
                                <>
                                  {fieldsArr.includes(f + '_type') &&
                                  r[f + '_type'] === 'bool' ? (
                                    <>{+r[f] ? 'yes' : 'no'}</>
                                  ) : (
                                    <>{r[f]}</>
                                  )}
                                </>
                              </td>
                            ))}
                          </tr>
                        ))}
                        <tr />
                      </tbody>
                    </table>
                  ) : (
                    <div className='placeholder-no-data'> NO DATA</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Spinner type={'fullPage'} />
      )}
    </div>
  );
};

export default storeConnector(AlertsConfig, {
  config: ['siteMeta'],
});
