import { NavLink } from 'react-router-dom';
import React from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';

function TopBar({ siteMeta }: { siteMeta: SiteMeta }) {
  return (
    <div className='topbar'>
      <div className='frame'>
        {siteMeta.ui.TabsList?.PV?.map((configuredTab) => (
          <NavLink
            data-test={`nav-sun-${configuredTab.toLowerCase()}`}
            key={`nav-sun-${configuredTab.toLowerCase()}`}
            to={`/sun/${configuredTab.toLowerCase()}`}
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            {configuredTab.toUpperCase()}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default storeConnector(TopBar, {
  config: ['siteMeta'],
});
