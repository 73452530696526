import React, { useEffect } from 'react';
import WeatherForecastWidget from '@components/Home/WeatherForecastWidget/WeatherForecastWidget';
import SunPositionOverlay from '@components/Home/SunPositionOverlay/SunPositionOverlay';
import WeatherStation from './WeatherStation';
import storeConnector from '@store/storeConnector';

const WeatherWidget = ({ actions, hideForecast }) => {
  useEffect(() => {
    actions.getWeather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='block-container sys-meter-mark cell'
      style={{ padding: 0, maxHeight: 'unset' }}
    >
      <div className='weather-row' style={{ width: '100%' }}>
        {!hideForecast && <WeatherForecastWidget forecast={true} />}
        <SunPositionOverlay width={300} />
        <WeatherStation />
      </div>
    </div>
  );
};

export default storeConnector(WeatherWidget, {});
