import React, { useEffect, useRef, useState } from 'react';
import './StackPreview.scoped.scss';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';
import ApplicationPreview from '@components/Home/Config/ApplicationPreview/ApplicationPreview';

const StackPreview = ({ actions, siteMeta, apps, stackName, stackid }) => {
  const [stack, setStack] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const prevStackid = useRef(false);

  useEffect(() => {
    if (apps) {
      parceApp(apps, stackName);
    } else {
      loadStack(stackid);
    }
  });

  const parceApp = (apps, stackName) => {
    if (!apps || prevStackid.current === apps) {
      return;
    }
    prevStackid.current = apps;
    setStack(stackName);
    setApplicationList(apps);
  };

  const loadStack = async (id) => {
    if (!id || prevStackid.current === id) {
      return;
    }
    setStack(false);
    prevStackid.current = id;
    const res = await actions.getStack(siteMeta.siteId, id);
    if (res && !res.error) {
      setStack(res);
      setApplicationList(res.applications);
    }
  };

  return (
    <div className='stack-details'>
      {stack ? (
        <>
          <div className='stack-title'>
            Details of stack{' '}
            <b>{(typeof stack === 'object' && stack.name) || stack}</b>
          </div>
          <div className='application-list'>
            {applicationList.map((app, i) => (
              <ApplicationPreview key={i} application={app} />
            ))}
          </div>
        </>
      ) : (
        <Spinner cover='container' />
      )}
    </div>
  );
};

export default storeConnector(StackPreview, {
  config: ['siteMeta'],
});
