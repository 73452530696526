const Colors: string[] = [
  '255, 91, 215',
  '138, 43, 226',
  '252, 67, 73',
  '0, 123, 255',
  '225, 206, 49',
  '49, 225, 3',
  '191, 77, 216',
  '77, 216, 217',
  '250, 150, 50',
  '233, 195, 164',
];

const ColorsMultiLine: string[][] = [
  ['255, 91, 215', '255, 173, 235', '173, 0, 130', '84, 0, 80'],
  ['138, 43, 226', '196, 148, 240', '69, 16, 118', '41, 0, 84'],
  ['252, 67, 73', '253, 161, 163', '156, 2, 7', '84, 0, 18'],
  ['0, 123, 255', '127, 189, 255', '15, 0, 112', '8, 0, 59'],
  ['225, 206, 49', '255, 230, 152', '119, 108, 17', '59, 51, 0'],
  ['49, 225, 3', '152, 255, 129', '24, 112, 1', '13, 59, 0'],
  ['191, 77, 216', '223, 165, 235', '103, 26, 120', '44, 0, 59'],
  ['77, 216, 217', '165, 235, 236', '25, 120, 121', '0, 56, 59'],
  ['250, 150, 50', '252, 202, 152', '146, 75, 3', '59, 32, 0'],
  ['233, 195, 164', '244, 244, 209', '159, 93, 38', '92, 58, 17'],
];

type ChartDataElement = {
  result: string;
  table: number;
  _time: string;
  [key: number]: number;
};

type ChartQuery = {
  dateFrom: string;
  dateTo: string;
  interval: {
    value: number;
    unit: string;
  };
  aggregateFunctions: string[];
  criteria: UnitCriteria[];
};

type UnitCriteria = {
  category: string;
  siteId: string;
  field: string;
  axis: string;
  unitId: string;
  sourceDeviceId: string;
  dataType: string;
  deviceId: string | null;
};

type XAxisType = {
  type: string;
  title: {
    text: string;
  };
};

type YAxisTypeItem = {
  title: { text: string };
  opposite: boolean;
};

type SeriesItem = {
  id: number;
  name: string;
  data: number[][];
  sourceDeviceId: string;
  deviceId: string;
  category: string;
  field: string;
  aggr: string;
  color: string;
  dataType: string;
};

type YAxisType = YAxisTypeItem[];

type ChartRequest = {
  dateFrom: string;
  dateTo: string;
  interval: {
    value: number;
    unit: string;
  };
  aggregateFunctions: string[];
  criteria: UnitCriteria[];
};

type ChartOptionsEMS = {
  exporting: {
    filename: string;
    csv: {
      dateFormat: string;
    };
    enabled: boolean;
  };
  credits: {
    enabled: boolean;
  };
  chart: {
    zoomType: string;
    height: string;
  };
  xAxis: XAxisType;
  yAxis: YAxisType | [];
  title: { text: string };
  time: {
    useUTC: boolean;
    timezoneOffset: number;
  };
  series: SeriesItem[];
};

type ChartOptionsWithCriteria = {
  chartOptions: ChartOptionsEMS;
  chartRequest: ChartRequest;
};

export { Colors, ColorsMultiLine };
export type {
  ChartOptionsEMS,
  ChartOptionsWithCriteria,
  ChartRequest,
  UnitCriteria,
  ChartQuery,
  ChartDataElement,
  XAxisType,
  YAxisTypeItem,
  YAxisType,
  SeriesItem,
};
