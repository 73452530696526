import React, { useEffect, useRef, useState } from 'react';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { useInterval } from '@utils';
import { CLOU_BATTERY_FIELDS } from './constants.js';
import storeConnector from '@store/storeConnector';
import RackTable from '@components/Battery/RackContainers/vendors/clou/RackTable';

function getKey(useNewTopicStructure, key, prefix) {
  return useNewTopicStructure ? key : `${prefix}${key}`;
}

const RackContainer_Clou = ({
  siteMeta,
  currentUnitDeviceId,
  fromPV,
  bmsId,
  bmsIndex,
}) => {
  const { Use_New_Topic_Structure: useNewTopicStructure } = siteMeta.ui;
  const [currHBInterval, setCurrHBInterval] = useState(0);
  const [prevHB, setPrevHB] = useState(0);
  const [prevCounter, setPrevCounter] = useState(0);
  const [batteryRt, setBatteryRt] = useState({});
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  useEffect(() => {
    setBatteryRt({});
  }, [currentUnitDeviceId]);

  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const uiOrderNoArr = Array.from(
    { length: siteMeta.Batt_perUnit_BMS_count },
    (_v, i) => i + 1 + siteMeta.Batt_perUnit_BMS_count * unitNameIdx,
  );
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';

  useWsSubscribe(
    {
      bms: {
        sns: [currentUnitDeviceId],
        sourceDeviceId: `bms_${bmsIndex + 1}`,
        fields: CLOU_BATTERY_FIELDS,
        cb: (data) => {
          setBatteryRt((prev) => ({ ...prev, ...data }));
        },
      },
    },
    [currentUnitDeviceId],
  );

  useInterval(() => {
    if (batteryRt?.[getKey(useNewTopicStructure, 'SysHB', bmsId)]) {
      if (batteryRt[getKey(useNewTopicStructure, 'SysHB', bmsId)] !== prevHB) {
        setCurrHBInterval(0);
        setPrevHB(batteryRt[getKey(useNewTopicStructure, 'SysHB', bmsId)]);
      } else {
        setCurrHBInterval((currHBInterval) => currHBInterval + 1);
      }
    } else {
      setCurrHBInterval(0);
      setPrevHB(0);
      if (batteryRt?.Counter && batteryRt.Counter !== prevCounter) {
        setPrevCounter(batteryRt.Counter);
      }
    }
  }, 1000);

  const formatValue = (val, toFixed) => {
    if ([undefined, null, NaN].includes(val) || (!+val && +val !== 0)) {
      return '-';
    }
    return toFixed ? (+val).toFixed(toFixed) : +val;
  };

  const getInfoDict = () => {
    const {
      Thresh_CellTMin,
      Thresh_CellVLow,
      Thresh_HB_BMS_TimeOut,
      Thresh_LowerSOC,
      Thresh_UpperSOC,
      Thresh_LowerSOH,
    } = siteMeta ? siteMeta : {};
    return [
      {
        field: 'Power (kW)',
        value: formatValue(
          batteryRt[getKey(useNewTopicStructure, 'SyskW', bmsId)],
          1,
        ),
        loading: isBatteryLoading('SyskW'),
      },
      {
        field: 'System Voltage (V)',
        value: batteryRt.SysVoltage,
        loading: isBatteryLoading('SysVoltage'),
        expectedSize: 5,
      },
      {
        field: 'Max ModV',
        value: formatValue(
          batteryRt[getKey(useNewTopicStructure, 'maxmodv', bmsId)],
          1,
        ),
        loading: isBatteryLoading('maxmodv'),
        expectedSize: 4,
      },
      {
        field: 'Max ModT',
        value: batteryRt[getKey(useNewTopicStructure, 'maxmodt', bmsId)],
        loading: isBatteryLoading('maxmodt'),
      },
      {
        field: 'System SOC',
        value: batteryRt[getKey(useNewTopicStructure, 'SysSOC', bmsId)],
        class:
          (+batteryRt.SysSOC > +Thresh_UpperSOC ? 'red-txt' : '') +
          (+batteryRt.SysSOC < +Thresh_LowerSOC ? 'blue-text' : ''),
        loading: isBatteryLoading('SysSOC'),
      },
      {
        field: 'System Current (A)',
        value: batteryRt[getKey(useNewTopicStructure, 'SysCurrent', bmsId)],
        loading: isBatteryLoading('SysCurrent'),
      },
      {
        field: 'Max ModV Pos',
        value: batteryRt[getKey(useNewTopicStructure, 'maxmodv_pos', bmsId)],
        loading: isBatteryLoading('maxmodv_pos'),
        expectedSize: 4,
      },
      {
        field: 'Max ModT Pos',
        value: batteryRt[getKey(useNewTopicStructure, 'madmodt_pos', bmsId)],
        loading: isBatteryLoading('madmodt_pos'),
      },
      {
        field: 'System SOH',
        value: batteryRt[getKey(useNewTopicStructure, 'SysSOH', bmsId)],
        class: +batteryRt.SysSOH < +Thresh_LowerSOH ? 'red-txt' : '',
        loading: isBatteryLoading('SysSOH'),
      },
      {
        field: 'Chrg Power Lim',
        value: batteryRt[getKey(useNewTopicStructure, 'battery_MinP', bmsId)],
        loading: isBatteryLoading('battery_MinP'),
        expectedSize: 5,
      },
      {
        field: 'Min ModV',
        value: batteryRt[getKey(useNewTopicStructure, 'minmodv', bmsId)],
        class: +batteryRt.minmodv < +Thresh_CellVLow ? 'blue-text' : '',
        loading: isBatteryLoading('minmodv'),
        expectedSize: 3,
      },
      {
        field: 'Min ModT',
        value: batteryRt[getKey(useNewTopicStructure, 'minmodt', bmsId)],
        class: +batteryRt.minmodt < +Thresh_CellTMin ? 'blue-text' : '',
        loading: isBatteryLoading('minmodt'),
      },
      {
        field: 'BMS HB',
        value: batteryRt[getKey(useNewTopicStructure, 'SysHB', bmsId)],
        class: currHBInterval > +Thresh_HB_BMS_TimeOut ? 'red-txt' : '',
        loading: isBatteryLoading('SysHB'),
        expectedSize: 7,
      },
      {
        field: 'DisChrg Power Lim',
        value: batteryRt[getKey(useNewTopicStructure, 'battery_MaxP', bmsId)],
        loading: isBatteryLoading('battery_MaxP'),
        expectedSize: 5,
      },
      {
        field: 'Min ModV Pos',
        value: batteryRt[getKey(useNewTopicStructure, 'minmodv_pos', bmsId)],
        loading: isBatteryLoading('minmodv_pos'),
        expectedSize: 4,
      },
      {
        field: 'Min ModT Pos',
        value: batteryRt[getKey(useNewTopicStructure, 'mindmodt_pos', bmsId)],
        loading: isBatteryLoading('mindmodt_pos'),
      },
      {
        field: 'BMS Fault State',
        value: batteryRt[getKey(useNewTopicStructure, 'fault_state', bmsId)],
        loading: isBatteryLoading('fault_state'),
        expectedSize: 5,
      },
      {
        field: '',
        value: '',
        loading: false,
        expectedSize: 0,
      },
      {
        field: 'Amb Humidity',
        value: batteryRt[getKey(useNewTopicStructure, 'amb_humidity', bmsId)],
        loading: isBatteryLoading('amb_humidity'),
        expectedSize: 4,
      },
      {
        field: 'Amb Temp',
        value: batteryRt[getKey(useNewTopicStructure, 'amb_temp', bmsId)],
        loading: isBatteryLoading('amb_temp'),
        expectedSize: 4,
      },
      {
        field: 'BMS Alarm State',
        value: batteryRt[getKey(useNewTopicStructure, 'alarm_state', bmsId)],
        loading: isBatteryLoading('alarm_state'),
        expectedSize: 4,
      },
      {
        field: '',
        value: '',
        loading: false,
        expectedSize: 0,
      },
      {
        field: '',
        value: '',
        loading: false,
        expectedSize: 0,
      },
      {
        field: '',
        value: '',
        loading: false,
        expectedSize: 0,
      },
      {
        field: 'Main DC Disconnect',
        value:
          +batteryRt[getKey(useNewTopicStructure, 'circuit_breaker', bmsId)] ===
          1
            ? 'CLOSED'
            : +batteryRt[
                  getKey(useNewTopicStructure, 'circuit_breaker', bmsId)
                ] === 0
              ? 'OPEN'
              : '',
        class:
          +batteryRt[getKey(useNewTopicStructure, 'circuit_breaker', bmsId)] ===
          1
            ? 'red-text'
            : +batteryRt[
                  getKey(useNewTopicStructure, 'circuit_breaker', bmsId)
                ] === 0
              ? 'green-text'
              : '',
        loading: isBatteryLoading('circuit_breaker'),
        expectedSize: 6,
      },
    ];
  };

  const isBatteryLoading = (field) => {
    return !Object.keys(batteryRt).includes(
      getKey(useNewTopicStructure, field, bmsId),
    );
  };

  let dscr = batteryRt?.Description || '';
  if (dscr) {
    dscr = ` - ${dscr}`;
  }

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell'>
        <div className='unit-info'>
          <div className='unit-col' style={{ maxHeight: '100%' }}>
            <div className='title-large'>
              {`${unitName && unitName + ' '}BESS ${uiOrderNoArr[bmsIndex]}${
                dscr || ''
              }`}
            </div>
            {getInfoDict() === null || !getInfoDict().length ? (
              <div className='bms-placeholder'> NO DATA</div>
            ) : (
              <>
                <div className='info-grid bms'>
                  {getInfoDict().map((f, i) => (
                    <React.Fragment key={i}>
                      <div className='title'>{f.field}</div>
                      <div className={f.class}>
                        <TextLoader
                          orientation='l'
                          loading={f.loading}
                          expectedSize={f.expectedSize}
                        >
                          {f.value}
                        </TextLoader>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}
            <div className='rack-table-container'>
              <RackTable bmsId={bmsId} bmsIndex={bmsIndex} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(RackContainer_Clou, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
