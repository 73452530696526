import React from 'react';

function EventTypeCell({ isActive }: { isActive: boolean }) {
  return (
    <span className={isActive ? 'bold-red' : ''}>
      {isActive ? 'Alert On' : 'Alert Off'}
    </span>
  );
}

export default EventTypeCell;
