import React, { useEffect, useMemo, useRef, useState } from 'react';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import { ConverterDetails } from './ConverterDetails';
import { useInterval, isKeyInObject } from '@utils';
import {
  ConverterCount,
  ConverterDetailsAllFields,
  ConverterInfoFields,
  getPCSFields,
  UNIT_FIELDS,
} from './constants.js';
import storeConnector from '@store/storeConnector';

const PCSContainerPEDC = ({
  siteMeta,
  currentUnitDeviceId,
  pcsType,
  fromPV,
  pcsIndex,
}) => {
  const [currHBInterval, setCurrHBInterval] = useState(0);
  const [prevHB, setPrevHB] = useState(0);
  const [selectedConverterIndex, setSelectedConverterIndex] = useState();
  const [unitRt, setUnitRt] = useState({});
  const [pcsRt, setPCSRt] = useState({});
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  useEffect(() => {
    setUnitRt({});
    setPCSRt({});
  }, [currentUnitDeviceId]);

  useWsSubscribe(
    {
      unit: {
        sns: [currentUnitDeviceId],
        fields: UNIT_FIELDS,
        cb: (data) => setUnitRt((prev) => ({ ...prev, ...data })),
      },
      pcs: {
        sns: [currentUnitDeviceId],
        fields: getPCSFields(pcsType),
        sourceDeviceId: `pcs_${pcsIndex + 1}`,
        cb: (data) => setPCSRt((prev) => ({ ...prev, ...data })),
      },
    },
    [currentUnitDeviceId],
  );

  useInterval(() => {
    if (pcsRt && pcsRt.WatchdogResponse !== prevHB) {
      setCurrHBInterval(0);
      setPrevHB(pcsRt.WatchdogResponse);
    } else {
      setCurrHBInterval((currHBInterval) => currHBInterval + 1);
    }
  }, 1000);

  const getInfoDict = () => {
    const { Thresh_HB_PCS_TimeOut } = siteMeta ? siteMeta : {};
    const infoDict = [
      {
        field: 'Status',
        value: unitRt['InvStatus'] || unitRt['Inv Status'],
        loading:
          isKeyInObject(unitRt, 'InvStatus') &&
          isKeyInObject(unitRt, 'Inv Status'),
      },
      { field: 'AC kW', value: pcsRt.kW, loading: isPCSLoading('kW') },
      { field: 'Va', value: pcsRt.PhVphA, loading: isPCSLoading('PhVphA') },
      {
        field: 'DC Disconnect',
        value: pcsRt.DCDisconnect,
        loading: isPCSLoading('DCDisconnect'),
      },
      {
        field: 'Mode',
        value: unitRt['RunMode'] || unitRt['Run Mode'],
        loading:
          isKeyInObject(unitRt, 'RunMode') && isKeyInObject(unitRt, 'Run Mode'),
      },
      { field: 'AC kVAR', value: pcsRt.kVAR, loading: isPCSLoading('kVAR') },
      { field: 'Vb', value: pcsRt.PhVphB, loading: isPCSLoading('PhVphB') },
      { field: 'DC kW', value: pcsRt.DCkW, loading: isPCSLoading('DCkW') },
      {
        field: 'AC Breaker',
        value:
          parseInt(unitRt['ACBreaker'] || unitRt['AC Breaker']) === 1
            ? 'Closed'
            : parseInt(unitRt['ACBreaker'] || unitRt['AC Breaker']) === 0
              ? 'Open'
              : 'ERR',
        loading:
          isKeyInObject(unitRt, 'ACBreaker') &&
          isKeyInObject(unitRt, 'AC Breaker'),
      },
      { field: 'AC kVA', value: pcsRt.kVA, loading: isPCSLoading('kVA') },
      { field: 'Vc', value: pcsRt.PhVphC, loading: isPCSLoading('PhVphC') },
      {
        field: 'DC Bus Voltage',
        value: pcsRt.DCV,
        loading: isPCSLoading('DCV'),
      },
      { field: 'Frequency', value: pcsRt.Hz, loading: isPCSLoading('Hz') },
      { field: 'PF', value: pcsRt.PF, loading: isPCSLoading('PF') },
      { field: 'Ia', value: pcsRt.AphA, loading: isPCSLoading('AphA') },
      { field: 'DC Amps', value: pcsRt.DCA, loading: isPCSLoading('DCA') },
      {
        field: 'PCS Heartbeat',
        value: pcsRt.WatchdogResponse || '-',
        class:
          currHBInterval > +Thresh_HB_PCS_TimeOut && prevHB !== 0
            ? 'bold red-txt'
            : '',
        loading: isPCSLoading('WatchdogResponse'),
      },
      { field: ' ', value: ' ' },
      { field: 'Ib', value: pcsRt.AphB, loading: isPCSLoading('AphB') },
      { field: ' ', value: ' ' },
      {
        field: 'Chg Power Limit ',
        value: pcsRt.PCS_MinP,
        loading: isPCSLoading('PCS_MinP'),
      },
      { field: ' ', value: ' ' },
      { field: 'Ic', value: pcsRt.AphC, loading: isPCSLoading('AphC') },
      { field: ' ', value: ' ' },
      {
        field: 'Dchg Power Limit',
        value: pcsRt.PCS_MaxP,
        loading: isPCSLoading('PCS_MaxP'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'kW Cmd',
        value: unitRt.PCS_P,
        loading: isKeyInObject(unitRt, 'PCS_P'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'kVAR Cmd',
        value: unitRt.PCS_Q,
        loading: isKeyInObject(unitRt, 'PCS_Q'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Mod St',
        value: unitRt.ModSt,
        loading: isKeyInObject(unitRt, 'ModSt'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Mod P',
        value: unitRt.ModP,
        loading: isKeyInObject(unitRt, 'ModP'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Current Status',
        value: unitRt.Status,
        loading: isKeyInObject(unitRt, 'Status'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Current Warning',
        value: unitRt.Warn,
        loading: isKeyInObject(unitRt, 'Warn'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Current Fault',
        value: unitRt.Flt,
        loading: isKeyInObject(unitRt, 'Flt'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'PV Curtailed',
        value: pcsRt.PV_Curtailed,
        loading: isKeyInObject(unitRt, 'PV_Curtailed'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
    ];
    return infoDict;
  };

  const isPCSLoading = (field) => {
    return !Object.keys(pcsRt).includes(field);
  };

  const converters = useMemo(() => {
    if (!pcsRt) {
      return [];
    }
    const result = [];
    for (let i = 0; i < ConverterCount; i++) {
      result.push(
        ConverterDetailsAllFields.reduce(
          (prev, converterField) => ({
            ...prev,
            [converterField.field]: {
              value: pcsRt[converterField.field]?.[i],
              loading: isPCSLoading(converterField.field),
            },
          }),
          {},
        ),
      );
    }

    return result;
  }, [pcsRt]);

  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell unit-cell'>
        <div className='unit-info'>
          <div className='unit-col'>
            <div className='title-large'>
              {`${unitName && unitName + ' '}PCS Status${
                pcsType ? ` - ${pcsType}` : ''
              }`}
              <span
                style={{
                  marginLeft: '10px',
                  opacity: '0.8',
                  fontWeight: 'normal',
                }}
              >
                {pcsRt.Description}
              </span>
            </div>
            <div className='info-grid pcs'>
              {getInfoDict().map((f, i) => (
                <React.Fragment key={`field-row-${f.field}-${i}`}>
                  <div className='title'>{f.field}</div>
                  <div className={f.class}>
                    <TextLoader orientation='l' loading={f.loading}>
                      {f.value}
                    </TextLoader>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className='converter-table-container'>
              <table className='converter-table'>
                <thead>
                  <tr>
                    <th className='converter-number-cell'>Converter #</th>
                    {ConverterInfoFields.map((converterField) => (
                      <th key={`conv-header-cell-${converterField.label}`}>
                        {converterField.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {converters.map((converterData, converterIndex) => (
                    <tr key={`conv-row-${converterIndex}`}>
                      <td
                        className='converter-number-cell'
                        onClick={() =>
                          setSelectedConverterIndex(converterIndex)
                        }
                      >
                        {converterIndex + 1}
                      </td>
                      {ConverterInfoFields.map((converterField) => (
                        <td
                          key={`conv-info-cell-${converterIndex}-${converterField.field}`}
                        >
                          <TextLoader
                            orientation='c'
                            loading={
                              converterData[converterField.field].loading
                            }
                          >
                            {converterData[converterField.field].value}
                          </TextLoader>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {selectedConverterIndex !== undefined &&
              converters[selectedConverterIndex] && (
                <ConverterDetails
                  converter={converters[selectedConverterIndex]}
                  converterIndex={selectedConverterIndex}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(PCSContainerPEDC, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
