import React, { useState } from 'react';
import './TrackerTableItem.scoped.scss';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';
import TextLoader from '@components/_elements/TextLoader';
import { isKeyInObject } from '@src/utils';

const MasterTrackerTableItem = ({ siteMeta, sn, setActiveTrackerSN }) => {
  const [unit, setUnit] = useState({});
  useWsSubscribe({
    master_tracker: {
      sns: [sn],
      sourceDeviceId: 'master_tracker_1',
      fields: [
        'status',
        'stow_status',
        'avg_angle',
        'target_angle',
        'wind_instant',
      ],
      cb: (data) =>
        setUnit((prev) => {
          return { ...prev, ...data };
        }),
    },
  });

  const rndrUnitField = (
    field,
    size,
    invStyle,
    placeholder = '-',
    orientation = 'r',
    after,
  ) => {
    return (
      <TextLoader
        orientation={orientation}
        fontSize={size}
        loading={isKeyInObject(unit, field)}
        invertedCcolor={invStyle}
        after={after}
      >
        {unit[field] !== undefined && unit[field] !== null ? (
          <>
            {unit[field]}
            {!!after && <>&nbsp;{after}</>}
          </>
        ) : (
          placeholder
        )}
      </TextLoader>
    );
  };

  return (
    <React.Fragment key={sn}>
      <tr style={{ height: '40px' }}>
        <td
          className='cell-with-cursor-pointer'
          onClick={() => {
            setActiveTrackerSN(siteMeta.siteId);
          }}
        >
          <b>Master</b>
        </td>
        <td>{rndrUnitField('status', 13, false, '-', 'c')}</td>
        <td>{rndrUnitField('stow_status', 13, false, '-', 'c')}</td>
        <td>{rndrUnitField('avg_angle', 13, false, '-', 'c')}</td>
        <td>{rndrUnitField('target_angle', 13, false, '-', 'c')}</td>
        <td>{rndrUnitField('wind_instant', 13, false, '-', 'c')}</td>
      </tr>
    </React.Fragment>
  );
};

export default storeConnector(MasterTrackerTableItem, {
  user: ['role'],
  config: ['siteMeta'],
  service: ['timeMode'],
});
