import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

const Stack = ({
  searchStr,
  name,
  onViewClick,
  selected,
  id,
  onDeleteClick,
}) => {
  const nameParts = searchStr
    ? name.split(new RegExp(`(${searchStr})`, 'gi'))
    : [name];
  return (
    <div className={`stack-style ${selected && 'selected'}`}>
      <Link
        className='stack-name'
        style={{ cursor: 'pointer' }}
        to={`/home/config/stacks/${id}`}
      >
        {nameParts.map((p, i) => (
          <span
            key={i}
            className={
              p.toUpperCase() === searchStr.toUpperCase() ? 'highlight' : ''
            }
          >
            {p}
          </span>
        ))}
      </Link>
      <div className='stack-icons'>
        <div className='clickable-icon' onClick={() => onViewClick(id)}>
          <FontAwesomeIcon
            className='stack-icons-icon'
            icon={selected ? faEyeSlash : faEye}
          />
        </div>
        <div className='clickable-icon'>
          <Link to={`/home/config/stacks/${id}`}>
            <FontAwesomeIcon className='stack-icons-icon' icon={faPencilAlt} />
          </Link>
        </div>
        <div className='clickable-icon' onClick={() => onDeleteClick(id)}>
          <FontAwesomeIcon className='stack-icons-icon' icon={faTrashAlt} />
        </div>
      </div>
    </div>
  );
};

export default Stack;
