import alerts from '@store/actionCreators/alerts';
import { calendar } from '@store/actionCreators/calendar';
import { config } from '@store/actionCreators/config';
import { devices } from '@store/actionCreators/devices';
import { historian } from '@store/actionCreators/historian';
import {
  publishMQTT,
  componentConnect,
  componentDisconnect,
  useWsSubscribe,
} from '@store/actionCreators/mqtt';
import * as notifications from '@store/actionCreators/notifications';
import { otherActions } from '@store/actionCreators/otherActions';
import { service } from '@store/actionCreators/service';
import { user } from '@store/actionCreators/user';

export default {
  ...alerts,
  ...calendar,
  ...config,
  ...devices,
  ...historian,
  publishMQTT,
  componentConnect,
  componentDisconnect,
  useWsSubscribe,
  ...notifications,
  ...otherActions,
  ...service,
  ...user,
};
