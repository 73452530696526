import React from 'react';
import { UserState } from '@store/reducers/userData';
import storeConnector from '@store/storeConnector';
import Button from '@components/_elements/Button/Button';
import ActionCreators from '@store/actionCreators';
import { UserRole } from '@src/services/auth/roles';

interface UserInfoProps {
  actions: { logout: ReturnType<typeof ActionCreators.logout> };
  role: UserRole;
  email: UserState['email'];
  username: UserState['username'];
}

function UserInfo({ role, email, username, actions }: UserInfoProps) {
  return (
    <div className='user-info'>
      <div className='bold'>{username}</div>
      <div>{email}</div>
      <div>{role}</div>
      <Button
        className='btn btn-danger btn-lg logout-btn'
        onClick={() => actions.logout()}
      >
        Log out
      </Button>
    </div>
  );
}

export default storeConnector(UserInfo, {
  user: ['role', 'email', 'username'],
});
