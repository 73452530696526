import prepHtmlDocumentLoader from '@pages/_loader/prepHtmlDocument';
import { SiteMeta } from '@src/types/SiteMeta';
import initKeycloak from './initKeycloak.loader';
import initMqttClient from './initMqttClient.loader';
import loadSiteMeta from './loadSiteMeta.loader';
import loadAlertsData from './loadAlertsData.loader';
import initI18N from './initI18N.loader';

export default async function protectedLoader(): Promise<SiteMeta> {
  let siteMeta;
  try {
    await initKeycloak();
    siteMeta = await loadSiteMeta();
    await initMqttClient();
    await loadAlertsData();
    await initI18N();
  } catch (e) {
    prepHtmlDocumentLoader().then();
    throw e;
  }
  return siteMeta;
}
