/* eslint-disable max-lines */
const tsc_config = [
  {
    'Control Mode': 'Battery Direct Real Power',
    'Point Label': 'Battery Direct Real Power Command (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20201_battery_direct_real_power_command/float',
    'CMD Payload':
      'PCS1_Holding_20201_Battery Direct Real Power Command_Write::[input value]',
    Units: 'W',
    Description:
      'Direct real power command to the system. Positive = discharge and negative = charge',
    'Register Address': 20201,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Direct Real Power',
    'Point Label': 'Battery Direct Real Power Ramp Rate (W/s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20203_battery_direct_real_power_ramp_rate/float',
    'CMD Payload':
      'PCS1_Holding_20203_Battery Direct Real Power Ramp Rate_Write::[input value]',
    Units: 'W/s',
    Description:
      'Ramp Rate of the power command, both for ramping up and down. Must be greater than zero to allow any direct real power',
    'Register Address': 20203,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },

  {
    'Control Mode': 'Maintain Energy',
    'Point Label': 'Enable Maintain Energy',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20301_maintain_energy_enable/float',
    'CMD Payload':
      'PCS1_Holding_20301_Maintain Energy Enable_Write::[input value]',
    Units: 'bool',
    Description: 'Enable Maintain Energy with 1, disable with 0.',
    'Register Address': 20301,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Maintain Energy',
    'Point Label': 'Maintain Energy Target (Wh)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20302_maintain_energy_target/float',
    'CMD Payload':
      'PCS1_Holding_20302_Maintain Energy Target_Write::[input value]',
    Units: 'Wh',
    Description:
      'The target energy. The system will charge/discharge until the Maintain Energy Target is met by the Remaining Battery Energy value',
    'Register Address': 20302,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Maintain Energy',
    'Point Label': 'Real Maximum (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20306_real_maximum/float',
    'CMD Payload': 'PCS1_Holding_20306_Real Maximum_Write::[input value]',
    Units: 'W',
    Description:
      'Maximum real (discharge) power for the Maintain Energy element. The discharge power set by Maintain Energy will not exceed this value',
    'Register Address': 20306,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Maintain Energy',
    'Point Label': 'Real Minimum (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20308_real_minimum/float',
    'CMD Payload': 'PCS1_Holding_20308_Real Minimum_Write::[input value]',
    Units: 'W',
    Description:
      'Negative value. Minimum real (charge) power for the Maintain Energy element. The charge power set by Maintain Energy will not exceed this value. Sign should be negative.',
    'Register Address': 20308,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },

  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Enable Frequency Droop',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20401_enable_frequency_droop/float',
    'CMD Payload':
      'PCS1_Holding_20401_Enable Frequency Droop_Write::[input value]',
    Units: '',
    Description: 'Enable Frequency Droop with 1, disable with 0',
    'Register Address': 20401,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Reference Frequency  (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20402_reference_frequency/float',
    'CMD Payload':
      'PCS1_Holding_20402_Reference Frequency_Write::[input value]',
    Units: 'Hz',
    Description:
      'The reference frequency for Frequency Droop, Frequency Integral and Grid Forming Applications. For Frequency Droop, Reference Frequency is the frequency around which the droop curve is defined.',
    'Register Address': 20402,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Upper Deadband  (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20404_upper_deadband/float',
    'CMD Payload': 'PCS1_Holding_20404_Upper Deadband _Write::[input value]',
    Units: 'Hz',
    Description:
      'The deviation above the Reference Frequency at which a Frequency Droop response to high frequency will start.',
    'Register Address': 20404,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Lower Deadband (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20406_lower_deadband/float',
    'CMD Payload': 'PCS1_Holding_20406_Lower Deadband_Write::[input value]',
    Units: 'Hz',
    Description:
      'Negative Value. The deviation below the Reference Frequency at which a Frequency Droop response to low frequency will start.',
    'Register Address': 20406,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Proportional Gain Upper (W/Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20408_proportional_gain_upper/float',
    'CMD Payload':
      'PCS1_Holding_20408_Proportional Gain Upper_Write::[input value]',
    Units: 'W/Hz',
    Description:
      'Defines the Power output of Frequency Droop for a frequency deviation above Upper Deadband.',
    'Register Address': 20408,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Proportional Gain Lower (W/Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20410_proportional_gain_lower/float',
    'CMD Payload':
      'PCS1_Holding_20410_Proportional Gain Lower_Write::[input value]',
    Units: 'W/Hz',
    Description:
      'Defines the Power output of Frequency Droop for a frequency deviation, below Lower Deadband.',
    'Register Address': 20410,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Upper Knee Frequency  (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20414_upper_knee_frequency/float',
    'CMD Payload':
      'PCS1_Holding_20414_Upper Knee Frequency _Write::[input value]',
    Units: 'Hz',
    Description:
      'Defines the frequency delta above the Reference Frequency beyond which Proportional Gain Upper 2 applies. Applies only to a 6-point droop curve. Upper Knee should be greater than Upper Deadband.',
    'Register Address': 20414,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Lower Knee Frequency (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20416_lower_knee_frequency/float',
    'CMD Payload':
      'PCS1_Holding_20416_Lower Knee Frequency_Write::[input value]',
    Units: 'Hz',
    Description:
      'Negative Value. Defines the frequency delta below the Reference Frequency beyond which Proportional Gain Lower 2 applies. The absolute value of Lower Knee should be greater than the absolute value of Lower Deadband. Applies only to a 6-point droop curve.',
    'Register Address': 20416,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Proportional Gain Upper 2 (W/Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20418_proportional_gain_upper_2/float',
    'CMD Payload':
      'PCS1_Holding_20418_Proportional Gain Upper 2_Write::[input value]',
    Units: 'W/Hz',
    Description:
      'Proportional gain of the droop curve when the Meter Frequency exceeds the Reference Frequency by more than the Upper Knee. Applies only to a 6-point droop curve.',
    'Register Address': 20418,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Proportional Gain Lower 2 (W/Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20420_proportional_gain_lower_2/float',
    'CMD Payload':
      'PCS1_Holding_20420_Proportional Gain Lower 2_Write::[input value]',
    Units: 'W/Hz',
    Description:
      'Proportional gain of the droop curve when the Meter Frequency drops below the Reference Frequency by more than the Lower Knee. Applies only to a 6-point droop curve.',
    'Register Address': 20420,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Real Maximum  (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20422_real_maximum/float',
    'CMD Payload': 'PCS1_Holding_20422_Real Maximum _Write::[input value]',
    Units: 'W',
    Description:
      'The maximum amount of power than can be discharged by the Frequency Droop element.',
    'Register Address': 20422,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Real Minimum  (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20424_real_minimum/float',
    'CMD Payload': 'PCS1_Holding_20424_Real Minimum_Write::[input value]',
    Units: 'W',
    Description:
      'Negative value. The highest amount of power that can be charged by the Frequency Droop element.',
    'Register Address': 20424,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Droop',
    'Point Label': 'Time Response (s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20426_time_responses/float',
    'CMD Payload': 'PCS1_Holding_20426_Time Responses _Write::[input value]',
    Units: 's',
    Description:
      'The time response of the low-pass filter on the output of Frequency Droop.',
    'Register Address': 20426,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Real Operator Limit',
    'Point Label': 'Real Operator Limit Maximum (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20501_real_operator_limit_maximum/float',
    'CMD Payload':
      'PCS1_Holding_20501_Real Operator Limit Maximum_Write::[input value]',
    Units: 'W',
    Description:
      'Maximum Real Power (discharge) that will be allowed for dispatch by the total of all elements. Must be greater than zero to allow any discharge power',
    'Register Address': 20501,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Real Operator Limit',
    'Point Label': 'Real Operator Limit Minimum (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20503_real_operator_limit_minimum/float',
    'CMD Payload':
      'PCS1_Holding_20503_Real Operator Limit Minimum_Write::[input value]',
    Units: 'W',
    Description:
      'Negative value. Minimum Real Power (charge) that will be allowed for dispatch by the total of all elements. Must be lower than zero to allow any charge power',
    'Register Address': 20503,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Direct Reactive Power',
    'Point Label': 'Battery Direct Reactive Power Command (VAR)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20601_battery_direct_reactive_power_command/float',
    'CMD Payload':
      'PCS1_Holding_20601_Battery Direct Reactive Power Command_Write::[input value]',
    Units: 'VAR',
    Description:
      'Direct Reactive Power command. Positive reactive power corresponds to an injection of reactive power from the battery system to the grid',
    'Register Address': 20601,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Direct Reactive Power',
    'Point Label': 'Battery Direct Reactive Power Ramp Rate (VAR/s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20603_battery_direct_reactive_power_ramp_rate/float',
    'CMD Payload':
      'PCS1_Holding_20603_Battery Direct Reactive Power Ramp Rate_Write::[input value]',
    Units: 'VAR/s',
    Description:
      'Reactive Ramp Rate, both for ramping up and down. Must be greater than zero to allow any direct reactive power',
    'Register Address': 20603,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Voltage Control',
    'Point Label': 'Enable Voltage Control',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20701_voltage_control_enable/float',
    'CMD Payload':
      'PCS1_Holding_20701_Voltage Control Enable_Write::[input value]',
    Units: '',
    Description: 'Enabled Voltage Conrol with 1 and disable with 0',
    'Register Address': 20701,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Voltage Control',
    'Point Label': 'Reference Voltage  (kV)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20702_reference_voltage/float',
    'CMD Payload': 'PCS1_Holding_20702_Reference Voltage_Write::[input value]',
    Units: 'V',
    Description:
      'The reference voltage for Voltage Droop and Grid Forming Applications. For Voltage Control element will in inject / absorb reactive power to maintain this referenece.',
    'Register Address': 20702,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Power Factor',
    'Point Label': 'Enable Power Factor',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20801_power_factor_enable/float',
    'CMD Payload':
      'PCS1_Holding_20801_Power Factor Enable_Write::[input value]',
    Units: '',
    Description: 'Enable Power Factor with 1 and disable with 0',
    'Register Address': 20801,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Power Factor',
    'Point Label': 'Export Power Factor',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20802_export_power_factor/float',
    'CMD Payload':
      'PCS1_Holding_20802_Export Power Factor_Write::[input value]',
    Units: '',
    Description:
      'Power Factor setpoint when the battery system is discharging (exporting real power to the grid)',
    'Register Address': 20802,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Power Factor',
    'Point Label': 'Export Excitation',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20804_export_excitation/float',
    'CMD Payload': 'PCS1_Holding_20804_Export Excitation_Write::[input value]',
    Units: '',
    Description:
      'Power Factor Excitation when the battery system is discharging. Set excitation to 1 for an over-excited behavior (injecting reactive power to the grid). Set excitation to -1 for an under-excited behavior',
    'Register Address': 20804,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Power Factor',
    'Point Label': 'Import Power Factor',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20805_import_power_factor/float',
    'CMD Payload':
      'PCS1_Holding_20805_Import Power Factor_Write::[input value]',
    Units: '',
    Description:
      'Power Factor setpoint when the battery system is charging (importing real power to the grid)',
    'Register Address': 20805,
    'Data Type': '',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Power Factor',
    'Point Label': 'Import Excitation',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20807_import_excitation/float',
    'CMD Payload': 'PCS1_Holding_20807_Import Excitation_Write::[input value]',
    Units: '',
    Description:
      'Power Factor Excitation when the battery system is charging. Set excitation to 1 for an over-excited behavior (injecting reactive power to the grid). Set excitation to -1 for an under-excited behavior (absorbing reactive power from the grid)',
    'Register Address': 20807,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Reactive Operator Limit',
    'Point Label': 'Reactive Operator Limit Maximum (VAR)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20901_reactive_operator_limit_maximum/float',
    'CMD Payload':
      'PCS1_Holding_20901_Reactive Operator Limit Maximum_Write::[input value]',
    Units: 'VAR',
    Description:
      'Maximum Reactive Power (over-excited, injecting, positive) that will be allowed for dispatch by the total of all elements. Must be greater than zero to allow any reactive power injection',
    'Register Address': 20901,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Reactive Operator Limit',
    'Point Label': 'Reactive Operator Limit Minimum (VAR)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20903_reactive_operator_limit_minimum/float',
    'CMD Payload':
      'PCS1_Holding_20903_Reactive Operator Limit Minimum_Write::[input value]',
    Units: 'VAR',
    Description:
      'Negative value. Minimum Reactive Power that will be allowed for dispatch by the total of all elements. Must be lower than zero to allow any reactive power absorption',
    'Register Address': 20903,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Switches',
    'Point Label': 'Peak Power Enable',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21001_peak_power_enable/float',
    'CMD Payload': 'PCS1_Holding_21001_Peak Power Enable_Write::[input value]',
    Units: 'bool',
    Description:
      'Enable Peak Power with 1, disable with 0. Peak Power should only be used according to the contract and may have warranty implications',
    'Register Address': 21001,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Switches',
    'Point Label': 'Heat Mode Enable',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21002_heat_mode_enable/float',
    'CMD Payload': 'PCS1_Holding_21002_Heat Mode Enable_Write::[input value]',
    Units: 'bool',
    Description: 'Enable Heat Mode with 1, disable with 0',
    'Register Address': 21002,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Switches',
    'Point Label': 'State Request',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21003_state_request/float',
    'CMD Payload': 'PCS1_Holding_21003_State Request_Write::[input value]',
    Units: 'enum',
    Description:
      'Commands the state of batteries: 0 = Off, 1 = On (required to operate), 99 = Emergency Off',
    'Register Address': 21003,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Switches',
    'Point Label': 'Mode Request',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21004_mode_request/float',
    'CMD Payload': 'PCS1_Holding_21004_Mode Request_Write::[input value]',
    Units: 'enum',
    Description:
      'Commands the mode of batteries: 1 = Grid-Following, 2 = Grid-Forming. Grid-Forming may not always be allowed by configuration',
    'Register Address': 21004,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Battery Switches',
    'Point Label': 'Black Start Request',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21005_black_start_request/float',
    'CMD Payload':
      'PCS1_Holding_21005_Black Start Request_Write::[input value]',
    Units: 'enum',
    Description:
      'Commands the batteries to black start the grid: 0 = No Black Start, 1 = Black Start Now',
    'Register Address': 21005,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Integral',
    'Point Label': 'Enable Frequency Integral',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21101_frequency_integral_enable/float',
    'CMD Payload':
      'PCS1_Holding_21101_Frequency Integral Enable_Write::[input value]',
    Units: '',
    Description:
      'Enable integral control of the Frequency. Frequency Droop only uses a proportional gain. In some cases, an integral regulator may be desired.',
    'Register Address': 21101,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Frequency Integral',
    'Point Label': 'Reference Frequency  (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_20402_reference_frequency/float',
    'CMD Payload':
      'PCS1_Holding_20402_Reference Frequency_Write::[input value]',
    Units: '',
    Description: '',
    'Register Address': '',
    'Data Type': '',
    'Read / Write': '',
  },
  {
    'Control Mode': 'Fast Frequency Response - Low Frequency',
    'Point Label': 'Enable Low Trigger',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21201_low_trigger_enable/float',
    'CMD Payload': 'PCS1_Holding_21201_Low Trigger Enable_Write::[input value]',
    Units: '',
    Description:
      'Enable Fast Frequency Response Low Trigger. When enabled, Fast Frequency Response will be armed and a response will start when the Meter Frequency crosses the threshold for the Trigger Time.',
    'Register Address': 21201,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - Low Frequency',
    'Point Label': 'Low Recall',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21202_low_recall/float',
    'CMD Payload': 'PCS1_Holding_21202_Low Recall_Write::[input value]',
    Units: '',
    Description:
      'Set to 1 to recall the Fast Frequency Response for a low frequency event, once the response has already started. Recall also prevents new Fast Frequency Response events from being triggered.',
    'Register Address': 21202,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - Low Frequency',
    'Point Label': 'Low Threshold  (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21203_low_threshold/float',
    'CMD Payload': 'PCS1_Holding_21203_Low Threshold _Write::[input value]',
    Units: 'Hz',
    Description: 'Frequency threshold to trigger a Fast Frequency Response.',
    'Register Address': 21203,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - Low Frequency',
    'Point Label': 'Low Power (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21205_low_power/float',
    'CMD Payload': 'PCS1_Holding_21205_Low Power_Write::[input value]',
    Units: 'W',
    Description:
      'Power setpoint for the Frequency response. Once frequency crosses the threshold, power will rise by the power provided as setpoint.',
    'Register Address': 21205,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - Low Frequency',
    'Point Label': 'Low Hold Timer (s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21207_low_hold_timer/float',
    'CMD Payload': 'PCS1_Holding_21207_Low Hold Timer_Write::[input value]',
    Units: 's',
    Description:
      'Duration of the Fast Frequency Response for a low frequency event.',
    'Register Address': 21207,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - Low Frequency',
    'Point Label': 'Low Rising Ramp Rate (W/s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21209_low_rising_ramp_rate/float',
    'CMD Payload':
      'PCS1_Holding_21209_Low Rising Ramp Rate_Write::[input value]',
    Units: 'W/s',
    Description:
      'Ramp rate at which the power will rise for Fast Frequency Response (low frequency). Applies to the beginning of the Fast Frequency Response (low frequency).',
    'Register Address': 21209,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - Low Frequency',
    'Point Label': 'Low Falling Ramp Rate (W/s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21211_low_falling_ramp_rate/float',
    'CMD Payload':
      'PCS1_Holding_21211_Low Falling Ramp Rate_Write::[input value]',
    Units: 'W/s',
    Description:
      'Negative Value. Ramp rate at which the power will fall for Fast Frequency Response (low frequency). Applies to the end of the Fast Frequency Response (low frequency).',
    'Register Address': 21211,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - High Frequency',
    'Point Label': 'Enable High Trigger',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21301_high_trigger_enable/float',
    'CMD Payload':
      'PCS1_Holding_21301_High Trigger Enable_Write::[input value]',
    Units: '',
    Description:
      'Enable Fast Frequency Response High Trigger. When enabled, Fast Frequency Response will be armed and a response will start when the Meter Frequency crosses the threshold for the Trigger Time.',
    'Register Address': 21301,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - High Frequency',
    'Point Label': 'High Recall',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21302_high_recall/float',
    'CMD Payload': 'PCS1_Holding_21302_High Recall_Write::[input value]',
    Units: '',
    Description:
      'Set to 1 to recall the Fast Frequency Response for a high frequency event, once the response has already started. Recall also prevents new Fast Frequency Response events from being triggered.',
    'Register Address': 21302,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - High Frequency',
    'Point Label': 'High Threshold (Hz)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21303_high_threshold/float',
    'CMD Payload': 'PCS1_Holding_21303_High Threshold_Write::[input value]',
    Units: 'Hz',
    Description: 'Frequency threshold to trigger a Fast Frequency Response.',
    'Register Address': 21303,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - High Frequency',
    'Point Label': 'High Power (W)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21305_high_power/float',
    'CMD Payload': 'PCS1_Holding_21305_High Power_Write::[input value]',
    Units: 'W',
    Description:
      'Negative Value. Power setpoint for the Fast Frequency Response. Once frequency crosses the threshold, power will decrease (or go negative) by the Power provided as setpoint.',
    'Register Address': 21305,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - High Frequency',
    'Point Label': 'High Hold Timer (s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21307_high_hold_timer/float',
    'CMD Payload': 'PCS1_Holding_21307_High Hold Timer_Write::[input value]',
    Units: 's',
    Description:
      'Duration of the Fast Frequency Response for a high frequency event.',
    'Register Address': 21307,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - High Frequency',
    'Point Label': 'High Rising Ramp Rate (W/s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21309_high_rising_ramp_rate/float',
    'CMD Payload':
      'PCS1_Holding_21309_High Rising Ramp Rate_Write::[input value]',
    Units: 'W/s',
    Description:
      'Ramp rate at which the power will rise for Fast Frequency Response (high frequency). Applies to the end of the Fast Frequency Response (high frequency).',
    'Register Address': 21309,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Fast Frequency Response - High Frequency',
    'Point Label': 'High Falling Ramp Rate (W/s)',
    'Input type': 'Input field',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21311_high_falling_ramp_rate/float',
    'CMD Payload':
      'PCS1_Holding_21311_High Falling Ramp Rate_Write::[input value]',
    Units: 'W/s',
    Description:
      'Negative Value. Ramp rate at which the power will fall for Fast Frequency Response (high frequency). Applies to the beginning of the Fast Frequency Response (high frequency).',
    'Register Address': 21311,
    'Data Type': 'Float32',
    'Read / Write': 'R/W',
  },
  {
    'Control Mode': 'Closed Loop Control',
    'Point Label': 'Closed Loop Control Enable',
    'Input type': 'Toggle button (enable/disable)',
    'Current Value topic':
      'ems/site/0215D28F/tsc/tsc_1/holding_21401_closed_loop_control_enable/float',
    'CMD Payload':
      'PCS1_Holding_21401_Closed Loop Control Enable_Write::[input value]',
    Units: '',
    Description:
      'Enable or Disable Closed Loop Control. Disabling Closed Loop Control is recommended to validate the Meter wiring',
    'Register Address': 21401,
    'Data Type': 'I16',
    'Read / Write': 'R/W',
  },
];

export default tsc_config;
