import React from 'react';
import { Button, DialogActions, DialogContent, Stack } from '@mui/material';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { AddUserFormValues } from '@src/hooks/api/mutations/useUserCreate';
import GroupField from './Fields/GroupField';
import EmailField from './Fields/EmailField';
import FirstNameField from './Fields/FirstNameField';
import LastNameField from './Fields/LastNameField';
import MfaRequiredField from './Fields/MfaRequiredField';
import UsernameField from './Fields/UsernameField';
import PasswordField from './Fields/PasswordField';
import PasswordConfirmationField from './Fields/PasswordConfirmationField';

const schema = yup
  .object({
    username: yup.string().required().label('Username'),
    email: yup.string().email().label('Email'),
    password: yup.string().required('Password is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Passwords must match'),
    firstName: yup.string().optional().label('First Name'),
    lastName: yup.string().optional().label('Last Name'),
    totp: yup.bool(),
    groups: yup
      .array()
      .of(yup.string().required())
      .min(1, 'Groups cannot be an empty array')
      .required()
      .label('Groups'),
  })
  .required();

const defaultValues: AddUserFormValues = {
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  totp: true,
  groups: [],
  password: '',
  passwordConfirmation: '',
};

interface AddUserFormProps {
  onCancel: () => void;
  onSubmit: (values: AddUserFormValues) => void;
  isLoading: boolean;
}

function AddUserForm({ onCancel, onSubmit, isLoading }: AddUserFormProps) {
  const methods = useForm<AddUserFormValues>({
    defaultValues,
    resolver: yupResolver<AddUserFormValues>(schema),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack gap={2}>
            <UsernameField />
            <EmailField />
            <PasswordField />
            <PasswordConfirmationField />
            <FirstNameField />
            <LastNameField />
            <GroupField />
            <MfaRequiredField />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <LoadingButton type='submit' variant='contained' loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}

export default AddUserForm;
