type RackTopic = {
  pointName: string;
  displayName: string;
  value: string;
  id: number;
};

// Initial row values for DataGridPro
const rackDefaultPopupPointNames: RackTopic[] = [
  {
    pointName: '',
    displayName: 'Description',
    value: '-',
    id: 1,
  },
  {
    pointName: 'RackSOH',
    displayName: 'Rack SOH',
    value: '-',
    id: 2,
  },
  {
    pointName: 'rack_switch_control_info',
    displayName: 'Rack Switch Control',
    value: '-',
    id: 3,
  },
  {
    pointName: 'rack_external_sensor_info',
    displayName: 'Rack External Sensor',
    value: '-',
    id: 4,
  },
  {
    pointName: 'module_communication_fault_position',
    displayName: 'Mod Comm Fault',
    value: '-',
    id: 5,
  },
  {
    pointName: 'fan_operation_info',
    displayName: 'Fan Operation',
    value: '-',
    id: 6,
  },
  {
    pointName: 'maximum1_cell_voltage_value',
    displayName: 'Max Cell V1',
    value: '-',
    id: 7,
  },
  {
    pointName: 'maximum1_cell_voltage_position',
    displayName: 'Max Cell V1 Position',
    value: '-',
    id: 8,
  },
  {
    pointName: 'maximum2_cell_voltage_value',
    displayName: 'Max Cell V2',
    value: '-',
    id: 9,
  },
  {
    pointName: 'maximum2_cell_voltage_position',
    displayName: 'Max Cell V2 Position',
    value: '-',
    id: 10,
  },
  {
    pointName: 'maximum1_cell_temperature_value',
    displayName: 'Max Cell T1',
    value: '-',
    id: 11,
  },
  {
    pointName: 'maximum1_cell_temperature_position',
    displayName: 'Max Cell T1 Position',
    value: '-',
    id: 12,
  },
  {
    pointName: 'maximum2_cell_temperature_value',
    displayName: 'Max Cell T2',
    value: '-',
    id: 13,
  },
  {
    pointName: 'maximum2_cell_temperature_position',
    displayName: 'Max Cell T2 Position',
    value: '-',
    id: 14,
  },
  {
    pointName: 'minimum1_cell_voltage_value',
    displayName: 'Min Cell V1',
    value: '-',
    id: 15,
  },
  {
    pointName: 'minimum1_cell_voltage_position',
    displayName: 'Min Cell V1 Position',
    value: '-',
    id: 16,
  },
  {
    pointName: 'minimum2_cell_voltage_value',
    displayName: 'Min Cell V2',
    value: '-',
    id: 17,
  },
  {
    pointName: 'minimum2_cell_voltage_position',
    displayName: 'Min Cell V2 Position',
    value: '-',
    id: 18,
  },
  {
    pointName: 'minimum1_cell_temperature_value',
    displayName: 'Min Cell T1',
    value: '-',
    id: 19,
  },
  {
    pointName: 'minimum1_cell_temperature_position',
    displayName: 'Min Cell T1 Position',
    value: '-',
    id: 20,
  },
  {
    pointName: 'minimum2_cell_temperature_value',
    displayName: 'Min Cell T2',
    value: '-',
    id: 21,
  },
  {
    pointName: 'minimum2_cell_temperature_position',
    displayName: 'Min Cell T2 Position',
    value: '-',
    id: 22,
  },
];

export default rackDefaultPopupPointNames;
