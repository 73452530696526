import React from 'react';
import { Box, CircularProgress, Dialog, DialogTitle } from '@mui/material';
import storeConnector from '@store/storeConnector';
import { Actions } from '@src/types/Actions';
import useUser from '@src/hooks/api/queries/useUser';
import useUserUpdate, {
  EditUserFormValues,
} from '@src/hooks/api/mutations/useUserUpdate';
import { useQueryClient } from '@tanstack/react-query';
import QueryKeys from '@src/constants/queryKeys';
import EditUserForm from '../Forms/EditUserForm';

interface EditUserDialogProps {
  open: boolean;
  onClose: () => void;
  actions: Actions;
  userId: string;
}

function EditUserDialog({
  open,
  onClose,
  actions,
  userId,
}: EditUserDialogProps) {
  const queryClient = useQueryClient();
  const { data: user, isLoading } = useUser(userId);
  const { mutate: updateUser, isPending } = useUserUpdate(userId);

  const handleSubmit = async (values: EditUserFormValues) => {
    updateUser(values, {
      onSuccess: () => {
        actions.notify('Successfully updated user.');
        queryClient.invalidateQueries({ queryKey: [QueryKeys.user, userId] });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.users] });
        onClose();
      },
      onError: (error: Error) => {
        const customError = error.cause as { error?: { message: string } };
        actions.notifyError(
          customError?.error?.message ?? 'Failed to update user.',
        );
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Edit User</DialogTitle>
      {isLoading || user === undefined ? (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <EditUserForm
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isPending}
          user={user}
        />
      )}
    </Dialog>
  );
}

export default storeConnector(EditUserDialog, {});
