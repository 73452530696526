import { SiteMeta } from '@src/types/SiteMeta';

export type DataPointQuery = {
  category: string;
  pointName: string;
  controllerId: string;
};

export type UnitConfig = {
  unitLabel: string;
  kw: string | undefined;
  kvar: string | undefined;
  soc: string | undefined;
  racksonline: string | undefined;
  invstatus: string | undefined;
  runmode: string | undefined;
};

export type DefaultSLDProps = {
  siteMeta: SiteMeta;
};

export enum UnitDataPoints {
  KW = 'kW',
  KVAR = 'kVAR',
  SOC = 'SOC',
  RacksOnline = 'racks_online',
  InvStatus = 'InvStatus',
  RunMode = 'RunMode',
}
