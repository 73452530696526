import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '@components/_elements/Spinner/Spinner';
import { cancelPendingRequests } from '@utils';
import { store } from '@store';
import storeConnector from '@store/storeConnector';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { tsDueTimeMode } from '@utils/index_ts';

const Messages = ({ actions, timeMode, siteMeta, withTitle }) => {
  const TOPIC = 'MSG/+';
  const state = store.getState();
  const client = state.mqtt.clientMQTT;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    client.subscribe({ [TOPIC]: { qos: 0, rh: 2 } });
    client.on('message', (topic, message) => {
      if (topic.includes('MSG')) {
        const data = JSON.parse(message.toString());
        setMessages((prev) => {
          return [
            {
              createdAt: data.TS,
              deviceId: data.SN,
              message: data,
            },
            ...prev,
          ];
        });
      }
    });
    return () => {
      client.unsubscribe(TOPIC);
    };
  }, [client]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const messages = await actions.getMessagesSimple();
      setMessages(messages);
      setLoading(false);
    })();
    return () => {
      (async () => {
        cancelPendingRequests();
      })();
    };
  }, []);

  const getCurrentTime = (ts) => {
    return tsDueTimeMode({
      timeMode,
      lat: siteMeta.GPSLat,
      long: siteMeta.GPSLong,
      splited: true,
      ts,
    });
  };

  return (
    <div
      className={`mess-container ${
        withTitle && 'cell overflow-hidden battery-sys-system-msgs flex-1'
      }`}
    >
      {withTitle && (
        <div className='title-table-line msg-title'>
          <FontAwesomeIcon icon={faComments} />
          System Messages
        </div>
      )}
      {messages && messages.length ? (
        <div className={`mess-container ${withTitle && 'with-title'}`}>
          {withTitle && (
            <>
              <div className='title-cell'>Date</div>
              <div className='title-cell'>Time</div>
              <div className='title-cell'>Device</div>
              <div className='title-cell'>Message</div>
            </>
          )}
          {messages.map((f, row) => (
            <React.Fragment key={row}>
              <div className='mess-date'>{getCurrentTime(f.createdAt)[0]}</div>
              <div className='mess-time'>{getCurrentTime(f.createdAt)[1]}</div>
              <div className='mess-time'>
                {siteMeta.Units.indexOf(f.deviceId) !== -1
                  ? `[Unit ${
                      siteMeta.UnitNames[
                        siteMeta.Units.findIndex((u) => u === f.deviceId)
                      ]
                    }]`
                  : '[SYSTEM]'}
              </div>
              <div className='ms-content'>{f.message.MSG}</div>
            </React.Fragment>
          ))}
        </div>
      ) : null}
      {!messages.length && loading && <Spinner cover='container' />}
    </div>
  );
};

export default storeConnector(Messages, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
