export default [
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name',
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    editable: true,
  },
  {
    field: 'engineeringId',
    headerName: 'Engineering ID',
    description: 'Engineering ID',
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    editable: true,
  },
  {
    field: 'type',
    headerName: 'Type',
    description: 'Type',
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    editable: false,
  },
  {
    field: 'controllerId',
    headerName: 'Controller ID',
    description: 'Controller ID',
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    editable: false,
  },
  {
    field: 'deviceId',
    headerName: 'Device ID',
    description: 'Device ID',
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    editable: false,
  },
  {
    field: 'isSiteControllerSourceDevice',
    headerName: 'Is Site Controller Source Device?',
    description: 'Is Site Controller Source Device?',
    type: 'boolean',
    width: 200,
    align: 'left',
    headerAlign: 'left',
    editable: false,
  },
];
