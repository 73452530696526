import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import './SunRiseSetWidget.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as SunCalc from 'suncalc-ts';
import ChartLoader from '@components/_elements/ChartLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { getLocale } from '@utils';
import storeConnector from '@store/storeConnector';
import { faSun } from '@fortawesome/free-solid-svg-icons';

const SunRiseSetWidget = ({ siteMeta, timeMode }) => {
  // Below renders the chart after component loads
  // to allow flexbox _layout to load,
  // and the chart to fill container width and height
  const [isLayoutLoaded, setLayoutLoaded] = useState(false);
  useEffect(() => {
    setLayoutLoaded(true);
  }, []);
  const [timeStamp, setTimeStamp] = useState(null);
  useWsSubscribe({
    site: { fields: ['PVkW'], cb: (_data, _sn, ts) => setTimeStamp(ts) },
  });

  let suntime;
  let localtime;
  let tz;
  let sunPercent = 30;
  if (timeStamp && siteMeta) {
    let lat = siteMeta.GPSLat;
    let lng = siteMeta.GPSLong;
    tz = getLocale({
      timeMode: timeMode,
      lat,
      long: lng,
    });
    localtime = moment.utc(timeStamp).tz(tz);
    suntime = SunCalc.getTimes(localtime, lat, lng);

    let daylength = suntime.sunset - suntime.sunrise;
    let currenttime = moment.utc(timeStamp) - suntime.sunrise;
    let sp = Math.round((currenttime / daylength) * 100);

    if (sp > 100) {
      sp = 100;
    }
    if (sp < 0) {
      sp = 0;
    }
    sunPercent = sp;
  }
  return isLayoutLoaded ? (
    <div className='weather-col'>
      <div className='weather-title'>Sun</div>
      {!timeStamp ? (
        <div className='weather-block' style={{ position: 'relative' }}>
          <ChartLoader />
        </div>
      ) : (
        <div
          className='weather-block weather-block-sun'
          style={{ padding: '0 10px' }}
        >
          <div className='sun-line'>
            <div
              className='sun-icon'
              style={{ animationDelay: -sunPercent + 's' }}
            >
              <div
                className='suntime suntime-now'
                style={{ animationDelay: -sunPercent + 's' }}
              >
                {localtime.format('h:mm\u00A0A')}
              </div>
              <FontAwesomeIcon icon={faSun} />
            </div>
          </div>
          <div className='sun-ellipse'>
            <div
              className='sun-ellipse-fill'
              style={{ animationDelay: -sunPercent + 's' }}
            />
          </div>
          <div className='suntime suntime-rise'>
            {moment(suntime.sunrise).tz(tz).format('h:mm\u00A0A')}
          </div>
          <div className='suntime suntime-set'>
            {moment(suntime.sunset).tz(tz).format('h:mm\u00A0A')}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default storeConnector(SunRiseSetWidget, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
