import React, { useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import storeConnector from '@store/storeConnector';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import { SiteMeta } from '@src/types/SiteMeta';
import rackDefaultPopupPointNames from './rack-utils';

// Interfaces and types declarations
interface RackContainerPopupProps {
  type: string;
  currentUnitDeviceId: string;
  siteMeta: SiteMeta;
  rackId: string;
}

export function RackContainerPopup({
  currentUnitDeviceId,
  type,
  siteMeta,
  rackId,
}: RackContainerPopupProps) {
  // useState hook variables
  const [rackCustomTopicValues, setRackCustomTopicValues] = useState(
    rackDefaultPopupPointNames,
  );

  const RACK_POINT_NAMES: string[] = siteMeta.ui.rackPopupPointNames || [''];
  const useBasicModal = type === 'basic';

  // Columns definition for DataGridPro
  const columns: GridColDef[] = [
    { field: 'displayName', headerName: 'Data Point', width: 200 },
    { field: 'value', headerName: 'Value', width: 200 },
  ];

  let pointNames: string[] = [];

  // display battery type in the first row of modal
  rackDefaultPopupPointNames[0].value = siteMeta.Batt_type;

  pointNames = RACK_POINT_NAMES;

  // Callback to update rack values and log the results
  const updateRackValues = (newData: Record<string, number>) => {
    setRackCustomTopicValues((prevRackCustomTopicValues) =>
      prevRackCustomTopicValues.map((row) =>
        Object.prototype.hasOwnProperty.call(newData, row.pointName)
          ? { ...row, value: newData[row.pointName].toString() }
          : row,
      ),
    );
  };

  useWsSubscribe(
    {
      rack: {
        fields: pointNames,
        sourceDeviceId: rackId,
        sns: [currentUnitDeviceId],
        cb: (data: Record<string, number>) => {
          updateRackValues(data);
        },
      },
    },
    [currentUnitDeviceId, rackId],
  );

  return useBasicModal ? (
    <div
      style={{
        maxWidth: 600,
        height: '400px',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <DataGridPro
        hideFooter
        autoHeight
        rows={rackCustomTopicValues}
        columns={columns}
      />
    </div>
  ) : null;
}

export default storeConnector(RackContainerPopup, {
  config: ['siteMeta'],
  service: ['currentUnitDeviceId'],
});
