import { store } from '@store';
import React, { Dispatch, ReactElement } from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  Grid2,
  Typography,
  Box,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ALERTS_SERVICE_URL } from '@utils';
import { HistoricalAlert } from '@src/types/Alerts';
import { isDayjsOrNull } from '@utils/index_ts';

interface TimeRangeModalProps {
  open: boolean;
  onClose: () => void;
  setTimelineAlerts: Dispatch<HistoricalAlert[]>;
  start: Dayjs | undefined;
  stop: Dayjs | undefined;
  setStart: Dispatch<Dayjs>;
  setStop: Dispatch<Dayjs>;
}

function TimeRangeModal(p: TimeRangeModalProps): ReactElement {
  const handleApplyTimeRange = async (): Promise<void> => {
    if (p.start && p.stop) {
      const alerts = await fetch(
        `${ALERTS_SERVICE_URL}/alert-history/range?start=${p.start.toISOString()}&stop=${p.stop.toISOString()}`,
        {
          headers: {
            authorization: `Bearer ${store.getState().user.keycloak?.token}`,
          },
        },
      ).then((r) => r.json());
      p.setTimelineAlerts(alerts);
      p.onClose();
    }
  };

  const onChange = (val: Dayjs | null, field: 'start' | 'stop'): void => {
    if (isDayjsOrNull(val) && val !== null) {
      if (field === 'start') {
        p.setStart(val);
      } else {
        p.setStop(val);
      }
    }
  };

  return (
    <Dialog role='alert-timeline-modal' onClose={p.onClose} open={p.open}>
      <DialogContent>
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 6 }}>
            <Typography align='center' variant='h6'>
              Start
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box role='alert-date-time-picker-start'>
                <DateTimePicker
                  maxDate={dayjs()}
                  ampm
                  onChange={(v: Dayjs | null) => onChange(v, 'start')}
                  value={p.start}
                />
              </Box>
            </LocalizationProvider>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Typography align='center' variant='h6'>
              Stop
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box role='alert-date-time-picker-stop'>
                <DateTimePicker
                  ampm
                  maxDateTime={dayjs()}
                  onChange={(v: Dayjs | null) => onChange(v, 'stop')}
                  value={p.stop}
                />
              </Box>
            </LocalizationProvider>
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleApplyTimeRange} variant='contained'>
          Apply Time Range
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TimeRangeModal;
