import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import { WidgetConfigBase } from '@src/types/Widget';

export default () =>
  useApiQuery<WidgetConfigBase[]>({
    queryKey: [QueryKeys.widgets],
    apiPath: '/widgets',
    method: 'GET',
    useV2API: true,
  });
