import HistorianTableView from '@pages/HistorianTableView/index';
import React from 'react';
import { RouteObject } from 'react-router-dom';

export default [
  {
    path: '/historian-tableview/:request',
    element: <HistorianTableView />,
  },
] as RouteObject[];
