import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction } from 'react';
import { CollectionPayload, CollectionTypes } from '@src/types/Collection';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GenerateDevicesButton from './Devices/GenerateDevicesButton';
import collectionSx from './configs/CollectionSx';

interface ToolbarProps {
  collectionType?: CollectionTypes;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
}

const baseAddPointConfig = {
  dataType: '',
  pointName: '',
  name: '',
};

const baseAddWidgetConfig = {
  type: '',
  internalId: '',
  name: '',
  isSystemWidget: false,
  header: '',
};

function CollectionToolbar({
  collectionType,
  setOpen,
  setJsonPayload,
}: ToolbarProps): React.ReactElement {
  const handleOpenAdd = () => {
    const baseConfig =
      collectionType === CollectionTypes.POINT_CONFIGS
        ? baseAddPointConfig
        : baseAddWidgetConfig;
    setOpen(true);
    setJsonPayload(baseConfig);
  };

  return (
    <GridToolbarContainer sx={collectionSx.collectionToolbar}>
      <div>
        {collectionType === CollectionTypes.DEVICES && (
          <GenerateDevicesButton
            setJsonPayload={setJsonPayload}
            setOpen={setOpen}
          />
        )}
        {(collectionType === CollectionTypes.POINT_CONFIGS ||
          collectionType === CollectionTypes.WIDGETS) && (
          <Button startIcon={<AddIcon />} onClick={handleOpenAdd}>
            Add config
          </Button>
        )}
      </div>

      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarQuickFilter
          sx={collectionSx.collectionToolbar.quickFilter}
        />
      </div>
    </GridToolbarContainer>
  );
}

export default CollectionToolbar;
