import QueryKeys from '@src/constants/queryKeys';
import useApiQuery from '@src/hooks/useApiQuery';
import { User } from '@src/types/User';

const useUsers = () =>
  useApiQuery<User[]>({
    queryKey: [QueryKeys.users],
    apiPath: '/users',
    method: 'GET',
    useV2API: true,
  });

export default useUsers;
