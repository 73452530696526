import { useQueryClient } from '@tanstack/react-query';
import useApiMutation from '@src/hooks/useApiMutation';
import QueryKeys from '@src/constants/queryKeys';

export type AddUserFormValues = {
  username: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  totp?: boolean;
  groups: string[];
  password: string;
  passwordConfirmation?: string;
};

export default () => {
  const queryClient = useQueryClient();

  return useApiMutation<Omit<AddUserFormValues, 'passwordConfirmation'>, void>(
    {
      apiPath: '/users',
      method: 'POST',
      useV2API: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.users] });
      },
    },
  );
};
