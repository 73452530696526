import React, { useRef } from 'react';
import SiteUnitCommandsPopup from '@components/Battery/SiteUnitCommandsPopup/SiteUnitCommandsPopup';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import storeConnector from '@store/storeConnector';

const COMMON_SITE_UNIT_FIELDS = [
  'Qset',
  'Pset',
  'AllowedMinP',
  'AllowedMaxP',
  'AllowedMaxQ',
  'AllowedMinQ',
];

const SiteUnitCommandsRTDataFetcher = ({
  isShownCommandsPopup,
  siteMeta,
  popupProps,
}) => {
  const batteryConnectingStatusTable = useRef({});
  const unitTable = useRef(
    siteMeta.Units.map((sn, idx) => ({
      SN: sn,
      UnitName: siteMeta.UnitNames[idx],
    })),
  );
  const rackModeTable = useRef({});
  const siteRt = useRef({});

  useWsSubscribe({
    bms: {
      sns: siteMeta.Units,
      fields: ['ConnectingStatus'],
      sourceDeviceIds: ['bms_1'],
      cb: (data, sn) =>
        (batteryConnectingStatusTable.current[sn] = data?.ConnectingStatus),
    },
    rack: {
      sns: siteMeta.Units,
      fields: ['RackMode'],
      sourceDeviceIds: ['bms_1_rack_1'],
      cb: (data, sn) => {
        if (data?.RackMode) {
          let rackMode = data.RackMode;
          rackModeTable.current[sn] = rackMode;
        }
      },
    },
    unit: {
      sns: siteMeta.Units,
      fields: [
        'RunMode',
        'InvStatus',
        'LocRemCtl',
        'ACBreaker',
        ...COMMON_SITE_UNIT_FIELDS,
      ],
      cb: (data, sn) => {
        const idx = siteMeta.Units.indexOf(sn);
        Object.assign(unitTable.current[idx], data);
      },
    },
    site: {
      fields: ['LocRemCtl', ...COMMON_SITE_UNIT_FIELDS],
      cb: (data) => {
        if (!siteRt.current) {
          siteRt.current = data;
        } else {
          Object.assign(siteRt.current, data);
        }
      },
    },
  });

  if (isShownCommandsPopup) {
    const sn = popupProps.sn;
    let siteOrUnit;
    const idx = siteMeta.Units.indexOf(sn);
    let UnitName = '';
    if (idx !== -1) {
      UnitName = popupProps.isPV
        ? siteMeta.UnitNamesPV?.[idx]
        : siteMeta.UnitNames[idx];
    }
    const childProps = {
      lotoTableRt: { [sn]: popupProps.lotoTableRt },
    };
    if (popupProps.popupType === 'site') {
      siteOrUnit = siteRt.current;
      Object.assign(childProps, {
        LocRemCtl: siteOrUnit.LocRemCtl,
        unitTable: unitTable.current,
      });
    } else if (popupProps.popupType === 'unit') {
      siteOrUnit = unitTable.current[idx];
      Object.assign(childProps, {
        rackModeArr: rackModeTable.current[sn],
        batteryConnectingStatus: batteryConnectingStatusTable.current[sn],
        runMode: siteOrUnit['RunMode'] || siteOrUnit['Run Mode'],
        UnitName,
        invStatus: siteOrUnit['InvStatus'] || siteOrUnit['Inv Status'],
      });
    }
    COMMON_SITE_UNIT_FIELDS.forEach(
      (field) => (childProps[field] = siteOrUnit[field]),
    );
    return <SiteUnitCommandsPopup {...childProps} />;
  }
  return null;
};

export default storeConnector(SiteUnitCommandsRTDataFetcher, {
  service: ['isShownCommandsPopup', 'popupProps'],
  config: ['siteMeta'],
});
