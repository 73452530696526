import { IMqttMessage } from '@src/types/Mqtt';
import { MqttClient } from 'mqtt';
import { Action } from 'redux';

export interface MqttState {
  clientMQTT?: MqttClient;
  topicDict?: Record<string, string>;
  subsMQTT?: unknown[];
  subsMqttTopicCbDict?: {
    [key in string]: {
      cbs: ((
        fieldValuePairs: {
          [field in string]: IMqttMessage['value'];
        },
        deviceId: string,
        ts: IMqttMessage['ts'],
        extraVars: Record<string, unknown>,
      ) => void)[];
      comps: Array<string>;
    };
  };
}

const initState: MqttState = {
  clientMQTT: undefined,
  topicDict: {},
  subsMQTT: [],
  subsMqttTopicCbDict: {},
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MqttState = initState,
  action: Action<string> & MqttState,
) => {
  const handlers: Record<string, () => MqttState> = {
    UPDATE_MQTT_MODULE: () => {
      const { type: _type, ...cleanAction } = action;
      return { ...state, ...cleanAction };
    },
  };
  if (action.type && handlers[action.type] !== undefined) {
    return handlers[action.type]();
  }
  return state;
};
