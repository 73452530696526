import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { ApiSiteMetaUpdate, SiteMeta } from '@src/types/SiteMeta';
import { callAPI } from '@utils';
import Popup from '@components/_elements/Popup/Popup';
import storeConnector from '@store/storeConnector';
import JSONEditor from '@components/_elements/JSONEditor/JSONEditor';

interface SiteMetaEditorPopupProps {
  siteMeta: SiteMeta;
  isOpen: boolean;
  close: () => void;
}

const prepareNewSiteMeta = (appSiteMeta: SiteMeta): ApiSiteMetaUpdate => {
  const {
    siteId,
    updatedBy: _updatedBy,
    updatedAt: _updatedAt,
    ...actualSiteMetaBody
  } = appSiteMeta;
  return {
    siteId,
    body: { ...actualSiteMetaBody },
  };
};

function SiteMetaEditorPopup({
  siteMeta,
  isOpen,
  close,
}: SiteMetaEditorPopupProps) {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [workingCopy, setWorkingCopy] = useState<SiteMeta>({ ...siteMeta });
  const lastUpdateDate = useMemo(() => {
    const date = new Date(siteMeta.updatedAt);
    return date.toLocaleString();
  }, [siteMeta.updatedAt]);

  const saveSiteMeta = async () => {
    setIsSaving(true);
    const newSiteMeta = prepareNewSiteMeta(workingCopy);
    const siteMetaRes = await callAPI('/site-meta', 'POST', newSiteMeta);
    if (siteMetaRes.error === undefined) {
      setIsSaving(false);
      navigate(0);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      title='Site Meta Editor'
      close={() => close()}
      bodyClass='content-popup site-meta-editor'
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
        }}
      >
        <JSONEditor
          json={{ ...siteMeta }}
          onChange={setWorkingCopy}
          options={{ mode: 'code' }}
        />
      </div>
      <div className='btn-row'>
        <div>
          <small>
            Last updated by <strong>{siteMeta.updatedBy}</strong> on{' '}
            <em>{lastUpdateDate}</em>
          </small>
        </div>
        <LoadingButton
          variant='contained'
          disabled={isSaving}
          loading={isSaving}
          onClick={saveSiteMeta}
          sx={{ minWidth: '180px' }}
        >
          Save and reload app
        </LoadingButton>
      </div>
    </Popup>
  );
}

export default storeConnector(SiteMetaEditorPopup, {
  config: ['siteMeta'],
});
