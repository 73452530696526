import React from 'react';
import IncidentCommand from '@pages/IncidentCommand/IncidentCommand';
import { RouteObject, useRouteLoaderData } from 'react-router-dom';
import AppLayout from '@pages/_layout/AppLayout';
import { SiteMeta } from '@src/types/SiteMeta';
import { findForwardRouteForIncidentCommandRoot } from '@pages/_loader/runRouteGuards';
import { PageNotFound } from '@pages/PageError';

function CheckIncidentCommandRootRoutePerms() {
  const siteMeta = useRouteLoaderData('root') as SiteMeta;
  const forwardPath = findForwardRouteForIncidentCommandRoot(siteMeta);
  return forwardPath ? <AppLayout /> : <PageNotFound />;
}

export default [
  {
    path: 'incident-command',
    element: <CheckIncidentCommandRootRoutePerms />,
    children: [{ index: true, element: <IncidentCommand /> }],
  },
] as RouteObject[];
