import ValueLoader from '@components/_shared/ValueLoader';
import { Box, BoxProps } from '@mui/material';
import { DataPointWithValue } from '@src/types/DataPoint';
import React, { ReactElement } from 'react';

interface RatioProps {
  dataPoint: DataPointWithValue;
  denominator: string;
  justifyContent?: BoxProps['justifyContent'];
}
export default function RatioValueLoader(p: RatioProps): ReactElement {
  // add color logic here for future hardware vendors
  return (
    <Box
      role='ratio'
      display='flex'
      justifyContent={
        p.justifyContent !== null && p.justifyContent !== undefined
          ? p.justifyContent
          : 'center'
      }
    >
      <ValueLoader dataPoint={p.dataPoint} />
      &nbsp;
      <Box>{`/ ${p.denominator}`}</Box>
    </Box>
  );
}
