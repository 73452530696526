import TextLoader from '@components/_elements/TextLoader/index';
import React, { useEffect, useRef, useState } from 'react';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { isKeyInObject, useInterval } from '@utils';
import { getPCSFields, UNIT_FIELDS } from './constants.js';
import storeConnector from '@store/storeConnector';

const PCSContainerSungrow = ({
  siteMeta,
  currentUnitDeviceId,
  pcsType,
  fromPV,
  titleOverride,
  pcsIndex,
}) => {
  const [currHBInterval, setCurrHBInterval] = useState(0);
  const [prevHB, setPrevHB] = useState(0);
  const [unitRt, setUnitRt] = useState({});
  const [pcsRt, setPCSRt] = useState({});
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  useEffect(() => {
    setUnitRt({});
    setPCSRt({});
  }, [currentUnitDeviceId]);

  useWsSubscribe(
    {
      unit: {
        sns: [currentUnitDeviceId],
        fields: UNIT_FIELDS,
        cb: (data) => setUnitRt((prev) => ({ ...prev, ...data })),
      },
      pcs: {
        sns: [currentUnitDeviceId],
        fields: getPCSFields(pcsType),
        sourceDeviceId: `pcs_${pcsIndex + 1}`,
        cb: (data) => setPCSRt((prev) => ({ ...prev, ...data })),
      },
    },
    [currentUnitDeviceId],
  );

  useInterval(() => {
    if (pcsRt && pcsRt.WatchdogResponse !== prevHB) {
      setCurrHBInterval(0);
      setPrevHB(pcsRt.WatchdogResponse);
    } else {
      setCurrHBInterval((currHBInterval) => currHBInterval + 1);
    }
  }, 1000);

  const getInfoDict = () => {
    const { Thresh_HB_PCS_TimeOut } = siteMeta ? siteMeta : {};
    const infoDict = [
      {
        field: 'Status',
        value: unitRt['InvStatus'] || unitRt['Inv Status'],
        loading:
          isKeyInObject(unitRt, 'InvStatus') &&
          isKeyInObject(unitRt, 'Inv Status'),
      },
      { field: 'AC kW', value: pcsRt.kW, loading: isPCSLoading('kW') },
      { field: 'Vab', value: pcsRt.Vab, loading: isPCSLoading('Vab') },
      {
        field: 'DC Disconnect',
        value: pcsRt.DCDisconnect_string,
        loading: isPCSLoading('DCDisconnect_string'),
      },
      {
        field: 'Mode',
        value: unitRt['RunMode'] || unitRt['Run Mode'],
        loading:
          isKeyInObject(unitRt, 'RunMode') && isKeyInObject(unitRt, 'Run Mode'),
      },
      { field: 'AC kVAR', value: pcsRt.kVAR, loading: isPCSLoading('kVAR') },
      { field: 'Vbc', value: pcsRt.Vbc, loading: isPCSLoading('Vbc') },
      { field: 'DC kW', value: pcsRt.DCkW, loading: isPCSLoading('DCkW') },
      {
        field: 'AC Breaker',
        value:
          parseInt(pcsRt['ACBreaker']) === 1
            ? 'Closed'
            : parseInt(pcsRt['ACBreaker']) === 0
              ? 'Open'
              : 'ERR',
        loading: isKeyInObject(pcsRt, 'ACBreaker'),
      },
      { field: 'AC kVA', value: pcsRt.kVA, loading: isPCSLoading('kVA') },
      { field: 'Vca', value: pcsRt.Vca, loading: isPCSLoading('Vca') },
      {
        field: 'Local/Remote Control',
        value: pcsRt.PV_PCS_LocalRemote_Control,
        loading: isPCSLoading('PV_PCS_LocalRemote_Control'),
      },
      { field: 'Frequency', value: pcsRt.Hz, loading: isPCSLoading('Hz') },
      { field: 'PF', value: pcsRt.PF, loading: isPCSLoading('PF') },
      { field: 'Ia', value: pcsRt.AphA, loading: isPCSLoading('AphA') },
      {
        field: 'Night SVG Switch',
        value: pcsRt.PV_PCS_Night_SVG_Switch,
        loading: isPCSLoading('PV_PCS_Night_SVG_Switch'),
      },
      {
        field: 'PCS Heartbeat',
        value: pcsRt.WatchdogResponse || '-',
        class:
          currHBInterval > +Thresh_HB_PCS_TimeOut && prevHB !== 0
            ? 'bold red-txt'
            : '',
        loading: isPCSLoading('WatchdogResponse'),
      },
      { field: ' ', value: ' ' },
      { field: 'Ib', value: pcsRt.AphB, loading: isPCSLoading('AphB') },
      { field: ' ', value: ' ' },
      {
        field: 'Chg Power Limit ',
        value: pcsRt.PCS_MinP,
        loading: isPCSLoading('PCS_MinP'),
      },
      { field: ' ', value: ' ' },
      { field: 'Ic', value: pcsRt.AphC, loading: isPCSLoading('AphC') },
      { field: ' ', value: ' ' },
      {
        field: 'Dchg Power Limit',
        value: pcsRt.PCS_MaxP,
        loading: isPCSLoading('PCS_MaxP'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'kW setting percent',
        value: pcsRt.Active_power_percentage_setting,
        loading: isPCSLoading('Active_power_percentage_setting'),
      },
      {
        field: 'Req kW Cmd',
        value: unitRt.Pset,
        loading: isKeyInObject(unitRt, 'Pset'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'kVAR setting percent',
        value: pcsRt.Reactive_power_percentage_setting,
        loading: isPCSLoading('Reactive_power_percentage_setting'),
      },
      {
        field: 'Req kVAR Cmd',
        value: unitRt.Qset,
        loading: isKeyInObject(unitRt, 'Qset'),
      },
    ];
    return infoDict;
  };

  const isPCSLoading = (field) => {
    return !Object.keys(pcsRt).includes(field);
  };

  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';

  const title =
    fromPV && titleOverride && titleOverride !== 'PCS'
      ? titleOverride + ' Status'
      : `${unitName && unitName + ' '}PCS Status${
          pcsType ? ` - ${pcsType}` : ''
        }`;

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell unit-cell'>
        <div className='unit-info'>
          <div className='unit-col'>
            <div className='title-large'>
              {title}
              <span
                style={{
                  marginLeft: '10px',
                  opacity: '0.8',
                  fontWeight: 'normal',
                }}
              >
                {pcsRt.Description}
              </span>
            </div>
            <div className='info-grid pcs'>
              {getInfoDict().map((f, i) => (
                <React.Fragment key={i}>
                  <div className='title'>{f.field}</div>
                  <div className={f.class}>
                    <TextLoader orientation='l' loading={f.loading}>
                      {f.value}
                    </TextLoader>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(PCSContainerSungrow, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
