import useApiMutation from '@hooks/useApiMutation';
import { Dispatch, SetStateAction } from 'react';

interface NoteMutationsParams {
  deviceId: string;
  selectedNoteId: string;
  setCurrentNote: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<boolean>>;
  successCallBack: () => void;
}

const useNoteMutations = ({
  deviceId,
  selectedNoteId,
  setCurrentNote,
  setError,
  successCallBack,
}: NoteMutationsParams) => {
  const handleSuccess = () => {
    successCallBack();
  };

  const handleError = () => {
    setError(true); // Adjust to fit your error handling strategy
  };

  const createNote = useApiMutation(
    {
      apiPath: '/notes',
      method: 'POST',
      useV2API: true,
    },
    {
      onSuccess: () => {
        setCurrentNote('');
        handleSuccess();
      },
      onError: handleError,
    },
  );

  const clearNotes = useApiMutation(
    {
      apiPath: `/notes/device/${deviceId}`,
      method: 'DELETE',
      useV2API: true,
    },
    {
      onSuccess: handleSuccess,
    },
  );

  const deleteNote = useApiMutation(
    {
      apiPath: `/notes/${selectedNoteId}`,
      method: 'DELETE',
      useV2API: true,
    },
    {
      onSuccess: handleSuccess,
    },
  );

  return {
    createNote,
    clearNotes,
    deleteNote,
  };
};

export default useNoteMutations;
