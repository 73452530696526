import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridPaginationModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import useApiQuery from '@hooks/useApiQuery';
import { AuditLog } from '@src/types/Audit-Logs';
import { PaginatedResponse } from '@src/types/pagination/PaginatedResponse';
import {
  apiFields,
  DefaultAPIBody,
  getAdjustedTime,
  getDefaultAPIBody,
} from '@components/Admin/AuditLogs/auditLogUtils';
import QueryKeys from '@src/constants/queryKeys';
import dayjs, { Dayjs } from 'dayjs';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import storeConnector from '@store/storeConnector';
import { TimeMode } from '@types';
import { SiteMeta } from '@src/types/SiteMeta';
import AuditLogDialog from '@components/Admin/AuditLogs/AuditLogDialog';
import generateAuditLogColumns from '@components/Admin/AuditLogs/auditLogGridColumns';
import BasicGridToolbar from '@components/_shared/BasicGridToolbar';
import '@components/_shared/MultiDateSelector/MultiDateSelector.scoped.scss';
import { PaginationModel } from '@src/types/pagination/PaginationModel';
import MultiDateSelector from '@components/_shared/MultiDateSelector/MultiDateSelector';
import EventTimeCell from '@components/Home/Alerts/Table/EventTimeCell';

interface AuditLogsProps {
  timeMode: TimeMode;
  siteMeta: SiteMeta;
}

// eslint-disable-next-line max-lines-per-function
function AuditLogs(props: AuditLogsProps): ReactElement {
  const defaultAPIBody: DefaultAPIBody = getDefaultAPIBody(
    props.timeMode,
    props.siteMeta,
  );

  const [apiBody, setAPIBody] = useState<object>(defaultAPIBody);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: defaultAPIBody.page,
    pageSize: defaultAPIBody.count,
  });

  const [selectedTimeMode, setSelectedTimeMode] = useState<TimeMode>(
    props.timeMode,
  );
  const [startDateISO, setStartDateISO] = useState<string>(defaultAPIBody.from);
  const [endDateISO, setEndDateISO] = useState<string | null>(null);
  const [auditLogRows, setAuditLogRows] = useState<AuditLog[] | []>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [requestBodyDetails, setRequestBodyDetails] = useState<Record<
    string,
    string
  > | null>(null);

  const [siteMeta] = useState<SiteMeta>(props.siteMeta);

  //  if Time Mode in header has been changed
  if (selectedTimeMode !== props.timeMode) {
    const updatedStartTimeISO: string = getAdjustedTime(
      dayjs(startDateISO),
      siteMeta,
      props.timeMode,
    );
    let endDateAPIObject = {};

    setSelectedTimeMode(props.timeMode);
    setStartDateISO(updatedStartTimeISO);

    //  only run if endDateISO has been previously set
    if (endDateISO) {
      const updatedEndDateTimeISO: string = getAdjustedTime(
        dayjs(endDateISO),
        siteMeta,
        props.timeMode,
      );
      endDateAPIObject = { to: updatedEndDateTimeISO };

      setEndDateISO(updatedEndDateTimeISO);
    }

    setAPIBody({ ...apiBody, from: updatedStartTimeISO, ...endDateAPIObject });
  }

  const startDateChange = (startDate: Dayjs | null): void => {
    const isoTimeAdjusted: string = getAdjustedTime(
      dayjs(startDate),
      siteMeta,
      selectedTimeMode,
    );

    setStartDateISO(isoTimeAdjusted);
    setAPIBody({
      ...apiBody,
      page: paginationModel.page,
      count: paginationModel.pageSize,
      from: isoTimeAdjusted,
      to: endDateISO,
    });
  };

  const endDateChange = (endDate: Dayjs | null): void => {
    const isoTimeAdjusted: string = getAdjustedTime(
      dayjs(endDate),
      siteMeta,
      selectedTimeMode,
    );
    setEndDateISO(isoTimeAdjusted);

    //  only reset apiBody state if there is also a start date
    //  if not, simply hold the isoEnd in its own state
    if (startDateISO) {
      setAPIBody({
        ...apiBody,
        page: paginationModel.page,
        count: paginationModel.pageSize,
        to: isoTimeAdjusted,
      });
    }
  };

  const getDatePickerComponent = (): ReactJSXElement => (
    <MultiDateSelector
      startDateISO={startDateISO}
      endDateISO={endDateISO}
      startDateChange={startDateChange}
      endDateChange={endDateChange}
    />
  );

  const handleDetailsClick = (gridRow: GridValidRowModel) => (): void => {
    setRequestBodyDetails(gridRow.body);
  };

  const auditLogGridColumns: GridColDef[] =
    generateAuditLogColumns(handleDetailsClick);

  const betweenDateRangeFilterOperator: GridFilterOperator = {
    label: 'Date Range',
    value: 'between-date-range',
    headerLabel: 'Date Range',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      const filterValue: boolean = filterItem.value;

      if (!filterItem.field || !filterValue || !filterItem.operator) {
        return null;
      }

      const [startRaw, endRaw] = filterItem.value.split(',');

      const start: Dayjs = dayjs(startRaw !== null ? startRaw : null);
      const end: Dayjs = dayjs(endRaw !== null ? endRaw : null);

      if (!start.isValid() || !end.isValid()) {
        return null;
      }

      return (rawValue) => {
        const value: Dayjs = dayjs(rawValue);
        return value.isValid() && value.isAfter(start) && value.isBefore(end);
      };
    },
    InputComponent: getDatePickerComponent,
  };

  const createdAtColumn: GridColDef = {
    headerName: 'Created At',
    description: 'created at filter',
    field: 'createdAt',
    minWidth: 200,
    headerAlign: 'center',
    renderCell: ({ value }) => <EventTimeCell time={new Date(value)} />,
    filterOperators: [betweenDateRangeFilterOperator],
  };

  auditLogGridColumns.push(createdAtColumn);

  const { refetch } = useApiQuery<PaginatedResponse<AuditLog>>({
    queryKey: [QueryKeys.auditLogs, Math.random().toString()],
    apiPath: '/audit-logs/search',
    method: 'POST',
    useV2API: true,
    body: { ...apiBody },
    enabled: false,
  });

  useEffect((): void => {
    const getAuditLogs = async (): Promise<void> => {
      const fetchOperation = await refetch();

      if (!fetchOperation.isError && fetchOperation.data) {
        const data: PaginatedResponse<AuditLog> =
          fetchOperation.data as PaginatedResponse<AuditLog>;
        const totalRows: number = data.totalCount;
        const auditLogs: AuditLog[] = data.paginatedResults;

        setTotalRowCount(totalRows);
        setAuditLogRows(auditLogs);
      }
    };

    if (Object.keys(apiBody).length > 0) {
      getAuditLogs();
    }
  }, [apiBody, paginationModel, refetch, selectedTimeMode]);

  const onFilterChange = useCallback(
    (filterModel: GridFilterModel): void => {
      const filters: GridFilterItem[] = filterModel.items;
      const filtersOn: boolean = filters.length > 0;
      let username = '';
      let email = '';
      let url = '';
      let httpMethod = {};

      //  if items are empty then the user
      //  selected clear filters so clear dates
      const from: string | null = filtersOn ? startDateISO : null;
      const to: string | null = filtersOn ? endDateISO : null;

      //  the onFilterChange fires on every keystroke
      //  it is possible that a value is not set yet
      //  ensure at least 1 search value exists.
      if (
        filtersOn &&
        Object.prototype.hasOwnProperty.call(filters[0], 'value')
      ) {
        filterModel.items.forEach((filterItem: GridFilterItem): void => {
          switch (filterItem.field) {
            case apiFields.httpMethod:
              if (filterItem.value !== undefined) {
                httpMethod = { httpMethod: filterItem.value.toUpperCase() };
              }
              break;
            case apiFields.username:
              username = filterItem.value;
              break;
            case apiFields.email:
              email = filterItem.value;
              break;
            case apiFields.url:
              url = filterItem.value;
              break;
            default:
              break;
          }
        });

        const searchBody = {
          from,
          to,
          page: paginationModel.page,
          count: paginationModel.pageSize,
          username,
          email,
          url,
          ...httpMethod,
        };

        setAPIBody(searchBody);
      } else {
        setAuditLogRows([]);
      }
    },
    [paginationModel.page, paginationModel.pageSize, startDateISO, endDateISO],
  );

  const paginationOnChange = (paginationEvent: GridPaginationModel): void => {
    const newPaginationModel = {
      page: paginationEvent.page,
      pageSize: paginationEvent.pageSize,
    };

    const newAPIBodyPagination = {
      page: newPaginationModel.page,
      count: newPaginationModel.pageSize,
    };

    setAPIBody({ ...apiBody, ...newAPIBodyPagination });
    setPaginationModel({ ...newPaginationModel });
  };

  const handleClose = (): void => {
    setRequestBodyDetails(null);
  };

  return (
    <div className='home-row frow'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <DataGridPro
            density='compact'
            pageSizeOptions={[25, 50, 75, 100]}
            pagination
            rowCount={totalRowCount}
            paginationMode='server'
            columns={auditLogGridColumns}
            rows={auditLogRows}
            paginationModel={paginationModel}
            onPaginationModelChange={paginationOnChange}
            filterMode='server'
            onFilterModelChange={onFilterChange}
            slots={{ toolbar: BasicGridToolbar }}
          />
          {requestBodyDetails && (
            <AuditLogDialog
              handleClose={handleClose}
              requestBodyDetails={requestBodyDetails}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default storeConnector(AuditLogs, {
  service: ['timeMode'],
  config: ['siteMeta'],
});
