import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridColDef,
  GridColType,
  GridFilterOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { ArticleSharp } from '@mui/icons-material';
import React from 'react';

// eslint-disable-next-line max-lines-per-function
export default function generateAuditLogColumns(
  handleDetailsClick: (gridRow: GridValidRowModel) => () => void,
): GridColDef[] {
  const stringFilterOperator = {
    operators: getGridStringOperators().filter(
      (operator) => operator.value === 'equals',
    ),
  };

  const singleSelectFilterOperator = {
    operators: getGridSingleSelectOperators().filter(
      (operator: GridFilterOperator): boolean => operator.value === 'is',
    ),
  };

  const AuditLogsGridColumns: GridColDef[] = [];

  const emailColumn: GridColDef = {
    field: 'email',
    headerName: 'email',
    width: 200,
    flex: 1,
    filterOperators: stringFilterOperator.operators,
  };

  const userNameColumn: GridColDef = {
    field: 'username',
    headerName: 'User Name',
    width: 200,
    flex: 1,
    filterOperators: stringFilterOperator.operators,
  };

  const urlColumn: GridColDef = {
    field: 'url',
    headerName: 'URL',
    width: 200,
    flex: 1,
    filterOperators: stringFilterOperator.operators,
  };

  const httpMethodColumn: GridColDef = {
    field: 'httpMethod',
    headerName: 'HTTP Method',
    width: 200,
    flex: 1,
    type: 'singleSelect',
    valueOptions: ['PUT', 'POST', 'PATCH', 'DELETE'],
    filterOperators: singleSelectFilterOperator.operators,
  };

  const requestBodyColumn: GridColDef = {
    field: 'body',
    type: 'actions' as GridColType,
    headerName: 'Details',
    description: 'Details',
    width: 120,
    flex: 1,
    cellClassName: 'actions',
    getActions: ({ row }) => [
      <GridActionsCellItem
        icon={<ArticleSharp />}
        label='Edit'
        className='textPrimaryx'
        color='inherit'
        onClick={handleDetailsClick(row)}
      />,
    ],
  };

  AuditLogsGridColumns.push(
    userNameColumn,
    urlColumn,
    httpMethodColumn,
    emailColumn,
    requestBodyColumn,
  );

  return AuditLogsGridColumns;
}
