import React from 'react';
import './Button.scoped.scss';
import classNames from 'classnames';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  classType?: string;
  type?: 'button' | 'submit' | 'reset';
  size?: string;
  disabled?: boolean;
  label?: string;
  title?: string;
  selected?: string;
}

function Button({
  children,
  classType,
  type,
  size,
  onClick,
  disabled = false,
  label = '',
  style,
  title,
  className = '',
  selected = '',
  ...rest
}: ButtonProps) {
  return (
    <button
      {...rest}
      className={classNames({
        [`type-${classType}`]: classType,
        [className]: className,
        selected,
        disabled,
        ...(size ? { [size]: size } : {}),
      })}
      style={style}
      onClick={onClick}
      disabled={disabled}
      title={title}
      type={type || 'button'}
    >
      {label !== undefined ? label : ''}
      {children !== undefined ? children : ''}
    </button>
  );
}

export default Button;
