import React from 'react';
import type { DataPointWithValue } from '@src/types/DataPoint';
import type { TransformedTeslaConfigItem } from '@src/types/TeslaConfig';
import { Switch, TableCell } from '@mui/material';
import { useCommandsModalContext } from '@src/components/Battery/Tesla/Commands/CommandsModalContext';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@src/store/storeConnector';
import TeslaStatusIcon from './TeslaStatusIcon';

interface StatusProps {
  datapoint: DataPointWithValue;
  item: TransformedTeslaConfigItem;
  siteMeta: SiteMeta;
}

function TSCStatus({ datapoint, item, siteMeta }: StatusProps) {
  const { sendCommand } = useCommandsModalContext();
  const handleCommand = (value: boolean) => {
    const newValue = value ? '1' : '0';

    sendCommand('WriteToVariant', {
      P: item.cmdPayload.replace('[input value]', newValue),
    });
  };

  return (
    <>
      <TableCell>
        <TeslaStatusIcon status={datapoint.value} />
      </TableCell>

      <TableCell>
        {siteMeta.ui.Command_Access_Enable && (
          <Switch
            checked={datapoint.value === 1}
            onChange={(e) => handleCommand(e.target.checked)}
          />
        )}
      </TableCell>
    </>
  );
}

export default storeConnector(TSCStatus, {
  config: ['siteMeta'],
});
