import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Button } from '@mui/material';
import {
  GridRowSelectionModel,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import useExportAlertConfigsQuery from '@src/hooks/api/queries/alerts/useExportAlertConfigsQuery';
import AlertsConfigBulkDialog from '../AlertsConfigBulkDialog';

interface AlertsConfigToolbarProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setBulkDelete: Dispatch<SetStateAction<boolean>>;
  rowSelectionModel: GridRowSelectionModel;
}

// eslint-disable-next-line max-lines-per-function
function AlertsConfigToolbar({
  setOpen,
  setBulkDelete,
  rowSelectionModel,
}: AlertsConfigToolbarProps) {
  const [importFile, setImportFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileType, setFileType] = useState<'csv' | 'json'>();
  useExportAlertConfigsQuery(fileType);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files ? e.currentTarget.files[0] : null;
    if (!file) return;
    setImportFile(file);
  };

  const handleCloseBulkDialog = () => {
    setImportFile(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  const handleBulkDelete = () => {
    setBulkDelete(true);
    setOpen(true);
  };

  return (
    <>
      <GridToolbarContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 1,
          '& .MuiButton-root': {
            mr: 1,
          },
        }}
      >
        <div>
          <Button startIcon={<AddIcon />} onClick={() => setOpen(true)}>
            Add config
          </Button>

          <Button component='label' startIcon={<CloudUploadIcon />}>
            Bulk add
            <input
              placeholder='alert-bulk-upload'
              type='file'
              accept='.csv,.json'
              ref={fileInputRef}
              onChange={handleFileChange}
              className='bulk-upload-hidden-input'
              multiple
            />
          </Button>
          <Button
            startIcon={<DeleteSweepIcon />}
            onClick={handleBulkDelete}
            disabled={rowSelectionModel.length <= 1}
          >
            Delete
          </Button>
        </div>
        <div>
          <Button onClick={() => setFileType('csv')}>Export CSV</Button>
          <Button onClick={() => setFileType('json')}>Export JSON</Button>
        </div>
      </GridToolbarContainer>
      {importFile && (
        <AlertsConfigBulkDialog
          importFile={importFile as File}
          handleCloseBulkDialog={handleCloseBulkDialog}
        />
      )}
    </>
  );
}

export default AlertsConfigToolbar;
