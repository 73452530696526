const toMatrix = (arr: unknown[], width: number) =>
  arr.reduce((rows: unknown[][], key, index: number) => {
    if (index % width === 0) {
      rows.push([key]);
    } else {
      rows[rows.length - 1].push(key);
    }
    return rows;
  }, []);

export default toMatrix;
