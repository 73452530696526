export default [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    width: 200,
  },
  {
    field: 'header',
    headerName: 'Header',
    type: 'string',
    width: 200,
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'string',
    width: 200,
  },
  {
    field: 'isSystemWidget',
    headerName: 'Is System Widget',
    type: 'boolean',
    width: 200,
  },
];
