import React from 'react';
import {
  ModeConfig,
  ModeMetadataContent,
  ModeStatus,
  ModeSubmitForm,
} from '@src/types/control-modes/Modes';
import './Modes.scoped.scss';
import { ControlMode } from '@src/types/control-modes/ControlModes';
import modesMetadata from './ModesMetadata';
import FullConfigPopupButton from './ModesPopupButtons/FullConfigPopupButton';
import ModeConfigForm from './ModeConfigForm';
import ModeStatusIcon from './ModeStatusIcon';

interface ModeRowProps {
  config: ModeConfig;
  status: ModeStatus;
  fractalStatus: ModeStatus;
  SCADAStatus: ModeStatus;
  mode: ControlMode;
  SCADAMode: boolean;
  submitForm: ModeSubmitForm;
}

function ModeRow({
  config,
  status,
  fractalStatus,
  SCADAStatus,
  mode,
  SCADAMode,
  submitForm,
}: ModeRowProps) {
  const modeMetadata: ModeMetadataContent = modesMetadata[mode];

  return (
    <div>
      <div className='modes-row'>
        <div className='modes-cell' style={{ justifyContent: 'right' }}>
          <span style={{ marginRight: '20px' }}>
            <b>{modeMetadata.displayName ? modeMetadata.displayName : mode}:</b>
          </span>
        </div>

        <div
          className='modes-cell'
          style={{ flex: 1, justifyContent: 'center' }}
        >
          <ModeStatusIcon status={status} />
        </div>
        <div
          className='modes-cell'
          style={{ flex: 1, justifyContent: 'center' }}
        >
          <ModeStatusIcon status={fractalStatus} />
        </div>
        <div
          className='modes-cell'
          style={{ flex: 1, justifyContent: 'center' }}
        >
          <ModeStatusIcon status={SCADAStatus} />
        </div>

        <div
          className='modes-cell'
          style={{ flex: 10, justifyContent: 'center' }}
        >
          <ModeConfigForm
            config={config}
            formType='quick'
            mode={mode}
            status={status}
            SCADAMode={SCADAMode}
            submitForm={submitForm}
          />
        </div>

        <div
          className='modes-cell'
          style={{ flex: 1, justifyContent: 'right', marginRight: '10px' }}
        >
          <FullConfigPopupButton
            config={config}
            modeDisplayName={modeMetadata.displayName}
            modeName={mode}
            submitForm={submitForm}
          />
        </div>
      </div>
      <hr />
    </div>
  );
}

export default ModeRow;
