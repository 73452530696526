import { dispatcher } from '@store';

export const service = {
  updateStore(data) {
    return function (dispatch) {
      Object.keys(data).forEach((module) => {
        dispatch(
          dispatcher(`UPDATE_${module.toUpperCase()}_MODULE`, data[module]),
        );
      });
    };
  },
  setInverterIdx(index) {
    return function (dispatch) {
      dispatch(
        dispatcher('UPDATE_SERVICE_MODULE', { currentInverterIdx: index }),
      );
    };
  },
  showComandsPopup(showState, popupPropsValue) {
    return function (dispatch) {
      dispatch(
        dispatcher('UPDATE_SERVICE_MODULE', {
          popupProps: popupPropsValue,
          isShownCommandsPopup: showState,
        }),
      );
    };
  },
  showNotesModal({ isNotesOpen, deviceId }) {
    return function (dispatch) {
      dispatch(
        dispatcher('UPDATE_SERVICE_MODULE', {
          noteProps: {
            isNotesOpen: isNotesOpen,
            deviceId: deviceId,
          },
        }),
      );
    };
  },
  setActiveUnitSN(unitSN) {
    return function (dispatch) {
      dispatch(
        dispatcher('UPDATE_SERVICE_MODULE', { currentUnitDeviceId: unitSN }),
      );
    };
  },
  setActiveUnitName(unitName) {
    return function (dispatch) {
      dispatch(
        dispatcher('UPDATE_SERVICE_MODULE', { currentUnitName: unitName }),
      );
    };
  },
  setTimeMode(timeMode) {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_SERVICE_MODULE', { timeMode }));
    };
  },
};
