import QueryKeys from '@src/constants/queryKeys';
import useApiQuery from '@src/hooks/useApiQuery';
import { User } from '@src/types/User';

const useUser = (userId: string) =>
  useApiQuery<User>({
    queryKey: [QueryKeys.user, userId],
    apiPath: `/users/${userId}`,
    method: 'GET',
    useV2API: true,
  });

export default useUser;
