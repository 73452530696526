import TextLoader from '@components/_elements/TextLoader';
import storeConnector from '@store/storeConnector';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { isKeyInObject, useInterval } from '@utils';
import React, { useEffect, useRef, useState } from 'react';
import { getPCSFields, UNIT_FIELDS } from './constants.js';

const PCSContainerPE = ({
  siteMeta,
  currentUnitDeviceId,
  pcsType,
  fromPV,
  pcsIndex,
}) => {
  const [currHBInterval, setCurrHBInterval] = useState(0);
  const [prevHB, setPrevHB] = useState(0);
  const [unitRt, setUnitRt] = useState({});
  const [pcsRt, setPCSRt] = useState({});
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  useEffect(() => {
    setUnitRt({});
    setPCSRt({});
  }, [currentUnitDeviceId]);

  useWsSubscribe(
    {
      unit: {
        sns: [currentUnitDeviceId],
        fields: UNIT_FIELDS,
        cb: (data) => setUnitRt((prev) => ({ ...prev, ...data })),
      },
      pcs: {
        sns: [currentUnitDeviceId],
        fields: getPCSFields(pcsType),
        sourceDeviceId: `pcs_${pcsIndex + 1}`,
        cb: (data) => setPCSRt((prev) => ({ ...prev, ...data })),
      },
    },
    [currentUnitDeviceId],
  );

  useInterval(() => {
    if (pcsRt && pcsRt.WatchdogResponse !== prevHB) {
      setCurrHBInterval(0);
      setPrevHB(pcsRt.WatchdogResponse);
    } else {
      setCurrHBInterval((currHBInterval) => currHBInterval + 1);
    }
  }, 1000);

  const processCommaString = (v, convertToInt = false) => {
    if (typeof v === 'string') {
      return v
        .split(',')
        .map((seg) => (convertToInt ? parseInt(seg, 10) : seg))
        .join(',');
    }
    return '-';
  };

  const getInfoDict = () => {
    const { Thresh_HB_PCS_TimeOut } = siteMeta ? siteMeta : {};
    return [
      {
        field: 'Status',
        value: unitRt['InvStatus'],
        tooltip: 'unit/InvStatus',
        loading:
          isKeyInObject(unitRt, 'InvStatus') &&
          isKeyInObject(unitRt, 'Inv Status'),
      },
      {
        field: 'AC kW',
        value: pcsRt.kW,
        tooltip: 'kW',
        loading: isPCSLoading('kW'),
      },
      {
        field: 'Va',
        value: pcsRt.PhVphA,
        tooltip: 'PhVphA',
        loading: isPCSLoading('PhVphA'),
      },
      { field: ' ', value: ' ' },
      {
        field: 'Mode',
        value: unitRt['RunMode'],
        tooltip: 'unit/RunMode',
        loading:
          isKeyInObject(unitRt, 'RunMode') && isKeyInObject(unitRt, 'Run Mode'),
      },
      {
        field: 'AC kVAR',
        value: pcsRt.kVAR,
        tooltip: 'kVAR',
        loading: isPCSLoading('kVAR'),
      },
      {
        field: 'Vb',
        value: pcsRt.PhVphB,
        tooltip: 'PhVphB',
        loading: isPCSLoading('PhVphB'),
      },
      {
        field: 'DC kW',
        value: pcsRt.DCkW,
        tooltip: 'DCkW',
        loading: isPCSLoading('DCkW'),
      },
      { field: ' ', value: ' ' },
      {
        field: 'AC kVA',
        value: pcsRt.kVA,
        tooltip: 'kVA',
        loading: isPCSLoading('kVA'),
      },
      {
        field: 'Vc',
        value: pcsRt.PhVphC,
        tooltip: 'PhVphC',
        loading: isPCSLoading('PhVphC'),
      },
      {
        field: 'DC Bus Voltage',
        value: pcsRt.DCV,
        tooltip: 'DCV',
        loading: isPCSLoading('DCV'),
      },
      {
        field: 'Frequency',
        value: pcsRt.Hz,
        tooltip: 'Hz',
        loading: isPCSLoading('Hz'),
      },
      {
        field: 'PF',
        value: pcsRt.PF,
        tooltip: 'PF',
        loading: isPCSLoading('PF'),
      },
      {
        field: 'Ia',
        value: pcsRt.AphA,
        tooltip: 'AphA',
        loading: isPCSLoading('AphA'),
      },
      {
        field: 'DC Amps',
        value: pcsRt.DCA,
        tooltip: 'DCA',
        loading: isPCSLoading('DCA'),
      },
      {
        field: 'PCS Heartbeat',
        value: pcsRt.WatchdogResponse || '-',
        tooltip: 'WatchdogResponse',
        class:
          currHBInterval > +Thresh_HB_PCS_TimeOut && prevHB !== 0
            ? 'bold red-txt'
            : '',
        loading: isPCSLoading('WatchdogResponse'),
      },
      {
        field: 'Bus 1 Enable',
        value: pcsRt.Bank1Enable || '-',
        tooltip: 'Bank1Enable',
        loading: isPCSLoading('Bank1Enable'),
      },
      {
        field: 'Ib',
        value: pcsRt.AphB,
        tooltip: 'AphB',
        loading: isPCSLoading('AphB'),
      },
      { field: ' ', value: ' ' },
      {
        field: 'Chg Power Limit ',
        value: pcsRt.PCS_MinP,
        tooltip: 'PCS_MinP',
        loading: isPCSLoading('PCS_MinP'),
      },
      {
        field: 'Bus 2 Enable',
        value: pcsRt.Bank2Enable || '-',
        tooltip: 'Bank2Enable',
        loading: isPCSLoading('Bank2Enable'),
      },
      {
        field: 'Ic',
        value: pcsRt.AphC,
        tooltip: 'AphC',
        loading: isPCSLoading('AphC'),
      },
      { field: ' ', value: ' ' },
      {
        field: 'Dchg Power Limit',
        value: pcsRt.PCS_MaxP,
        tooltip: 'PCS_MaxP',
        loading: isPCSLoading('PCS_MaxP'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'kW Cmd',
        value: unitRt.PCS_P,
        tooltip: 'unit/PCS_P',
        loading: isKeyInObject(unitRt, 'PCS_P'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'kVAR Cmd',
        value: unitRt.PCS_Q,
        tooltip: 'unit/PCS_Q',
        loading: isKeyInObject(unitRt, 'PCS_Q'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Mod St',
        value: processCommaString(pcsRt.ModSt),
        tooltip: 'ModSt',
        loading: isKeyInObject(pcsRt, 'ModSt'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Mod P',
        value: processCommaString(pcsRt.ModP, true),
        tooltip: 'ModP',
        loading: isKeyInObject(pcsRt, 'ModP'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Current Status',
        value: pcsRt.CurrentStatus,
        tooltip: 'CurrentStatus',
        loading: isKeyInObject(pcsRt, 'CurrentStatus'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Current Warning',
        value: pcsRt.CurrentWarn,
        tooltip: 'CurrentWarn',
        loading: isKeyInObject(pcsRt, 'CurrentWarn'),
      },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      { field: ' ', value: ' ' },
      {
        field: 'Current Fault',
        value: pcsRt.CurrentFault,
        tooltip: 'CurrentFault',
        loading: isKeyInObject(pcsRt, 'CurrentFault'),
      },
    ];
  };

  const isPCSLoading = (field) => {
    return !Object.keys(pcsRt).includes(field);
  };

  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell unit-cell'>
        <div className='unit-info'>
          <div className='unit-col'>
            <div className='title-large'>
              {`${unitName && unitName + ' '}PCS Status${
                pcsType ? ` - ${pcsType}` : ''
              }`}
              <span
                style={{
                  marginLeft: '10px',
                  opacity: '0.8',
                  fontWeight: 'normal',
                }}
              >
                {pcsRt.Description}
              </span>
            </div>
            <div className='info-grid pcs'>
              {getInfoDict().map((f, i) => (
                <React.Fragment key={`field-row-${f.field}-${i}`}>
                  <div className='title'>{f.field}</div>
                  <div className={f.class}>
                    <TextLoader
                      orientation='l'
                      loading={f.loading}
                      toolTipContent={f.tooltip}
                    >
                      {f.value}
                    </TextLoader>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(PCSContainerPE, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
