import React from 'react';
import './SiteUnitCommandsPopup.scoped.scss';
import Input from '@components/_elements/Input/Input';
import Draggable from 'react-draggable';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';

const LotoPopup = ({
  popupProps,
  batteryConnectingStatus,
  invStatus,
  LocRemCtl,
  overInput,
  setLotoPopupIsOpen,
  setOverInput,
  lotoReason,
  setLotoConfirm,
  setLotoReason,
}) => {
  const isUnitRunned = () => {
    if (popupProps.popupType === 'unit') {
      let racksOnline = 0;
      let racks = 0;
      if (batteryConnectingStatus && batteryConnectingStatus.length === 4) {
        racksOnline = parseInt(batteryConnectingStatus.substr(0, 2), 16);
        racks = parseInt(batteryConnectingStatus.substr(2), 16);
      }
      const isStopped =
        ['Ready', 'Off'].includes(invStatus) &&
        LocRemCtl === 'Manual' &&
        racks === racksOnline;
      return !isStopped;
    }
    return false;
  };

  return (
    <div className='cover-container cover-container-confirm'>
      <Draggable bounds='body' disabled={overInput}>
        <div
          className='control-popup-container'
          style={{ padding: '20px 30px' }}
        >
          {!isUnitRunned() ? (
            <div>
              <div>
                Please ensure unit is stopped and all DC Contactors are OPEN
                prior to Lockout
              </div>
              <div className='popup-row commands-popup-control'>
                <Button size='m' onClick={() => setLotoPopupIsOpen(false)}>
                  OK
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className='loto-reason-row'>
                <span>Reason for Controls Lock</span>
                <Input
                  type='text'
                  onMouseEnter={() => setOverInput(true)}
                  onMouseLeave={() => setOverInput(false)}
                  value={lotoReason}
                  onChange={(val) => setLotoReason(val)}
                  placeholder={'Enter reason'}
                />
              </div>
              <div className='popup-row commands-popup-control'>
                <Button
                  disabled={!lotoReason}
                  onClick={() => {
                    setLotoConfirm(true);
                    setLotoPopupIsOpen(false);
                  }}
                >
                  LOCK CONTROLS
                </Button>
                <Button
                  size='m'
                  onClick={() => {
                    setLotoReason('');
                    setLotoPopupIsOpen(false);
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </div>
          )}
        </div>
      </Draggable>
    </div>
  );
};

export default storeConnector(LotoPopup, {
  service: ['popupProps'],
});
