import React, { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import UsersList from '@src/components/Admin/Users/UserList/UsersList';

function UserPanel(): ReactElement {
  return (
    <div className='home-row frow'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <UsersList />
        </div>
      </div>
    </div>
  );
}

export default storeConnector(UserPanel, {
  service: ['timeMode'],
});
