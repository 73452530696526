import { getUserRole } from '@src/services/auth';
import { callAPI, tzDict } from '@utils';
import * as d3 from 'd3';
import { dispatcher, store } from '@store';

export const user = {
  updateClient(data) {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_USER_MODULE', { client: data }));
    };
  },

  logout() {
    return function (dispatch) {
      d3.select('#cubism-chart').remove();
      localStorage.removeItem(
        `EMS_${store.getState().user.username}_activeTzMode`,
      );
      store.getState().user.keycloak.logout();
      dispatch(dispatcher('LOGOUT'));
    };
  },

  setUserIp() {
    return async function (dispatch) {
      let ip = 'unavailable';
      try {
        ip = await (await fetch('https://api64.ipify.org?format=text')).text();
      } catch (e) {
        console.debug('Failed to get IP:', e);
      }

      dispatch(
        dispatcher('UPDATE_USER_MODULE', {
          ip,
        }),
      );
    };
  },

  setUser(parsedToken) {
    return async function (dispatch) {
      try {
        const atrs = parsedToken.attributes || {};
        const {
          showMap,
          theme,
          timeMode,
          audioNotificationEnabled,
          bannerNotificationEnabled,
        } = atrs;

        const userRoles = store.getState().user.keycloak.realmAccess.roles;

        dispatch(
          dispatcher('UPDATE_USER_MODULE', {
            role: getUserRole(userRoles || []),
            username: parsedToken.preferred_username,
            email: parsedToken.email,
            name: parsedToken.name,
          }),
        );

        dispatch(
          dispatcher('UPDATE_SERVICE_MODULE', {
            ...(Object.keys(atrs).includes('timeMode')
              ? { timeMode: tzDict[timeMode] }
              : {}),
            ...(Object.keys(atrs).includes('showMap')
              ? { showMap: [true, 'true'].includes(showMap) }
              : {}),
            ...(Object.keys(atrs).includes('theme')
              ? { darkTheme: theme === 'dark' }
              : {}),
            ...(Object.keys(atrs).includes('audioNotificationEnabled')
              ? { audioNotificationEnabled }
              : {}),
            ...(Object.keys(atrs).includes('bannerNotificationEnabled')
              ? { bannerNotificationEnabled }
              : {}),
          }),
        );
      } catch (error) {
        console.error('Error setting user:', error);
      }
    };
  },

  changeUserData(data) {
    return function () {
      return new Promise((resolve, reject) => {
        callAPI('/userParams', 'PUT', data)
          .then((r) => resolve(r))
          .catch((r) => reject(r));
      });
    };
  },
};
