import React, { useState } from 'react';
import Input from '@components/_elements/Input/Input';
import Popup from '@components/_elements/Popup/Popup';
import storeConnector from '@store/storeConnector';

const SettingsPopup = ({
  actions,
  timeMode,
  showMap,
  darkTheme,
  audioNotificationEnabled: stateAudioNotificationEnabled,
  bannerNotificationEnabled: stateUnitFaultBannerAlarmEnabled,
  isOpen,
  close,
}) => {
  const [actShowMap] = useState(!!showMap);
  const [theme, setTheme] = useState(darkTheme ? 'dark' : 'light');
  const [loading, setLoading] = useState(false);
  const [audioNotificationEnabled, setAudioNotificationEnabled] = useState(
    stateAudioNotificationEnabled,
  );

  const [bannerNotificationEnabled, setBannerNotificationEnabled] = useState(
    stateUnitFaultBannerAlarmEnabled,
  );

  const updateSettings = async (field) => {
    const currParams = {
      timeMode: timeMode.toLowerCase(),
      showMap: `${actShowMap}`,
      theme,
      audioNotificationEnabled,
      bannerNotificationEnabled,
    };

    setLoading(true);

    switch (field) {
      case 'theme':
        currParams.theme = theme === 'dark' ? 'light' : 'dark';
        setTheme(currParams.theme);
        if (currParams.theme === 'dark') {
          document.querySelector('body').classList.remove('theme--light');
          document.querySelector('body').classList.add('theme--dark');
        } else {
          document.querySelector('body').classList.remove('theme--dark');
          document.querySelector('body').classList.add('theme--light');
        }
        break;
      case 'audioNotificationEnabled':
        currParams.audioNotificationEnabled = !audioNotificationEnabled;
        setAudioNotificationEnabled(currParams.audioNotificationEnabled);
        break;
      case 'bannerNotificationEnabled':
        currParams.bannerNotificationEnabled = !bannerNotificationEnabled;
        setBannerNotificationEnabled(currParams.bannerNotificationEnabled);
        break;
    }

    const res = await actions.changeUserData(currParams);

    if (!res || (res && res.error)) {
      actions.notifyError(
        `Error saving user settings: ${res.error || 'Params update error'}`,
      );
    }

    actions.updateStore({
      service: {
        ...(field === 'theme'
          ? { darkTheme: currParams.theme === 'dark' }
          : {}),
        ...(field === 'audioNotificationEnabled'
          ? {
              audioNotificationEnabled: currParams.audioNotificationEnabled,
            }
          : {}),
        ...(field === 'theme'
          ? {
              bannerNotificationEnabled: currParams.bannerNotificationEnabled,
            }
          : {}),
      },
    });

    setLoading(false);
  };

  return (
    <Popup
      isOpen={isOpen}
      title={'Settings'}
      close={() => close()}
      loading={loading}
    >
      <div>Theme dark mode</div>
      <Input
        onChange={() => updateSettings('theme')}
        checked={theme === 'dark'}
        type='toggle'
        label='changeTheme'
      />
      <div>Audio Notification</div>
      <Input
        onChange={() => updateSettings('audioNotificationEnabled')}
        checked={audioNotificationEnabled}
        type='toggle'
        label='audioNotification'
      />
      <div>Banner Notification</div>
      <Input
        onChange={() => updateSettings('bannerNotificationEnabled')}
        checked={bannerNotificationEnabled}
        type='toggle'
        label='bannerNotification'
      />
    </Popup>
  );
};

export default storeConnector(SettingsPopup, {
  service: [
    'showMap',
    'darkTheme',
    'timeMode',
    'audioNotificationEnabled',
    'bannerNotificationEnabled',
  ],
});
