import { store } from '@src/store';

const convertToC = (value: number): number => ((value - 32) * 5) / 9;
const convertToF = (value: number): number => value * (9 / 5) + 32;

interface AdditionalParams {
  decimals?: number;
  initialUnit?: 'C' | 'F';
}

const defaultParams: AdditionalParams = {
  decimals: 1,
  initialUnit: 'C',
};

export const getSiteTemperatureUnit = () =>
  store.getState().config.siteMeta.ui.Default_Temp_Unit || 'C';

const getTemperatureString = (
  value: unknown,
  {
    decimals = defaultParams.decimals,
    initialUnit = defaultParams.initialUnit,
  }: AdditionalParams = defaultParams,
): string => {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return 'ERR';
  }
  const targetUnit = getSiteTemperatureUnit();

  let temperatureValue = value;
  if (initialUnit === 'F' && targetUnit === 'C') {
    temperatureValue = convertToC(value);
  } else if (initialUnit === 'C' && targetUnit === 'F') {
    temperatureValue = convertToF(value);
  }

  return `${temperatureValue.toFixed(decimals)}°${targetUnit}`;
};

export default getTemperatureString;
