import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Stack from '@components/Home/Config/Stack';
import StackPreview from '@components/Home/Config/StackPreview/StackPreview';
import Input from '@components/_elements/Input/Input';
import Spinner from '@components/_elements/Spinner/Spinner';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';

const StackList = ({ actions, siteMeta }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletingElementId, setDeletingElementId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stacks, setStacks] = useState({});
  const [selectedStack, setSelectedStack] = useState(false);
  const [isModelStacksShown, setIsModelStacksShown] = useState(true);
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      const stacks = await actions.loadStacks(siteMeta.siteId);
      setStacks(stacks);
      setLoading(false);
    })();
  }, []);

  const previewStack = async (stackId) => {
    if (selectedStack === stackId) {
      stackId = false;
    }
    setSelectedStack(stackId);
  };

  const sortObjKeys = (obj) => {
    return Object.keys(obj).sort((a, b) => {
      if (obj[a].name.toUpperCase() > obj[b].name.toUpperCase()) {
        return 1;
      }
      if (obj[a].name.toUpperCase() < obj[b].name.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  };
  const filterObj = (obj, str) => {
    const newObj = {};
    Object.keys(obj).forEach((el) => {
      if (obj[el].name.toUpperCase().includes(str.toUpperCase())) {
        newObj[el] = obj[el];
      }
    });
    return newObj;
  };
  const filterObjNoModel = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((el) => {
      if (!obj[el].Operator.includes('Model-')) {
        newObj[el] = obj[el];
      }
    });
    return newObj;
  };
  const deleteStackClicked = (id) => {
    setShowConfirm(true);
    setDeletingElementId(id);
  };
  const deleteStackAccepted = async () => {
    setLoading(true);
    setShowConfirm(false);
    await actions.deleteStack(siteMeta.siteId, deletingElementId);
    const stacks = await actions.loadStacks(siteMeta.siteId);
    if (selectedStack === deletingElementId) {
      setSelectedStack(false);
    }
    setStacks(stacks);
    setLoading(false);
  };

  const stacksSearched = searchStr ? filterObj(stacks, searchStr) : stacks;
  const stacksToRender = isModelStacksShown
    ? stacksSearched
    : filterObjNoModel(stacksSearched);
  return (
    <div className='home-row'>
      {!loading ? (
        <div className='frow'>
          <div className='fcol'>
            <div className='all-container cell block-container'>
              <div className='all-list'>
                <div className='all-list-title'>STACKS</div>
                <div className='control-row'>
                  <div className='show-hide-btn'>
                    <Input
                      type='checkbox'
                      className='colored-active'
                      value={isModelStacksShown}
                      onChange={() =>
                        setIsModelStacksShown(
                          (isModelStacksShown) => !isModelStacksShown,
                        )
                      }
                    />
                    <span
                      onClick={() =>
                        setIsModelStacksShown(
                          (isModelStacksShown) => !isModelStacksShown,
                        )
                      }
                    >
                      Show Model stacks
                    </span>
                  </div>
                  <Input
                    type='text'
                    className='dark-bg'
                    noAutocomplete
                    value={searchStr}
                    onChange={(val) => setSearchStr(val)}
                    placeholder={'Enter search request'}
                  />
                </div>
                {stacksToRender &&
                  sortObjKeys(stacksToRender).map((id) => (
                    <Stack
                      searchStr={searchStr}
                      name={stacks[id].name}
                      key={'key_' + id}
                      id={id}
                      selected={selectedStack === id}
                      onViewClick={(id) => previewStack(id)}
                      onDeleteClick={(id) => deleteStackClicked(id)}
                    />
                  ))}
                {!sortObjKeys(stacksToRender).length && (
                  <div
                    style={{ paddingTop: '100px', paddingBottom: '100px' }}
                    className='warn-alarm-no-data'
                  >
                    {searchStr.length
                      ? 'No stacks with such name'
                      : 'No created stacks'}
                  </div>
                )}
                <Link className='link-btn' to={'/home/config/stacks/create/'}>
                  <Button className='stacks-button'>Create Stack</Button>
                </Link>
              </div>
              {selectedStack && <StackPreview stackid={selectedStack} />}
              {showConfirm && (
                <div className='cover-container'>
                  <div className='control-popup-container small-popup'>
                    <div className='popup-row centre'>
                      <div className='bold '>
                        {`Delete${' ' + stacks[deletingElementId].name || ''}?`}
                      </div>
                    </div>
                    <div className='popup-row commands-popup-control'>
                      <Button
                        size='m'
                        onClick={() => {
                          deleteStackAccepted();
                        }}
                      >
                        OK
                      </Button>
                      <Button size='m' onClick={() => setShowConfirm(false)}>
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Spinner type={'fullPage'} />
      )}
    </div>
  );
};

export default storeConnector(StackList, {
  config: ['siteMeta'],
});
