export interface NotesState {
  deviceId: string;
  isNotesOpen: boolean;
}

export interface Note {
  note: string;
  createdAt: string;
  deviceId: string;
  id: string;
  user: string;
}

export interface NotePayload {
  note: string;
  deviceId: string;
  user: string;
}

export interface DeviceNotesResponse {
  notes: Note[];
}

export enum ConfirmType {
  CREATE = 'CREATE',
  CLEAR = 'CLEAR',
  DELETE = 'DELETE_NOTE',
}
