import React from 'react';
// import { UserRole } from '@src/services/auth/roles';
import storeConnector from '@store/storeConnector';

import { DataGridPro } from '@mui/x-data-grid-pro';

function PointConfigCollection() {
  return (
    <div className='home-row frow' id='alerts-config-wrapper'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <DataGridPro rows={[]} columns={[]} />
        </div>
      </div>
    </div>
  );
}

export default storeConnector(PointConfigCollection, {
  user: ['role'],
});
