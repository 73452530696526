import Spinner from '@components/_elements/Spinner/Spinner';
import MultiColPointDataTable from '@components/_shared/MultiColPointDataTable';
import UIBox from '@components/_shared/UIBox';
import { useCurrentPowinControllerIdContext } from '@hooks/useCurrentPowinControllerId';
import { Grid2 } from '@mui/material';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';
import { SiteMeta } from '@src/types/SiteMeta';
import { WidgetTypePointList } from '@src/types/Widget';
import storeConnector from '@store/storeConnector';
import React, { useEffect } from 'react';

function PowinControllerDetails({ siteMeta }: { siteMeta: SiteMeta }) {
  const { currentPowinControllerId } = useCurrentPowinControllerIdContext();
  const {
    data: widgetConfig,
    isLoading,
    refetch,
  } = useGetWidgetConfig<WidgetTypePointList>('powinControllerDetails', {
    sourceDeviceId: currentPowinControllerId,
  });

  useEffect(() => {
    refetch();
  }, [refetch, currentPowinControllerId]);

  return (
    <UIBox
      header={`${siteMeta.PowinControllerNames[siteMeta.PowinControllers.indexOf(currentPowinControllerId)]} Details`}
    >
      {isLoading ? (
        <Spinner type='content-small' />
      ) : (
        <Grid2 container justifyContent='space-evenly'>
          <MultiColPointDataTable
            dataPointsMatrix={widgetConfig?.dataPoints || []}
          />
        </Grid2>
      )}
    </UIBox>
  );
}

export default storeConnector(PowinControllerDetails, {
  config: ['siteMeta'],
});
