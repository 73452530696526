import React from 'react';
import { Box } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import GridToolbarAcknowledgeButton from './GridToolbarAcknowledgeButton';

function AlertsGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />

      {!process.env.VITE_READ_ONLY_MODE && <GridToolbarAcknowledgeButton />}

      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default AlertsGridToolbar;
