import { AlertsState } from '@src/types/Alerts';
import { CombinedState, combineReducers } from 'redux';
import mqtt, { MqttState } from '@store/reducers/mqttData';
import service, { ServiceState } from './serviceData';
import user, { UserState } from './userData';
import site, { SiteState } from './siteReducer';
import config, { ConfigState } from './configData';
import alerts from './alerts';

export interface AppDefaultStateProps {
  service: ServiceState;
  user: UserState;
  site: SiteState;
  mqtt: MqttState;
  config: ConfigState;
  alerts: AlertsState;
}

export type AggregatedState =
  | ServiceState
  | UserState
  | SiteState
  | MqttState
  | ConfigState
  | AlertsState;

export type UnionOfKeys<T> = T extends T ? keyof T : never;

export type StoreConnectorData = {
  service?: 'all' | UnionOfKeys<ServiceState>[];
  user?: 'all' | UnionOfKeys<UserState>[];
  site?: 'all' | UnionOfKeys<SiteState>[];
  mqtt?: 'all' | UnionOfKeys<MqttState>[];
  config?: 'all' | UnionOfKeys<ConfigState>[];
  alerts?: 'all' | UnionOfKeys<AlertsState>[];
};

export type AppDefaultState = CombinedState<AppDefaultStateProps>;

export default combineReducers({
  service,
  user,
  site,
  mqtt,
  config,
  alerts,
});
