import CloseIcon from '@mui/icons-material/Close';
import { generateMqttClient } from '@pages/_loader/initMqttClient.loader';
import { MqttClient } from 'mqtt';
import React, { useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import MegapackDetails from '@components/Battery/Tesla/MegapackDetails';

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  selectedMpId: string;
  open: boolean;
  tscName: string;
  onClose: () => void;
}

function MPDetailsModal(props: Props) {
  const { selectedMpId, open } = props;
  const [mqttClient, setMqttClient] = React.useState<MqttClient | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!open) return () => {};
    const startClient = async () => {
      const client = await generateMqttClient();
      setMqttClient(client);
    };
    startClient();
    return () => mqttClient?.end();
    // eslint-disable-next-line
  }, [open]);

  const onClose = () => {
    props.onClose();
    mqttClient?.end();
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth='md' fullWidth>
      <StyledDialogTitle>
        {props.tscName} Megapack {props.selectedMpId.split('_').pop()} Details
        <IconButton onClick={onClose} sx={{ mr: -2, mt: -1 }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent dividers>
        <Box sx={{ mt: 1, mb: 5 }}>
          {open ? (
            <MegapackDetails
              selectedMpId={selectedMpId}
              useNewClient={mqttClient}
            />
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default MPDetailsModal;
