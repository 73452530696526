import Keycloak from 'keycloak-js';
import { KEYCLOAK_URL } from '@utils';
import { dispatcher, store } from '@store';
import { user } from '@store/actionCreators/user';
import { Action } from 'redux';

export default async function initKeycloak() {
  const REFRESH_KEYCLOAK_INTERVAL = 60;

  const keycloak = new Keycloak({
    url: KEYCLOAK_URL,
    realm: process.env.VITE_KEYCLOAK_REALM || 'fractal',
    clientId: process.env.VITE_KEYCLOAK_CLIENT_ID || 'fractal',
  });
  store.dispatch(dispatcher('UPDATE_USER_MODULE', { keycloak }));

  await keycloak
    .init({ onLoad: 'login-required', checkLoginIframe: false })
    .then(async (auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        // Set user token
        store.dispatch(
          user.setUser(keycloak.idTokenParsed) as unknown as Action,
        );
        // Set user IP
        store.dispatch(user.setUserIp() as unknown as Action);

        // Token Refresh
        setInterval(
          () => {
            keycloak.updateToken(REFRESH_KEYCLOAK_INTERVAL).catch(() => {
              store.dispatch(user.logout() as unknown as Action);
            });
          },
          (REFRESH_KEYCLOAK_INTERVAL / 2) * 1000,
        );
      }
    });
}
