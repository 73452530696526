import React, { ReactElement } from 'react';
import useDataPointsSearch from '@hooks/api/queries/useDataPointsSearch';
import useSubscription from '@hooks/useSubscription';
import { DataPointWithValue } from '@src/types/DataPoint';
import storeConnector from '@store/storeConnector';
import {
  DataPointQuery,
  UnitDataPoints,
  UnitConfig,
  DefaultSLDProps,
} from '@src/sld/types';
import TopSwitchResistorSection from '@src/sld/sagebrush/sagebrush_b40/components/TopSwitchResistorSection';
import Switch2Units from '@src/sld/sagebrush/sagebrush_b40/components/Switch2Units';
import Switch1Units from '@src/sld/sagebrush/sagebrush_b40/components/Switch1Units';
import BreakerStack1 from '@src/sld/sagebrush/sagebrush_b40/components/BreakerStack1';
import HorizontalLine from '@src/sld/components/Line/HorizontalLine';

const unitDataPoints: UnitDataPoints[] = [
  UnitDataPoints.KW,
  UnitDataPoints.KVAR,
  UnitDataPoints.SOC,
  UnitDataPoints.RacksOnline,
  UnitDataPoints.InvStatus,
  UnitDataPoints.RunMode,
];

// eslint-disable-next-line max-lines-per-function
function SageBrushB40Diagram(props: DefaultSLDProps): ReactElement {
  const { siteMeta } = props;

  const queryItems: DataPointQuery[] = [];
  const units: UnitConfig[] = [];

  siteMeta.Units.forEach((controllerId: string): void => {
    unitDataPoints.forEach((pointName: UnitDataPoints): void => {
      queryItems.push({ category: 'unit', pointName, controllerId });
    });
  });

  const { data: dataPoints } = useDataPointsSearch('sagebrush_40-sld', [
    ...queryItems,
  ]);

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    dataPoints || [],
  );

  const getUnitDataPoint = (
    pointName: string,
    unitPoints: DataPointWithValue[],
  ) =>
    unitPoints.find(
      (x: DataPointWithValue): boolean => x.pointName === pointName,
    );

  siteMeta.Units.forEach((controllerId: string, index: number): void => {
    const unitPoints: DataPointWithValue[] = dataPointsWithValue.filter(
      (dataPointObj: DataPointWithValue): boolean =>
        dataPointObj.controllerId === controllerId,
    );

    const kw = getUnitDataPoint(UnitDataPoints.KW, unitPoints)?.value;
    const kvar = getUnitDataPoint(UnitDataPoints.KVAR, unitPoints)?.value;
    const racksonline = getUnitDataPoint(
      UnitDataPoints.RacksOnline,
      unitPoints,
    )?.value;
    const invstatus = getUnitDataPoint(
      UnitDataPoints.InvStatus,
      unitPoints,
    )?.value;
    const soc = getUnitDataPoint(UnitDataPoints.SOC, unitPoints)?.value;
    const runmode = getUnitDataPoint(UnitDataPoints.RunMode, unitPoints)?.value;

    units.push({
      unitLabel: siteMeta.UnitNames[index],
      kw,
      kvar,
      racksonline,
      invstatus,
      soc,
      runmode,
    } as UnitConfig);
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      viewBox='125 25 1300 645'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor', overflowX: 'auto' }}
    >
      <title>Sagebrush B40</title>

      <TopSwitchResistorSection
        translateXCoordinate={-85}
        translateYCoordinate={-20}
      />
      <BreakerStack1 />
      <HorizontalLine
        color='white'
        width={0.5}
        length={550}
        translateXCoordinate={400}
        translateYCoordinate={315}
      />
      <Switch1Units unitConfigs={units} />
      <Switch2Units unitConfigs={units} />
    </svg>
  );
}

export default storeConnector(SageBrushB40Diagram, {
  config: ['siteMeta'],
});
