import { API_V2_URL, callAPI } from '@utils';
import { store } from '@store';
import { notifyError } from './notifications.js';

export const config = {
  getApplication(siteId, id) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(`/application/${siteId}/${id}`, 'GET');
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get application');
          return resolve([]);
        }
        resolve(
          [res].map((c) => {
            const newConf = { ...c.body, ...c };
            delete newConf.body;
            return newConf;
          }),
        );
      });
    };
  },
  loadApplications(siteId) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(`/application/list/${siteId}`);
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get application list');
          return resolve({});
        }
        const applications = res.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        resolve(applications);
      });
    };
  },
  deleteApplication(deviceId, id) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(`/application/${deviceId}/${id}`, 'DELETE');
        if (!res || res.error) {
          notifyError(res.error || 'Cannot delete application');
        }
        resolve(res);
      });
    };
  },
  saveApplication(siteId, id, item) {
    return function () {
      return new Promise(async (resolve) => {
        let res;
        if (id) {
          res = await callAPI(`/application/${siteId}/${id}`, 'PUT', item);
        } else {
          res = await callAPI(`/application/${siteId}`, 'POST', item);
        }
        if (!res || res.error) {
          notifyError(res.error || 'Cannot save application');
        }
        resolve(res);
      });
    };
  },
  loadStacks() {
    return function () {
      return new Promise(async (resolve) => {
        const { siteId } = store.getState().config.siteMeta;
        const res = await callAPI(`/stack/list/${siteId}`);
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get stack list');
          return resolve({});
        }
        const stacks = res.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        resolve(stacks);
      });
    };
  },
  getStack(siteId, id) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(`/stack/${siteId}/${id}`, 'GET');
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get stack');
          resolve({});
        } else {
          resolve(res);
        }
      });
    };
  },
  saveStack(siteId, id, item) {
    return function () {
      return new Promise(async (resolve) => {
        let res;
        if (id) {
          res = await callAPI(`/stack/${siteId}/${id}`, 'PUT', item);
        } else {
          res = await callAPI(`/stack/${siteId}`, 'POST', item);
        }
        if (!res || res.error) {
          notifyError(res.error || 'Cannot save stack');
        }
        resolve(res);
      });
    };
  },
  deleteStack(siteId, id) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(`/stack/${siteId}/${id}`, 'DELETE');
        if (!res || res.error) {
          notifyError(res.error || 'Cannot delete stack');
        }
        resolve(res);
      });
    };
  },
  sendCommand(commands, deviceId, unitName = null) {
    return function () {
      return new Promise(async (resolve) => {
        const state = store.getState();

        const commandDTO = {
          deviceId,
          commands,
          unitName: unitName ? unitName : '',
          topicPrefix: state.config.siteMeta.topicPrefix,
        };

        const URI = `${API_V2_URL}/controllers/commands`;

        await fetch(`${URI}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${state.user.keycloak?.token}`,
          },
          body: JSON.stringify(commandDTO),
        });
        resolve();
      });
    };
  },
  getRackAlarmMapConfig() {
    return function () {
      return new Promise((resolve) => {
        callAPI('/config/filter', 'GET', {
          deviceIds: [store.getState().config.siteMeta.siteId],
          descriptions: ['Rack Alarm Map'],
        }).then((r) => {
          resolve(r && r[0]?.body.Map ? r[0].body.Map : []);
        });
      });
    };
  },
  getCurrentFaultConfigAll(deviceIds) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI('/config/filter', 'GET', {
          deviceIds,
          descriptions: ['Fault Config'],
        });
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get fault configs');
          return resolve([]);
        }
        resolve(
          res.map((c) => {
            const newConf = { ...c.body, ...c };
            delete newConf.body;
            return newConf;
          }),
        );
      });
    };
  },
  getSystemConfig(deviceId, ControlMode) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI('/config/filter', 'GET', {
          deviceIds: [deviceId],
          descriptions: [ControlMode],
        });
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get system config');
          resolve([]);
        }
        resolve(res);
      });
    };
  },
  updateSystemConfig(deviceId, description, config) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(`/config/${deviceId}/${description}`, 'PUT', {
          body: config.body,
        });
        if (!res || res.error) {
          notifyError(res.error || 'Can’t update config');
          return resolve();
        }
        resolve();
      });
    };
  },
};
