import { store } from '@store';
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

const CurrentPowinControllerIdContext = createContext(
  null as unknown as {
    currentPowinControllerId: string;
    setCurrentPowinControllerId: React.Dispatch<React.SetStateAction<string>>;
  },
);

export function CurrentPowinControllerIdProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentPowinControllerId, setCurrentPowinControllerId] = useState(
    store.getState().config.siteMeta.PowinControllers[0],
  );

  const value = useMemo(
    () => ({ currentPowinControllerId, setCurrentPowinControllerId }),
    [currentPowinControllerId, setCurrentPowinControllerId],
  );

  return (
    <CurrentPowinControllerIdContext.Provider value={value}>
      {children}
    </CurrentPowinControllerIdContext.Provider>
  );
}

export const useCurrentPowinControllerIdContext = () => {
  const { currentPowinControllerId, setCurrentPowinControllerId } = useContext(
    CurrentPowinControllerIdContext,
  );
  return { currentPowinControllerId, setCurrentPowinControllerId };
};
