import { engineerOrAdmin } from '@src/services/auth';
import { store } from '@src/store';
import {
  AppFeature,
  ConfigFeature,
  LayoutPermission,
} from '@src/types/LayoutPermission';

const getAppFeatures = (): AppFeature[] => {
  const { ui } = store.getState().config.siteMeta;

  if (ui.Modes_Only_UI === true) {
    return [];
  }
  let features: AppFeature[] = [];
  if (ui.Battery_Enable) {
    features = [...features, 'battery'];
  }
  if (ui.Sun_Enable) {
    features = [...features, 'sun'];
  }
  if (ui.Mkt_Enable) {
    features = [...features, 'mkt'];
  }
  if (ui.History_Enable) {
    features = [...features, 'history'];
  }
  if (ui.Wind_Enable) {
    features = [...features, 'wind'];
  }
  if (ui.Incident_Command_Enable) {
    features = [...features, 'incident-command'];
  }
  return features;
};
const getLeftBar = (): boolean => {
  const { ui } = store.getState().config.siteMeta;

  if (ui.Modes_Only_UI === true) {
    return false;
  }
  return true;
};
const getConfigFeatures = (): ConfigFeature[] => {
  const { siteMeta } = store.getState().config;
  const { role } = store.getState().user;

  if (siteMeta.ui.Modes_Only_UI === true) {
    return ['modes'];
  }
  const features: ConfigFeature[] = [];
  if (siteMeta.ui.Home_Calendar_Enable && engineerOrAdmin(role!)) {
    features.push('applications');
  }
  if (siteMeta.ui.Home_Calendar_Enable && engineerOrAdmin(role!)) {
    features.push('stack');
  }
  if (
    siteMeta.ui.Home_Config_Modes_Enable &&
    siteMeta.ControlModes.length > 0
  ) {
    features.push('modes');
  }
  if (
    siteMeta.ui.TSC_Config_Enable &&
    siteMeta.TeslaSiteControllers.length > 0
  ) {
    features.push('tsc-config');
  }
  if (siteMeta.ui.Home_Config_Alerts_Enable && engineerOrAdmin(role!)) {
    features.push('alerts');
  }

  return features;
};

const useLayoutPermission = (): LayoutPermission => ({
  appFeatures: getAppFeatures(),
  leftbar: getLeftBar(),
  configFeatures: getConfigFeatures(),
});

export default useLayoutPermission;
