import SystemMeter from '@components/_shared/SystemMeter';
import PowinBessList from '@components/Battery/Powin/PowinBessList';
import PowinControllerDetails from '@components/Battery/Powin/PowinControllerDetails';
import { CurrentPowinControllerIdProvider } from '@hooks/useCurrentPowinControllerId';
import useMakePageScrollable from '@hooks/useMakePageScrollable';
import PowinControllerList from '@components/Battery/Powin/PowinControllerList';
import React, { ReactElement } from 'react';

function POWIN(): ReactElement {
  useMakePageScrollable();
  return (
    <CurrentPowinControllerIdProvider>
      <div className='home-row'>
        <div className='fcol'>
          <SystemMeter />
          <PowinControllerList />
        </div>
        <div className='fcol'>
          <PowinControllerDetails />
          <PowinBessList />
        </div>
      </div>
    </CurrentPowinControllerIdProvider>
  );
}

export default POWIN;
