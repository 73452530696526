import React, { ReactElement } from 'react';

interface SwitchProps {
  isClosed: boolean;
  translateXCoordinate: number;
  translateYCoordinate: number;
  label: string;
  stroke?: string;
}

const switchTextProps = {
  fill: 'currentColor',
  stroke: 'none',
  textAnchor: 'right',
  fontSize: '8px',
};

function Switch(props: SwitchProps): ReactElement {
  const {
    isClosed,
    translateXCoordinate,
    translateYCoordinate,
    label,
    stroke,
  } = props;
  const translate = `translate(${translateXCoordinate}, ${translateYCoordinate})`;

  return (
    <g transform={translate}>
      {/* - open=x2{10} / closed={x2{0} */}
      <line
        x1={0}
        y1={10}
        x2={isClosed ? 0 : 10}
        y2={0}
        style={{ stroke: stroke || 'white', strokeWidth: 0.5 }}
      />
      <text x={15} y={10} {...switchTextProps}>
        {label}
      </text>
    </g>
  );
}

export default Switch;
