import Spinner from '@components/_elements/Spinner/Spinner';
import MultiColPointDataTable from '@components/_shared/MultiColPointDataTable';
import UIBox from '@components/_shared/UIBox';
import { useCurrentTscIdContext } from '@hooks/useCurrentTscId';
import { Grid2 } from '@mui/material';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';
import { SiteMeta } from '@src/types/SiteMeta';
import { WidgetTypePointList } from '@src/types/Widget';
import storeConnector from '@store/storeConnector';
import React, { useEffect } from 'react';

function TeslaControllerDetails({ siteMeta }: { siteMeta: SiteMeta }) {
  const { currentTscId } = useCurrentTscIdContext();
  const {
    data: widgetConfig,
    isLoading,
    refetch,
  } = useGetWidgetConfig<WidgetTypePointList>('tscDetails', {
    sourceDeviceId: currentTscId,
  });

  useEffect(() => {
    refetch();
  }, [refetch, currentTscId]);

  return (
    <UIBox
      header={`${siteMeta.TeslaSiteControllerNames[siteMeta.TeslaSiteControllers.indexOf(currentTscId)]} Details`}
    >
      {isLoading ? (
        <Spinner type='content-small' />
      ) : (
        <Grid2 container justifyContent='space-evenly'>
          <MultiColPointDataTable
            dataPointsMatrix={widgetConfig?.dataPoints || []}
          />
        </Grid2>
      )}
    </UIBox>
  );
}

export default storeConnector(TeslaControllerDetails, {
  config: ['siteMeta'],
});
