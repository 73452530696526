import React from 'react';
import { NavLink } from 'react-router-dom';
import AlertsIcon from '@assets/alerts.svg?react';
import HistorianIcon from '@assets/diagram.svg?react';
import SystemMessagesIcon from '@assets/system_messages.svg?react';
import ConfigIcon from '@assets/config.svg?react';
import CalendarIcon from '@assets/calendar.svg?react';
import {
  faGrip,
  faHardDrive,
  faServer,
} from '@fortawesome/free-solid-svg-icons';
import { UserRole } from '@src/services/auth/roles';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isActionRole, isSuperAdmin } from '@src/services/auth';

interface LeftBarProps {
  role: UserRole;
  siteMeta: SiteMeta;
}

const prepLeftBarItems = (siteMeta: SiteMeta, role: LeftBarProps['role']) => [
  {
    title: 'Overview',
    suffix: 'overview',
    icon: <FontAwesomeIcon size='xl' icon={faServer} />,
  },
  ...(siteMeta.ui.Home_Dashboard_Enable
    ? [
        {
          title: 'Dashboards',
          suffix: 'dashboards',
          icon: <FontAwesomeIcon size='xl' icon={faGrip} />,
        },
      ]
    : []),
  ...(isSuperAdmin(role)
    ? [
        {
          title: 'Collections',
          suffix: 'collections',
          icon: <FontAwesomeIcon size='xl' icon={faHardDrive} />,
        },
      ]
    : []),
  {
    title: 'Alerts',
    suffix: 'alerts/active',
    icon: <AlertsIcon />,
  },
  {
    title: 'Historian',
    suffix: 'historian/data',
    icon: <HistorianIcon />,
  },
  {
    title: 'System Messages',
    suffix: 'system_messages',
    icon: <SystemMessagesIcon />,
  },
  ...(siteMeta.ui.Home_Config_Alerts_Enable ||
  siteMeta.ui.Home_Calendar_Enable ||
  siteMeta.ui.Home_Config_Modes_Enable
    ? [
        {
          title: 'System Config',
          suffix: 'config',
          icon: <ConfigIcon />,
        },
      ]
    : []),
  ...(siteMeta.ui.Home_Calendar_Enable && role && isActionRole(role)
    ? [
        {
          title: 'Calendar',
          suffix: 'calendar',
          icon: <CalendarIcon />,
        },
      ]
    : []),
];

function LeftBar({ role, siteMeta }: LeftBarProps) {
  const toPath = (suffix: string) => `/home/${suffix}`;
  const leftBarItems = prepLeftBarItems(siteMeta, role);

  return (
    <div className='frame'>
      {leftBarItems.map(({ icon, suffix, title }) => (
        <NavLink
          data-test={suffix.replace('/', '-')}
          key={title}
          title={title}
          to={toPath(suffix)}
          className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
        >
          {icon}
        </NavLink>
      ))}
    </div>
  );
}

export default storeConnector(LeftBar, {
  config: ['siteMeta'],
  user: ['role'],
});
