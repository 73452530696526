import { isSuperAdmin } from '@src/services/auth';
import { SiteMeta } from '@src/types/SiteMeta';
import { UserRole } from '@src/services/auth/roles';
import storeConnector from '@store/storeConnector';
import { NavLink } from 'react-router-dom';
import React from 'react';

function TopBar({ siteMeta, role }: { siteMeta: SiteMeta; role: UserRole }) {
  return (
    <div className='topbar'>
      <div className='frame'>
        <NavLink
          to='/home/alerts/active'
          className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
        >
          Active alerts
        </NavLink>
        {siteMeta.ui?.Home_Alerts_Timeline_Enable ? (
          <NavLink
            to='/home/alerts/timeline'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            Alerts timeline
          </NavLink>
        ) : null}
        {(role && isSuperAdmin(role)) ||
        siteMeta.ui?.Home_Alerts_Config_Editor_Enable ? (
          <NavLink
            to='/home/alerts/configs'
            className={({ isActive }) => `link ${isActive ? 'active' : ''}`}
          >
            Alert configs
          </NavLink>
        ) : null}
      </div>
      <div />
    </div>
  );
}

export default storeConnector(TopBar, {
  config: ['siteMeta'],
  user: ['role'],
});
