import { Button, Grid2 } from '@mui/material';
import React from 'react';
import { useCommandsModalContext } from '../CommandsModalContext';

function ControlSection() {
  const { sendCommand } = useCommandsModalContext();

  const handleClickEnable = () => sendCommand('Enable');
  const handleClickDisable = () => sendCommand('Disable');

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 4 }}
        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
      >
        Control:
      </Grid2>
      <Grid2 size={{ xs: 8 }}>
        <Button variant='contained' onClick={handleClickEnable}>
          Enable
        </Button>
        <Button variant='contained' sx={{ ml: 2 }} onClick={handleClickDisable}>
          Disable
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default ControlSection;
