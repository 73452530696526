enum DefaultUnits {
  Irradiance = 'Wm2',
  Ambient_Temperature = 'C',
  Relative_Humidity = '%',
  Barometric_Pressure = 'mbar',
  Wind_Speed = 'm/s',
  Wind_Direction = '°',
  Precipitation = 'in',
}

export default DefaultUnits;
