import TextLoader from '@components/_elements/TextLoader/index';
import React, { useState } from 'react';
import { useWsSubscribe } from '@store/actionCreators/mqtt';
import storeConnector from '@store/storeConnector';
import { snakeToTitle } from '@utils';
import { SiteMeta } from '@src/types/SiteMeta';
import defaultFields from './constants';

interface TrackerDetailProps {
  activeTrackerSN: string;
  siteMeta: SiteMeta;
  activeUnitName: string;
  fromPV: boolean;
}

function TrackerDetail({
  activeTrackerSN,
  siteMeta,
  activeUnitName,
  fromPV,
}: TrackerDetailProps) {
  const [trackerRt, setTrackerRt] = useState<Record<string, unknown>>({});
  const hasSiteMetaTrackerConfig = !!siteMeta.ui.TrackerList?.PV;
  const isMasterTracker = activeTrackerSN === siteMeta.siteId;
  let fields;
  if (hasSiteMetaTrackerConfig) {
    if (isMasterTracker) fields = siteMeta.ui.TrackerList?.PV?.masterTracker;
    else fields = siteMeta.ui.TrackerList?.PV?.unitTracker;
  } else if (isMasterTracker) fields = defaultFields.masterTracker;
  else fields = defaultFields.unitTracker;

  useWsSubscribe(
    {
      [isMasterTracker ? 'master_tracker' : 'tracker']: {
        sns: [activeTrackerSN],
        sourceDeviceId: isMasterTracker ? 'master_tracker_1' : 'tracker_1',
        fields,
        cb: (data: Record<string, unknown>) =>
          setTrackerRt((prev) => ({ ...prev, ...data })),
      },
    },
    [activeTrackerSN],
  );

  const isTrackerLoading = (field: string) =>
    !Object.keys(trackerRt).includes(field);

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell unit-cell'>
        <div className='unit-info'>
          <div className='unit-col'>
            <div className='title-large'>
              {isMasterTracker ? 'Master' : `${activeUnitName}`} Tracker Detail
            </div>
            <div className='info-grid pcs'>
              {fields?.map((field: string) => (
                <React.Fragment key={field}>
                  <div className='title'>{snakeToTitle(field)}</div>
                  <div>
                    <TextLoader
                      loading={isTrackerLoading(field)}
                      orientation='l'
                    >
                      {trackerRt[field]}
                    </TextLoader>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default storeConnector(TrackerDetail, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
});
