import { Actions } from '@src/types/Actions';
import { SiteMeta } from '@src/types/SiteMeta';
import React, { ReactElement, useEffect, useState } from 'react';
import { SavedSearch } from '@types';
import storeConnector from '@store/storeConnector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/_elements/Button/Button';
import Input from '@components/_elements/Input/Input';
import {
  faPen,
  faPlus,
  faSave,
  faTrash,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { DeletePopup, SavePopup } from './Popups';

interface ButtonProps {
  icon: IconDefinition;
  onClick: () => void;
  title: string;
}

interface SelectButtonGroupProps {
  setSearchUnderEdit: React.Dispatch<SavedSearch>;
  setActiveSearch: React.Dispatch<SavedSearch>;
  activeSearch: SavedSearch;
  newSearch: SavedSearch;
  setDeletePopup: React.Dispatch<boolean>;
}
function SelectButtonGroup(props: SelectButtonGroupProps): ReactElement {
  const {
    setActiveSearch,
    setSearchUnderEdit,
    newSearch,
    setDeletePopup,
    activeSearch,
  } = props;
  const buttonProps = [
    {
      icon: faPen,
      onClick: () => setSearchUnderEdit(activeSearch),
      title: 'Edit Saved Search',
    },
    {
      icon: faPlus,
      onClick: () => setActiveSearch(newSearch),
      title: 'Add New Saved Search',
    },
    {
      icon: faTrash,
      onClick: () => setDeletePopup(true),
      title: 'Delete Saved Search',
    },
  ] as ButtonProps[];
  return (
    <>
      {buttonProps.map((p) => (
        <Button
          style={{ marginLeft: 10 }}
          key={p.title}
          classType='container-bg'
          size='s'
          title={p.title}
          onClick={p.onClick}
        >
          <FontAwesomeIcon icon={p.icon} />
        </Button>
      ))}
    </>
  );
}

interface SelectButtonsProps {
  activeSearch: SavedSearch;
  setSavePopup: React.Dispatch<boolean>;
  setDeletePopup: React.Dispatch<boolean>;
  setSearchUnderEdit: React.Dispatch<SavedSearch>;
  setActiveSearch: React.Dispatch<SavedSearch>;
  newSearch: SavedSearch;
}
function SelectButtons(props: SelectButtonsProps): ReactElement {
  const { activeSearch, setSavePopup, ...rest } = props;
  if (activeSearch.name) {
    return <SelectButtonGroup activeSearch={activeSearch} {...rest} />;
  }
  return (
    <Button
      style={{ marginLeft: 10 }}
      classType='container-bg'
      size='s'
      title='Save Search'
      onClick={(): void => setSavePopup(true)}
    >
      <FontAwesomeIcon icon={faSave} />
    </Button>
  );
}

interface SelectInputProps {
  savedSearches: SavedSearch[];
  searchOptions: string[];
  setActiveSearch: React.Dispatch<SavedSearch>;
  activeSearch: SavedSearch;
}
function SelectInput(props: SelectInputProps): ReactElement {
  const { savedSearches, activeSearch, setActiveSearch, searchOptions } = props;
  const disabled = savedSearches.length === 0;
  function handOnChange(index: number): void {
    setActiveSearch(savedSearches[index]);
  }
  return (
    <Input
      type='dropdown'
      value={{ value: activeSearch.name }}
      onChange={(index: number): void => handOnChange(index)}
      options={searchOptions}
      disabled={disabled}
    />
  );
}

interface SelectFormProps {
  actions: Actions;
  setActiveSearch: React.Dispatch<SavedSearch>;
  activeSearch: SavedSearch;
  newSearch: SavedSearch;
  getTsDueTimeMode: () => string;
  timeMode: string;
  siteMeta: SiteMeta;
  setSearchUnderEdit: React.Dispatch<SavedSearch>;
  setSavedSearches: React.Dispatch<SavedSearch[]>;
  savedSearches: SavedSearch[];
  searchOptions: string[];
}

export const selectFormStyles = {
  display: 'flex',
  alignItems: 'center',
  width: '50%',
};

interface SelectFormContentProps {
  searchOptions: string[];
  savedSearches: SavedSearch[];
  activeSearch: SavedSearch;
  setActiveSearch: React.Dispatch<SavedSearch>;
  setSearchUnderEdit: React.Dispatch<SavedSearch>;
  setDeletePopup: React.Dispatch<boolean>;
  newSearch: SavedSearch;
  setSavePopup: React.Dispatch<boolean>;
}
function SelectFormContent(props: SelectFormContentProps): ReactElement {
  const {
    searchOptions,
    savedSearches,
    activeSearch,
    setActiveSearch,
    setSearchUnderEdit,
    setDeletePopup,
    newSearch,
    setSavePopup,
  } = props;
  return (
    <>
      <SelectInput
        searchOptions={searchOptions}
        savedSearches={savedSearches}
        activeSearch={activeSearch}
        setActiveSearch={setActiveSearch}
      />
      <SelectButtons
        setDeletePopup={setDeletePopup}
        setActiveSearch={setActiveSearch}
        newSearch={newSearch}
        setSearchUnderEdit={setSearchUnderEdit}
        activeSearch={activeSearch}
        setSavePopup={setSavePopup}
      />
    </>
  );
}
function SelectForm(p: SelectFormProps): ReactElement {
  const [deletePopup, setDeletePopup] = useState(false);
  const [savePopup, setSavePopup] = useState(false);
  const [searchNewName, setSearchNewName] = useState('');
  useEffect(() => {
    p.actions.getHistorianSearchList().then(p.setSavedSearches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function addNewSearch(): Promise<void> {
    const newActiveSearch = { ...p.activeSearch, name: searchNewName };
    p.setActiveSearch(await p.actions.addHistorianSearch(newActiveSearch));
    setSavePopup(false);
    p.actions.getHistorianSearchList().then(p.setSavedSearches);
  }
  async function deleteSavedSearch(): Promise<void> {
    if (p.activeSearch.id) {
      await p.actions.deleteHistorianSearch(p.activeSearch.id);
      p.setActiveSearch(p.newSearch);
      setDeletePopup(false);
      p.actions.getHistorianSearchList().then(p.setSavedSearches);
    } else {
      console.error('no id on active search');
    }
  }
  return (
    <div style={selectFormStyles}>
      <SelectFormContent
        setSavePopup={setSavePopup}
        setDeletePopup={setDeletePopup}
        {...p}
      />
      {deletePopup && (
        <DeletePopup
          deleteSavedSearch={deleteSavedSearch}
          activeSearch={p.activeSearch}
          setDeletePopup={setDeletePopup}
        />
      )}
      {savePopup && (
        <SavePopup
          searchOptions={p.searchOptions}
          setSearchNewName={setSearchNewName}
          addNewSearch={addNewSearch}
          setSavePopup={setSavePopup}
          searchNewName={searchNewName}
        />
      )}
    </div>
  );
}

export default storeConnector(SelectForm, {
  config: ['siteMeta'],
  service: ['timeMode'],
});
