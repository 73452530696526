import * as React from "react";
const SvgCaution = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Capa_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 512 512", style: {
  enableBackground: "new 0 0 512 512"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGCleanerId_0_2_", gradientUnits: "userSpaceOnUse", x1: 236.9377, y1: 514.4543, x2: 236.9377, y2: -83.3227, gradientTransform: "matrix(1.0667 0 0 -1.0667 3.2666 557.533)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFCF95"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.427, style: {
  stopColor: "#FFC954"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#FFC200"
} })), /* @__PURE__ */ React.createElement("path", { id: "SVGCleanerId_0", style: {
  fill: "url(#SVGCleanerId_0_2_)"
}, d: "M505.389,409.894l-208.042-356.1 c-18.485-31.641-64.209-31.641-82.694,0L6.611,409.894c-18.651,31.923,4.374,72.043,41.347,72.043h416.082 C501.014,481.936,524.04,441.817,505.389,409.894z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGCleanerId_0_3_", gradientUnits: "userSpaceOnUse", x1: 236.9377, y1: 514.4543, x2: 236.9377, y2: -83.3227, gradientTransform: "matrix(1.0667 0 0 -1.0667 3.2666 557.533)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFCF95"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.427, style: {
  stopColor: "#FFC954"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#FFC200"
} })), /* @__PURE__ */ React.createElement("path", { id: "SVGCleanerId_0_1_", style: {
  fill: "url(#SVGCleanerId_0_3_)"
}, d: "M505.389,409.894l-208.042-356.1 c-18.485-31.641-64.209-31.641-82.694,0L6.611,409.894c-18.651,31.923,4.374,72.043,41.347,72.043h416.082 C501.014,481.936,524.04,441.817,505.389,409.894z" })), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: 464.8496, y1: 53.5691, x2: 195.8196, y2: 322.5991, gradientTransform: "matrix(1.0667 0 0 -1.0667 3.2666 557.533)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFC200",
  stopOpacity: 0
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.203, style: {
  stopColor: "#FFBB00",
  stopOpacity: 0.203
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.499, style: {
  stopColor: "#FFA700",
  stopOpacity: 0.499
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.852, style: {
  stopColor: "#FF8800",
  stopOpacity: 0.852
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#FF7800"
} })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "url(#SVGID_1_)"
}, d: "M464.039,481.933H396.57L248.5,333.85c-2.97-1.446-4.467-3.968-4.467-7.565 c0-13.568-0.563-35.725-1.677-66.458s-1.664-52.89-1.664-66.47c0-4.326,1.472-7.68,4.416-10.035 c2.957-2.355,6.771-3.533,11.469-3.533c4.685,0,8.128,1.229,10.33,3.674l244.736,244.749 C515.021,455.321,494.157,481.933,464.039,481.933z" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_2_", gradientUnits: "userSpaceOnUse", x1: 411.3916, y1: 0.1111, x2: 142.3616, y2: 269.1411, gradientTransform: "matrix(1.0667 0 0 -1.0667 3.2666 557.533)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFC200",
  stopOpacity: 0
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.203, style: {
  stopColor: "#FFBB00",
  stopOpacity: 0.203
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.499, style: {
  stopColor: "#FFA700",
  stopOpacity: 0.499
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.852, style: {
  stopColor: "#FF8800",
  stopOpacity: 0.852
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#FF7800"
} })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "url(#SVGID_2_)"
}, d: "M389.402,481.933h-45.542l-99.635-99.635c-3.469-3.059-5.21-6.63-5.21-10.714 c0-4.262,1.741-7.872,5.21-10.842c3.469-2.982,7.693-4.467,12.646-4.467c4.365,0,8.141,1.485,11.328,4.467L389.402,481.933z" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_3_", gradientUnits: "userSpaceOnUse", x1: 464.4816, y1: 129.7462, x2: -3.4284, y2: 77.6462, gradientTransform: "matrix(1.0667 0 0 -1.0667 3.2666 557.533)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FFC200",
  stopOpacity: 0
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.203, style: {
  stopColor: "#FFBB00",
  stopOpacity: 0.203
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.499, style: {
  stopColor: "#FFA700",
  stopOpacity: 0.499
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.852, style: {
  stopColor: "#FF8800",
  stopOpacity: 0.852
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#FF7800"
} })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "url(#SVGID_3_)"
}, d: "M47.959,481.936h416.082c35.039,0,57.524-36.028,43.91-66.969H4.048 C-9.566,445.909,12.921,481.936,47.959,481.936z" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_4_", gradientUnits: "userSpaceOnUse", x1: 236.9375, y1: 480.2773, x2: 236.9375, y2: -172.7127, gradientTransform: "matrix(1.0667 0 0 -1.0667 3.2666 557.533)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#5B6A6A"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#303033"
} })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "url(#SVGID_4_)"
}, d: "M483.286,422.807L275.244,66.708C269.42,56.74,259.847,55.664,256,55.664 c-3.846,0-13.42,1.076-19.244,11.044L28.715,422.807c-5.875,10.058-2.016,18.972-0.085,22.336 c1.932,3.365,7.681,11.193,19.329,11.193h416.082c11.648,0,17.397-7.827,19.329-11.193 C485.302,441.778,489.16,432.865,483.286,422.807z M447.398,430.736H64.603c-4.817,0-7.818-5.227-5.388-9.387L250.613,93.738 c2.409-4.123,8.366-4.123,10.774,0l191.397,327.611C455.215,425.509,452.215,430.736,447.398,430.736z" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_5_", gradientUnits: "userSpaceOnUse", x1: 236.9373, y1: 361.4873, x2: 236.9373, y2: 110.7773, gradientTransform: "matrix(1.0667 0 0 -1.0667 3.2666 557.533)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#5B6A6A"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#303033"
} })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "url(#SVGID_5_)"
}, d: "M239.02,371.582c0-4.254,1.735-7.868,5.205-10.843c3.472-2.976,7.69-4.467,12.65-4.467 c4.366,0,8.141,1.491,11.325,4.467c3.185,2.975,4.78,6.589,4.78,10.843c0,4.085-1.595,7.655-4.78,10.718 c-3.184,3.062-6.959,4.592-11.325,4.592c-4.96,0-9.178-1.53-12.65-4.592C240.755,379.237,239.02,375.667,239.02,371.582z  M240.69,193.361c0-4.333,1.474-7.681,4.422-10.038c2.95-2.357,6.768-3.536,11.457-3.536c4.688,0,8.132,1.227,10.33,3.68 c2.196,2.452,3.297,5.75,3.297,9.895c0,13.579-0.235,35.731-0.698,66.465c-0.465,30.734-0.695,52.89-0.695,66.465 c0,2.831-1.328,5.049-3.975,6.65c-2.654,1.601-5.408,2.401-8.26,2.401c-8.359,0-12.54-3.015-12.54-9.052 c0-13.575-0.558-35.731-1.67-66.465C241.246,229.092,240.69,206.94,240.69,193.361z" }), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null));
export default SvgCaution;
