import { ModeConfigInputType } from '@src/types/control-modes/Modes';
import React, { useState } from 'react';
import { validateInputNumber } from '../modes-utils';

interface ValidatedModeInputProps {
  handleInputUpdate: (
    e: React.FormEvent<HTMLInputElement>,
    isInvalid?: boolean,
  ) => void;
  inputType: ModeConfigInputType;
  inputErrorCount: number;
  name: string;
  range: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInputErrorCount: any;
  value: string;
  disabled?: boolean;
}

function ValidatedModeInput({
  handleInputUpdate,
  inputType = 'number',
  inputErrorCount,
  name,
  range,
  setInputErrorCount,
  value,
  disabled = false,
}: ValidatedModeInputProps) {
  const [validationError, setValidationError] = useState('');

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const initWithError = validationError.length;
    let inputValidationError = '';
    if (inputType === 'number') {
      inputValidationError = validateInputNumber(e.currentTarget.value, range);
    }
    setValidationError(inputValidationError);

    if (initWithError === 0 && inputValidationError) {
      setInputErrorCount(inputErrorCount + 1);
    } else if (!inputValidationError) {
      if (inputErrorCount !== 0) setInputErrorCount(inputErrorCount - 1);
    }

    handleInputUpdate(e, !!inputValidationError);
  };

  return (
    <span className='mode-form-box'>
      <input
        type='text'
        size={15}
        name={name}
        style={{
          margin: '10px 15px 0 0',
          ...(validationError ? { backgroundColor: '#edafaf' } : {}),
        }}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {validationError ? (
        <span
          className='mode-form-tooltip'
          style={{
            backgroundColor: '#822222',
            color: '#d7d7d7',
            width: '150px',
          }}
        >
          {validationError}
        </span>
      ) : (
        <div style={{ width: '138px' }} />
      )}
    </span>
  );
}

export default ValidatedModeInput;
