import useApiMutation from '@hooks/useApiMutation';
import { ModeConfig } from '@src/types/control-modes/Modes';
import { ControlMode } from '@src/types/control-modes/ControlModes';
import { SiteMeta } from '@src/types/SiteMeta';

interface ConfigUpdateParameters {
  configBody: ModeConfig['body'] | SiteMeta;
  modeName: ControlMode;
  siteId: string;
}

export default () =>
  useApiMutation<ConfigUpdateParameters, void>({
    apiPath: `/controllers/config`,
    method: 'POST',
    useV2API: true,
  });
