import React from 'react';
import { ModeConfigFormType } from '@src/types/control-modes/Modes';

interface QuickConfigInputLabelProps {
  configInputLabel: string;
  formType: ModeConfigFormType;
}
function QuickConfigInputLabel({
  configInputLabel,
  formType,
}: QuickConfigInputLabelProps) {
  return formType === 'quick' ? (
    <div className='modes-cell' style={{ justifyContent: 'right' }}>
      {configInputLabel ? <label>{configInputLabel}:</label> : ''}
    </div>
  ) : (
    ''
  );
}

export default QuickConfigInputLabel;
