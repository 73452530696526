import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { User } from '@src/types/User';
import DeleteUserDialog from '../Dialogs/DeleteUserDialog';

function DeleteUserButton({ user }: { user: User }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClick = () => setDialogOpen(true);

  return (
    <>
      <Tooltip title='Delete User'>
        <IconButton color='error' onClick={handleClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      {dialogOpen && (
        <DeleteUserDialog
          open
          onClose={() => setDialogOpen(false)}
          user={user}
        />
      )}
    </>
  );
}

export default DeleteUserButton;
