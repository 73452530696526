import React, { useEffect } from 'react';
import storeConnector from '@store/storeConnector';
import SunRiseSetWidget from './SunRiseSetWidget/SunRiseSetWidget';
import WeatherForecastWidget from './WeatherForecastWidget/WeatherForecastWidget';
import WindWidget from './WindWidget';

const WeatherWidget = ({
  actions,
  hideSun = false,
  hideWind = false,
  hideWeather = false,
}) => {
  useEffect(() => {
    actions.getWeather();
  }, []);

  return (
    <div className='frow h100'>
      {!hideWeather && <WeatherForecastWidget title='Weather' />}
      {!hideSun && <SunRiseSetWidget />}
      {!hideWind && <WindWidget />}
    </div>
  );
};

export default storeConnector(WeatherWidget, {});
