import React, { useState } from 'react';
import TextLoader from '@components/_elements/TextLoader';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import storeConnector from '@store/storeConnector';
import { getTopicForTooltip } from '@utils/index_ts';

const CustomSitePoints = ({ siteMeta, topicDict }) => {
  const [siteRt, setSiteRtData] = useState({});

  useWsSubscribe({
    site: {
      fields: Array.isArray(siteMeta.Custom_Site_Points)
        ? siteMeta.Custom_Site_Points.map((point) => point.PointName)
        : [],
      cb: (data) => setSiteRtData((prev) => ({ ...prev, ...data })),
    },
  });

  const isLoading = (field) => {
    return !Object.keys(siteRt).includes(field);
  };

  return (
    <>
      {Array.isArray(siteMeta.Custom_Site_Points) &&
        siteMeta.Custom_Site_Points.map((customSitePoint, index) => (
          <tr key={`custom-site-point-row-${index}`}>
            <td>{customSitePoint.DisplayName}:</td>
            <td>
              <TextLoader
                toolTipContent={getTopicForTooltip(
                  customSitePoint.PointName,
                  topicDict,
                )}
                loading={isLoading(customSitePoint.PointName)}
              >
                {siteRt[customSitePoint.PointName]}
              </TextLoader>
            </td>
          </tr>
        ))}
    </>
  );
};

export default storeConnector(CustomSitePoints, {
  mqtt: ['topicDict'],
});
