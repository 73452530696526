import { store } from '@src/store';
import { IMqttMessage } from '@src/types/Mqtt';
import { MqttClient } from 'mqtt';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DataPointWithValue, DataPoint } from '@src/types/DataPoint';

function updateDataPointsWithValues(
  prev: Array<DataPointWithValue>,
  topic: string,
  dataPointWithValue: DataPointWithValue,
): Array<DataPointWithValue> {
  // initial state fetch hook is undefined, therefore prev is first an [], and we need to initialize it. This sets the order of the points
  const index = prev.findIndex((p) => p.fullTopic === topic);
  return prev.map((p, i) => (i === index ? dataPointWithValue : p));
}

function handleBadPayload(topic: string, e: string): IMqttMessage {
  console.error(`Error parsing message for topic : ${topic}`, e);
  return { value: null, ts: null };
}
function parsePayload(message: Buffer, topic: string): IMqttMessage {
  try {
    const payload = JSON.parse(message.toString());
    if (
      payload.value === null ||
      payload.ts === null ||
      payload.value === undefined ||
      payload.ts === undefined
    ) {
      return handleBadPayload(topic, "Payload doesn't contain value or ts");
    }
    return payload;
  } catch (e) {
    return handleBadPayload(topic, String(e));
  }
}

function messageHandler(
  topic: string,
  message: Buffer,
  dataPoints: Array<DataPoint>,
  setDataPointsWithValues: Dispatch<SetStateAction<Array<DataPointWithValue>>>,
) {
  const { value, ts } = parsePayload(message, topic);
  const [dataPoint] = dataPoints.filter((s) => s.fullTopic === topic);
  const dataPointWithValue: DataPointWithValue = {
    ...dataPoint,
    value,
    ts,
  };
  setDataPointsWithValues((prev) =>
    updateDataPointsWithValues(prev, topic, dataPointWithValue),
  );
}
function useSubscription(
  dataPoints: DataPoint[],
  useNewClient?: MqttClient,
): Array<DataPointWithValue> {
  const initialDataPointsWithValues: Array<DataPointWithValue> = dataPoints.map(
    (dp) => ({ ...dp, value: undefined, ts: undefined }),
  );
  const [dataPointsWithValues, setDataPointsWithValues] = useState<
    Array<DataPointWithValue>
  >(initialDataPointsWithValues);

  // initializes data load when available from fetch query
  useEffect(() => {
    setDataPointsWithValues(initialDataPointsWithValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPoints]);

  useEffect(() => {
    if (dataPoints.length === 0) {
      return () => {};
    }
    const state = store.getState();
    const mqtt =
      useNewClient !== undefined ? useNewClient : state.mqtt.clientMQTT;
    const topics = dataPoints.map((t) => t.fullTopic);
    const onMessageCb = (topic: string, buffer: Buffer) => {
      if (topics.includes(topic)) {
        messageHandler(topic, buffer, dataPoints, setDataPointsWithValues);
      }
    };
    if (mqtt) {
      mqtt.on('message', onMessageCb);
      mqtt.subscribe(topics);
    }
    return () => {
      if (mqtt) {
        mqtt.unsubscribe(topics);
        mqtt.off('message', onMessageCb);
        setDataPointsWithValues([]);
      }
    };
  }, [dataPoints, useNewClient]);
  return dataPointsWithValues;
}

export default useSubscription;
