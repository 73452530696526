import { store } from '@store';
import { ChartQuery } from '@utils/historianChart/historianChartTypes';
import { API_URL } from '@utils';

const HISTORIAN_PATH = 'historian/query';

const runHistorianQuery = async (body: ChartQuery): Promise<unknown> => {
  const URI = `${API_URL}/${HISTORIAN_PATH}`;

  const apiResponse: Response = await fetch(`${URI}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${store.getState().user.keycloak?.token}`,
    },
    body: JSON.stringify(body),
  });

  if (!apiResponse.ok) {
    throw new Error(`Failed to fetch from ${apiResponse.url}`, {
      cause: apiResponse,
    });
  }

  return apiResponse.json();
};

// eslint-disable-next-line import/prefer-default-export
export { runHistorianQuery };
