const COMMON_RACK_FIELDS = [
  'AvgCellTemp',
  'AvgCellVoltageValue',
  'MaxCellT',
  'MaxCellV',
  'MinCellT',
  'MinCellV',
  'RackCurrent',
  'RackMode',
  'RackSOC',
  'RackSOH',
  'RackVoltage',
];
const RACK_FIELDS_DICT = {
  default: ['Alarms'],
  LG: ['RackFaultCode', 'OtherFaultCode', 'RackWarningCode', 'RackAlarmCode'],
  Samsung: ['RackFaultCode', 'RackCellBalance'],
  EPC: ['Alarms', 'Description'],
  Clou: ['Alarms'],
};
export const getRackFields = (type) => [
  ...COMMON_RACK_FIELDS,
  ...(RACK_FIELDS_DICT[type] || RACK_FIELDS_DICT['default']),
];

// Is this set of "constants" going to vary for each sld?
export const COMMON_BATTERY_FIELDS = [
  'AvgCellT',
  'AvgCelTemp',
  'AvgCellV',
  'AvgCellVoltageValue', // <--- todo:   replace with with better point name
  'ChaCurrLimit',
  'ConnectingStatus',
  'Description',
  'DischCurrLimit',
  'MaxCellT',
  'MinCellT',
  'MaxCellV',
  'MinCellV',
  'RackCount',
  'RackCurrent',
  'RackMode',
  'RackSOC',
  'RackSOH',
  'RackVoltage',
  'SysCurrent',
  'SysHB',
  'SysSOC',
  'SysSOH',
  'SysVoltage',
  'Counter', // <--- this field is not in db,
  'battery_MaxP',
  'battery_MinP',
];

export const CLOU_BATTERY_FIELDS = [
  'SyskW',
  'SysSOC',
  'SysSOH',
  'fault_state',
  'alarm_state',
  'SysHB',
  'circuit_breaker',
  'SysVoltage',
  'SysCurrent',
  'battery_MinP',
  'battery_MaxP',
  'maxmodv',
  'maxmodv_pos',
  'minmodv',
  'minmodv_pos',
  'amb_humidity',
  'maxmodt',
  'madmodt_pos',
  'minmodt',
  'mindmodt_pos',
  'amb_temp',
];
