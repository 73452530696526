import { Action } from 'redux';

export interface SiteState {
  sitePV: Record<string, unknown>;
  awardsDates: unknown[];
  siteMetaError?: Error | string;
}

const initState: SiteState = {
  sitePV: {},
  awardsDates: [],
  siteMetaError: undefined,
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: SiteState = initState,
  action: Action<string> & SiteState,
) => {
  const handlers: Record<string, () => SiteState> = {
    UPDATE_SITE_MODULE: () => {
      const { type: _type, ...cleanAction } = action;
      return { ...state, ...cleanAction };
    },
  };
  if (action.type && handlers[action.type] !== undefined) {
    return handlers[action.type]();
  }
  return state;
};
