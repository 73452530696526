import { prepColumns } from '@components/Home/Alerts/utils';
import React from 'react';
import './index.scoped.scss';
import storeConnector from '@store/storeConnector';
import { AlertsState } from '@src/types/Alerts';
import { DataGridPro, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import {
  initialDataGridState,
  slotProps,
  useGetRowSpacing,
} from '@components/Home/Alerts/config';
import { ConfigState } from '@store/reducers/configData';
import AlertsGridToolbar from '@src/components/Home/Alerts/Table/AlertsGridToolbar';
import { getAlertId } from '@src/utils/alerts';

interface ActiveAlertsProps {
  activeAlerts?: AlertsState['activeAlerts'];
  siteMeta: ConfigState['siteMeta'];
}

function ActiveAlerts({ activeAlerts = [], siteMeta }: ActiveAlertsProps) {
  const getRowSpacing = useGetRowSpacing();

  return (
    <div className='home-row frow'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <DataGridPro
            className='alerts-data-grid'
            getRowId={getAlertId}
            getRowSpacing={getRowSpacing}
            rows={activeAlerts}
            columns={prepColumns(siteMeta)}
            initialState={initialDataGridState}
            slots={{ toolbar: AlertsGridToolbar }}
            slotProps={slotProps}
            getRowClassName={({ row: { isAcked } }: GridRowClassNameParams) =>
              isAcked === true ? 'inactive-row' : ''
            }
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}

export default storeConnector(ActiveAlerts, {
  alerts: ['activeAlerts'],
  service: ['timeMode'],
  config: ['siteMeta'],
});
