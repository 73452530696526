/* eslint-disable
  import/extensions,
  max-lines-per-function,
  @typescript-eslint/no-explicit-any
*/
import React, { ReactElement } from 'react';
import BarHighChart from '@components/_elements/BarHighChart/BarHighChart';

interface EnergyProductionProps {
  battActive: boolean;
  dailyChartData: any;
  formatDailyData: any;
  loadingChartsData: any;
  pvActive: boolean;
  windActive: boolean;
}

function EnergyProduction({
  battActive,
  dailyChartData,
  formatDailyData,
  loadingChartsData,
  pvActive,
  windActive,
}: EnergyProductionProps): ReactElement {
  return (
    <div className='in' style={{ display: 'flex', flexDirection: 'column' }}>
      <div className='title-line mb-2 ml-1'>Energy Production MWh</div>
      <div className='eng-prod-legend'>
        {pvActive && <div className='pv label'>PV Production</div>}
        {windActive && <div className='wind label'>Wind Production</div>}
        {battActive && <div className='bess label'>BESS Energy</div>}
      </div>
      {(dailyChartData !== undefined && dailyChartData.length > 0) ||
      loadingChartsData.daily !== undefined ? (
        <BarHighChart
          unit='MWh'
          margins={{ top: 55, right: 30, bottom: 35, left: 60 }}
          variables={{
            ...(pvActive ? { 'PV Production': 'PV' } : {}),
            ...(windActive ? { 'Wind Production': 'Wind' } : {}),
            ...(battActive ? { 'BESS energy': 'BESS' } : {}),
          }}
          barsClasses={[
            ...(pvActive ? [0] : []),
            ...(windActive ? [1] : []),
            ...(battActive ? [2] : []),
          ]}
          loading={loadingChartsData.daily}
          data={formatDailyData}
        />
      ) : (
        <div className='placeholder-no-data'>
          No data available for Energy Production chart
        </div>
      )}
    </div>
  );
}

export default EnergyProduction;
