export enum CommandsCategory {
  TSC = 'TSC',
  MP = 'MP',
  POWINEMS = 'POWINEMS',
  POWINBESS = 'POWINBESS',
}

export type CommandsTSCOperation =
  | 'Enable'
  | 'Disable'
  | 'Power'
  | 'WriteToVariant';
export type CommandsMPOperation =
  | 'OpenBreaker'
  | 'CloseBreaker'
  | 'DisableMegapack'
  | 'ClearDisableMegapack';

export type CommandsOperation = CommandsTSCOperation | CommandsMPOperation;

export type CommandsParams = Record<string, string>;
