export const ConverterCount = 6;

export const ConverterInfoFields = [
  {
    label: 'BESS kW',
    field: 'DCDC_P_BESS',
  },
  {
    label: 'BESS DCV',
    field: 'DCDC_DCV_BESS',
  },
  {
    label: 'BESS DCI',
    field: 'DCDC_DCI_BESS',
  },
  {
    label: 'PV kW',
    field: 'DCDC_P_PV',
  },
  {
    label: 'PV DCV',
    field: 'DCDC_DCV_PV',
  },
  {
    label: 'PV DCI',
    field: 'DCDC_DCI_PV',
  },
];

export const ConverterDetailsStatusFields = [
  {
    label: 'Status',
    field: 'DCDC_StatusDC',
  },
  {
    label: 'Dchg Power Limit',
    field: 'DCDC_DischargePAvailable',
  },
  {
    label: 'Chg Power Limit',
    field: 'DCDC_ChargePAvailable',
  },
];

export const ConverterDetailsBessFields = [
  {
    label: 'BESS Contactor Status',
    field: 'DCDC_BESSContactorStatus',
  },
  {
    label: 'BESS Module Current',
    field: 'DCDC_Mod_BESScurrent',
  },
  {
    label: 'BESS Module Voltage',
    field: 'DCDC_Mod_BESSvoltage',
  },
  {
    label: 'BESS Module Power',
    field: 'DCDC_Mod_P_BESS',
  },
];

export const ConverterDetailsPvFields = [
  {
    label: 'PV Breaker Status',
    field: 'DCDC_PVBreakerStatus',
  },
  {
    label: 'PV Module Current',
    field: 'DCDC_Mod_PVcurrent',
  },
  {
    label: 'PV Module Voltage',
    field: 'DCDC_Mod_PVvoltage',
  },
  {
    label: 'PV Module Power',
    field: 'DCDC_Mod_P_PV',
  },
];

export const ConverterDetailsAllFields = [
  ...ConverterInfoFields,
  ...ConverterDetailsStatusFields,
  ...ConverterDetailsBessFields,
  ...ConverterDetailsPvFields,
];

const COMMON_PCS_FIELDS = [
  'ACBreaker',
  'AphA',
  'AphB',
  'AphC',
  'DCA',
  'DCDisconnect',
  'DCkW',
  'DCV',
  'Description',
  'Hz',
  'kVA',
  'kVAR',
  'kW',
  'PCS_MaxP',
  'PCS_MinP',
  'PF',
  'PhVphA',
  'PhVphB',
  'PhVphC',
  'WatchdogResponse',
];
const COMMON_PE_PEDC_FIELDS = [
  'ModSt',
  'ModP',
  'CurrentStatus',
  'CurrentWarn',
  'CurrentFault',
  'Bank1Enable',
  'Bank2Enable',
];
const PCS_FIELDS_DICT = {
  EPC: [
    'PCS_TmpInlt',
    'PCS_tInlet',
    'PCS_tBridgeA',
    'PCS_tBridgeB',
    'Pcmd_Sent',
    'PCS_P',
    'PCS_Q',
    'DCAHi',
    'DCVHi',
    'InvStatus',
    'RunMode',
    'PFa',
    'PFb',
    'PFc',
  ],
  PE: [...COMMON_PE_PEDC_FIELDS].filter(
    (field) => field !== 'ACBreaker' && field !== 'DCDisconnect',
  ),
  PE4bus: [...COMMON_PE_PEDC_FIELDS, 'Bank3Enable', 'Bank4Enable'].filter(
    (field) => field !== 'ACBreaker' && field !== 'DCDisconnect',
  ),
  PEDC: [
    ...COMMON_PE_PEDC_FIELDS,
    ...ConverterDetailsAllFields.map((el) => el.field),
  ],
  Sungrow: [
    'Vab',
    'Vbc',
    'Vca',
    'PV_PCS_Night_SVG_Switch',
    'PV_PCS_LocalRemote_Control',
    'DCDisconnect_string',
    'Active_power_percentage_setting',
    'Reactive_power_percentage_setting',
  ],
  SungrowBESS: ['InvStatus'],
};
export const getPCSFields = (type) => [
  ...COMMON_PCS_FIELDS,
  ...(PCS_FIELDS_DICT[type] || []),
];

export const UNIT_FIELDS = [
  'InvStatus',
  'RunMode',
  'PCS_P',
  'PCS_Q',
  'Pset',
  'Qset',
];
