import React, { useEffect, useRef } from 'react';
import JsonEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './JSONEditor.scoped.scss';
import storeConnector from '@store/storeConnector';

function JSONEditor({ onChange, options, json, darkTheme }) {
  const container = useRef();
  const jsonEditorInstance = useRef();

  useEffect(() => {
    const opts = {
      mode: 'view',
      enableTransform: false,
      enableSort: false,
      onChangeText: (v) => {
        try {
          onChange(JSON.parse(v));
        } catch {
          /* empty */
        }
      },
      ...(options || {}),
    };

    jsonEditorInstance.current = new JsonEditor(container.current, opts);
    jsonEditorInstance.current.set(json);

    return () => {
      if (jsonEditorInstance.current) {
        jsonEditorInstance.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    // to prevent resorting when writing code
    if (jsonEditorInstance.current.mode !== 'code') {
      jsonEditorInstance.current.update(json);
    }
  });

  return (
    <div
      className={`jsoneditor-react-container ${
        darkTheme ? 'dark-jsonparser' : 'light-jsonparser'
      }`}
      ref={container}
    />
  );
}

export default storeConnector(JSONEditor, {
  service: ['darkTheme'],
});
