export interface IMqttMessage {
  value: string | number | null;
  ts: string | null;
}

export interface IMqttSystemMessage {
  MSG: string;
  SN: string;
  TS: string;
  Description: string;
  Version: string;
  Counter: string;
  ItemDeletionTime: number;
}

export function isIMqttMessage(o: unknown): o is IMqttMessage {
  return o != null && typeof o === 'object' && 'value' in o && 'ts' in o;
}
