import React from 'react';
import './Windmill.scoped.scss';
import D1 from '@assets/404-1-01.svg?react';
import D2 from '@assets/404-2-01.svg?react';

interface WindmillProps {
  opacity?: string;
  top?: string;
  left?: string;
  scale?: string;
  right?: string;
  bottom?: string;
}

function Windmill({ opacity, top, left, scale, right, bottom }: WindmillProps) {
  const style = {
    ...(opacity ? { opacity } : {}),
    ...(top ? { top } : {}),
    ...(left ? { left } : {}),
    ...(scale ? { transform: `scale(${scale})` } : {}),
    ...(right ? { left: 'unset', right } : {}),
    ...(bottom ? { top: 'unset', bottom } : {}),
  };
  return (
    <div className='wind-mill' style={style}>
      <div className='part1'>
        <D1 />
      </div>
      <div className='part2'>
        <D2 />
      </div>
    </div>
  );
}

export default Windmill;
