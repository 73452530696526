import React from 'react';
import './Notifications.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';
import storeConnector from '@store/storeConnector';

const Notifications = ({ tmpNotifications, actions }) => {
  return (
    tmpNotifications && (
      <div className='notifications-list' style={{ pointerEvents: 'none' }}>
        {tmpNotifications.map((n) => (
          <div
            key={n.id}
            className={`notification ${n.type} ${n.className}`}
            style={{ pointerEvents: 'all' }}
          >
            <div>
              <div>{n.message}</div>
              <div
                className='close-notification'
                onClick={() => actions.closeNotification(n.id)}
              >
                &times;
              </div>
            </div>
            {n.yesCallBack && (
              <div>
                <Button
                  className='notification-button'
                  onClick={() => {
                    actions.closeNotification(n.id);
                    n.yesCallBack();
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
                <Button
                  className='notification-button'
                  onClick={() => {
                    actions.closeNotification(n.id);
                    n.noCallBack();
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    )
  );
};

export default storeConnector(Notifications, {
  service: ['tmpNotifications'],
});
