import * as React from "react";
const SvgWarning = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Capa_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 511.999 511.999", style: {
  enableBackground: "new 0 0 511.999 511.999"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E50027"
}, d: "M501.449,368.914L320.566,66.207C306.751,43.384,282.728,29.569,256,29.569 s-50.752,13.815-64.567,36.638L10.55,368.914c-13.812,23.725-14.113,51.954-0.599,75.678c13.513,23.723,37.836,37.838,65.165,37.838 h361.766c27.329,0,51.653-14.115,65.165-37.838C515.563,420.868,515.262,392.639,501.449,368.914z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#C1001F"
}, d: "M502.049,444.592c-13.513,23.723-37.836,37.838-65.165,37.838H256V29.57 c26.727,0,50.752,13.815,64.567,36.638L501.45,368.915C515.262,392.639,515.563,420.868,502.049,444.592z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FD003A"
}, d: "M75.109,452.4c-16.628,0-30.851-8.27-39.063-22.669c-8.211-14.414-8.065-31.087,0.469-45.72 L217.23,81.549c8.27-13.666,22.816-21.951,38.769-21.951s30.5,8.284,38.887,22.157l180.745,302.49 c8.388,14.4,8.534,31.072,0.322,45.485c-8.211,14.4-22.435,22.669-39.063,22.669H75.109V452.4z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E50027"
}, d: "M436.891,452.4c16.628,0,30.851-8.27,39.063-22.669c8.211-14.414,8.065-31.087-0.322-45.485 L294.886,81.754c-8.388-13.871-22.933-22.157-38.887-22.157V452.4H436.891z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E1E4FB"
}, d: "M286.03,152.095v120.122c0,16.517-13.514,30.03-30.03,30.03s-30.031-13.514-30.031-30.03V152.095 c0-16.517,13.514-30.031,30.031-30.031S286.03,135.578,286.03,152.095z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#C5C9F7"
}, d: "M286.03,152.095v120.122c0,16.517-13.514,30.03-30.03,30.03V122.064 C272.516,122.064,286.03,135.578,286.03,152.095z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E1E4FB"
}, d: "M256,332.278c-24.926,0-45.046,20.119-45.046,45.046c0,24.924,20.119,45.046,45.046,45.046 s45.046-20.121,45.046-45.046C301.046,352.398,280.925,332.278,256,332.278z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#C5C9F7"
}, d: "M301.046,377.323c0,24.924-20.119,45.046-45.046,45.046v-90.091 C280.925,332.278,301.046,352.398,301.046,377.323z" }), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null), /* @__PURE__ */ React.createElement("g", null));
export default SvgWarning;
