import { callAPI, getLocale } from '@utils';
import { store } from '@store';
import moment from 'moment-timezone';
import { notifyError } from './notifications';

/*
eventFromLog:{
    eventID: String,
    deviceId: String,
    status: String, // started, completed
    //
    start_ts: String,
    end_ts:,
}
eventFromDB:{
    id: String,            // UUID
    title: String,
    deviceId: String,       // deviceId of Site
    startDate: MM/DD/YYYY,  // convert to date
    startTime: HH:MM:SS,    // convert to date
    stopTime: HH:MM:SS,     // convert to date
    color: String,          // #ffb623
    period: String,         // day, month, weekend, weekday
    recurrence: String,     // "", ntimes, tilldate, forever
    repeatTime: Int,        // count of repear time
    tillDate: Int,          // convert to date
    startCommands:[String],
    stopCommands:[String],
    stack: String,
    activeWeekdays: String,  // JSON.stringify([boolean x7])
    frequencyValue Int,
    frequencyPeriod: String,
    monthlyType: String
}
*/

export const calendar = {
  getTimeframeEvents(timeframe) {
    return function () {
      return new Promise(async (resolve) => {
        const events = await callAPI(
          `/calendar/event/list/${store.getState().config.siteMeta.siteId}`,
          'GET',
          timeframe,
        );
        if (events && !events.error) {
          resolve(events);
        } else {
          notifyError('Cannot get events');
          resolve([]);
        }
      });
    };
  },

  previewNewEvent(timeframe, event) {
    return function () {
      return new Promise(async (resolve) => {
        const events = await callAPI(
          `/calendar/event/${store.getState().config.siteMeta.siteId}`,
          'POST',
          { event, preview: true, timeframe },
        );
        if (events && !events.error) {
          resolve(events);
        } else {
          notifyError('Cannot get events');
          resolve([]);
        }
      });
    };
  },

  saveNewEvent(timeframe, event) {
    return function () {
      return new Promise(async (resolve) => {
        const events = await callAPI(
          `/calendar/event/${store.getState().config.siteMeta.siteId}`,
          'POST',
          { event, timeframe },
        );
        if (events && !events.error) {
          resolve(events);
        } else {
          notifyError('Cannot get events');
          resolve([]);
        }
      });
    };
  },

  previewDeleteEvent(timeframe, eventId, deleteEventAt) {
    return function () {
      return new Promise(async (resolve) => {
        const { siteId } = store.getState().config.siteMeta;
        const res = await callAPI(
          `/calendar/event/${siteId}/${eventId}`,
          'DELETE',
          { preview: true, timeframe, deleteEventAt },
        );
        resolve(res);
      });
    };
  },

  saveDeleteEvent(timeframe, eventId, deleteEventAt) {
    return function () {
      return new Promise(async (resolve) => {
        const { siteId } = store.getState().config.siteMeta;
        const res = await callAPI(
          `/calendar/event/${siteId}/${eventId}`,
          'DELETE',
          { timeframe, deleteEventAt },
        );
        resolve(res);
      });
    };
  },

  previewEditEvent(timeframe, eventId, event, editEventAt) {
    return function () {
      return new Promise(async (resolve) => {
        const { siteId } = store.getState().config.siteMeta;
        const res = await callAPI(
          `/calendar/event/${siteId}/${eventId}`,
          'PUT',
          { preview: true, timeframe, editEventAt, event },
        );
        resolve(res);
      });
    };
  },

  saveEditEvent(timeframe, eventId, event, editEventAt) {
    return function () {
      return new Promise(async (resolve) => {
        const { siteId } = store.getState().config.siteMeta;
        const res = await callAPI(
          `/calendar/event/${siteId}/${eventId}`,
          'PUT',
          { timeframe, editEventAt, event },
        );
        resolve(res);
      });
    };
  },

  convertEvents(stackList, baseEvents, className, selectedEventID, PREVIEW_ID) {
    return function () {
      const { GPSLat, GPSLong } = store.getState().config.siteMeta;
      const { timeMode } = store.getState().service;
      const tz = getLocale({ timeMode, lat: GPSLat, long: GPSLong });
      return _convertEvents(
        baseEvents,
        className,
        tz,
        stackList,
        selectedEventID,
        PREVIEW_ID,
      );
    };
  },
};

function _convertEvents(
  baseEvents,
  className,
  tz,
  stacks,
  selectedEvent,
  PREVIEW_ID,
) {
  const isDateBeforeNow = (date) => {
    const momentNow = moment.utc();
    const momentDate = moment.utc(date);
    return momentDate.isBefore(momentNow);
  };
  return baseEvents
    .filter((e) => !(!e.log && isDateBeforeNow(e.stop)))
    .map((event) => {
      let isLog = event.log;
      let title = event.title ? event.title : '';
      const startCmds = event.startCommands
        ? event.startCommands.reduce(
            (p, c) => (c ? `${p ? `${p}<br>` : ''}↗${c}` : p),
            '',
          )
        : '';
      title += startCmds ? `\n${startCmds}` : '';

      let stack = '';
      if (event.stack) {
        stack += `☰${stacks[event.stack]?.Name}`;
      }
      if (event.stackName) {
        stack += `☰${event.stackName}`;
      }
      title += stack ? `\n${stack}` : '';
      const stopCmds = event.stopCommands
        ? event.stopCommands.reduce(
            (p, c) => (c ? `${p ? `${p}<br>` : ''}↘${c}` : p),
            '',
          )
        : '';
      title += stopCmds ? `\n${stopCmds}` : '';
      if (isLog) {
        return {
          id: event.id,
          title: title,
          start: moment(event.start).valueOf(),
          end: moment(event.stop).valueOf(),
          className: `event-from-log eventEMS_log_${event.id} ${
            selectedEvent === event.id && 'event-editing'
          }`,
          backgroundColor: 'gray',
          rendering: 'event',
          status: event.status,
          isLog,
        };
      } else {
        return {
          id: event.id,
          title: title,
          start: event.start ? event.start.valueOf() : '',
          end: event.stop ? event.stop.valueOf() : '',
          className: `${className} eventEMS_${event.id} ${
            (selectedEvent === event.id || PREVIEW_ID === event.id) &&
            'event-editing'
          }`,
          backgroundColor:
            moment.tz(event.start, tz) < moment().tz(tz) &&
            moment().tz(tz) > moment.tz(event.stop, tz)
              ? 'gray'
              : event.color,
          rendering: 'event',
          status: event.status,
          isLog,
        };
      }
    });
}
