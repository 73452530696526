import React, { useMemo } from 'react';
import { isActionRole } from '@src/services/auth';
import useSubscription from '@src/hooks/useSubscription';
import { useCommandsModalContext } from '@src/components/Battery/Tesla/Commands/CommandsModalContext';
import { Switch } from '@mui/material';
import storeConnector from '@src/store/storeConnector';
import { UserRole } from '@src/services/auth/roles';
import { SiteMeta } from '@src/types/SiteMeta';
import TeslaStatusIcon from './TeslaStatusIcon';
import './TSCConfig.scoped.scss';

interface StatusProps {
  siteMeta: SiteMeta;
  role: UserRole;
}

function TSCStatusDisplay({ siteMeta, role }: StatusProps) {
  const { siteId, ui } = siteMeta;
  const statusPointConfig = useMemo(
    () => [
      {
        category: 'site',
        controllerId: siteId,
        pointName: '',
        dataType: 'string',
        displayName: 'InvStatus',
        fullTopic: `ems/site/${siteId}/tsc/tsc_1/InvStatus/string`,
        scalingFactor: null,
        sourceDeviceId: null,
        sourceDeviceType: null,
        decimalPrecision: null,
        siteId,
        unit: null,
        displayDenominator: null,
      },
    ],
    [siteId],
  );
  const dataPoints = useSubscription(statusPointConfig);
  const { value } = dataPoints[0];

  const isEnabled = value === 'RunPQ';
  const enabledStatus = value === 'RunPQ' ? 1 : 0;

  const { sendCommand } = useCommandsModalContext();
  const handleCommand = (val: boolean) => {
    const newValue = val ? 'Enable' : 'Disable';

    sendCommand(newValue);
  };

  return (
    <div className='modes-row'>
      <div className='modes-cell status__title-cell '>
        <strong className='tsc__title'>TSC Enabled:</strong>
      </div>
      <div className='modes-cell'>
        <div className='status__status-cell'>
          <TeslaStatusIcon status={enabledStatus} />
        </div>
        {ui.Command_Access_Enable && (
          <Switch
            checked={isEnabled}
            onChange={(e) => handleCommand(e.target.checked)}
            disabled={!ui.TSC_Config_Enable || !isActionRole(role)}
          />
        )}
      </div>
    </div>
  );
}

export default storeConnector(TSCStatusDisplay, {
  user: ['role'],
  config: ['siteMeta'],
});
