import { Button, Grid2, styled } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { isValueInRange } from '@utils/mathHelpers';
import CommandsNumberInput from './CommandsNumberInput';
import { useCommandsModalContext } from '../CommandsModalContext';

const StyledCommandLabel = styled(Grid2)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
}));

// eslint-disable-next-line max-lines-per-function
function PowerSection({
  currentPset,
  currentQset,
}: {
  currentPset: string;
  currentQset: string;
}) {
  const { sendCommand } = useCommandsModalContext();
  const [activePower, setActivePower] = useState<string>(currentPset);
  const [reactivePower, setReactivePower] = useState<string>(currentQset);

  const isPowerInputValid = useMemo(
    () =>
      isValueInRange(activePower, -93000, 93000) &&
      isValueInRange(reactivePower, -93000, 93000),
    [activePower, reactivePower],
  );

  const handleClickSend = () => {
    if (!isPowerInputValid) {
      return;
    }
    sendCommand('Power', {
      P: activePower,
      Q: reactivePower,
    });
  };

  return (
    <Grid2 container spacing={1}>
      <StyledCommandLabel size={{ xs: 4 }}>
        Real (P) Command:
      </StyledCommandLabel>
      <Grid2 size={{ xs: 8 }}>
        <CommandsNumberInput
          label='Active Power'
          unit='kW'
          minimum={-93000}
          maximum={93000}
          value={activePower}
          setValue={setActivePower}
        />
      </Grid2>
      <StyledCommandLabel size={{ xs: 4 }}>
        Reactive (Q) Command:
      </StyledCommandLabel>
      <Grid2 size={{ xs: 8 }}>
        <CommandsNumberInput
          label='Reative Power'
          unit='kVAR'
          minimum={-93000}
          maximum={93000}
          value={reactivePower}
          setValue={setReactivePower}
        />
      </Grid2>
      <Grid2 size={{ xs: 4 }} />
      <Grid2 size={{ xs: 8 }} sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          disabled={!isPowerInputValid}
          onClick={handleClickSend}
        >
          Send
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default PowerSection;
