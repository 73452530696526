import React from 'react';
import { RouteObject } from 'react-router-dom';
import AppLayout from '@pages/_layout/AppLayout';
import UserPanel from '.';

export default [
  {
    path: 'users',
    element: <AppLayout />,
    children: [{ index: true, element: <UserPanel /> }],
  },
] as RouteObject[];
