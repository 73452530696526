import QueryKeys from '@src/constants/queryKeys';
import useApiQuery from '@src/hooks/useApiQuery';
import { Group } from '@src/types/Group';

const useUserGroups = (userId: string | undefined) =>
  useApiQuery<Group[]>({
    queryKey: [QueryKeys['user-groups'], userId ?? ''],
    apiPath: `/users/${userId}/groups`,
    method: 'GET',
    useV2API: true,
    enabled: !!userId,
  });

export default useUserGroups;
