import { callAPI, getLocale } from '@utils';
import { notifyError } from './notifications';
import { store } from '@store';
import historianSearchSerializer from '@src/utils/historianSearchSerializer';

export const historian = {
  getHistorianOptions(params) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI('/historian/options', 'GET', params || {});
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get historian options');
        }
        resolve(res);
      });
    };
  },
  getHistorianSearchList() {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI('/historian/savedSearch/list', 'GET');
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get historian search list');
          resolve([]);
        }
        resolve(res.map(historianSearchSerializer.deserialize));
      });
    };
  },
  addHistorianSearch(search) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(
          '/historian/savedSearch',
          'POST',
          historianSearchSerializer.serialize(search),
        );
        if (!res || res.error) {
          notifyError(res.error || 'Cannot add historian search request');
          resolve({
            error: res.error || 'Cannot add historian search request',
          });
        }
        resolve(historianSearchSerializer.deserialize(res));
      });
    };
  },
  saveEditedHistorianSearch(search) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(
          `/historian/savedSearch/${search.id}`,
          'PUT',
          historianSearchSerializer.serialize(search),
        );
        if (!res || res.error) {
          notifyError(res.error || 'Cannot save historian search request');
          resolve({
            error: res.error || 'Cannot save historian search request',
          });
        }
        resolve(historianSearchSerializer.deserialize(res));
      });
    };
  },
  deleteHistorianSearch(id) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(`/historian/savedSearch/${id}`, 'DELETE');
        if (!res || res.error) {
          notifyError(res.error || 'Cannot delete historian search request');
          resolve({
            error: res.error || 'Cannot delete historian search request',
          });
        }
        resolve(res);
      });
    };
  },
  runHistorianDataDump(params) {
    return function () {
      return new Promise(async (resolve) => {
        const state = store.getState();
        const res = await callAPI('/historian/dataDownloadToken', 'GET', {
          ...params,
          timeMode: state.service.timeMode,
          timezoneOffset: getLocale({
            timeMode: state.service.timeMode,
            lat: state.config.siteMeta.GPSLat,
            long: state.config.siteMeta.GPSLong,
            utcOffset: true,
          }),
        });
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get historian Active dump');
          resolve({ error: res.error || 'Cannot get historian Active dump' });
        }
        resolve(res);
      });
    };
  },
  getHistorianDataDump(params) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(
          '/historian/dataDownload',
          'GET',
          params,
          true,
        );
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get historian Active dump');
          resolve({ error: res.error || 'Cannot get historian Active dump' });
        }
        resolve(res);
      });
    };
  },
  getHistorianData(req) {
    return function (dispatch) {
      return new Promise(async (resolve) => {
        const res = await callAPI('/historian/query', 'POST', req);
        if (!res || res.error) {
          dispatch(notifyError(res.error || 'Cannot get historian Active'));
          resolve({ error: res.error || 'Cannot get historian Active' });
        }
        resolve(res);
      });
    };
  },
  getHistorianCategoriesAndUnits() {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI('/historian/filters', 'GET');
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get historian categories options');
        }
        resolve(res);
      });
    };
  },
  getHistorianPointNames(body) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(
          '/historian/filters/pointNames',
          'POST',
          body,
        );
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get historian point name options');
          return;
        }
        resolve(res);
      });
    };
  },
  getHistorianSourceDeviceIds(body) {
    return function () {
      return new Promise(async (resolve) => {
        const res = await callAPI(
          '/historian/filters/sourceDeviceIds',
          'POST',
          body,
        );
        if (!res || res.error) {
          notifyError(res.error || 'Cannot get historian options');
        }
        resolve(res);
      });
    };
  },
};
