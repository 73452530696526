import { DeviceNotesResponse } from '@src/types/Notes';

const notesx = {
  boxWrapper: {
    position: 'absolute',
    top: '50%',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '850px',
    maxWidth: '95%',
    borderRadius: '10px',
    outline: 'none !important',
    height: 650,
    bgcolor: '#393e44',
    py: 3,
  },
  title: {
    mb: 3,
    color: 'white',
  },
  textField: {
    flexBasis: '80%',
    flexGrow: '1',
    marginRight: '16px',
    '& .MuiInputBase-input': { color: 'black' },
    '& .MuiInputBase-root': { bgcolor: 'rgba(255, 255, 255, 0.35)' },
  },
  alert: {
    mb: 2,
  },
  dialogActions: {
    mt: 2,
  },
  notesWrapper: {
    display: 'flex',
    p: 1.5,
    my: 2,
    bgcolor: 'rgba(215, 215, 215, 0.05)',
    justifyContent: 'space-between',
    fontSize: '13px',
    alignItems: 'center',
  },
  notesButton: (data: DeviceNotesResponse | undefined) => ({
    opacity:
      data?.notes?.length !== undefined && data?.notes?.length > 0
        ? '1'
        : '0.3',
  }),
};

export default notesx;
