import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import storeConnector from '@store/storeConnector';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColType,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import useGetWidgetConfigs from '@src/hooks/api/queries/collections/useGetWidgetConfigs';
import { CollectionPayload, CollectionTypes } from '@src/types/Collection';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { GridBaseColDef } from '@mui/x-data-grid/internals';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import deviceSx from '@src/components/Home/Collections/configs/DeviceSx';
import getCollectionsInitialState from '@src/components/Home/Collections/utils/getCollectionsInitialState';
import CollectionToolbar from '@src/components/Home/Collections/CollectionToolbar';
import widgetConfigBaseColumns from '@src/components/Home/Collections/configs/widgetConfigBaseColumns';
import WidgetConfigDialog from '@src/components/Home/Collections/WidgetConfigs/WidgetConfigDialog';
import { WidgetConfigBase } from '@src/types/Widget';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    collectionType?: CollectionTypes;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
  }
}

// eslint-disable-next-line max-lines-per-function
function WidgetConfigCollection({ role }: { role: UserRole }) {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [rows, setRows] = useState<WidgetConfigBase[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const apiRef = useGridApiRef();

  const [jsonPayload, setJsonPayload] = useState<CollectionPayload | null>(
    null,
  );
  const { data, isFetching, isPending, isSuccess, error, isRefetching } =
    useGetWidgetConfigs();

  const handleClose = (
    _: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setConfirmOpen(false);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (apiRef.current !== undefined) {
      setJsonPayload({
        ...(apiRef.current.getRow(id) as CollectionPayload),
        remove: true,
      });
    }
    setOpen(true);
  };

  const handleCancelClick = (id?: GridRowId) => () => {
    if (id !== undefined) {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    }
    setOpen(false);
    setJsonPayload(null);
  };

  const columns = [
    ...widgetConfigBaseColumns,
    ...(isActionRole(role) && !process.env.VITE_READ_ONLY_MODE
      ? [
          {
            field: 'actions',
            type: 'actions' as GridColType,
            headerName: 'Actions',
            description: 'Actions',
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }: GridRowParams<{ id: string }>) => [
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label='Delete'
                onClick={handleDeleteClick(id)}
                color='inherit'
              />,
            ],
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    if (isSuccess && data !== null) {
      setRows(data?.map((device) => ({ ...device, isNew: false })));
    }
  }, [isSuccess, data, error]);

  return (
    <div className='home-row frow' id='alerts-config-wrapper'>
      <div className='fcol h100 full-width'>
        <div className='cell block-container flex-1 overflow-y-auto'>
          <Snackbar
            open={confirmOpen}
            autoHideDuration={4000}
            onClose={handleClose}
            sx={deviceSx.confirm}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              variant='filled'
              sx={deviceSx.confirm.alert}
            >
              Point config changes saved
            </Alert>
          </Snackbar>
          <DataGridPro
            rows={rows}
            apiRef={apiRef}
            checkboxSelection
            rowModesModel={rowModesModel}
            onRowModesModelChange={setRowModesModel}
            onRowSelectionModelChange={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            initialState={getCollectionsInitialState()}
            columns={columns as GridBaseColDef[]}
            getRowId={({ id }) => id}
            loading={!isRefetching && (isFetching || isPending)}
            slots={{
              toolbar: CollectionToolbar,
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
              toolbar: {
                collectionType: CollectionTypes.WIDGETS,
                setOpen,
                setJsonPayload,
              },
            }}
          />

          {open && (
            <WidgetConfigDialog
              jsonData={jsonPayload as WidgetConfigBase}
              setOpen={setOpen}
              handleCancel={handleCancelClick}
              setRowModesModel={setRowModesModel}
              setJsonPayload={setJsonPayload}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default storeConnector(WidgetConfigCollection, {
  user: ['role'],
});
