const collectionSx = {
  collectionToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    quickFilter: {
      ml: 2,
    },
  },
};

export default collectionSx;
