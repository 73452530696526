import React, { ReactElement, ReactNode } from 'react';
import { Box, Card, Theme, Typography } from '@mui/material';

interface UIBoxProps {
  header: string;
  children: ReactNode;
}

const PADDING = 2;
const MARGIN = 2;

const sx = (theme: Theme) => ({
  borderColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  p: PADDING,
  m: MARGIN,
});

function UIBox(p: UIBoxProps): ReactElement {
  return (
    <Card className='cell' sx={sx}>
      {p.header ? (
        <Typography variant='h6' sx={{ marginBottom: '15px' }}>
          {p.header}
        </Typography>
      ) : null}
      <Box>{p.children}</Box>
    </Card>
  );
}

export default UIBox;
