import React from 'react';
import './ApplicationPreview.scoped.scss';
import JSONEditor from '@components/_elements/JSONEditor/JSONEditor';

const ApplicationPreview = ({ application }) => {
  return application ? (
    <div className='application-details'>
      <div className='app-title'>
        {application.name} <small>({application.description})</small>
      </div>
      <div className='content'>
        <JSONEditor json={application} options={{}} />
      </div>
    </div>
  ) : null;
};

export default ApplicationPreview;
