import {
  DataPoint,
  QueryDataPointsSearchBodyElement,
} from '@src/types/DataPoint';
import { SiteMeta } from '@src/types/SiteMeta';
import DataTypePointNameDictionary from '@utils/config/dataTypePointName.map';
import { capitalized, purgeEmptyStrings } from '@utils/index_ts';

export type GetFullTopicV2Args = {
  dataType: string;
} & QueryDataPointsSearchBodyElement;

export const SiteControllerSourceDeviceCategories = [
  'ads',
  'alerts',
  'comap',
  'commstatus',
  'facp',
  'imd',
  'local controller',
  'master_tracker',
  'meter',
  'mkt',
  'mp',
  'mpc',
  'plc',
  'powinems',
  'powinbess',
  'rtac',
  'site',
  'tsc',
  'ups',
  'weather',
];

export const getFullTopicV1 = (o: GetFullTopicV2Args, siteId: string) =>
  purgeEmptyStrings(() => {
    switch (capitalized(o.category)) {
      case 'Site':
        return `Site/${o.controllerId}/${o.pointName}/${o.dataType}`;
      case 'MSG':
        return `MSG/${o.controllerId}`;
      default:
        return `${siteId}/${capitalized(o.category)}/${o.controllerId}/${
          o.pointName
        }/${o.dataType}`;
    }
  });

export const getFullTopicV2 = (
  o: GetFullTopicV2Args,
  siteId: string,
  topicPrefix: string | undefined,
  topicRegister: string | null | undefined,
) => {
  const siteLevelDeviceCategory = SiteControllerSourceDeviceCategories.find(
    (d) => d === o.category,
  );
  const tPrefix = topicPrefix || 'ems';
  const tRegister = topicRegister ? `/${topicRegister}` : '';
  // Site root point - 7 segments
  if (o.category === 'site' && o.sourceDeviceId === undefined) {
    return `${tPrefix}/site/${siteId}/root/${o.pointName}/${o.dataType}${tRegister}`;
  }
  // Site sourceDevice point - 8 segments
  if (siteLevelDeviceCategory !== undefined && o.sourceDeviceId !== undefined) {
    return `${tPrefix}/site/${siteId}/${siteLevelDeviceCategory}/${o.sourceDeviceId}/${o.pointName}/${o.dataType}${tRegister}`;
  }
  // Unit root point - 9 segments
  if (o.category === 'unit' && o.sourceDeviceId === undefined) {
    return `${tPrefix}/site/${siteId}/unit/${o.controllerId}/root/${o.pointName}/${o.dataType}${tRegister}`;
  }
  // Unit sourceDevice point - 10 segments
  if (siteLevelDeviceCategory === undefined && o.sourceDeviceId !== undefined) {
    return `${tPrefix}/site/${siteId}/unit/${o.controllerId}/${o.category}/${o.sourceDeviceId}/${o.pointName}/${o.dataType}${tRegister}`;
  }
  return null;
};

export const generateDataPoint = (
  isNewTopicStructure: boolean,
  pointObj: QueryDataPointsSearchBodyElement,
  siteId: string,
  topicPrefix: string | undefined,
  topicRegister: string | null | undefined,
): DataPoint => {
  const dataType = DataTypePointNameDictionary[pointObj.pointName] || 'float';
  const fullTopic =
    (isNewTopicStructure
      ? getFullTopicV2(
          { ...pointObj, dataType },
          siteId,
          topicPrefix,
          topicRegister,
        )
      : getFullTopicV1({ ...pointObj, dataType }, siteId)) ||
    'incorrect/parameters/to/generate/topic';
  return {
    pointAlias: pointObj.pointAlias,
    category: pointObj.category,
    controllerId: pointObj.controllerId || siteId,
    pointName: pointObj.pointName,
    fullTopic,
    siteId,
    dataType,
    sourceDeviceId: pointObj.sourceDeviceId || null, // Type.String()
    sourceDeviceType: pointObj.sourceDeviceId ? pointObj.category : null,
    displayName: null, // Type.String()
    unit: null, // Type.String()
    scalingFactor: null, // Type.Number()
    decimalPrecision: null, // Type.Number()
    displayDenominator: null, // Type.Number()
  };
};

export const generateTopics = (
  isNewTopicStructure: boolean,
  data: QueryDataPointsSearchBodyElement[],
  siteId: SiteMeta['siteId'],
  topicPrefix: SiteMeta['topicPrefix'],
  topicRegister: SiteMeta['topicRegister'],
) =>
  data.map((p) =>
    generateDataPoint(
      isNewTopicStructure,
      p,
      siteId,
      topicPrefix,
      topicRegister,
    ),
  );
