import { Actions } from '@src/types/Actions';
import React, { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import TextLoader from '@components/_elements/TextLoader';
import LineHighChart from '@components/_elements/LineHighChart/LineHighChart';
import { roundToTwoNonZero } from '@src/utils/mathHelpers';

interface SOCChartProps {
  actions: Actions;
  battActive: boolean;
  isSiteLoading: (v: string) => boolean;
  siteRt: Record<string, number | undefined>;
  siteMeta: SiteMeta;
}

// eslint-disable-next-line max-lines-per-function
function SOCChart({
  actions,
  battActive,
  isSiteLoading,
  siteRt,
  siteMeta,
}: SOCChartProps): ReactElement {
  const { siteId } = siteMeta;
  return (
    <div className='cell h-50 block-container'>
      <LineHighChart
        hideVarSwitches
        hideGrids
        defaultShowVariables={
          battActive
            ? ['MaxSOC', 'MinSOC', 'AvgSOC']
            : ['UnitMaxP', 'UnitMinP', 'UnitAvgP']
        }
        margins={{ top: 75, right: 30, bottom: 40, left: 40 }}
        fixedYTicks={3}
        min={battActive ? siteMeta.SOCPlotMin : siteMeta.Thresh_UnitMinP}
        max={battActive ? siteMeta.SOCPlotMax : siteMeta.Thresh_UnitMaxP}
        units={
          battActive
            ? {
                MaxSOC: '%',
                MinSOC: '%',
                SOC: '%',
              }
            : {
                UnitMaxP: 'kW',
                UnitMinP: 'kW',
                UnitAvgP: 'kW',
              }
        }
        variables={
          battActive
            ? {
                MaxSOC: 'MaxSOC',
                MinSOC: 'MinSOC',
                AvgSOC: 'SOC',
              }
            : {
                UnitMaxP: 'UnitMaxP',
                UnitMinP: 'UnitMinP',
                UnitAvgP: 'UnitAvgP',
              }
        }
        dataLoadFunction={(duration: string) =>
          actions.loadChartData(
            siteId,
            duration,
            battActive
              ? ['MinSOC', 'MaxSOC', 'SOC']
              : ['UnitMaxP', 'UnitMinP', 'UnitAvgP'],
          )
        }
        durationSwitches={['15M', '1H', '12H', '1D', '3D']}
      >
        <div className='over-chart'>
          <div className='soc-info-section'>
            <div className='info-col'>
              <div className='big'>{battActive ? 'SOC' : 'Unit Output'}</div>
            </div>
            <div className='info-col'>
              <div className='big'>Avg</div>
              <div className='small'>Max / Min</div>
            </div>
            <div className='info-col'>
              <div className='big'>
                <TextLoader
                  fontSise={18}
                  loading={isSiteLoading(battActive ? 'SOC' : 'UnitAvgP')}
                >
                  {battActive
                    ? `${roundToTwoNonZero(siteRt.SOC)}%`
                    : `${roundToTwoNonZero(siteRt.UnitAvgP)} kW`}
                </TextLoader>
              </div>
              <div className='small flex-row'>
                <TextLoader
                  fontSise={10}
                  loading={isSiteLoading(battActive ? 'MaxSOC' : 'UnitMaxP')}
                >
                  {battActive
                    ? `${roundToTwoNonZero(siteRt.MaxSOC)}%`
                    : `${roundToTwoNonZero(siteRt.UnitMaxP)} kW`}
                </TextLoader>
                <span>&nbsp;/&nbsp;</span>
                <TextLoader
                  fontSise={10}
                  loading={isSiteLoading(battActive ? 'MinSOC' : 'UnitMinP')}
                >
                  {battActive
                    ? `${roundToTwoNonZero(siteRt.MinSOC)}%`
                    : `${roundToTwoNonZero(siteRt.UnitMinP)} kW`}
                </TextLoader>
              </div>
            </div>
          </div>
          <div className='chart-titles'>
            <div
              className='color-chart-0 small-text'
              style={{ marginRight: '10px' }}
            >
              {battActive ? 'Max SOC' : 'Unit Max'}
            </div>
            <div
              className='color-chart-1 small-text'
              style={{ marginRight: '10px' }}
            >
              {battActive ? 'Min SOC' : 'Unit Min'}
            </div>
            <div className='color-chart-2 small-text'>
              {battActive ? 'Avg SOC' : 'Unit Avg'}
            </div>
          </div>
        </div>
      </LineHighChart>
    </div>
  );
}

export default storeConnector(SOCChart, {
  config: ['siteMeta'],
});
