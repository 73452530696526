import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import { AlertConfig } from '@src/types/Alerts';

export default (file?: 'csv' | 'json') =>
  useApiQuery<AlertConfig[]>({
    queryKey: [QueryKeys.alerts, `export-${file}`],
    apiPath: `/alerts/alert-configs/file?extension=${file}`,
    method: 'GET',
    isFile: true,
    enabled: !!file,
  });
