import React from 'react';
import type { ReactElement } from 'react';
import Section4 from './Section4';
import Section3 from './Section3';
import Section2 from './Section2';
import Section1 from './Section1';

function Defs(): ReactElement {
  return (
    <defs>
      <g id='lend4'>
        <path d='M2 1 0 0l2-1v2' stroke='none' />
      </g>
      <marker
        id='marker_0'
        refX='-7'
        orient='auto'
        markerUnits='strokeWidth'
        overflow='visible'
        fill='currentColor'
      >
        <use xlinkHref='#lend4' transform='scale(-3.52)' />
      </marker>
    </defs>
  );
}

function Diagram(): ReactElement {
  return (
    <svg
      style={{ transform: 'scale(1.2)' }}
      width={800}
      height={700}
      fill='none'
      stroke='currentColor'
      strokeWidth={1.5}
    >
      <Defs />
      <g transform='rotate(90) translate(-250,-775)'>
        <Section1 />
        <Section2 />
        <g id='shunt' transform='translate(482.878 -594.253)'>
          <path d='M0 792v-22.25h245.35' />
        </g>
        <Section3 />
        <Section4 />
      </g>
    </svg>
  );
}
export default Diagram;
