import { SiteControllerSourceDeviceCategories } from '@utils/generateTopic';
import React, {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HistorianDataPoint, PointField } from '@types';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import Button from '@components/_elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  styled,
} from '@mui/material';
import { Actions } from '@src/types/Actions';
import { GetHistorianPointNamesBody } from '@src/types/Historian';

interface QueryDropdownsProps {
  disabled: boolean;
  actions: Actions;
  cloneDataPoint: (i: number, dp: HistorianDataPoint) => void;
  removeDataPoint: (i: number) => void;
  updateDataPoint: (i: number, dp: HistorianDataPoint) => void;
  dp: HistorianDataPoint;
  i: number;
  categories: string[];
  siteMeta: SiteMeta;
  hideDataPointDropdown: boolean;
}

const s = {
  label: {
    paddingTop: 25,
  },
} as const;

const axisOptions = ['Y1', 'Y2'];

const StyledAutoComplete = styled(Autocomplete)(() => ({
  '& div.MuiInputBase-root.MuiOutlinedInput-root': {
    padding: 0,
  },
  '& .MuiAutocomplete-endAdornment .MuiButtonBase-root': {
    visibility: 'visible',
  },
  '& .MuiAutocomplete-endAdornment .MuiButtonBase-root.MuiAutocomplete-popupIndicator .MuiSvgIcon-root':
    {
      fill: '#0b0b0f',
    },
  '& + .MuiAutocomplete-popper .MuiAutocomplete-paper': {
    width: 'fit-content',
    whiteSpace: 'nowrap',
  },
}));

type Option = {
  label: string;
  value: string;
};

const renderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: unknown,
) => (
  <li {...props} key={(option as Option).value}>
    {(option as Option).label}
  </li>
);

const renderOptionUpperCased = (
  props: HTMLAttributes<HTMLLIElement>,
  option: unknown,
) => (
  <li {...props} key={option as string}>
    {(option as string).toUpperCase()}
  </li>
);

const renderInput = (label: string) => {
  function TextFieldComponent(params: AutocompleteRenderInputParams) {
    return (
      <TextField {...params} label={label} variant='standard' size='small' />
    );
  }

  return TextFieldComponent;
};

// eslint-disable-next-line max-lines-per-function
export function QueryDropdownsOnly(p: QueryDropdownsProps): ReactElement {
  // constants
  const categoryOptions = [...new Set(p.categories.map((cat) => cat))];
  const unitIdOptions: Option[] = p.siteMeta.Units.map((unitId, index) => ({
    label: p.siteMeta.UnitNames[index] ?? unitId,
    value: unitId,
  }));

  const [showUnitIdsDropdown, setShowUnitIdsDropdown] = useState(false);
  const [showSourceDeviceIdsDropdown, setShowSourceDeviceIdsDropdown] =
    useState(false);

  useEffect(() => {
    setShowUnitIdsDropdown(
      Boolean(p.dp.category) &&
        !SiteControllerSourceDeviceCategories.includes(p.dp.category),
    );
    setShowSourceDeviceIdsDropdown(
      Boolean(p.dp.category) && !['site', 'unit'].includes(p.dp.category),
    );
  }, [p.dp.category]);

  // state
  const [dataPointOptions, setDataPointOptions] = useState<PointField[]>([]);
  const [sourceDeviceIds, setSourceDeviceIds] = useState<string[]>([]);

  const dataPointInputOptions = useMemo<Option[]>(
    () =>
      dataPointOptions.map(({ pointName, displayName }) => ({
        label: displayName || pointName,
        value: pointName,
      })),
    [dataPointOptions],
  );
  const selectedUnitIdOption = useMemo(
    () =>
      unitIdOptions.find(
        (unitIdOption) => unitIdOption.value === p.dp.unitId,
      ) ?? null,
    [p.dp.unitId, unitIdOptions],
  );
  const selectedDataPointInputOption = useMemo(
    () =>
      dataPointInputOptions.find(
        (dataPointInputOption) =>
          (typeof p.dp.dataPoint === 'string'
            ? p.dp.dataPoint
            : p.dp.dataPoint.pointName) === dataPointInputOption.value,
      ) ?? null,
    [p.dp.dataPoint, dataPointInputOptions],
  );

  useEffect(() => {
    (async () => {
      if (!p.dp.category) {
        return;
      }

      const getPointNamesBody: GetHistorianPointNamesBody = {
        category: p.dp.category,
        controllerId: p.dp.unitId || p.dp.siteId,
        ...(p.dp.sourceDeviceId ? { sourceDeviceId: p.dp.sourceDeviceId } : {}),
      };

      const deviceIdsRes =
        await p.actions.getHistorianSourceDeviceIds(getPointNamesBody);
      setSourceDeviceIds(deviceIdsRes);
      const pointNamesRes =
        await p.actions.getHistorianPointNames(getPointNamesBody);
      setDataPointOptions(pointNamesRes);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p.dp.category]);

  const onChange = (field: string, value: string): void => {
    const updatedDataPoint = p.dp as HistorianDataPoint;
    switch (field) {
      case 'category':
        updatedDataPoint.category = value;
        updatedDataPoint.unitId = '';
        updatedDataPoint.sourceDeviceId = '';
        updatedDataPoint.dataPoint = '';
        break;
      case 'unitId':
        updatedDataPoint.unitId = value;
        break;
      case 'sourceDeviceId':
        updatedDataPoint.sourceDeviceId = value;
        break;
      case 'dataPoint':
        updatedDataPoint.dataPoint =
          dataPointOptions.find(({ pointName }) => pointName === value) ?? '';
        break;
      case 'axis':
        updatedDataPoint.axis = value;
        break;
      default:
        break;
    }
    p.updateDataPoint(p.i, updatedDataPoint);
  };

  return (
    <>
      <div role='historian-category-dropdown'>
        <StyledAutoComplete
          disablePortal
          options={categoryOptions}
          sx={{
            width: 150,
            '& .MuiInputBase-input': { textTransform: 'uppercase' },
          }}
          value={p.dp.category || null}
          onChange={(_, value) => onChange('category', value as string)}
          renderInput={renderInput('Category')}
          renderOption={renderOptionUpperCased}
        />
      </div>
      {showUnitIdsDropdown && (
        <div role='historian-unit-dropdown'>
          <StyledAutoComplete
            disablePortal
            options={unitIdOptions}
            sx={{ width: 150 }}
            value={selectedUnitIdOption}
            onChange={(_, value) => {
              onChange('unitId', (value as Option | null)?.value ?? '');
            }}
            renderOption={renderOption}
            renderInput={renderInput('Unit')}
          />
        </div>
      )}
      {showSourceDeviceIdsDropdown && (
        <div role='historian-source-device-id-dropdown'>
          <StyledAutoComplete
            disablePortal
            options={sourceDeviceIds}
            sx={{ width: 150 }}
            value={p.dp.sourceDeviceId || null}
            onChange={(_, value) => onChange('sourceDeviceId', value as string)}
            renderInput={renderInput(
              `${p.dp.category ? p.dp.category.toUpperCase() : ''} ID`,
            )}
          />
        </div>
      )}
      {!p.hideDataPointDropdown && (
        <div role='historian-point-name-dropdown'>
          <StyledAutoComplete
            disablePortal
            options={dataPointInputOptions}
            sx={{ width: 190 }}
            value={selectedDataPointInputOption}
            onChange={(_, value) => {
              onChange('dataPoint', (value as Option | null)?.value ?? '');
            }}
            renderOption={renderOption}
            renderInput={renderInput('Data Point')}
          />
        </div>
      )}
      <div role='historian-axis-dropdown'>
        <StyledAutoComplete
          disablePortal
          options={axisOptions}
          sx={{ width: 150 }}
          value={p.dp.axis || null}
          onChange={(_, value) => onChange('axis', value as string)}
          renderInput={renderInput('Axis')}
        />
      </div>
    </>
  );
}

function QueryDropdowns(p: QueryDropdownsProps): ReactElement {
  return (
    <>
      <div className='label' style={s.label}>
        <span className='icon'>DataPoint {p.i + 1}</span>
      </div>
      <div className='inputs-row dis'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <QueryDropdownsOnly {...p} />
          <Button
            className='round-active-color'
            disabled={p.disabled}
            title='Duplicate data point'
            onClick={() => p.cloneDataPoint(p.i, p.dp)}
          >
            <FontAwesomeIcon icon={faClone} />
          </Button>
          <Button
            className='round-active-color'
            title='Remove data point'
            onClick={() => p.removeDataPoint(p.i)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </div>
    </>
  );
}

export default storeConnector(QueryDropdowns, {
  config: ['siteMeta'],
});
