import Spinner from '@components/_elements/Spinner/Spinner';
import MPDetailsModal from '@components/Battery/Tesla/MPDetailsModal';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { SiteMeta } from '@src/types/SiteMeta';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import storeConnector from '@store/storeConnector';
import React, { useEffect, useMemo, useState } from 'react';
import UIBox from '@components/_shared/UIBox';
import DeviceDataTable, {
  DeviceDataTableRow,
} from '@components/_shared/DeviceDataTable';
import useSubscription from '@hooks/useSubscription';
import { useCurrentTscIdContext } from '@hooks/useCurrentTscId';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';
import MPCommandsModal from './Commands/MP/MPCommandsModal';

interface ListContentProps {
  currentTscId: string;
  siteMeta: SiteMeta;
  onRowClick: ({ id }: DeviceDataTableRow) => void;
  openCmdModal: (id: string) => void;
  role: UserRole;
}

function ListContent({
  currentTscId,
  siteMeta,
  onRowClick,
  openCmdModal,
  role,
}: ListContentProps) {
  const deviceNameDictionary = useMemo(
    () =>
      Array.from(new Array(siteMeta.NumMegapacks)).map((_e, i) => ({
        id: `${currentTscId}_mp_${i + 1}`,
        name: `MP${i + 1}`,
      })),
    [currentTscId, siteMeta],
  );

  const {
    data: widgetConfig,
    isLoading,
    refetch,
  } = useGetWidgetConfig<WidgetTypeDeviceList>('mpList', {
    sourceDeviceId: currentTscId,
  });

  const widgetConfigDatapoints = widgetConfig?.dataPoints
    ? widgetConfig?.dataPoints
    : [];

  useEffect(() => {
    refetch();
  }, [currentTscId, refetch]);

  const megaDataPointsValues = useSubscription(widgetConfigDatapoints);

  return isLoading ? (
    <Spinner type='content-small' />
  ) : (
    <DeviceDataTable
      enableCmdBtnColumn={
        siteMeta.ui.Command_Access_Enable &&
        !process.env.VITE_READ_ONLY_MODE &&
        isActionRole(role)
      }
      hideFooter
      data={megaDataPointsValues}
      disableRowSelectionOnClick
      openCmdModal={openCmdModal}
      onRowClick={onRowClick}
      getRowClassName={() => 'MuiDataGrid-clickable'}
      deviceNameDictionary={deviceNameDictionary}
    />
  );
}

// eslint-disable-next-line max-lines-per-function
function MegapackList({
  siteMeta,
  role,
}: {
  siteMeta: SiteMeta;
  role: UserRole;
}) {
  const { currentTscId } = useCurrentTscIdContext();
  const [cmdModal, setCmdModal] = useState(false);
  const [mpDetailsModal, setMpDetailsModal] = useState(false);
  const [currentMP, setCurrentMP] = useState<string>('');

  const tscName = useMemo(
    () =>
      siteMeta.TeslaSiteControllerNames[
        siteMeta.TeslaSiteControllers.indexOf(currentTscId)
      ],
    [siteMeta, currentTscId],
  );

  return (
    <UIBox header={`${tscName} Megapack List`}>
      <ListContent
        role={role}
        siteMeta={siteMeta}
        currentTscId={currentTscId}
        onRowClick={({ id }: DeviceDataTableRow) => {
          setCurrentMP(id);
          setMpDetailsModal(true);
        }}
        openCmdModal={(deviceId) => {
          setCurrentMP(deviceId);
          setCmdModal(true);
        }}
      />
      <MPCommandsModal
        tscName={tscName}
        megapackId={currentMP}
        onClose={() => setCmdModal(false)}
        open={cmdModal}
      />
      <MPDetailsModal
        selectedMpId={currentMP}
        tscName={tscName}
        open={mpDetailsModal}
        onClose={() => setMpDetailsModal(false)}
      />
    </UIBox>
  );
}

export default storeConnector(MegapackList, {
  config: ['siteMeta'],
  user: ['role'],
});
