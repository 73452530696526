import React, { Suspense } from 'react';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import SwitchPCSContainer from '@components/_shared/PCSContainers/SwitchPCSContainer';

function PCS({ siteMeta }: { siteMeta: SiteMeta }) {
  const pcsCount = Number(siteMeta.Batt_perUnit_PCS_count ?? 0);
  return (
    <Suspense fallback={<Spinner type='fullPage' />}>
      {Array.from({ length: pcsCount }, (_, index) => (
        <SwitchPCSContainer
          pcsType={siteMeta.PCS_type}
          key={`pcs-section-${index}`}
          pcsIndex={index}
        />
      ))}
    </Suspense>
  );
}

export default storeConnector(PCS, {
  config: ['siteMeta'],
});
