import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

export default (sortField?: string): GridInitialStatePro => ({
  pagination: {
    paginationModel: { pageSize: 500 },
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ['actions'],
  },
  density: 'comfortable',
  sorting: {
    sortModel: [{ field: sortField || '', sort: 'desc' }],
  },
});
