import { useEffect } from 'react';

function useMakePageScrollable(): void {
  useEffect(() => {
    document.getElementById('app-layout-content')?.classList.add('scrollable');
    return () =>
      document
        .getElementById('app-layout-content')
        ?.classList.remove('scrollable');
  }, []);
}

export default useMakePageScrollable;
