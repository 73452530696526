import React from 'react';
import { SiteMeta } from '@src/types/SiteMeta';
import { CustomLink } from '@src/types/SiteMetaUI';
import { KEYCLOAK_URL } from '@utils';
import { UserState } from '@store/reducers/userData';
import storeConnector from '@store/storeConnector';
import { isAdmin } from '@src/services/auth';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '@src/services/auth/roles';

interface UserPanelLinksProps {
  keycloak: UserState['keycloak'];
  showSettings: () => void;
  showSupport: () => void;
  siteMeta: SiteMeta;
  role: UserRole;
}

function UserPanelLinks({
  keycloak,
  showSettings,
  showSupport,
  siteMeta,
  role,
}: UserPanelLinksProps) {
  const navigate = useNavigate();
  return (
    <div className='links'>
      <div
        onClick={() => {
          window.location.href = keycloak?.createAccountUrl() || '#';
        }}
      >
        Manage Profile
      </div>
      {role && isAdmin(role) && (
        <div onClick={() => navigate('/admin/users', { replace: true })}>
          Manage Users
        </div>
      )}
      <div onClick={() => navigate('/admin/audit-logs', { replace: true })}>
        Audit Logs
      </div>
      <div onClick={() => showSettings()}>Settings</div>
      <div onClick={() => showSupport()}>Support</div>
      {role && isAdmin(role) && (
        <>
          <div
            style={{ marginTop: '30px' }}
            onClick={() => {
              window.location.href = `${KEYCLOAK_URL}admin/fractal/console/#/fractal`;
            }}
          >
            Keycloak
          </div>
          <div
            onClick={() => {
              window.location.href = `${document.location.origin}:8086/`;
            }}
          >
            InfluxDB
          </div>
        </>
      )}
      {(siteMeta.ui.custom_links || []).map((customLink: CustomLink) => (
        <div
          key={`user-custom-link-${customLink.display_name}`}
          onClick={() => {
            window.location.href = customLink.url;
          }}
        >
          {customLink.display_name}
        </div>
      ))}
    </div>
  );
}

export default storeConnector(UserPanelLinks, {
  user: ['role', 'keycloak'],
  config: ['siteMeta'],
});
