import { TextField } from '@mui/material';
import { AddUserFormValues } from '@src/hooks/api/mutations/useUserCreate';
import { EditUserFormValues } from '@src/hooks/api/mutations/useUserUpdate';
import React from 'react';
import { useFormContext } from 'react-hook-form';

function LastNameField() {
  const { register } = useFormContext<EditUserFormValues | AddUserFormValues>();

  return (
    <TextField
      fullWidth
      label='Last Name'
      {...register('lastName')}
      size='small'
    />
  );
}

export default LastNameField;
