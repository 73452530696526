import { store } from '@store';
import { Actions } from '@src/types/Actions';
import { ALERTS_SERVICE_URL } from '@utils';
import { AckAlertDto } from '@src/types/Alerts';

const AlertsActions: {
  acknowledgeAlarm: Actions['acknowledgeAlarm'];
} = {
  acknowledgeAlarm(params: AckAlertDto) {
    return async function ack() {
      const res = await fetch(`${ALERTS_SERVICE_URL}/alerts/ack`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${store.getState().user.keycloak?.token}`,
        },
        body: JSON.stringify(params),
      }).then((response) => response.json());
      if (res.error !== undefined) {
        throw res.error?.message;
      }
    };
  },
};

export default AlertsActions;
