import { AlertEntity } from '@src/types/Alerts';
import { ALERTS_SERVICE_URL } from '@utils';
import { dispatcher, store } from '@store';

export default async function loadAlertsData() {
  await fetch(`${ALERTS_SERVICE_URL}/alerts/active`, {
    headers: {
      authorization: `Bearer ${store.getState().user.keycloak?.token}`,
    },
  })
    .then((res) => res.json())
    .then((activeAlerts: AlertEntity[]) => {
      if (Array.isArray(activeAlerts)) {
        store.dispatch(
          dispatcher('UPDATE_ACTIVE_ALERTS', {
            setAlerts: activeAlerts,
          }),
        );
      }
    })
    .catch((err) => {
      console.error(err);
    });

  const state = store.getState();
  const client = state.mqtt.clientMQTT;
  if (!client) {
    return;
  }
  const TOPIC = 'site/alerts';
  client.subscribe(TOPIC);
  client.on('message', (topic, message) => {
    if (topic === TOPIC) {
      const newAlert = JSON.parse(message.toString()) as AlertEntity & {
        isActive: boolean;
      };
      if (!newAlert.isActive) {
        store.dispatch(
          dispatcher('UPDATE_ACTIVE_ALERTS', {
            removeAlert: newAlert,
          }),
        );
      } else {
        store.dispatch(
          dispatcher('UPDATE_ACTIVE_ALERTS', { addAlert: newAlert }),
        );
      }
    }
  });
}
