import storeConnector from '@store/storeConnector';
import React from 'react';
import './DroopCurves.scoped.scss';
const VIEW_BOX_WIDTH = 340;

const DroopCurves = ({ config, darkTheme = {} }) => {
  const color = darkTheme ? '#fff' : '#999';
  let c = null;
  let description = null;
  //h - helper
  let h = {};
  let axis = {};
  const pointsValues = [];
  let points = [];
  let physicalChartToAxesDistance;
  if (config) {
    description = config.description;
    c = config.body;
    if (description === 'Frequency Response') {
      h = {
        MaxCharge: +c['Power Settings']['Max Charge (kW)'],
        MaxDischarge: +c['Power Settings']['Max Discharge (kW)'],
        Nominal: +c['Frequency Setpoints']['Nominal Frequency (Hz)'],
        DroopSlopeHigh:
          +c['Droop Setpoints (Hz/kW)']['Droop Slope High (Hz/kW)'],
        DroopSlopeLow: +c['Droop Setpoints (Hz/kW)']['Droop Slope Low (Hz/kW)'],
        LowerDeadband: +c['Frequency Setpoints']['Lower Deadband (Hz)'],
        UpperDeadband: +c['Frequency Setpoints']['Upper Deadband (Hz)'],
        DropOffsetLow: +c['Droop Setpoints (Hz/kW)']['Droop Offset Low (kW)'],
        DropOffsetHigh: +c['Droop Setpoints (Hz/kW)']['Droop Offset High (kW)'],
      };
      const fDevLow =
        h.MaxDischarge * (h.DroopSlopeLow * (h.Nominal / h.MaxDischarge));
      const fDevHigh =
        h.MaxCharge * (h.DroopSlopeHigh * (h.Nominal / h.MaxCharge));

      axis.xAxisLength = fDevLow + fDevHigh + 0.2;
      axis.minXAxis = h.Nominal - fDevLow - 0.1;
      axis.maxXAxis = h.Nominal + fDevHigh + 0.1;
      axis.middleLabel = (axis.minXAxis + axis.maxXAxis) / 2.0;
      axis.maxYAxis =
        1.1 * Math.max(Math.abs(h.MaxDischarge), Math.abs(h.MaxCharge));
      axis.minYAxis = -axis.maxYAxis;
      axis.yAxisLength = axis.maxYAxis - axis.minYAxis;

      pointsValues.push({ x: h.Nominal - fDevLow, y: h.MaxDischarge });
      pointsValues.push({ x: h.LowerDeadband, y: h.DropOffsetLow });
      pointsValues.push({ x: h.LowerDeadband, y: 0 });
      pointsValues.push({ x: h.Nominal, y: 0 });
      pointsValues.push({ x: h.UpperDeadband, y: 0 });
      pointsValues.push({ x: h.UpperDeadband, y: -h.DropOffsetHigh });
      pointsValues.push({ x: h.Nominal + fDevHigh, y: h.MaxCharge });
    } else if (description === 'Voltage Response') {
      h = {
        Nominal: +c['Voltage Setpoints']['Nominal Voltage (V)'],
        LowerDeadband: +c['Voltage Setpoints']['Lower Deadband (V)'],
        UpperDeadband: +c['Voltage Setpoints']['Upper Deadband (V)'],
        MaxPositive: +c['Power Settings']['Max Positive (kVAr)'],
        MaxNegative: +c['Power Settings']['Max Negative (kVAr)'],
        DroopSlopeHigh:
          +c['Droop Setpoints (V/kVAr)']['Droop Slope High (V/kVAr)'],
        DroopSlopeLow:
          +c['Droop Setpoints (V/kVAr)']['Droop Slope Low (V/kVAr)'],
        DropOffsetLow:
          +c['Droop Setpoints (V/kVAr)']['Droop Offset Low (kVAr)'],
        DropOffsetHigh:
          +c['Droop Setpoints (V/kVAr)']['Droop Offset High (kVAr)'],
      };
      const devHigh =
        h.MaxNegative * (h.DroopSlopeHigh * (h.Nominal / h.MaxNegative));
      const devLow =
        h.MaxPositive * (h.DroopSlopeLow * (h.Nominal / h.MaxPositive));

      axis.xAxisLength = devLow + devHigh + 0.02 * h.Nominal;
      axis.minXAxis = h.Nominal - devLow - 0.01 * h.Nominal;
      axis.maxXAxis = h.Nominal + devHigh + 0.01 * h.Nominal;
      axis.maxYAxis =
        1.1 * Math.max(Math.abs(h.MaxPositive), Math.abs(h.MaxNegative));
      axis.minYAxis = -axis.maxYAxis;
      axis.xScale = (axis.maxXAxis - axis.minXAxis) / 100.0;
      axis.middleLabel = (axis.minXAxis + axis.maxXAxis) / 2.0;
      axis.yAxisLength = axis.maxYAxis - axis.minYAxis;

      pointsValues.push({ x: h.Nominal - devLow, y: h.MaxPositive });
      pointsValues.push({ x: h.LowerDeadband, y: h.DropOffsetLow });
      pointsValues.push({ x: h.LowerDeadband, y: 0 });
      pointsValues.push({ x: h.Nominal, y: 0 });
      pointsValues.push({ x: h.UpperDeadband, y: 0 });
      pointsValues.push({ x: h.UpperDeadband, y: -h.DropOffsetHigh });
      pointsValues.push({ x: h.Nominal + devHigh, y: h.MaxNegative });
    } else if (description === 'SOC Rebalance') {
      h = {
        TargetSOC: +c['SOC Rebalance Parameters']['Target SOC'],
        DeadbandLowerThreshold:
          +c['SOC Rebalance Parameters']['Deadband Lower Threshold'],
        DeadbandUpperThreshold:
          +c['SOC Rebalance Parameters']['Deadband Upper Threshold'],
        MaxCharge: -+c['SOC Rebalance Parameters']['Max Charge kW'],
        MaxDischarge: +c['SOC Rebalance Parameters']['Max Discharge kW'],
        MaxLowerThreshold:
          +c['SOC Rebalance Parameters']['Max Lower Threshold'],
        MaxUpperThreshold:
          +c['SOC Rebalance Parameters']['Max Upper Threshold'],
        MinCharge: -+c['SOC Rebalance Parameters']['Min Charge kW'],
        MinDischarge: +c['SOC Rebalance Parameters']['Min Discharge kW'],
        MinLowerThreshold:
          +c['SOC Rebalance Parameters']['Min Lower Threshold'],
        MinUpperThreshold:
          +c['SOC Rebalance Parameters']['Min Upper Threshold'],
      };
      axis.xAxisLength = h.MaxUpperThreshold - h.MaxLowerThreshold;
      axis.minXAxis = h.MaxLowerThreshold;
      axis.maxXAxis = h.MaxUpperThreshold;
      axis.maxYAxis = h.MaxDischarge;
      axis.minYAxis = h.MaxCharge;
      axis.yAxisLength = h.MaxDischarge - h.MaxCharge;
      axis.xScale = (axis.maxXAxis - axis.minXAxis) / 100.0;
      axis.middleLabel = h.TargetSOC;

      pointsValues.push({ x: h.MaxLowerThreshold, y: h.MaxCharge });
      pointsValues.push({ x: h.MinLowerThreshold, y: h.MinCharge });
      pointsValues.push({ x: h.DeadbandLowerThreshold, y: 0 });
      pointsValues.push({ x: h.TargetSOC, y: 0 });
      pointsValues.push({ x: h.DeadbandUpperThreshold, y: 0 });
      pointsValues.push({ x: h.MinUpperThreshold, y: h.MinDischarge });
      pointsValues.push({ x: h.MaxUpperThreshold, y: h.MaxDischarge });
    } else {
      c = null;
    }

    if (c) {
      physicalChartToAxesDistance = (15 * axis.xAxisLength) / 100;

      pointsValues.unshift({
        x: axis.minXAxis - physicalChartToAxesDistance,
        y: pointsValues[0].y,
      });
      pointsValues.push({
        x: axis.maxXAxis + physicalChartToAxesDistance,
        y: pointsValues[pointsValues.length - 1].y,
      });

      points = pointsValues.map((p) => ({
        x: p.x - axis.minXAxis,
        y: axis.yAxisLength / 2.0 - p.y,
        xValue: p.x,
        yValue: p.y,
      }));
    }
  }
  const ratio = (axis.xAxisLength * 1) / axis.yAxisLength;
  setTimeout(() => {
    const syncHeight = () => {
      const container = document.querySelector('.block-container-droop');
      if (container) {
        container.style.width =
          document.querySelector('.block-container-droop').clientHeight + 'px';
        document.querySelector('.system-right').style.width =
          container.style.width;
      }
    };
    syncHeight();
    window.onresize = syncHeight;
  });
  const units = {
    'Frequency Response': [' kW', ' Hz'],
    'Voltage Response': ['kvar', 'V'],
    'SOC Rebalance': ['  kW', '%'],
  };

  return (
    c && (
      <div
        className='block-container block-container-droop cell'
        id='droop-curves'
      >
        <svg className='config-chart-axes'>
          <defs>
            <marker
              id='arrow'
              viewBox='0 0 10 10'
              refX='0'
              refY='3'
              markerWidth='6'
              markerHeight='6'
              orient='auto'
              markerUnits='strokeWidth'
            >
              <path d='M0,0 L0,6 L9,3 z' fill={color} />
            </marker>
            <marker
              id='arrowDown'
              viewBox='0 0 10 10'
              refX='0'
              refY='3'
              markerWidth='6'
              markerHeight='6'
              orient='90deg'
              markerUnits='strokeWidth'
            >
              <path d='M0,0 L0,6 L9,3 z' fill={color} />
            </marker>
          </defs>
          <line
            x1='0%'
            y1='50%'
            x2='100%'
            y2='50%'
            stroke={color}
            strokeWidth='2'
            markerEnd='url(#arrow)'
          />
          <line
            x1='0%'
            y1='100%'
            x2='0%'
            y2='0%'
            stroke={color}
            strokeWidth='2'
            markerEnd='url(#arrow)'
            markerStart='url(#arrowDown)'
          />

          <text x='10px' y='0%' fill={color} textAnchor='start'>
            {units[description] ? units[description][0] : ''}
          </text>
          <text x='100%' y='47.5%' fill={color} textAnchor='middle'>
            {units[description] ? units[description][1] : ''}
          </text>
          <text x='-5px' y='51.5%' fill={color} textAnchor='end'>
            0
          </text>

          <text
            x='0'
            y='0'
            fill={color}
            textAnchor='start'
            fontSize='13'
            transform='translate(6, 39)'
          >
            {axis.maxYAxis.toFixed(1)}
          </text>
          <text
            x='0'
            y='100%'
            fill={color}
            textAnchor='start'
            fontSize='13'
            transform='translate(6, -31)'
          >
            {axis.minYAxis.toFixed(1)}
          </text>
          {description !== 'SOC Rebalance' ? (
            <>
              <text
                x='0'
                y='50%'
                fill={color}
                textAnchor='middle'
                fontSize='13'
                transform='translate(35, 15)'
              >
                {axis.minXAxis.toFixed(1)}
              </text>
              {axis.middleLabel && (
                <text
                  x='50%'
                  y='50%'
                  fill={color}
                  textAnchor='middle'
                  fontSize='13'
                  transform='translate(0, 15)'
                >
                  {axis.middleLabel.toFixed(1)}
                </text>
              )}
              <text
                x='100%'
                y='50%'
                fill={color}
                textAnchor='middle'
                fontSize='13'
                transform='translate(-35, 15)'
              >
                {axis.maxXAxis.toFixed(1)}
              </text>
            </>
          ) : (
            <>
              {JSON.parse(JSON.stringify(points))
                .slice(1, -1)
                .map((p, i) => (
                  <text
                    x={p.x * (VIEW_BOX_WIDTH / axis.xAxisLength)}
                    y='50%'
                    fill={color}
                    key={i}
                    textAnchor='middle'
                    fontSize='10'
                    transform='translate(35, 15)'
                  >
                    {+(p.x + axis.minXAxis).toFixed(1)}
                  </text>
                ))}
            </>
          )}
          <rect
            x='0'
            y='0%'
            width='8'
            height='1'
            transform='translate(-4,35)'
            fill={color}
            stroke={color}
          />
          <rect
            x='0'
            y='100%'
            width='8'
            height='1'
            transform={'translate(-4,-35)'}
            fill={color}
            stroke={color}
          />
          {description !== 'SOC Rebalance' ? (
            <>
              <rect
                x='0%'
                y='50%'
                width='1'
                height='8'
                transform={'translate(35,-4)  '}
                fill={color}
                stroke={color}
              />
              <rect
                x='50%'
                y='50%'
                width='1'
                height='8'
                transform='translate(0,-4)'
                fill={color}
                stroke={color}
              />
              <rect
                x='100%'
                y='50%'
                width='1'
                height='8'
                transform='translate(-35,-4)'
                fill={color}
                stroke={color}
              />
            </>
          ) : (
            <>
              {JSON.parse(JSON.stringify(points))
                .slice(1, -1)
                .map((p, i) => (
                  <rect
                    key={i}
                    x={p.x * (VIEW_BOX_WIDTH / axis.xAxisLength)}
                    y='50%'
                    width='1'
                    height='8'
                    transform={'translate(35,-4)'}
                    fill={color}
                    stroke={color}
                  />
                ))}
            </>
          )}
        </svg>
        <div className='config-chart-area-wrapper'>
          <svg
            className='config-chart-area'
            preserveAspectRatio='none'
            viewBox={`0 0 ${axis.xAxisLength} ${axis.yAxisLength}`}
          >
            <path
              d={`M${points[0].x} ${points[0].y} L${points[1].x} ${
                points[1].y
              } L${points[2].x} ${points[2].y} L${points[3].x} ${
                points[3].y
              } L${-physicalChartToAxesDistance} ${axis.yAxisLength / 2.0}  Z`}
              className='fill-color-1'
            />
            <path
              d={`M${points[5].x} ${points[5].y} L${points[6].x} ${
                points[6].y
              } L${points[7].x} ${points[7].y} L${points[8].x} ${
                points[8].y
              } L${axis.xAxisLength + physicalChartToAxesDistance} ${
                axis.yAxisLength / 2.0
              }  Z`}
              className='fill-color-3'
            />
            {points.map((p, i) => {
              let color;
              if (i < 3) {
                color = '1';
              } else if (i >= 5) {
                color = '3';
              } else {
                color = '2';
              }
              return i + 1 !== points.length ? (
                <line
                  x1={p.x}
                  y1={p.y}
                  x2={points[i + 1].x}
                  y2={points[i + 1].y}
                  className={'line-color-' + color}
                  vectorEffect='non-scaling-stroke'
                  strokeWidth='2'
                  key={`line-${i}`}
                />
              ) : (
                ''
              );
            })}
            {points.reduce((acc, p, i) => {
              if (i !== 0 && i !== points.length - 1) {
                acc.push(
                  <circle
                    cx={p.x / ratio}
                    cy={p.y}
                    transform={`scale(${ratio}, 1)`}
                    r={axis.yAxisLength / 100.0}
                    fill={color}
                    key={`point-${i}`}
                    cursor='pointer'
                  >
                    <title key={`title-${i}`}>
                      {`${+p.yValue.toFixed(3)}${
                        units[description] ? units[description][0] : ''
                      } / ${+p.xValue.toFixed(3)}${
                        units[description] ? units[description][1] : ''
                      }`}
                    </title>
                  </circle>,
                );
              }
              return acc;
            }, [])}
          </svg>
        </div>
      </div>
    )
  );
};

export default storeConnector(DroopCurves, {
  service: ['darkTheme'],
});
