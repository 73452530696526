import { UnitConfig } from '@src/sld/types';

const noData = 'missing data';

const unitConfigWithNoData: UnitConfig = {
  unitLabel: noData,
  kw: noData,
  kvar: noData,
  soc: noData,
  racksonline: noData,
  invstatus: noData,
  runmode: noData,
};

const getUnitConfig = (unitConfigItem: UnitConfig): UnitConfig =>
  unitConfigItem !== undefined ? unitConfigItem : unitConfigWithNoData;

// eslint-disable-next-line import/prefer-default-export
export { getUnitConfig };
