import React, { Fragment, useState } from 'react';
import storeConnector from '@store/storeConnector';
import { useWsSubscribe } from '@src/store/actionCreators/mqtt';
import { SiteMeta } from '@src/types/SiteMeta';
import { Actions } from '@src/types/Actions';

const isUnitFault = (prevStatus?: string, newStatus?: string): boolean =>
  prevStatus === 'RunPQ' &&
  Boolean(newStatus) &&
  newStatus !== 'Off' &&
  newStatus !== 'RunPQ';

function UnitFaultNotification({
  unitSn,
  onUnitFault,
}: {
  unitSn: string;
  onUnitFault: (prevStatus: string, newStatus: string) => void;
}) {
  const [, setUnitStatus] = useState<string>();

  useWsSubscribe({
    unit: {
      sns: [unitSn],
      fields: ['InvStatus'],
      cb: (data: Record<string, string>) => {
        const newStatus = data.InvStatus;
        if (!newStatus) {
          return;
        }

        setUnitStatus((prevStatus) => {
          if (isUnitFault(prevStatus, newStatus)) {
            onUnitFault(prevStatus!, newStatus);
          }

          return newStatus;
        });
      },
    },
  });

  return null;
}

function UnitsFaultNotifications({
  actions,
  siteMeta,
  audioNotificationEnabled,
  bannerNotificationEnabled,
}: {
  actions: Actions;
  siteMeta: SiteMeta;
  audioNotificationEnabled: boolean;
  bannerNotificationEnabled: boolean;
}) {
  const [audio] = useState(new Audio('/audio/unit-fault3.wav'));

  if (!audioNotificationEnabled && !bannerNotificationEnabled) {
    return null;
  }

  const handleUnitFault =
    (unitIndex: number) => (prevStatus: string, newStatus: string) => {
      if (bannerNotificationEnabled) {
        actions.notifyError(
          `Unit ${siteMeta.UnitNames[unitIndex]} has changed from ${prevStatus} to ${newStatus}`,
          3600_000,
        );
      }
      if (audioNotificationEnabled) {
        audio.play().then();
      }
    };
  return (
    <>
      {siteMeta.Units.map((unitSn, index) => (
        <UnitFaultNotification
          key={`unit-fault-alarm-${unitSn}`}
          unitSn={unitSn}
          onUnitFault={handleUnitFault(index)}
        />
      ))}
    </>
  );
}

export default storeConnector(UnitsFaultNotifications, {
  config: ['siteMeta'],
  service: ['audioNotificationEnabled', 'bannerNotificationEnabled'],
});
