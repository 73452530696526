import { store } from '@store';

const notify_ = ({
  type,
  message,
  dispatch,
  yesCallBack,
  noCallBack,
  interval,
}) => {
  const t = [...store.getState().service.tmpNotifications];
  if (t.length > 10) {
    return;
  }

  const n = {
    message,
    type: type,
    className: 'hidden',
    id: '' + Math.random(),
    yesCallBack,
    noCallBack,
  };

  t.push(n);
  dispatch({
    type: 'UPDATE_SERVICE_MODULE',
    tmpNotifications: t,
  });

  const refreshState = () => {
    dispatch({
      type: 'UPDATE_SERVICE_MODULE',
      tmpNotifications: [...store.getState().service.tmpNotifications],
    });
  };

  setTimeout(() => {
    n.className = '';
    refreshState();
  }, 1);
  setTimeout(
    () => {
      n.className = 'removing';
      refreshState();

      setTimeout(() => {
        const tmpNotifications = store
          .getState()
          .service.tmpNotifications.filter((v) => v.id !== n.id);
        dispatch({ type: 'UPDATE_SERVICE_MODULE', tmpNotifications });
      }, 1_000);
    },
    interval > 0 ? interval : 5_000,
  );
};

export const notify = (message) => {
  return function (dispatch) {
    setTimeout(() => {
      notify_({ message, type: 'info', dispatch });
    });
  };
};

export const askYesNo = (message, yesCallBack, noCallBack) => {
  return function (dispatch) {
    setTimeout(() => {
      notify_({ message, type: 'info', dispatch, yesCallBack, noCallBack });
    });
  };
};

export const notifyError = (message, interval) => {
  return function (dispatch) {
    setTimeout(() => {
      notify_({ message, type: 'error', dispatch, interval });
    });
  };
};

export const closeNotification = (notificationId) => {
  return function (dispatch) {
    const refreshState = () => {
      dispatch({
        type: 'UPDATE_SERVICE_MODULE',
        tmpNotifications: [...store.getState().service.tmpNotifications],
      });
    };

    const n = store
      .getState()
      .service.tmpNotifications.find((v) => v.id === notificationId);
    n.className = 'removing';
    refreshState();

    setTimeout(() => {
      const tmpNotifications = store
        .getState()
        .service.tmpNotifications.filter((v) => v.id !== n.id);
      dispatch({ type: 'UPDATE_SERVICE_MODULE', tmpNotifications });
    }, 1000);
  };
};
